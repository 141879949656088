import close_icon from "../assets/icons/close-icon.svg";

const ModalClose = ({ onClick }) => {
    return (
        <div className="modal-close">
            <button onClick={onClick} className="modal-close__btn">
                <img src={close_icon} alt="close icon" />
            </button>
        </div>
    );
};

export default ModalClose;
