import Types from "../constants";

const {
    airtimeTypes: { SET_AIRTIME_FORM },
} = Types;

export const setAirtimeForm = (payload) => {
    return {
        type: SET_AIRTIME_FORM,
        payload: payload,
    };
};
