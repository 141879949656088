import { useEffect, useState } from "react";
import "./questionsInput.scss";
import "./date.scss";
import { invertDateFormat } from "../../../../helpers/invertDateFormat.js";

const DateComponent = (props) => {
    const [answer, setAnswer] = useState((props.answer) ? invertDateFormat(props.answer) : invertDateFormat(""));

    useEffect(() => {
        setAnswer((props.answer) ? invertDateFormat(props.answer) : invertDateFormat(""));
    }, [props.id]);

    useEffect(() => {
        setData();
    }, [answer]);

    const checkForNextRoute = () => {
        let nextRoute = props.next_route;
        return nextRoute;
    }
    const handleChange = (date) => {
        setAnswer(date);
    }
    const setData = () => {
        props.onchange({
            answer: invertDateFormat(answer),
            nextRoute: checkForNextRoute()
        });
    }

    return (
        <div>
            <input style={{ width: "250px", height: '40px' }} type="date" placeholder="Select date" value={`${answer}`} onChange={(e) => { handleChange(e.target.value) }} />
        </div>
    )
}

export default DateComponent;