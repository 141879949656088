import { useHistory, NavLink } from 'react-router-dom';
import Container from '../components/Container';
import { FaAngleLeft } from 'react-icons/fa';
import UserProfileHeader from '../components/UserProfileHeader/UserProfileHeader';

const MorePageLayout = ({ header, children, page_title }) => {
    const history = useHistory();

    return (
        <div className="morepage-layout">
            <Container>
                <h2
                    onClick={() => history.goBack()}
                    className="morepage-layout__title mb-4"
                >
                    <FaAngleLeft className="morepage-layout__title-icon" />
                    <span>{page_title}</span>
                </h2>
                <div className="morepage-layout__header">
                    <UserProfileHeader />
                    {header}
                </div>
                <div className="morepage-layout__content">
                    <div className="morepage-layout__content-desktop">
                        <div className="morepage-layout__content-desktop__header">
                            <NavLink
                                activeClassName="active"
                                to={{ pathname: `/membership` }}
                                className="morepage-layout__content-desktop__header-link"
                            >
                                Membership & Points
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={{ pathname: `/leaderboard` }}
                                className="morepage-layout__content-desktop__header-link"
                            >
                                Leaderboard
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={{ pathname: `/settings` }}
                                className="morepage-layout__content-desktop__header-link"
                            >
                                Settings
                            </NavLink>
                        </div>
                        <div className="morepage-layout__content-desktop__content">
                            {children}
                        </div>
                    </div>
                    <div className="morepage-layout__content-mobile">
                        {children}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MorePageLayout;
