import { useState, useEffect } from 'react';
//import packageJson from '../../package.json';
global.appVersion = localStorage.getItem("currentAppVersion") || "0.1.0";

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
    let run = 0
    while (run !== 1) {
        run = 1
        const a = versionA;
        const b = versionB;
        if (a === b) {
            continue;
        }
        localStorage.setItem("currentAppVersion", a);
        return true
    }
    return false;
};

const refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
        });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
}

const CacheBuster = (props) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
            fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.version;
                    const currentVersion = global.appVersion;

                    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        console.log(`We have a new version - ${latestVersion}.Should force refresh`);
                        refreshCacheAndReload();
                    } else {
                        setLoading(false);
                        console.log(`You already have the latest version - ${latestVersion}.No cache refresh needed.`);
                    }
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (<div>
        {!loading ? (
            <div>
                {props.children}</div>
        ) : null}
    </div>
    );
}

export default CacheBuster;