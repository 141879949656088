import rightArrow from './right-arrow.svg';
import bankTransfer from './bank-transfer-icon.svg';
import nairaCurrency from './hashtag-naira-currency.svg';
import arrowLeft from './arrow-left.svg'

export const icons = {
    rightArrow,
    bankTransfer,
    nairaCurrency,
    arrowLeft
}