import { useEffect, useState } from "react";
import { unSubscribeEmail } from "../../services/dashboard.service";

const EmailUnsubscribeLogic = () => {
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        setIsUnsubscribing(true);
        unSubscribeEmail().then(() => {
            setIsUnsubscribing(false)
        }).catch((error) => {
            setIsUnsubscribing(false)
            if (error.response === undefined) return
            if (error.response.status === 400) setErrorMessage(error.response.data.detail)
        })
    }, [])

    return { isUnsubscribing, errorMessage }
}

export default EmailUnsubscribeLogic