import Types from "../constants";

import {
    fetchSurveyRequest,
    fetchSurveySuccess,
    fetchSurveyFailure,
    submitSurveySuccess,
    submitSurveyFailure,
    submitSurveyRequest,
    fetchPollsRequest,
    fetchPollsSuccess,
    fetchPollsFailure,
} from "../actions/survey.action";

import { fetchSurvey, fetchPolls } from "../../services/survey.service";


const {
    FETCH_SURVEY_REQUEST,
    FETCH_SURVEY_SUCCESS,
    FETCH_SURVEY_FAILURE,
    SUBMIT_SURVEY_REQUEST,
    SUBMIT_SURVEY_SUCCESS,
    SUBMIT_SURVEY_FAILURE,
    FETCH_POLLS_REQUEST,
    FETCH_POLLS_SUCCESS,
    FETCH_POLLS_FAILURE,
} = Types.surveyTypes;

const initialState = {
    loading: false,
    surveys: [],
    polls: [],
    surveyPagination: {},
    pollsPagination: {},
    surveyDetails: { questions: [{}], max_points: 0 },
};

export const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SURVEY_REQUEST:
            return { ...state, loading: true };
        case FETCH_SURVEY_SUCCESS:
            return { ...state, loading: false, surveys: action.payload.data, surveyPagination: action.payload.meta };
        case FETCH_SURVEY_FAILURE:
            return { ...state, loading: false };
        case SUBMIT_SURVEY_REQUEST:
            return { ...state };
        case SUBMIT_SURVEY_SUCCESS:
            return { ...state };
        case SUBMIT_SURVEY_FAILURE:
            return { ...state };
        case FETCH_POLLS_REQUEST:
            return { ...state, loading: true };
        case FETCH_POLLS_SUCCESS:
            return { ...state, loading: false, polls: action.payload.data, pollsPagination: action.payload.meta };
        case FETCH_POLLS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const getSurvey = (page) => {
    return (dispatch) => {
        dispatch(fetchSurveyRequest());
        return new Promise((resolve, reject) => {
            fetchSurvey(page)
                .then((response) => {
                    dispatch(fetchSurveySuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(fetchSurveyFailure(error));
                    reject();
                });
        });
    };
};

export const getPolls = (page) => {
    return (dispatch) => {
        dispatch(fetchPollsRequest());
        return new Promise((resolve, reject) => {
            fetchPolls(page)
                .then((response) => {
                    dispatch(fetchPollsSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(fetchPollsFailure(error));
                    reject();
                });
        });
    };
};