import OtpInput from "react-otp-input";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FaAngleLeft } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { accountVerificationServiceOTP } from "../../services/verification.service";

import Form from "../Form";
import Label from "../Label";
import FormGroup from "../FormGroup";
import Button from "../Button";

const AccountVerificationForm = ({ navigation }) => {
  const schema = yup.object().shape({
    code: yup
      .string()
      .min(6, "Invalid verification code format")
      .max(6, "Invalid verification code format")
      .required("field isrequired"),
  });

  const [loading, setLoading] = useState(false);
  const [verificationError, setVerificationError] = useState("");

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { code: "" },
    mode: "all",
    reValidateMode: "onChange",
  });

  const handleVerify = async (data) => {
    setLoading(true);
    try {
      const response = await accountVerificationServiceOTP(data);
      if (response.status === 200) {
        setLoading(false);
        navigation.go(4);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 422) {
          const { detail } = error.response.data;
          setVerificationError(detail);
        }
      }
    }
  };

  const onSubmit = async (data) => {
    const { code } = data;
    const payload = { otp: code };
    await handleVerify(payload);
  };

  return (
    <>
      {verificationError && (
        <p className="text-danger my-2 text-center">{verificationError}</p>
      )}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="account-verification__form"
      >
        <FormGroup>
          <Label>Enter PIN*</Label>
          <Controller
            render={({ field: { value, onChange } }) => (
              <OtpInput
                value={value}
                onChange={onChange}
                numInputs={6}
                className="account-verification__form__input"
              />
            )}
            control={control}
            defaultValue=""
            name="code"
          />
          {errors.code && (
            <p className="text-danger mt-2">{errors.code.message}</p>
          )}
        </FormGroup>
        <Button disabled={loading} className="btn btn--primary mt-4">
          {loading ? (
            <FontAwesomeIcon className="mr-2" icon={["fas", "spinner"]} spin />
          ) : null}
          {loading ? "Verifying..." : "Verify"}
        </Button>

        <p className="mt-3 text-center">
          Didn't receive OTP or your Number is not linked to BVN?
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScMGHIv4AKt6tfCE8qAfTGWutEBOyXG15uUTShwZ9EtjOKsMw/viewform"
            className="mt-3 purple--text"
            target="_blank"
            rel="noreferrer"
          >
            <br />Click here for manually verification
          </a>{" "}
        </p>
      </Form>
    </>
  );
};

const AccountVerification = ({ navigation }) => {
  return (
    <div className="account-verification">
      <h3 onClick={() => navigation.previous()} className="d-flex mb-2">
        <FaAngleLeft className="mr-2" />
        <span>Enter Verification Code</span>
      </h3>
      <p>
        Kindly provide the 6- digit verification code sent to the phone number
        and email attached to your BVN.
      </p>
      <AccountVerificationForm navigation={navigation} />
    </div>
  );
};

export default AccountVerification;
