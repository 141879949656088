import axios from "axios";

// base url
let baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const authHttpClient = axios.create({
  baseURL: baseUrl,
});


// setting authorization header
const httpRequest = (config) => {
  //config.headers["X-platform"] = "web";
  return config;
};


// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

/** Adding the request interceptors */
authHttpClient.interceptors.request.use(httpRequest);

/** Adding the response interceptors */
authHttpClient.interceptors.response.use(onFulfilled);

export default authHttpClient;