import phone_icon from '../assets/icons/positive-action-phone.svg';
import light_icon from '../assets/icons/positive-action-light.svg';
import medal_icon from '../assets/icons/positive-action-medal.svg';
import cart_icon from '../assets/icons/positive-action-cart.svg';
import video_icon from '../assets/icons/positive-action-video.svg';
import survey_icon from '../assets/icons/positive-action-survey.svg';
import wifi_icon from '../assets/icons/positive-action-wifi.svg';

export const __INCENTIVES__ = {
    weekly_spend: {
        title: 'Earn Extra Points Weekly',
        icon: cart_icon,
        path: '/activities/pay-bills/bills',
        description:
            'Make a purchase with a minimum of N1000 weekly using your Lumi Naira Wallet',
        buttonText: 'Earn Bonus',
    },
    first_airtime: {
        title: 'First time Airtime Purchase Bonus',
        icon: phone_icon,
        path: 'activities/pay-bills/airtime',
        description:
            'Buy your first airtime using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Airtime Bonus',
    },
    first_data: {
        title: 'First time Data Purchase Bonus',
        icon: wifi_icon,
        path: '/activities/pay-bills/bills/mobile-data',
        description:
            'Buy your first data using your Lumi Naira Wallet and earn Bonus points.',
        buttonText: 'Earn Data Bonus',
    },
    first_cable: {
        title: 'First time Cable Subscription Renewal Bonus',
        icon: video_icon,
        path: '/activities/pay-bills/bills/cable',
        description:
            'Renew your cable subscription using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Cable Bonus',
    },
    first_power: {
        title: 'First time Power Renewal Bonus',
        icon: light_icon,
        path: '/activities/pay-bills/bills/electricity',
        description:
            'Renew your electricity using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Power Bonus',
    },
    first_survey: {
        title: 'Take your first survey and earn extra points.',
        icon: survey_icon,
        path: '/activities/surveys',
        description: 'Take your first survey on Lumi and earn extra points',
        buttonText: 'Earn Survey Bonus',
    },
    gold_membership: {
        title: 'Lumi Gold Member’s Monthly  Bonus',
        icon: medal_icon,
        path: '/faq',
        description:
            'Become a gold member and earn additional bonus points monthly',
        buttonText: 'Read More',
        hasBorder: false,
    },
};

export const __INCENTIVES__BILLS__ = {
    weekly_spend: {
        title: 'Earn Extra Points Weekly',
        icon: cart_icon,
        path: '/bills',
        description:
            'Make a purchase with a minimum of N1000 weekly using your Lumi Naira Wallet',
        buttonText: 'Earn Bonus',
    },
    first_airtime: {
        title: 'First time Airtime Purchase Bonus',
        icon: phone_icon,
        path: '/airtime',
        description:
            'Buy your first airtime using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Airtime Bonus',
    },
    first_data: {
        title: 'First time Data Purchase Bonus',
        icon: wifi_icon,
        path: '/bills/mobile-data',
        description:
            'Buy your first data using your Lumi Naira Wallet and earn Bonus points.',
        buttonText: 'Earn Data Bonus',
    },
    first_cable: {
        title: 'First time Cable Subscription Renewal Bonus',
        icon: video_icon,
        path: '/bills/cable',
        description:
            'Renew your cable subscription using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Cable Bonus',
    },
    first_power: {
        title: 'First time Power Renewal Bonus',
        icon: light_icon,
        path: '/bills/electricity',
        description:
            'Renew your electricity using your Lumi Naira Wallet and earn Bonus points',
        buttonText: 'Earn Power Bonus',
    },
    first_survey: {
        title: 'Take your first survey and earn extra points.',
        icon: survey_icon,
        path: '/surveys',
        description: 'Take your first survey on Lumi and earn extra points',
        buttonText: 'Earn Survey Bonus',
    },
    gold_membership: {
        title: 'Lumi Gold Member’s Monthly  Bonus',
        icon: medal_icon,
        path: '/faq',
        description:
            'Become a gold member and earn additional bonus points monthly',
        buttonText: 'Read More',
        hasBorder: false,
    },
};

export const mapIncentives = (data, callback) => {
    let _data = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].key === 'new_reg' || data[i].key === 'referee_bonus')
            continue;
        _data.push({ ...__INCENTIVES__[`${data[i].key}`], ...data[i] });
    }

    callback(_data);
};

export const mapIncentivesBills = (data, callback) => {
    let _data = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].key === 'new_reg' || data[i].key === 'referee_bonus')
            continue;
        _data.push({ ...__INCENTIVES__BILLS__[`${data[i].key}`], ...data[i] });
    }

    callback(_data);
};

export const __REFERRAL_POINTS__ = process.env.REACT_APP_REFERRAL_POINTS;
