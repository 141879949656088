import Types from "../constants";

const { dashboardTypes } = Types;

export const fetchDashboardRequest = () => {
    return {
        type: dashboardTypes.FETCH_DASHBOARD_REQUEST,
    };
};

export const fetchDashboardSuccess = (payload) => {
    return {
        type: dashboardTypes.FETCH_DASHBOARD_SUCCESS,
        payload: payload,
    };
};

export const fetchDashboardFailure = (error) => {
    return {
        type: dashboardTypes.FETCH_DASHBOARD_FAILURE,
        payload: error,
    };
};
