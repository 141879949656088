import Types from "../constants";

const {
    transactionTypes: {
        FETCH_TRANSACTION_FAILURE,
        FETCH_TRANSACTION_REQUEST,
        FETCH_TRANSACTION_SUCCESS,
        FETCH_CREDIT_TRANSACTION,
        FETCH_DEBIT_TRANSACTION,
        FETCH_ALL_TRANSACTIONS,
    },
} = Types;

export const fetchTransactionRequest = () => ({
    type: FETCH_TRANSACTION_REQUEST,
});

export const fetchTransactionSuccess = (payload) => ({
    type: FETCH_TRANSACTION_SUCCESS,
    payload: payload,
});

export const fetchTransactionFailure = (payload) => ({
    type: FETCH_TRANSACTION_FAILURE,
    error: payload,
});

export const fetchAllTransactions = (payload) => ({
    type: FETCH_ALL_TRANSACTIONS,
    payload: payload,
});

export const fetchCreditTransactions = (payload) => ({
    type: FETCH_CREDIT_TRANSACTION,
    payload,
});

export const fetchDebitTransactions = (payload) => ({
    type: FETCH_DEBIT_TRANSACTION,
    payload,
});
