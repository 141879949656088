import { useForm } from "react-hook-form";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPrivacySettings, updatePrivacySettings } from "../../../redux/reducers/privacy.reducer";
import { deleteUserAccount } from "../../../services/privacy.service";
import { logoutHandler } from "../../../helpers/logoutHandler.js";

import "./privacy-settings.scss";

import useModal from "../../../hooks/useModal";
import Form from "../../../components/Form";
import FormGroup from "../../../components/FormGroup";
import Label from "../../../components/Label";
import Button from "../../../components/Button";
import ResponseModal from "../../../components/ResponseModal";
import PasswordInput from "../../../components/PasswordInput";
import ModalClose from "../../../components/ModalClose";

const PrivacySettings = () => {
    const privacySettingsSchema = yup.object().shape({
        current_password: yup.string(),
        password: yup
            .string()
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                "Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number"
            )
            .required("Password is required"),
        confirm_password: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords do not match!")
            .required("Confirm Password"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        resolver: yupResolver(privacySettingsSchema),
        defaultValues: {
            current_password: "",
            password: "",
            confirm_password: "",
        },
        mode: "all",
        reValidateMode: "onSubmit"
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const { privacy } = useSelector((state) => state);
    const data = privacy.data !== null && privacy.data;

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [deleteAccountModal, setDeleteAccountModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [deletePassword, setDeletePassword] = useState(null);
    const [showDeletePassword, setShowDeletePassword] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const deletePasswordError = useRef(false);

    useEffect(() => {
        dispatch(getPrivacySettings());
    }, [dispatch]);

    useEffect(() => {
        if (deletePassword != null) {
            validatePassword();
        }
    }, [deletePassword]);

    const onSubmit = (data) => {
        const {
            current_password,
            confirm_password: new_password_confirmation,
            password: new_password,
        } = data;
        setLoading(true);
        dispatch(
            updatePrivacySettings({
                new_password,
                new_password_confirmation,
                current_password,
            })
        ).then(() => {
            setLoading(false);
            toggleModal();
        }).catch((error) => {
            setLoading(false);
            if(error.response) {
                const { detail } = error.response.data;
                setError("current_password", {
                    type: "validate",
                    message: detail,
                });
            }
        });
    };

    const deleteAccount = () => {
        validatePassword();
        if (!deletePasswordError.current) {
            setDeleteLoader(true);
            deleteUserAccount({ password: deletePassword }).then(() => {
                setDeleteLoader(false);
                logoutHandler()
                history.push("/login");
                window.location.reload();
            }).catch((error) => {
                setDeleteLoader(false);
                if(error.response){
                    deletePasswordError.current = true;
                    if(error.response.status === 422){
                        setPasswordErrorMessage(error.response.data.errors.password[0]);
                    }
                    if(error.response.status === 400){
                        setPasswordErrorMessage(error.response.data.detail);
                    }
                }
            });
        }
    }

    const validatePassword = () => {
        // validate that a password was entered
        if (deletePassword == null || deletePassword == "") {
            setPasswordErrorMessage("please enter a valid password");
            deletePasswordError.current = true;
        } else {
            setPasswordErrorMessage("");
            deletePasswordError.current = false;
        }
    }
    const handlePassword = (event) => {
        setDeletePassword(event.target.value);
    }

    return (
        <div className="privacy--settings">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h4 className="privacy--settings-heading">Change Password</h4>
                <div className="privacy--settings-container">
                    <div className="privacy--settings-container-top">
                        {data.user_has_set_password ? (
                            <FormGroup>
                                <Label>Current password</Label>
                                <PasswordInput
                                    register={register}
                                    placeholder="Please enter your current password"
                                    required
                                    label="current_password"
                                    error={errors.current_password}
                                />
                            </FormGroup>
                        ) : null}
                    </div>
                    <div className="privacy--settings-container-bottom">
                        <FormGroup>
                            <Label>New password</Label>
                            <PasswordInput
                                register={register}
                                placeholder="Please enter your new password"
                                required
                                label="password"
                                error={errors.password}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirm new password</Label>
                            <PasswordInput
                                register={register}
                                placeholder="Re-enter your new password"
                                required
                                label="confirm_password"
                                error={errors.confirm_password}
                            />
                        </FormGroup>
                    </div>
                    <div className="btn-section">
                        <a className="delete-btn" onClick={() => setDeleteAccountModal(true)}>Delete account</a>
                        <Button
                            disabled={loading}
                            className="btn btn--primary btn--privacy-settings"
                        >
                            {loading ? (
                                <FontAwesomeIcon
                                    icon="spinner"
                                    className="fa-spin"
                                />
                            ) : (
                                "Save Changes"
                            )}
                        </Button>
                    </div>
                    <CustomModal
                        isActive={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        width={"350px"}
                    >
                        <div className="modal__container__content">
                            <div className="modal__container__content__default-header mb-3">
                                <ModalClose onClick={() => setModalOpen(false)} />
                            </div>
                            <ResponseModal message="Password updated successfully" status="pass" />
                        </div>
                    </CustomModal>
                </div>
            </Form>
            <CustomModal
                isActive={deleteAccountModal}
                closeModal={() => setDeleteAccountModal(false)}
                width={"350px"}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => {
                            setDeleteAccountModal(false);
                            setShowDeletePassword(false);
                        }} />
                    </div>
                    {(!showDeletePassword) ?
                        <div>
                            <p style={{ textAlign: 'left' }}>Are you sure you want to delete account?</p>
                            <div className="mt-5 mb-4 modal-btn-section">
                                <button className="btn btn--outline" onClick={() => setDeleteAccountModal(false)}>No, cancel</button>
                                <button className="btn btn--danger" onClick={() => setShowDeletePassword(true)}>Delete</button>
                            </div>
                        </div> :
                        (<div>
                            {/* password input section */}
                            <div style={{ textAlign: "left" }}>
                                <Label>Enter your password to complete the deletion of your account*</Label>
                                <input
                                    type="password"
                                    className="input--field"
                                    onChange={handlePassword}
                                />
                                <p className="mb-0 mt-1 error--text">
                                    {passwordErrorMessage}
                                </p>
                            </div>
                            <button className="btn btn--danger mt-3" onClick={deleteAccount} disabled={deleteLoader}>
                                {deleteLoader ? (
                                    <span>
                                        <FontAwesomeIcon
                                            icon="spinner"
                                            className="fa-spin mr-1"
                                        />
                                        <span>Deleting...</span>
                                    </span>
                                ) : (
                                    "Delete now"
                                )}
                            </button>
                        </div>)}
                </div>
            </CustomModal>
        </div>
    );
};

export default PrivacySettings;
