import './summary.scss';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { buyData } from '../../../../redux/reducers/mobile-data.reducer';
import useModal from '../../../../hooks/useModal';
import useNairaWallet from '../../../../hooks/core/FundTransfer/useNairaWallet';
import Form from '../../../../components/Form';
import FormGroup from '../../../../components/FormGroup';
import ErrorText from '../../../../components/ErrorText';
import Button from '../../../../components/Button';
import PaymentResponse from './PaymentResponse';
import ResponseModal from '../../../../components/ResponseModal';
import ModalClose from '../../../../components/ModalClose';

const MobileDataCheckout = () => {
    const electricitySchema = yup.object().shape({
        pin: yup
            .string()
            .min(6, 'Invalid transaction pin format')
            .max(6, 'Invalid transaction pin format')
            .required('Enter your enlumi password'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(electricitySchema),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const dispatch = useDispatch();

    const { dashboard, mobileData } = useSelector((state) => state);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [loading, setLoading] = useState(false);
    const [isSuccessfull, setIsSuccessfull] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { data } = useNairaWallet();
    const { withdrawable_balance } = data !== null && data;
    const naira_wallet_balance = parseFloat(withdrawable_balance) || 0;

    const { enlumi_rewards_wallet_balance } =
        dashboard.data !== null && dashboard.data;

    const { formData, summary } = mobileData;

    const { funding_channel, service_id, variation_code } = formData;
    const { phone_number, amount, points } = summary;

    const onSubmit = (data) => {
        const { pin } = data;
        setLoading(true);
        dispatch(
            buyData({
                funding_channel,
                service_id,
                variation_code,
                amount,
                pin,
                phone_number,
            })
        )
            .then((response) => {
                setLoading(false);
                setIsSuccessfull(true);
                if (response?.reward_message) {
                    const purchaseObj = {
                        isFirstAirtime: false,
                        isFirstMobileData: true,
                    };
                    localStorage.setItem(
                        'isFirstTimePurchase',
                        JSON.stringify(purchaseObj)
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                setIsSuccessfull(false);
                if (error.status === 422 || error.status === 400) {
                    if (error.data) {
                        setErrorMessage(error.data.detail);
                        toggleModal();
                    }
                }
            });
    };

    return (
        <div className="mobile__data-summary">
        
            <div className="mobile__data-summary-container">
                <div className="summary__details">
                    <div className="summary__detail">
                        <p className="left">From:</p>
                        <p className="right">
                            <span className="wallet">
                                {funding_channel === 'CASH_WALLET'
                                    ? 'Naira Wallet'
                                    : 'Rewards Wallet'}
                            </span>
                            <span className="balance">
                                Balance: #
                                {funding_channel === 'CASH_WALLET'
                                    ? naira_wallet_balance
                                    : enlumi_rewards_wallet_balance}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Biller:</p>
                        <p className="right">
                            <span className="wallet">
                                {service_id.toUpperCase()}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Product:</p>
                        <p className="right">
                            <span className="wallet">
                                {variation_code.toUpperCase()}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Phone Number:</p>
                        <div className="right">
                            <span className="wallet">{`+234${phone_number}`}</span>
                        </div>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Amount:</p>
                        <div className="right">
                            <span className="wallet">#{amount}</span>
                        </div>
                    </div>
                    {funding_channel !== 'REWARDS_WALLET' && (
                        <div className="summary__detail">
                            <p className="left">Points to be earned:</p>
                            <div className="right">
                                <span className="wallet">{points}</span>
                            </div>
                        </div>
                    )}
                </div>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="summary__form"
                >
                    <FormGroup>
                        <h4 className="form__group-heading">
                            Enter Your Transaction Pin to continue
                        </h4>
                        <input
                            type="password"
                            className="enlumi__password-field"
                            id="enlumi__password"
                            {...register('pin')}
                            inputMode="numeric"
                        />
                        {errors.pin && (
                            <ErrorText message={errors.pin.message} />
                        )}
                    </FormGroup>

                    <Button className="btn btn--primary btn__summary">
                        {loading ? (
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin"
                            />
                        ) : (
                            'Pay Now'
                        )}
                    </Button>
                    <CustomModal
                        isActive={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        width={'25%'}
                    >
                        <div className="modal__container__content">
                            <div className="modal__container__content__default-header mb-3">
                                <ModalClose
                                    onClick={() => setModalOpen(false)}
                                />
                            </div>
                            <ResponseModal
                                status={'fail'}
                                message={errorMessage}
                            />
                        </div>
                    </CustomModal>
                </Form>

                <CustomModal isActive={isSuccessfull}>
                    <div className="modal__container__content">
                        <PaymentResponse
                            points={
                                funding_channel !== 'REWARDS_WALLET' && points
                            }
                        />
                    </div>
                </CustomModal>
            </div>
        </div>
    );
};

export default MobileDataCheckout;
