import { useClickOutSide } from "../hooks/useClickOutside";
import {
  fetchNotifications,
  markAllAsRead,
} from "../redux/reducers/notifications.reducer";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import speaker_icon from "../assets/icons/speaker.svg";
import useAuth from "../hooks/useAuth";

const Notifications = ({ show, toggle }) => {
  const notificationRef = useClickOutSide(() => {
    toggle();
  });

  const isAuth = useAuth();

  const { notifications } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchNotifications());
    }
  }, [dispatch]);

  if (show) {
    return (
      <div ref={notificationRef} className="notification--items">
        <div className="notification--items-header">
          <div className="title" onClick={() => toggle()}>
            <span className="material-icons-round">navigate_before</span>
            <h4>Notifications</h4>
          </div>
          <p onClick={() => dispatch(markAllAsRead(notifications.all))}>
            Mark all as read
          </p>
        </div>
        <div className="notification--items-body">
          {notifications.all.length <= 0 && (
            <div className="notification--item">
              <div className="notification--item-title">
                You dont have any notifications.
              </div>
            </div>
          )}
          {notifications.all.length > 0 &&
            notifications.all.map((notification, index) => {
              if (notification.read === false) {
                return (
                  <div className="notification--item" key={notification.id}>
                    <div className="notification--item-details">
                      <div className="notification--item-icon">
                        <img src={speaker_icon} alt="voice" />
                      </div>
                      <div className="notification--item-title">
                        {notification.message}
                      </div>
                    </div>
                    <p className="notification--item-time">
                      {notification.created_at.split(" ")[1]}
                    </p>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  }

  return null;
};

export default Notifications;
