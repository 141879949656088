import BackDrop from "./BackDrop";

const Modal = ({ isActive, closeModal, children, width }) => {
  if (!isActive) {
    return null;
  } else {
    return (
      <div id="webapp">
        <div className="modal">
          <BackDrop closeModal={closeModal} />
          <div className="modal__container" style={{ width: width }}>
            {children}
          </div>
        </div>
      </div>
    );
  }
};

export default Modal;
