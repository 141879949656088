import { useHistory } from 'react-router-dom';
import { useStep } from 'react-hooks-helper';

import './mobile-data.scss';

import arrow_left from '../../../../assets/icons/arrow-left.svg';
import PurchaseMobileData from './PurchaseMobileData';
import MobileDataCheckout from './MobileDataCheckout';

const MobileData = () => {
    const history = useHistory();

    const steps = [
        { id: 1, title: 'Buy Data' },
        { id: 2, title: 'Buy Data Checkout' },
    ];

    const { step, navigation } = useStep({ steps, initialStep: 0 });

    const renderSteps = () => {
        switch (step.title) {
            case 'Buy Data':
                return <PurchaseMobileData navigation={navigation} />;
            case 'Buy Data Checkout':
                return <MobileDataCheckout />;
            default:
                return null;
        }
    };

    return (
        <div className="mobile__data">
            <div
                onClick={() => {
                    if (step.title === 'Buy Data') {
                        history.goBack();
                    } else {
                        navigation.previous();
                    }
                }}
                className="mobile__data-header"
            >
                <img src={arrow_left} alt="back" />
                <div>
                    <h2 className="mobile__data-heading">Data Top-up</h2>
                </div>
            </div>
            <p className="mobile__data-description">
                Kindly provide the details for your recharge.{' '}
            </p>
            {renderSteps()}
        </div>
    );
};

export default MobileData;
