const ComingSoon = ({ icon, message }) => {
    return (
        <div className="coming--soon">
            <img src={icon} alt="icon" />
            <h2 className="coming--soon-heading">Coming Soon</h2>
            <p className="coming--soon-description">{message}</p>
        </div>
    );
};

export default ComingSoon;
