import { useReducer, useEffect, useCallback } from 'react';
import {
    fetchTransactions,
    fetchNairaWalletTransactions,
} from '../../../services/transaction.service';

const intitialState = {
    status: 'idle',
    data: null,
    error: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'idle':
            return { ...state, status: 'idle' };
        case 'pending':
            return { ...state, status: 'pending' };
        case 'resolved':
            return { ...state, status: 'resolved', data: action.data };
        case 'rejected':
            return { ...state, status: 'rejected', error: action.error };
        default:
            return state;
    }
};

const useFetchTransactions = (wallet = 'reward') => {
    const [state, dispatch] = useReducer(reducer, intitialState);

    const handleFetchNairaWalletTransactions = useCallback(async () => {
        dispatch({ type: 'pending' });
        fetchNairaWalletTransactions().then(onFulfilled, onRejected);
    }, []);

    const handleFetchRewardWalletTransactions = useCallback(async () => {
        dispatch({ type: 'pending' });
        fetchTransactions().then(onFulfilled, onRejected);
    }, []);

    const filterTransactions = useCallback(
        (type) => {
            switch (type) {
                case 'all':
                    return state.data;
                case 'credit':
                    return [
                        ...state.data.filter((transaction) => {
                            return transaction.type === 'credit';
                        }),
                    ];
                case 'debit':
                    return [
                        ...state.data.filter((transaction) => {
                            return transaction.type === 'debit';
                        }),
                    ];
                default:
                    return state.data;
            }
        },
        [state.data]
    );

    function onFulfilled(response) {
        if (response) {
            if (Array.isArray(response.data)) {
                dispatch({ type: 'resolved', data: response.data });
            } else {
                dispatch({ type: 'resolved', data: response.data?.data });
            }
        }
    }

    function onRejected(error) {
        dispatch({ type: 'rejected', error: error?.response?.data?.detail });
    }

    useEffect(() => {
        if (wallet === 'reward') {
            handleFetchRewardWalletTransactions();
        } else if (wallet === 'naira') {
            handleFetchNairaWalletTransactions();
        }
    }, [
        handleFetchNairaWalletTransactions,
        handleFetchRewardWalletTransactions,
        wallet,
    ]);

    return { ...state, filterTransactions };
};

export default useFetchTransactions;
