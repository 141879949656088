import React from "react";
import { useState, useRef, useEffect } from "react";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { validateBiodata } from "../services/validation.service";
import GoogleRecaptchaV3 from "./GoogleRecaptchaV3";
import Button from "./Button";
import {
  exchangeGoogleToken,
  exchangeFacebookToken,
} from "../services/auth.service";

import google_logo from "../assets/icons/google-icon.png";
import facebook_logo from "../assets/icons/facebook-logo.png";

const SocialButtons = ({
  facebookText,
  googleText,
  setErrorMessage,
  grantUserAccess,
  setSocialDetails,
}) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const app_id = process.env.REACT_APP_FACEBOOK_APP_ID;
  const recaptchaRef = useRef();
  const [accessToken, setAccessToken] = useState(null);
  const [loginType, setLoginType] = useState(null);

  useEffect(() => {
    if (accessToken !== null) recaptchaRef.current.handleLoaded();
  }, [accessToken]);

  const onSuccessGoogle = (res) => {
    if (googleText.includes("Login")) {
      setLoginType("google");
      setAccessToken(res.accessToken);
    } else {
      validateBiodata(res.profileObj.email, null, null).then((response) => {
        if (response.data) {
          if (!response.data.email.available) {
            setErrorMessage("This account already exist");
          } else {
            setErrorMessage("");
            setSocialDetails({
              email: res.profileObj.email,
              registration_type: "google",
              access_token: res.accessToken,
            });
          }
        }
      });
    }
  };

  const onSuccessFacebook = (res) => {
    if (facebookText.includes("Login")) {
      setLoginType("facebook");
      setAccessToken(res.accessToken);
    } else {
      validateBiodata(res.email, null, null).then((response) => {
        if (response.data) {
          if (!response.data.email.available) {
            setErrorMessage("This account already exist");
          } else {
            setErrorMessage("");
            setSocialDetails({
              email: res.email,
              registration_type: "facebook",
              access_token: res.accessToken,
            });
          }
        }
      });
    }
  };

  const requestlogin = (recaptchaToken) => {
    if (loginType === "google") {
      exchangeGoogleToken({
        access_token: accessToken,
        "g-recaptcha-response": recaptchaToken,
      })
        .then((response) => {
          grantUserAccess(response.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              setErrorMessage(error.response.data.detail);
            }
          }

          if (!navigator.onLine) {
            setErrorMessage(
              "No internet connection, please check your network connectivity"
            );
          }
        });
    }

    if (loginType === "facebook") {
      exchangeFacebookToken({
        access_token: accessToken,
        "g-recaptcha-response": recaptchaToken,
      })
        .then((response) => {
          grantUserAccess(response.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              setErrorMessage(error.response.data.detail);
            }
          }

          if (!navigator.onLine) {
            setErrorMessage(
              "No internet connection, please check your network connectivity"
            );
          }
        });
    }
  };

  return (
    <div className="social--button-group">
      {/* google recaptcha */}
      <GoogleRecaptchaV3
        ref={recaptchaRef}
        resolved={requestlogin}
        page="login"
      />

      <GoogleLogin
        clientId={clientId}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            className="btn btn--outline btn--social"
          >
            <img
              src={google_logo}
              alt="google"
              className="btn--social-icon google"
            />
            <span className="btn--social-text google-text mt-2">
              {googleText}
            </span>
          </Button>
        )}
        onSuccess={onSuccessGoogle}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "100px" }}
        isSignedIn={false}
      />
      <FacebookLogin
        appId={app_id}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            className="btn btn--outline btn--social"
          >
            <img
              src={facebook_logo}
              className="btn--social-icon facebook"
              alt="facebook"
            />
            <span className="btn--social-text facebook-text mt-2">
              {facebookText}
            </span>
          </Button>
        )}
        fields="name,email"
        callback={onSuccessFacebook}
      />
    </div>
  );
};

export default SocialButtons;