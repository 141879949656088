import useModal from '../../hooks/useModal';
import ModalClose from '../ModalClose';
import success_image from '../../assets/images/congratulations.png';
import lumi_image from '../../assets/images/lumi-rewards.svg';

import './positive-action-modals-styles.scss';

import { useHistory } from 'react-router-dom';

const FirstData = ({ isFirstTime, username = '...' }) => {
    const [modalOpen, setModalOpen, toggleModal, CustomModal] =
        useModal(isFirstTime);

    const history = useHistory();

    return (
        <CustomModal
            isActive={modalOpen}
            closeModal={() => {
                setModalOpen(false);
                localStorage.removeItem('isFirstTimePurchase');
            }}
            width="480px"
        >
            <div className="modal__container__content">
                {/* <div className="modal__container__content__default-header mb-3">
                    <ModalClose
                        onClick={() => {
                            setModalOpen(false);
                            localStorage.removeItem('isFirstTimePurchase');
                        }}
                    />
                </div> */}
                <div className="positive__action__modal">
                    <div className="positive__action__modal-header">
                        <h3>Lumi Rewards</h3>
                        <img src={lumi_image} alt="Congratulations" />
                    </div>
                    <div className="positive__action__modal-body">
                        <h3>Congratulations {username} 🥰</h3>
                        <p>
                            You have just been rewarded with{' '}
                            <span style={{ color: '#766df4', fontWeight: 700 }}>
                                375 bonus points
                            </span>{' '}
                            for your first electricity unit purchase on Lumi.
                            <br />
                            You can also earn additional{' '}
                            <span style={{ color: '#766df4', fontWeight: 700 }}>
                                1,000
                            </span>{' '}
                            bonus points weekly when you spend up to
                            <span style={{ color: '#766df4', fontWeight: 700 }}>
                                N1,000
                            </span>{' '}
                            cable, power, data or airtime purchase using your
                            Lumi Naira wallet
                        </p>
                        <button
                            onClick={() => {
                                setModalOpen(false);
                                localStorage.removeItem('isFirstTimePurchase');
                                history.push('/activities/pay-bills/bills');
                            }}
                        >
                            Earn More Points
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default FirstData;
