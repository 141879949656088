import "./fund-wallet.scss";
import ModalClose from "../ModalClose";

import useModal from "../../hooks/useModal";
import AccountDetails from "./AccountDetails";


const FundWallet = ({setModalOpen, modalOpen}) => {
  const [, , , CustomModal] = useModal();

  return (
    <CustomModal
      isActive={modalOpen}
      closeModal={() => setModalOpen(false)}
      width="450px"
    >
      <div className="modal__container__content">
        <div className="modal__container__content__default-header mb-3">
          <ModalClose
            onClick={() => setModalOpen(false)}
          />
        </div>
        <div className="fund-wallet-modal">
          <AccountDetails />
        </div>
      </div>
    </CustomModal>
  );
};

export default FundWallet;