import './verification-page.scss';
import OtpInput from 'react-otp-input';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from '../../components/Form';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import {
    verifyUserEmail,
    verifyUserEmailResendOTP,
} from '../../services/verification.service';
import Countdown from 'react-countdown';

import VerifyLayout from '../../Layouts/VerifyLayout';
import Button from '../../components/Button';

import verify_email_icon from '../../assets/icons/verify-email.svg';

const VerificationPage = () => {
    const otpSchema = yup.object().shape({
        otp: yup
            .string()
            .min(6, 'Invalid verification code')
            .max(6, 'Invalid verification code')
            .required('verification code is required'),
    });

    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(otpSchema),
        defaultValues: {
            otp: '',
        },
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const [loading, setLoading] = useState(false);
    const [verificationError, setVerificationError] = useState('');
    const [resendOtp, setResendOtp] = useState(false);

    const handleVerify = async (data) => {
        setLoading(true);
        try {
            const response = await verifyUserEmail(data);
            if (response.status === 200) {
                setLoading(false);
                window.location.href = '/login';
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 422
                ) {
                    const { detail } = error.response.data;
                    setVerificationError(detail);
                }
            }
        }
    };

    const handleResendOtp = async (data) => {
        try {
            const response = await verifyUserEmailResendOTP(data);
            if (response.status === 200) {
                setResendOtp(true);
            }
        } catch (error) {
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 422
                ) {
                    const { detail } = error.response.data;
                    setVerificationError(detail);
                }
            }
        }
    };

    const onSubmit = async (data) => {
        const { otp } = data;
        const payload = { code: otp };
        await handleVerify(payload);
    };

    return (
        <VerifyLayout>
            <div className="verify--email">
                <div className="verify--email-container">
                    <div className="verify--email-content">
                        <h2 className="verify--email-heading">
                            Enter Verification Code
                        </h2>
                        <p className="verify--email-description">
                            Kindly provide the 6-digit verification code sent
                            to your email. Check your spam or promotions folder
                        </p>
                        {verificationError && (
                            <p className="text-danger my-3 text-center">
                                {verificationError}
                            </p>
                        )}
                        <Form
                            className="verify--email-form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Controller
                                render={({ field: { value, onChange } }) => (
                                    <OtpInput
                                        className="verify--email-code"
                                        onChange={onChange}
                                        value={value}
                                        numInputs={6}
                                    />
                                )}
                                control={control}
                                name="otp"
                                defaultValue=""
                            />
                            {errors.otp && (
                                <p className="mt-3 text-danger">
                                    {errors.otp.message}
                                </p>
                            )}
                            <Button
                                disabled={loading}
                                className="btn btn--primary btn--resend-email"
                            >
                                {loading ? (
                                    <FontAwesomeIcon
                                        icon={'spinner'}
                                        className="fa-spin mr-2"
                                    />
                                ) : null}
                                <span>
                                    {loading
                                        ? 'Verifying...'
                                        : 'Verify account'}
                                </span>
                            </Button>
                        </Form>
                        <div className="my-4 text-center">
                            {!resendOtp ? (
                                <p>
                                    Didn't recieve code?{' '}
                                    <b
                                        onClick={handleResendOtp}
                                        style={{
                                            color: '#766df4',
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                        }}
                                    >
                                        Send it again.
                                    </b>
                                </p>
                            ) : (
                                <Countdown
                                    date={Date.now() + 300000}
                                    renderer={({
                                        minutes,
                                        seconds,
                                        completed,
                                    }) => {
                                        if (completed) {
                                            return (
                                                <p>
                                                    Didn't recieve code?{' '}
                                                    <b
                                                        onClick={
                                                            handleResendOtp
                                                        }
                                                        style={{
                                                            color: '#766df4',
                                                            cursor: 'pointer',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        Send it again.
                                                    </b>
                                                </p>
                                            );
                                        } else {
                                            return (
                                                <p className="my-3 text-center">
                                                    Resend in{' '}
                                                    <b>{`${minutes}:${seconds}`}</b>
                                                </p>
                                            );
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </VerifyLayout>
    );
};

export default VerificationPage;

