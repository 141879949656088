import Types from "../constants";
import {
    fetchUserFailure,
    fetchUserRequest,
    fetchUserSuccess,
    updateUserFailure,
    updateUserRequest,
    updateUserSuccess,
} from "../actions/auth.actions";

import {
    fetUserDetails,
    updateUserDetails,
} from "../../services/auth.service";

const {
    FETCH_USER_FAILURE,
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
} = Types.authTypes;

const initialState = {
    loading: false,
    accessToken: null,
    requestingTokens: false,
    created: false,
    user: { is_verified: false },
    isLoggedIn: false,
    profile_survey: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACCESS_TOKEN":
            return { ...state, accessToken: action.accessToken }
        case "SET_REQUESTING_TOKEN":
            return { ...state, requestingTokens: action.requestingTokens }
        case FETCH_USER_REQUEST:
            return { ...state, loading: true };
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_REQUEST:
            return { ...state, loading: true };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const getUserDetails = () => {
    return (dispatch) => {
        dispatch(fetchUserRequest());
        return new Promise((resolve, reject) => {
            fetUserDetails()
                .then((response) => {
                    dispatch(fetchUserSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch(fetchUserFailure(error.response.data));
                        reject(error.response.data);
                    } else {
                        dispatch(fetchUserFailure(error.message));
                        reject(error.message);
                    }
                });
        });
    };
};

export const updateBiodata = (payload) => {
    return (dispatch) => {
        dispatch(updateUserRequest());
        return new Promise((resolve, reject) => {
            updateUserDetails(payload)
                .then((response) => {
                    dispatch(updateUserSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch(updateUserFailure(error.response.data));
                        reject(error.response.data);
                    } else {
                        dispatch(updateUserFailure(error.message));
                        reject(error.message);
                    }
                });
        });
    };
};