import Types from "../constants";

import {
    fetchReferredUsersFailure,
    fetchReferredUsersRequest,
    fetchReferredUsersSuccess,
    fetchQualifiedReferees,
    fetchUnqualifiedReferees,
    fetchAllReferees
} from "../actions/referred-users.action";

import { fetchReferrals } from "../../services/referral.service";


const {
    FETCH_REFERRED_USERS_FAILURE,
    FETCH_REFERRED_USERS_REQUEST,
    FETCH_REFERRED_USERS_SUCCESS,
    FETCH_QUALIFIED_REFEREES,
    FETCH_UNQUALIFIED_REFEREES,
    FETCH_ALL_REFEREES
} = Types.referredUsersTypes;

const initialState = {
    loading: false,
    data: {
            "referral_code": "",
            "points_earned":0,
            "referees":[],
            "total_referrals":0,
            "value":0
        },
    filteredReferees: [],
    error: null,
};

export const referredUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REFERRED_USERS_REQUEST:
            return { ...state,
                        loading: true,
                         };
        case FETCH_REFERRED_USERS_SUCCESS:
            return { ...state,
                        loading: false,
                        data: action.payload,
                        filteredReferees: action.payload.referees
                    };
        case FETCH_ALL_REFEREES:
                return { ...state,
                            loading: false,
                            filteredReferees: action.payload
                        };
        case FETCH_QUALIFIED_REFEREES:
                return { ...state,
                            loading: false,
                            filteredReferees: action.payload
                        };
        case FETCH_UNQUALIFIED_REFEREES:
                return { ...state,
                            loading: false,
                            filteredReferees: action.payload
                        };
        case FETCH_REFERRED_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                filteredReferees: action.payload,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getReferredUsers = () => {
    return (dispatch) => {
        dispatch(fetchReferredUsersRequest());
        return new Promise((resolve, reject) => {
            fetchReferrals()
                .then((response) => {
                    dispatch(fetchReferredUsersSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch(fetchReferredUsersFailure(error.response.data));
                        ;
                        reject(error.response.data);
                    } else {
                        dispatch(fetchReferredUsersFailure(error.message));
                        reject(error.message);
                    }
                });
        });
    };
};

export const filterReferees = (data, type) => {
    return (dispatch) =>{
        let _filteredReferees = null;
        if (type === 'qualified') {
            _filteredReferees = data.referees.filter(
                (referee) => referee.qualified
            );
            dispatch(fetchQualifiedReferees(_filteredReferees));
        } else if (type === 'unqualified') {
            _filteredReferees = data.referees.filter(
                (referee) => !referee.qualified
            );
            dispatch(fetchUnqualifiedReferees(_filteredReferees));
        } else {
            dispatch(fetchAllReferees(data.referees));
        }
    }
}