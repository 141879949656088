import { useEffect, useState } from "react";
import "./questionsInput.scss";

const LinearScale = (props) => {
    const initialAnswer = 0;
    const [answer, setAnswer] = useState((props.answer)? props.answer: initialAnswer);

    useEffect(() => {
        setData();
    }, [answer]);

    useEffect(() => {
        resetAnswer();
    }, [props.id]);

    const resetAnswer = () => {
        setAnswer((props.answer)? props.answer: initialAnswer);
    }
    
    const checkForNextRoute = () => {
        let nextRoute = props.next_route;
        return nextRoute;
    }

    const handleChange =(e)=> {
        setAnswer(e.target.value)
    }
    
    const setData= () => {
        props.onchange({
            answer: answer,
            nextRoute: checkForNextRoute()
        });
    }
    return (
        <div>
            <p>Value: <span style={{fontWeight: 600, color:"black"}}>{answer}</span></p>
            <input className="rs-range" onChange={handleChange} type="range" value={answer} min={(props.min_range)? props.min_range: 0} max={(props.range)? props.range: 10} step="1"/>
        </div>
    )
}

export default LinearScale;