import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { validateBiodata } from "../../../services/validation.service";

import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import Label from "../../../components/Label";
import PhoneInput from "../../../components/PhoneInput";
import SocialButtons from "../../../components/SocialButtons";
import FormHeader from "../../../components/FormHeader";
import TextDivider from "../../../components/TextDivider";

const BiodataForm = ({ setBiodata, setSocialDetails, socialDetails }) => {
    const biodataSchema = yup.object().shape({
        email: yup
            .string()
            .email("Invalid email address")
            .required("Email is required"),
        username: yup
            .string()
            .matches(/^@?(\w){1,15}$/, "Invalid username")
            .required("Username is required"),
        phone_number: yup
            .string()
            .matches(/^[0-9]+$/, "Phone number must contain only digits")
            .test(
                "num",
                "Invalid phone number format",
                (val) => !val.startsWith("0")
            )
            .min(10, "Invalid phone number format")
            .max(10, "Invalid phone number format")
            .required("Phone number is required"),
    });
    const params = new URLSearchParams(window.location.search);
    const [referral_code, setReferral_code] = useState(params.get('referral_id') || "");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [initialBioDetails, setInitailBioDetails] = useState({ email: "", phone_number: "", username: "" });

    const { register, handleSubmit, reset, setError, formState: { errors }, } = useForm({
        resolver: yupResolver(biodataSchema),
        defaultValues: {
            email: initialBioDetails.email,
            username: initialBioDetails.username,
            phone_number: initialBioDetails.phone_number,
        },
    });
    useEffect(() => {
        if (socialDetails.email && socialDetails.registration_type !== "") {
            reset({ email: socialDetails.email });
        }
        return () => reset({ email: "" });
    }, [socialDetails, reset]);

    const submitBiodata = (data) => {
        setLoading(true);
        validateBiodata(data.email, data.username, data.phone_number).then((response) => {
            setLoading(false);
            if (response.data) {
                if (!response.data.email.available) {
                    setError(
                        "email",
                        {
                            type: "validate",
                            message: "email already exists",
                        },
                        { shouldFocus: true }
                    );
                }
                if (!response.data.username.available) {
                    setError(
                        "username",
                        {
                            type: "validate",
                            message: "username already exists",
                        },
                        { shouldFocus: true }
                    );
                }
                if (!response.data.phone_number.available) {
                    setError(
                        "phone_number",
                        {
                            type: "validate",
                            message: "phone number already exists",
                        },
                        { shouldFocus: true }
                    );
                }
                if (
                    response.data.email.available &&
                    response.data.username.available &&
                    response.data.phone_number.available
                ) {
                    setBiodata(data);
                }
            }
        }).catch(() => {
            setLoading(false);
        })
    };

    return (
        <div className="biodata-section">
            <FormHeader
                title="Next Level Rewards"
                subtitle="Shop, pay bills take surveys and get rewarded instantly."
            />
            <div className="biodata-section__form">
                <SocialButtons
                    googleText={"Create an account with Google"}
                    facebookText={"Create an account with facebook"}
                    setErrorMessage={setErrorMessage}
                    setSocialDetails={setSocialDetails}
                />
                <TextDivider />
                {/* error message */}
                <p className="text-center error--text">{errorMessage}</p>

                <div className="mb-3">
                    <Label htmlFor="email">Email*</Label>
                    <InputField
                        type="text"
                        placeholder="Enter your email address"
                        register={register}
                        error={errors.email}
                        label="email"
                        disabled={socialDetails.registration_type === "google" || socialDetails.registration_type === "facebook"}
                        required
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="username">Username*</Label>
                    <InputField
                        type="text"
                        placeholder="Enter your chosen username"
                        register={register}
                        label="username"
                        error={errors.username}
                        required
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="phone">Phone Number*</Label>
                    <PhoneInput
                        type="text"
                        inputMode="numeric"
                        placeholder="Enter your phone number"
                        register={register}
                        label="phone_number"
                        error={errors.phone_number}
                        required
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="phone">Referral code (optional)</Label>
                    <InputField
                        type="text"
                        placeholder="Enter referral code"
                        register={register}
                        label="referral_code"
                        defaultValue={referral_code}
                    />
                </div>
                {/* next btn */}
                <Button
                    disabled={loading}
                    onClick={handleSubmit(submitBiodata)}
                    className="btn btn--primary mt-2"
                >
                    {loading ? (
                        <>
                            <FontAwesomeIcon icon="spinner" className="fa-spin mr-2" />
                            Creating profile...
                        </>
                    ) : (
                        "Next"
                    )}
                </Button>
            </div>
            <div className="text-center mt-3 mobile-link" >
                <span>Already have an account? <Link className='purple--text ml-1' to='/login'>Log in</Link></span>
            </div>
        </div>
    );
};

export default BiodataForm;
