import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { filterTransactions } from '../../redux/reducers/transaction.reducer';

import arrow_left from '../../assets/icons/arrow-left.svg';
import { useState } from 'react';

import Dropdown from '../../components/Dropdown/Dropdown';

const TransactionsHeader = ({ wallet_options, handleWalletChange, transactions, filterTransactions }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    // const { transactions } = useSelector((state) => state);
    // const { data } = transactions;

    const tabs = [
        {
            id: 0,
            title: 'All',
            count: transactions !== null ? transactions.length : [],
        },
        {
            id: 1,
            title: 'Credit',
            count:
                transactions !== null
                    ? transactions.filter((data) => data.type === 'CREDIT').length
                    : [],
        },
        {
            id: 2,
            title: 'Debit',
            count:
                transactions !== null
                    ? transactions.filter((data) => data.type === 'DEBIT').length
                    : [],
        },
    ];

    const [activeTab, setActiveTab] = useState(0);

    const handleClick = (id, type) => {
        setActiveTab(id);
        filterTransactions(type);
    };

    return (
        <div className={'transactions--header '}>
            <div
                onClick={() => history.goBack()}
                className="transactions--header-nav"
            >
                <img src={arrow_left} alt="arrow left" />
                <div>
                    <h2 className="transactions--heading">History</h2>
                </div>
            </div>

            <div className={'transactions--header-controls'}>
                {tabs.map(({ title, id, count }) => {
                    return (
                        <div
                            key={id}
                            onClick={() => handleClick(id, title.toUpperCase())}
                            className={`control ${
                                activeTab === id ? 'active' : ''
                            }`}
                        >
                            <span className="control--title">{title}</span>
                            <span className="control--count">{count}</span>
                        </div>
                    );
                })}
                <div style={{ marginLeft: 'auto' }}>
                    <Dropdown
                        onChange={handleWalletChange}
                        options={wallet_options}
                        placeholder="Select wallet"
                        nameKey="label"
                        idKey="value"
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionsHeader;
