import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const ussdSchema = yup
    .object({
        bank: yup.string().required('Select a bank'),
        amount: yup
            .number()
            .required('Amount is required')
            .positive('Invalid amount'),
    })
    .required();

const UssdValidation = (bank, amount) => {
    return useForm({
        resolver: yupResolver(ussdSchema),
        shouldFocusError: true,
        mode: 'onBlur',
        defaultValues: {
            bank: bank,
            amount: amount,
        },
    });
};

export default UssdValidation;
