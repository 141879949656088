import FormHeader from '../../../components/FormHeader';
import Button from '../../../components/Button';
import PinInput from 'react-pin-input';

import './create-pin.scss';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreatePin = ({
    setActiveStep,
    setTransactionPin,
    loading,
    socialDetails,
}) => {
    const transactionPinSchema = yup.object().shape({
        pin: yup
            .string()
            .min(6, 'Transaction pin must be greater than 6 digits')
            .max(6, 'Transaction pin is greater than 6 digits ')
            .required('Transaction pin is required'),
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(transactionPinSchema),
        defaultValues: {
            pin: '',
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const onSubmit = (data) => {
        setTransactionPin(data);
    };

    return (
        <div className="pin-section">
            <FormHeader
                title="Create Transaction Pin"
                subtitle="This will be used for all transactions"
            />
            <div className="pin-section__form">
                <div className="mb-3">
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <PinInput
                                length={6}
                                initialValue={value}
                                onChange={onChange}
                                secret
                                type="numeric"
                                inputMode="number"
                            />
                        )}
                        control={control}
                        name="pin"
                        defaultValue=""
                    />
                    {errors.pin && (
                        <p className="mt-3 text-danger">{errors.pin.message}</p>
                    )}
                </div>
                <div className="btn-section mt-4">
                    <Button
                        onClick={() => {
                            if (
                                socialDetails.registration_type === 'google' ||
                                socialDetails.registration_type === 'facebook'
                            ) {
                                setActiveStep(2);
                            } else {
                                setActiveStep(3);
                            }
                        }}
                        className="btn prev-btn"
                    >
                        Previous
                    </Button>
                    <Button
                        disabled={loading}
                        className="btn btn--primary next-btn"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon
                                    icon="spinner"
                                    className="fa-spin mr-2"
                                />
                                Submiting...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreatePin;
