import httpClient from '../axiosBase/axiosHandler.js';

export const fetchReferrals = () => {
    const response = httpClient.get(`/settings/referral/`);
    return response;
};

export const sendReminder = (email) => {
    const response = httpClient.post(`/settings/remind-referee/`, {email});
    return response;
};
