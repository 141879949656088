import Types from "../constants";

const {
    privacyTypes: {
        GET_PRIVACY_SETTINGS_FAILURE,
        GET_PRIVACY_SETTINGS_REQUEST,
        GET_PRIVACY_SETTINGS_SUCCESS,
        
        UPDATE_PRIVACY_SETTINGS_FAILURE,
        UPDATE_PRIVACY_SETTINGS_REQUEST,
        UPDATE_PRIVACY_SETTINGS_SUCCESS,
    },
} = Types;

export const getPrivacySettingsRequest = () => {
    return {
        type: GET_PRIVACY_SETTINGS_REQUEST,
    };
};

export const getPrivacySettingsSuccess = (payload) => {
    return {
        type: GET_PRIVACY_SETTINGS_SUCCESS,
        payload: payload,
    };
};

export const getPrivacySettingsFailure = (error) => {
    return {
        type: GET_PRIVACY_SETTINGS_FAILURE,
        payload: error,
    };
};

export const updatePrivacySettingsRequest = () => {
    return {
        type: UPDATE_PRIVACY_SETTINGS_REQUEST,
    };
};

export const updatePrivacySettingsSuccess = (payload) => {
    return {
        type: UPDATE_PRIVACY_SETTINGS_SUCCESS,
        payload: payload,
    };
};

export const updatePrivacySettingsFailure = (error) => {
    return {
        type: UPDATE_PRIVACY_SETTINGS_FAILURE,
        payload: error,
    };
};
