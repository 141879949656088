import { Route, useRouteMatch, Switch } from "react-router-dom";

import Surveys from "../webappPages/ActivitiesPage/Surveys/Surveys";
import Questions from "../webappPages/ActivitiesPage/Surveys/Questions";


const SurveyLayout = () => {
    const { path } = useRouteMatch();
    return (
        <div>
            <Switch>
                <Route exact path={path}>
                    <Surveys />
                </Route>
                <Route path={`${path}/start`}>
                    <Questions />
                </Route>
            </Switch>
        </div>
    )
}

export default SurveyLayout;