import httpClient from "../axiosBase/axiosHandler";

export const getBankList = () => {
    return httpClient.get(`/billing-service/bank-list/`);
}

export const resolveAcccountNumber = (payload) => {
    return httpClient.post(`/billing-service/resolve-bank-account/`, payload);
}

export const makeTransafer = (payload) => {
    return httpClient.post(`/billing-service/make-transfer/`, payload);
}

export const getWalletInfo = () => {
    return httpClient.get(`/billing-service/wallet-info/`);
}

export const fetchLatestBalance = () => {
    return httpClient.post(`/billing-service/refresh-balance/`, {});
}

export const getBankUssdCodes = () => {
    return httpClient.get(`/billing-service/transfer-ussd/`);
}
