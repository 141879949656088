import './fund-transfer-page.scss';

import { FaAngleLeft } from 'react-icons/fa';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useStep } from 'react-hooks-helper';
import { useState, useEffect } from 'react';
import { useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getBankList,
    resolveAcccountNumber,
} from '../../services/bank-transfer-service';
import { useSelector } from 'react-redux';
import useNairaWallet from '../../hooks/core/FundTransfer/useNairaWallet';
import useModal from '../../hooks/useModal';
import Container from '../../components/Container';
import InputField from '../../components/InputField';
import Form from '../../components/Form';
import FormGroup from '../../components/FormGroup';
import Label from '../../components/Label';
import Button from '../../components/Button';
import FundTransferCheckout from './FundTransferCheckout';
import Dropdown from '../../components/Dropdown/Dropdown';
import FundWallet from '../../components/FundWalletModal/FundWallet';
import NubanVerification from '../../components/VerificationModals/NubanVerification/NubanVerification';


const FundTransferPage = () => {
    const schema = Yup.object().shape({
        amount: Yup.string().required('Amount is required'),
        account_number: Yup.string()
            .min(10, 'Invalid format')
            .required('Account number is required'),
        bank: Yup.string().required('Bank is required'),
    });

    const steps = [
        { id: 1, label: 'Transfer' },
        { id: 2, label: 'Checkout' },
    ];

    const { step, navigation } = useStep({ steps, initialStep: 0 });
    const history = useHistory();
    const [loadingBanks, setLoadingBanks] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [resolvingAccountNumber, setResolvingAccountNumber] = useState(false);
    const [accountResolveError, setAccountResolveErrror] = useState('');
    const [accountNumberVerified, setAccountNumberVerified] = useState(false);
    const [accountDetails, setAccountDetails] = useState({});
    const [bankDetails, setBankDetails] = useState({});
    const { data } = useNairaWallet();

    const { has_kyc } = useSelector((state) => state.auth?.user);
    // const { url } = useRouteMatch();

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
    const { withdrawable_balance } = data !== null && data;
    const wallet_balance = parseFloat(withdrawable_balance);

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            amount: '',
            account_number: '',
            bank: '',
        },
    });

    const handleFetchBanks = async () => {
        setLoadingBanks(true);
        try {
            const res = await getBankList();
            if (res) {
                setLoadingBanks(false);
                setBankList(res.data?.data);
            }
        } catch (error) {
            setLoadingBanks(false);
            if (error.response) {
                const { detail } = error.response.data;
                console.log(detail);
            }
        }
    };

    const handleResolveAccountNumber = async (data) => {
        setAccountNumberVerified(false);
        setResolvingAccountNumber(true);
        setAccountResolveErrror('');
        try {
            const res = await resolveAcccountNumber(data);
            if (res.status === 200) {
                setResolvingAccountNumber(false);
                setAccountNumberVerified(true);
                setAccountResolveErrror('');
                setAccountDetails(res.data?.data);
            }
        } catch (error) {
            setResolvingAccountNumber(false);
            setAccountNumberVerified(false);
            if (error.response) {
                const { detail } = error.response.data;
                setAccountResolveErrror(detail);
            }
        }
    };

    const bankCode = useWatch({ control, name: 'bank' });
    const accountNumberField = register('account_number', { required: true });

    const handleAccountNumberChange = (event) => {
        setAccountNumberVerified(false);
        const value = event.target.value;
        if (value.length === 10 && bankCode !== '') {
            handleResolveAccountNumber({
                account_number: value,
                bank_code: bankCode,
            });
        }
    };

    const handleBackButton = () => {
        if (step.label === 'Transfer') {
            history.replace('/dashboard');
        } else {
            navigation.previous();
        }
    };

    const onSubmit = (data) => {
        if (data.amount > wallet_balance) {
            return setError(
                'amount',
                {
                    type: 'manual',
                    message: "You don't have enough balance in your wallet",
                },
                { shouldFocus: true }
            );
        }

        if (
            !accountNumberVerified ||
            accountResolveError ||
            accountDetails === null
        ) {
            return setError(
                'account_number',
                'required',
                'Account number is required'
            );
        }

        setAccountDetails((prevState) => ({
            ...prevState,
            ...data,
            wallet_balance
        }));

        navigation.previous();
    };

    useEffect(() => {
        handleFetchBanks(); //
    }, []);

    return (
        <div className="fund-transfer">
            <Container>
                <div
                    onClick={handleBackButton}
                    className="fund-transfer__header"
                >
                    <FaAngleLeft className="pt-1 fund-transfer__header__icon" />
                    <h2 className="pt-1">Transfer</h2>
                </div>
                <p>Kindly provide the details for your withdrawal</p>
                {step.label === 'Transfer' && (
                    <>
                        {loadingBanks ? (
                            <FontAwesomeIcon
                                className="d-block my-4 mx-auto purple--text text-center"
                                icon={['fas', 'spinner']}
                                spin
                            />
                        ) : (
                            <Form
                                className="fund-transfer__form"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <FormGroup>
                                    <Label>Wallet*</Label>
                                    <div className="fund-transfer__form__wallet">
                                        <div className="wallet__top">
                                            <p className="wallet__name">
                                                Naira Wallet
                                            </p>
                                            <input
                                                type="radio"
                                                className="d-none"
                                            />
                                            <div className="wallet__input"></div>
                                        </div>
                                        <div className="wallet__details">
                                            <p className="wallet__name">
                                                Naira Wallet
                                            </p>
                                            <h2 className="wallet__balance">
                                                N
                                                {wallet_balance?.toLocaleString()}
                                            </h2>
                                        </div>
                                        <div className="wallet__topup">
                                            <div
                                                onClick={() => {
                                                    if (!has_kyc) {
                                                        toggleVerifyModal();
                                                    } else {
                                                        setModalOpen(true)
                                                    }
                                                }}
                                                className="wallet__topup__button"
                                            >
                                                Top up wallet
                                            </div>
                                            <div
                                                onClick={() => {
                                                    if (!has_kyc) {
                                                        toggleVerifyModal();
                                                    } else {
                                                        setModalOpen(true)
                                                    }
                                                }}
                                                className="wallet__topup__button-mobile"
                                            >
                                                <span className="pt-2">
                                                    Top up wallet
                                                </span>
                                                <FontAwesomeIcon
                                                    className="ml-2 mt-1"
                                                    icon={'angle-right'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Bank*</Label>
                                    <Controller
                                        render={({
                                            field: { value, onChange, ref },
                                        }) => (
                                            <Dropdown
                                                value={bankList.find(
                                                    (val) => val.value === value
                                                )}
                                                onChange={(val) => {
                                                    onChange(val.value);
                                                    setBankDetails(val);
                                                }}
                                                placeholder="Select Bank"
                                                options={bankList}
                                                nameKey="bank_name"
                                                idKey="bank_code"
                                            />
                                        )}
                                        control={control}
                                        name="bank"
                                        defaultValue=""
                                    />
                                    {errors.bank && (
                                        <p className="text--danger mt-2">
                                            {errors.bank.message}
                                        </p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Account Number*</Label>
                                    <div style={{ position: 'relative' }}>
                                        <div className="account__number__input-field">
                                            <input
                                                type="text"
                                                name="account_number"
                                                inputMode="numeric"
                                                {...accountNumberField}
                                                onChange={(event) => {
                                                    accountNumberField.onChange(
                                                        event
                                                    );
                                                    handleAccountNumberChange(
                                                        event
                                                    );
                                                }}
                                                placeholder="Account Number"
                                            />
                                        </div>
                                        {errors.account_number && (
                                            <p className="text-danger mt-2">
                                                {errors.account_number.message}
                                            </p>
                                        )}
                                        {accountResolveError && (
                                            <p className="text-danger mt-2">
                                                {accountResolveError}
                                            </p>
                                        )}
                                        {accountNumberVerified && (
                                            <p
                                                style={{
                                                    color: '#766df4',
                                                }}
                                            >
                                                {accountDetails.account_name}
                                            </p>
                                        )}
                                        {resolvingAccountNumber && (
                                            <FontAwesomeIcon
                                                icon={['fas', 'spinner']}
                                                size="sm"
                                                spin
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '10px',
                                                    color: '#766df4',
                                                }}
                                            />
                                        )}
                                        {accountNumberVerified && (
                                            <FontAwesomeIcon
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '10px',
                                                    color: 'green',
                                                }}
                                                icon={'check'}
                                                size="sm"
                                            />
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Amount (NGN)*</Label>
                                    <InputField
                                        register={register}
                                        required
                                        inputMode="numeric"
                                        label="amount"
                                        type="text"
                                        placeholder="Amount"
                                        error={errors.amount}
                                    />
                                </FormGroup>
                                <Button
                                    disabled={
                                        accountDetails === null ||
                                        !accountNumberVerified
                                    }
                                    className="btn btn--primary"
                                >
                                    Proceed to Transfer
                                </Button>
                            </Form>
                        )}
                    </>
                )}
                {step.label === 'Checkout' && (
                    <FundTransferCheckout
                        accountDetails={accountDetails}
                        bankDetails={bankDetails}
                    />
                )}
            </Container>
            <NubanVerification
                verifyModalOpen={verifyModalOpen}
                setVerifyModalOpen={setVerifyModalOpen}
            />
            <FundWallet setModalOpen={setModalOpen} modalOpen={modalOpen}/>
        </div>
    );
};

export default FundTransferPage;
