import './change-pin.scss';
import dialog_img from '../../../../assets/icons/celebration-confetti.svg';

import { useStep } from 'react-hooks-helper';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updatePin } from '../../../../services/transaction-pin.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModal from '../../../../hooks/useModal';
import { useHistory } from 'react-router-dom';

import NewPin from './NewPin';
import ConfirmPin from './ConfirmPin';
import CurrentPin from './CurrentPin';
import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import ResponseModal from '../../../../components/ResponseModal';
import ModalClose from '../../../../components/ModalClose';

const steps = [
    {
        id: 1,
        title: 'Current Pin',
        description: 'Enter your current pin to begin',
    },
    {
        id: 2,
        title: 'New Pin',
        description: 'Enter your new pin ',
    },
    {
        id: 3,
        title: 'Confirm Pin',
        description: 'Enter your new pin to save changes',
    },
];

const ChangePin = () => {
    const { step, navigation } = useStep({ steps, initialStep: 0 });
    const [loading, setLoading] = useState(false);
    const [updatePinError, setUpdatePinError] = useState(null);

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [successModalOpen, setSuccessModalOpen, toggleSuccessModal] =
        useModal();

    const history = useHistory();

    const schema = yup.object().shape({
        old_pin: yup
            .string()
            .min(6, 'Invalid transaction pin')
            .max(6, 'Invalid transaction pin')
            .required('Current pin is required'),
        new_pin: yup
            .string()
            .min(6, 'Pin must be 6 digits')
            .max(6, 'Pin must be 6 digits')
            .required('New pin is required'),
        confirm_pin: yup
            .string()
            .min(6, 'Invalid transaction pin')
            .max(6, 'Invalid transaction pin')
            .required('Confirm pin is required'),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            old_pin: '',
            new_pin: '',
            confirm_pin: '',
        },
        resolver: yupResolver(schema),
    });

    function renderSteps() {
        switch (step.title) {
            case 'Current Pin':
                return (
                    <CurrentPin
                        navigation={navigation}
                        control={control}
                        errors={errors}
                    />
                );
            case 'New Pin':
                return (
                    <NewPin
                        navigation={navigation}
                        control={control}
                        errors={errors}
                    />
                );
            case 'Confirm Pin':
                return (
                    <ConfirmPin
                        navigation={navigation}
                        control={control}
                        errors={errors}
                    />
                );
            default:
                return null;
        }
    }

    const handleClick = () => {
        if (step.title === 'Current Pin' && errors.old_pin) {
            return;
        } else if (step.title === 'New Pin' && errors.new_pin) {
            return;
        } else if (step.title === 'Confirm Pin' && errors.confirm_pin) {
            return;
        } else {
            navigation.next();
        }
    };

    const handleUpdatePin = async (data) => {
        setLoading(true);
        try {
            const res = await updatePin(data);
            if (res) {
                setLoading(false);
                toggleSuccessModal();
            }
        } catch (error) {
            setLoading(false);
            toggleModal();
            if (error.response) {
                if (error.response.data) {
                    const message = error.response.data.detail;
                    setUpdatePinError(message);
                }
            } else {
                setUpdatePinError(error?.message);
            }
        }
    };

    const onSubmit = async (data) => {
        await handleUpdatePin(data);
    };

    return (
        <div className="change-pin">
            <h3>Change Pin</h3>
            <p>{step.description}</p>
            <Form
                className="change-pin__form"
                onSubmit={handleSubmit(onSubmit)}
            >
                {renderSteps()}
                {step.id === 3 || step.title === 'Confirm Pin' ? (
                    <Button
                        disabled={loading}
                        type={'submit'}
                        className="btn btn--primary"
                    >
                        {loading ? (
                            <FontAwesomeIcon
                                icon={'spinner'}
                                className="fa-spin mr-2"
                            />
                        ) : null}
                        <span>{loading ? 'Updating Pin...' : 'Finish'}</span>
                    </Button>
                ) : (
                    <Button
                        onClick={handleClick}
                        className="btn btn--primary"
                        type={'button'}
                    >
                        Next
                    </Button>
                )}
            </Form>
            <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width={'25%'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header">
                        <ModalClose onClick={() => setModalOpen(false)} />
                    </div>
                    <ResponseModal status={'fail'} message={updatePinError} />
                </div>
            </CustomModal>
            <CustomModal
                isActive={successModalOpen}
                closeModal={() => setSuccessModalOpen(false)}
                width="22%"
            >
                <div className="change-pin__success-dialog">
                    <img src={dialog_img} alt="" />
                    <div className="change-pin__success-dialog__content">
                        <h3>Changes saved</h3>
                        <p className="my-3">
                            You can now make use of your new <br /> PIN for all
                            transactions
                        </p>
                        <Button
                            onClick={() =>
                                history.push('/settings/transaction-pin')
                            }
                            className="btn btn--primary"
                        >
                            Return to Settings
                        </Button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ChangePin;
