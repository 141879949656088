import useModal from '../hooks/useModal';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';

import Button from '../components/Button';
import PasswordInput from '../components/PasswordInput';
import InputField from '../components/InputField';
import Form from '../components/Form';
import FormGroup from '../components/FormGroup';
import Label from '../components/Label';
import SocialButtons from './SocialButtons';
import { setAuthModalState } from '../redux/reducers/errorHandler.reducer';
import { fetchToken } from '../services/auth.service';
import GoogleRecaptchaV3 from './GoogleRecaptchaV3';

const AuthorizationModal = () => {
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const authDialog = useSelector((state) => state.errorHandler.showAuthModal);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [erroMessage, setErrorMessage] = useState('');
    const [loginData, setLoginData] = useState({});
    const recaptchaRef = useRef();
    const loginSchema = yup.object().shape({
        email: yup.string().required('Email or Username is required'),
        password: yup.string().required('password is required'),
    });
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(loginSchema), mode: 'all' });

    useEffect(() => {
        if (loginData.email !== undefined) {
            setLoading(true);
            recaptchaRef.current.handleLoaded();
        }
    }, [loginData]);

    const grantUserAccess = (response) => {
        localStorage.setItem('access_token', response.access);
        localStorage.setItem('refresh_token', response.refresh);
        dispatch(setAuthModalState({ showAuthModal: false }));
        window.location.reload();
    };

    const handleLogin = (recaptchaToken) => {
        if (loginData.email === undefined) return;
        fetchToken({
            email: loginData.email,
            password: loginData.password,
            'g-recaptcha-response': recaptchaToken,
        })
            .then((response) => {
                grantUserAccess(response.data);
            })
            .catch((error) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                setLoading(false);
                setErrorMessage(error.response.data.detail);

                if (!navigator.onLine) {
                    setErrorMessage(
                        'No internet connection, please check your network connectivity'
                    );
                }
            });
    };

    const setLoginDetails = (data) => {
        setLoginData((prevConfig) => {
            return {
                ...prevConfig,
                ...{ email: data.email, password: data.password },
            };
        });
    };

    return (
        <div>
            <CustomModal isActive={authDialog} width={'450px'}>
                <div className="modal__container__content">
                    <h3 className="mt-3 mb-3">Token Expired!</h3>
                    <p className="error--text">{erroMessage}</p>
                    <SocialButtons
                        googleText={'Login with Google'}
                        facebookText={'Login with facebook'}
                        setErrorMessage={setErrorMessage}
                        grantUserAccess={grantUserAccess}
                    />
                    <p className="mt-4">OR</p>
                    <Form
                        onSubmit={handleSubmit(setLoginDetails)}
                        className="mt-3"
                        style={{ textAlign: 'left' }}
                    >
                        {/* google recaptcha */}
                        <GoogleRecaptchaV3
                            ref={recaptchaRef}
                            resolved={handleLogin}
                            onReset={() => setLoading(false)}
                            page="login"
                        />
                        <div className="mb-4">
                            <FormGroup>
                                <Label htmlFor="email">Email or Username</Label>
                                <InputField
                                    type="text"
                                    id="email"
                                    placeholder="Enter Email or Username"
                                    register={register}
                                    required
                                    label="email"
                                    error={errors.email}
                                />
                            </FormGroup>
                        </div>
                        <div className="mb-4">
                            <FormGroup>
                                <Label htmlFor="password">Password</Label>
                                <PasswordInput
                                    placeholder="Enter password"
                                    id="password"
                                    register={register}
                                    label="password"
                                    error={errors.password}
                                    required
                                />
                            </FormGroup>
                        </div>
                        <Button
                            disabled={loading}
                            className="btn btn--primary btn--login"
                        >
                            {loading ? (
                                <FontAwesomeIcon
                                    icon="spinner"
                                    className="fa-spin"
                                />
                            ) : null}
                            {loading ? 'Authorizing' : 'Authorize'}
                        </Button>
                    </Form>
                </div>
            </CustomModal>
        </div>
    );
};
export default AuthorizationModal;
