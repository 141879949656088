import * as NewsActions from "../actions/news.actions";
import Types from "../constants";
import NewsService from "../../services/news.service";

const { newsTypes } = Types;
const {
  FETCH_ALL_ARTICLES,
  FETCH_ARTICLES,
  FETCH_ARTICLE,
  FETCH_ARTICLE_LIKES_COUNT,
  FETCH_COMMENTS,
  ADD_COMMENT,
  ARTICLES_FINISHED,
  LIKE_ARTICLE,
  NEWS_REQUEST_FAILURE,
  CLEAR_ARTICLES,
  LOAD_MORE_ARTICLES,
} = newsTypes;
const {
  fetchAllArticles,
  fetchArticle,
  fetchArticleLikesCount,
  fetchArticles,
  fetchComments,
  addNewComment,
  likeArticle,
  requestFailure,
} = NewsActions;

const initialState = {
  allArticles: [],
  articles: [],
  selectedArticle: {
    article: null,
    comments: [],
    articleLikesCount: 0,
    articleLiked: false,
  },
  loading: true,
  error: false,
  articlesFinished: false,
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_ARTICLES:
      return { ...state, loading: false, allArticles: action.payload };
    case FETCH_ARTICLES:
      return {
        ...state,
        loading: false,
        articles: [...state.articles, ...action.payload],
      };
    case LOAD_MORE_ARTICLES:
      return {
        ...state,
        loading: false,
        articles: [...state.articles, ...action.payload],
      };
    case FETCH_ARTICLE:
      return { ...state, loading: false, selectedArticle: action.payload };
    case FETCH_COMMENTS:
      return {
        ...state,
        loading: false,
        selectedArticle: { ...state.selectedArticle, comments: action.payload },
      };
    case FETCH_ARTICLE_LIKES_COUNT:
      return {
        ...state,
        loading: false,
        selectedArticle: {
          ...state.selectedArticle,
          articleLikesCount: action.payload,
        },
      };
    case LIKE_ARTICLE:
      return {
        ...state,
        loading: false,
        selectedArticle: { ...state.selectedArticle, articleLiked: true },
      };
    case NEWS_REQUEST_FAILURE:
      return { ...state, error: true };
    case ARTICLES_FINISHED:
      return { ...state, loading: false, articlesFinished: action.payload };
    case CLEAR_ARTICLES:
      return { ...state, articles: [] };
    default:
      return state;
  }
};

export const getAllArticles = () => {
  return async (dispatch) => {
    try {
      const response = await NewsService.fetchAllArticles();
      if (response.data) {
        const articles = response.data.data;
        dispatch(fetchAllArticles(articles));
      }
    } catch (error) {
      if (error.response) {
        dispatch(requestFailure(error));
      }
      dispatch(requestFailure(error));
    }
  };
};

export const getArticles = (page) => {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await NewsService.fetchArticles({ page });
        if (response.data) {
          const articles = response.data.data;
          // if (page > 1) {
          //   dispatch({ type: LOAD_MORE_ARTICLES, payload: articles });
          // }
          if (articles.length === 0) {
            dispatch({
              type: "ARTICLES_FINISHED",
              payload: true,
            });
          }
          dispatch(fetchArticles(articles));
          resolve(articles);
        }
      } catch (error) {
        if (error.response) {
          dispatch(requestFailure(error));
          reject(error);
        }
        dispatch(requestFailure(error));
        reject(error);
      }
    });
  };
};

export const getArticle = (articleId) => {
  return async (dispatch) => {
    try {
      const response = await NewsService.fetchArticle(articleId);
      if (response.data) {
        const article = response.data.data;
        dispatch(fetchArticle(article));
      }
    } catch (error) {
      if (error.response) {
        dispatch(requestFailure(error));
      }
      dispatch(requestFailure(error));
    }
  };
};

export const getComments = (articleId) => {
  return async (dispatch) => {
    try {
      const response = await NewsService.fetchComments(articleId);
      if (response.data) {
        const comments = response.data.data;
        dispatch(fetchComments(comments));
      }
    } catch (error) {
      if (error.response) {
        dispatch(requestFailure(error));
      }
      dispatch(requestFailure(error));
    }
  };
};
