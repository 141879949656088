import Types from "../constants";

const { forgortPasswordTypes } = Types;

export const forgotPasswordRequest = () => {
    return {
        type: forgortPasswordTypes.FORGOT_PASSWORD_REQUEST,
    };
};

export const forgotPasswordSuccess = (payload) => {
    return {
        type: forgortPasswordTypes.FORGOT_PASSWORD_SUCCESS,
        payload: payload,
    };
};

export const forgotPasswordFailure = (error) => {
    return {
        type: forgortPasswordTypes.FORGOT_PASSWORD_FAILURE,
        payload: error,
    };
};
