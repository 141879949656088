import Label from '../../Label';
import PinInput from 'react-pin-input';
import { Controller } from 'react-hook-form';

const ConfirmPin = ({ control, error }) => {
    return (
        <div className="tnx-pin-inputs">
            <Label>Confirm Transaction Pin*</Label>
            <Controller
                render={({ field: { value, onChange } }) => (
                    <PinInput
                        length={6}
                        initialValue={value}
                        onChange={onChange}
                        secret
                        type="numeric"
                        inputMode="numeric"
                    />
                )}
                control={control}
                name="confirm_pin"
                defaultValue=""
            />
            {error && <p className="text-danger">{error.message}</p>}
        </div>
    );
};

export default ConfirmPin;
