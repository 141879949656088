import PinInput from 'react-pin-input';
import Button from '../../../../components/Button';

import { Controller } from 'react-hook-form';

const CurrentPin = ({ navigation, control, errors }) => {
    return (
        <div className="current-pin">
            <Controller
                render={({ field: { value, onChange } }) => (
                    <PinInput
                        length={6}
                        initialValue={value}
                        onChange={onChange}
                        secret
                        type="numeric"
                        inputMode="numeric"
                    />
                )}
                control={control}
                name="old_pin"
                defaultValue={''}
            />
            {errors.old_pin && (
                <p className="mt-3 text-danger">{errors.old_pin.message}</p>
            )}
            {/* <Button className="btn btn--primary" type={'button'}>
                Next
            </Button> */}
        </div>
    );
};

export default CurrentPin;
