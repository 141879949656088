import { useForm, Controller, useWatch } from 'react-hook-form';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    verifyAccountService,
    getBankList,
} from '../services/verification.service';
import { getUserDetails } from '../redux/reducers/auth.reducer';
import { useDispatch } from 'react-redux';
import { invertDateFormat } from '../helpers/invertDateFormat.js';
import { useHistory } from 'react-router-dom';

import Form from './Form';
import FormGroup from './FormGroup';
import InputField from './InputField';
import SelectInput from './SelectInput';
import Label from './Label';
import Button from './Button';
import ModalClose from './ModalClose';
import ResponseModal from './ResponseModal';
import useModal from '../hooks/useModal';
import ErrorText from './ErrorText';

const VerifyAccount = (props) => {
    const verifySchema = yup.object().shape({
        first_name: yup.string().required('First name is required'),
        last_name: yup.string().required('Last name is required'),
        verification_type: yup.string().required('This field is required'),
        identification_number: yup.string().required('This field is required'),
        bank: yup.string().required('This field is required'),
        dob: yup.string().required('This field is required'),
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(verifySchema) });

    const dispatch = useDispatch();
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [loading, setLoading] = useState(false);
    const [verificationError, setVerificationError] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusImage, setStatusImage] = useState('fail');
    const [verificationSuccess, setVerificationSucess] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [loadingBankList, setLoadingBankList] = useState(false);

    const history = useHistory();

    const verification_type = useWatch({
        control,
        name: 'verification_type',
        defaultValue: 'Bank Account Number - Recommended',
    });

    const onSubmit = (data) => {
        if (verification_type === 'Bank Account Number - Recommended') {
            data.verification_type = 'Nuban';
        }
        setLoading(true);
        verifyAccountService({
            firstname: data.first_name,
            lastname: data.last_name,
            middlename:
                data.middle_name !== null || data.middle_name !== ''
                    ? data.middle_name
                    : null,
            identification_number: data.identification_number,
            verification_type: data.verification_type,
            bank: data.bank,
            birthdate: data.dob !== undefined && invertDateFormat(data.dob),
        })
            .then(() => {
                setLoading();
                setVerificationError(true);
                setStatusImage('pass');
                setStatusMessage('Account verification successfull!');
                setVerificationSucess(true);
                dispatch(getUserDetails());
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 422 ||
                        error.response.status === 302
                    ) {
                        setVerificationError(true);
                        setStatusImage('fail');
                        setStatusMessage(error.response.data.detail);
                    }
                }
            });
    };

    const isBankVerification =
        verification_type === 'Bank Account Number - Recommended';

    useEffect(() => {
        const handleGetBankList = () => {
            setLoadingBankList(true);
            getBankList()
                .then((res) => {
                    setLoadingBankList(false);
                    if (res) {
                        setBankList(res.data.data);
                    }
                })
                .catch(() => setLoadingBankList(false));
        };

        handleGetBankList();
    }, [isBankVerification]);

    const bankField = (
        <FormGroup key={'bank'}>
            <Label>Select Bank</Label>
            <Controller
                render={({ field: { value, onChange } }) => (
                    <SelectInput
                        placeholder="Select Bank"
                        options={bankList}
                        value={value}
                        onChange={onChange}
                        arrayOfObject={true}
                        nameKey={'name'}
                        idKey={'id'}
                    />
                )}
                name="bank"
                control={control}
                defaultValue="Select Bank"
            />
        </FormGroup>
    );
    const idNumberField = (
        <FormGroup key={'idnumber'}>
            <Label>
                {verification_type === 'Bank Account Number - Recommended'
                    ? 'Account Number*'
                    : 'ID Number*'}
            </Label>
            <InputField
                register={register}
                label="identification_number"
                placeholder={
                    verification_type === 'Bank Account Number - Recommended'
                        ? 'Enter your account number'
                        : 'Enter your ID Number'
                }
                required
                error={errors.identification_number}
            />
        </FormGroup>
    );
    const dobField = (
        <FormGroup key={'dob'}>
            <Label>DOB*</Label>
            <InputField
                register={register}
                label="dob"
                placeholder="Enter your DOB"
                type="date"
                required
                error={errors.dob}
            />
        </FormGroup>
    );

    function renderFormFields() {
        if (verification_type === 'Bank Account Number - Recommended') {
            return [bankField, idNumberField, dobField];
        } else {
            return [idNumberField, dobField];
        }
    }

    return (
        <div className="verify--account-modal">
            <div className="verify--account-modal-container">
                <div className="verify--account-modal-header">
                    <ModalClose onClick={() => props.setModalOpen(false)} />
                    <h3 className="verify--account-modal-heading">
                        Verify its you
                    </h3>
                    <p className="verify--account-modal-description">
                        Please provide the following information for your
                        verification:
                    </p>
                </div>
                <div className="verify--account-modal-body">
                    <Form
                        className="verify--account-form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormGroup>
                            <Label>First Name*</Label>
                            <InputField
                                register={register}
                                label="first_name"
                                placeholder="Enter your first name"
                                required
                                error={errors.first_name}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last name*</Label>
                            <InputField
                                register={register}
                                label="last_name"
                                placeholder="Enter your last name"
                                error={errors.last_name}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Middle Name</Label>
                            <InputField
                                register={register}
                                label="middle_name"
                                placeholder="Enter your middle name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Verification Type*</Label>
                            <Controller
                                render={({ field: { value, onChange } }) => (
                                    <SelectInput
                                        placeholder="Select verification type"
                                        options={[
                                            'Voters Card',
                                            'Drivers License',
                                            'Bank Account Number - Recommended',
                                            'NIN'
                                        ]}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                                name="verification_type"
                                control={control}
                                defaultValue="Bank Account Number - Recommended"
                            />
                            {errors.verification_type && (
                                <ErrorText
                                    message={errors.verification_type.message}
                                />
                            )}
                        </FormGroup>
                        {renderFormFields()}

                        <Button
                            type={'submit'}
                            className="btn btn--primary"
                            disabled={loading}
                        >
                            {loading ? (
                                <span>
                                    <FontAwesomeIcon
                                        icon="spinner"
                                        className="fa-spin mr-2"
                                    />
                                    Verifying...
                                </span>
                            ) : (
                                'Verify Now'
                            )}
                        </Button>
                    </Form>
                </div>
            </div>
            {/* status modal */}
            <CustomModal
                isActive={verificationError}
                closeModal={() => {
                    setVerificationError(false);
                }}
                width="350px"
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose
                            onClick={() => {
                                setVerificationError(false);
                                if (verificationSuccess) {
                                    props.setModalOpen(false);
                                }
                            }}
                        />
                    </div>
                    <ResponseModal message={statusMessage} status={statusImage}>
                        {statusMessage ===
                            'User has not taken the profile survey' && (
                            <div className='px-4'>
                                <Button onClick={() => history.push('/activities/surveys')} className="btn btn--primary mt-3">
                                    Take Profile Survey
                                </Button>
                            </div>
                        )}
                    </ResponseModal>
                </div>
            </CustomModal>
        </div>
    );
};

export default VerifyAccount;
