import Types from "../constants";

const { surveyTypes } = Types;

export const fetchSurveyRequest = () => {
    return {
        type: surveyTypes.FETCH_SURVEY_REQUEST,
    };
};

export const fetchSurveySuccess = (payload) => {
    return {
        type: surveyTypes.FETCH_SURVEY_SUCCESS,
        payload: payload,
    };
};

export const fetchSurveyFailure = (error) => {
    return {
        type: surveyTypes.FETCH_SURVEY_FAILURE,
        payload: error,
    };
};

export const fetchSurveyDetailsRequest = () => {
    return {
        type: surveyTypes.FETCH_SURVEY_DETAILS_REQUEST,
    };
};

export const fetchSurveyDetailsSuccess = (payload) => {
    return {
        type: surveyTypes.FETCH_SURVEY_DETAILS_SUCCESS,
        payload: payload,
    };
};

export const fetchSurveyDetailsFailure = (error) => {
    return {
        type: surveyTypes.FETCH_SURVEY_DETAILS_FAILURE,
        payload: error,
    };
};

export const submitSurveyRequest = () => {
    return {
        type: surveyTypes.SUBMIT_SURVEY_REQUEST,
    };
};

export const submitSurveySuccess = (payload) => {
    return {
        type: surveyTypes.SUBMIT_SURVEY_SUCCESS,
        payload: payload,
    };
};

export const submitSurveyFailure = (error) => {
    return {
        type: surveyTypes.SUBMIT_SURVEY_FAILURE,
        payload: error,
    };
};

export const fetchPollsRequest = () => {
    return {
        type: surveyTypes.FETCH_POLLS_REQUEST,
    };
};

export const fetchPollsSuccess = (payload) => {
    return {
        type: surveyTypes.FETCH_POLLS_SUCCESS,
        payload: payload,
    };
};

export const fetchPollsFailure = (error) => {
    return {
        type: surveyTypes.FETCH_POLLS_FAILURE,
        payload: error,
    };
};