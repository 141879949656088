import failed_icon from "../assets/images/failure-icon.svg";
import success_icon from "../assets/images/success-icon.svg";

const ResponseModal = ({ message, status, children }) => {
    return (
        <div>
            <img src={`${(status === "pass") ? success_icon : failed_icon}`} alt="" className="status-image" />
            <h4>{message}</h4>
            {children}
        </div>
    );
};

export default ResponseModal;
