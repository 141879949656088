import TableHead from "./TableHead";
import TableBody from "./TableBody";

const Table = ({ columns, rows }) => {
    return (
        <table className="table">
            <TableHead columns={columns} />
            <TableBody rows={rows} />
        </table>
    );
};

export default Table;
