import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faSpinner,
    faAngleRight,
    faAngleLeft,
    faCheck,
    faLock,
    faExclamation,
    faBars,
    faChevronLeft,
    faHeart,
    faEllipsisV,
    faEnvelope,
    faArrowRight,
    faRedoAlt,
    faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';

import { faShopify } from '@fortawesome/free-brands-svg-icons';
library.add(
    faSpinner,
    faAngleLeft,
    faArrowRight,
    faAngleRight,
    faCheck,
    faShopify,
    faLock,
    faExclamation,
    faBars,
    faChevronLeft,
    faHeart,
    farHeart,
    faEllipsisV,
    faEnvelope,
    faRedoAlt,
    faExclamationCircle
);
