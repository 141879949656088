import Types from "../constants";
import {
    fetchDashboardFailure,
    fetchDashboardRequest,
    fetchDashboardSuccess,
} from "../actions/dashboard.action";

import { fetchDashboard } from "../../services/dashboard.service";


const {
    FETCH_DASHBOARD_FAILURE,
    FETCH_DASHBOARD_REQUEST,
    FETCH_DASHBOARD_SUCCESS,
} = Types.dashboardTypes;

const initialState = {
    loading: false,
    data: {has_completed_profile_survey: true, has_bank:false},
    error: null,
};

export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            };
        case FETCH_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getDashboard = () => {
    return (dispatch) => {
        dispatch(fetchDashboardRequest());
        fetchDashboard()
            .then((response) => {
                dispatch(fetchDashboardSuccess(response.data));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(fetchDashboardFailure(error.response.data));
                } else {
                    dispatch(fetchDashboardFailure(error.message));
                }
            });
    };
};
