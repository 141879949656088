import Types from "../constants";

const {
    airtimeTypes: { SET_AIRTIME_FORM },
} = Types;

const initialState = {
    amount: "",
    funding_channel: "",
    phone_number: "",
    network: "",
    pin: "",
    points_to_earn: 0,
};

export const airtimeFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AIRTIME_FORM:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
