import React, { Component } from "react";
import PropTypes from "prop-types";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";

class FreestarAds extends Component {
  componentDidMount() {
    window.freestar.queue.push(function () {
      window.freestar.deleteStickyFooter();
    });
  }

  render() {
    const { placementName, slotId, publisher, classNames } = this.props;
    return (
      <div style={{ width: "105%", marginLeft: "-2.5%", zoom: "115%" }}>
        <FreestarAdSlot
          publisher={publisher}
          placementName={placementName}
          slotId={slotId}
          classList={classNames}
        />
      </div>
    );
  }
}

FreestarAds.propTypes = {
  slotId: PropTypes.string,
  size: PropTypes.string,
};

FreestarAds.defaultProps = {
  classNames: "",
};

export default FreestarAds;