
const ErrorText = ({ message }) => {
    return (
        <span className={`error--feedback ${message ? "show" : ""}`}>
            {message}
        </span>
    );
};

export default ErrorText;
