import { useSelector } from 'react-redux';

import './UserProfileCard.scss';
import user_img from '../../assets/icons/profile-photo-icon.svg';

const UserProfileCard = () => {
    const { user } = useSelector((state) => state.auth);
    const { username, email, phone_number } = user !== null && user;
    return (
        <div className="user-profile-card">
            <div className="user-profile-card__image">
                <img src={user_img} alt="profile" />
            </div>
            <div className="user-profile-card__details">
                <h5 className="user-profile-card__details__name mb-0">{username}</h5>
                <p>
                    <span className="user-profile-card__details__email">{email}</span>|
                    <span className="user-profile-card__details__phone">
                        {phone_number}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default UserProfileCard;
