import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { fetchState, fetchCountryStates } from "../../../redux/reducers/country-states.reducer";
import Label from "../../../components/Label";
import Form from "../../../components/Form";
import SelectInput from "../../../components/SelectInput";
import Button from "../../../components/Button";
import ErrorText from "../../../components/ErrorText";
import CheckBox from "../../../components/CheckBox";
import FormHeader from "../../../components/FormHeader";

const LocationForm = ({ setActiveStep, setLocations, registrationType, loading }) => {
    const dispatch = useDispatch();
    const { states, selectedState } = useSelector((state) => state.states);
    const [initialLocationdata, setInitialLocationdata] = useState({ state: "", city: "", local_government: "" });
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [subscribeUpdate, setSubscribeUpdate] = useState(true);

    const locationSchema = yup.object().shape({
        state: yup.string().required("Select a state"),
        city: yup.string().required("Select nearest city"),
        local_government: yup.string().required("Select local government"),
    });

    const { handleSubmit, formState: { errors }, control, } = useForm({
        resolver: yupResolver(locationSchema),
        defaultValues: {
            state: initialLocationdata.state,
            local_government: initialLocationdata.local_government,
            city: initialLocationdata.city,
        },
    });

    const onSubmit = (data) => {
        setLocations(data);
    };

    const state_val = useWatch({
        name: "state",
        control: control,
    });

    useEffect(() => {
        dispatch(fetchCountryStates());
    }, [dispatch]);

    useEffect(() => {
        if (state_val) {
            dispatch(fetchState(state_val));
        }
    }, [state_val, dispatch]);

    return (
        <div className="loaction-section">
            <FormHeader
                title="Where do you live?"
                subtitle="We’ll need your location to match you with reward opportunities."
            />
            <div className="loaction-section__form">
                <div className="mb-3">
                    <Label>State*</Label>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <SelectInput
                                placeholder="Select state"
                                options={states}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="state"
                        control={control}
                        defaultValue=""
                    />
                    {errors.state && <ErrorText message={errors.state.message} />}
                </div>
                <div className="mb-3">
                    <Label>Local Government Area*</Label>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <SelectInput
                                placeholder="Select local government"
                                options={
                                    selectedState !== null ? selectedState.lgas : []
                                }
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="local_government"
                        control={control}
                        defaultValue=""
                    />
                    {errors.local_government && (
                        <ErrorText message={errors.local_government.message} />
                    )}
                </div>
                <div className="mb-3">
                    <Label>Nearest City or Town*</Label>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <SelectInput
                                placeholder="Select city"
                                options={
                                    selectedState !== null
                                        ? selectedState.cities
                                            ? selectedState.cities
                                            : []
                                        : []
                                }
                                value={value}
                                onChange={onChange}
                            />
                        )}
                        name="city"
                        control={control}
                        defaultValue=""
                    />
                    {errors.city && <ErrorText message={errors.city.message} />}
                </div>
                {registrationType === "google" ||
                    registrationType === "facebook" ? (
                    <div className="mt-4">
                        <div className="d-flex mb-2">
                            <CheckBox
                                id="subscribeUpdate"
                                value={subscribeUpdate}
                                onChange={(value) => setSubscribeUpdate(value)}
                                defaultChecked={subscribeUpdate}
                            />
                            <p className="ml-2">
                                I would like to receive the latest platform
                                updates, plus tips and best practices for
                                mastering agile market research
                            </p>
                        </div>
                        <div className="d-flex">
                            <CheckBox
                                id="agreeTerms"
                                value={agreeTerms}
                                onChange={(value) => setAgreeTerms(value)}
                                defaultChecked={agreeTerms}
                            />
                            <p className="ml-2 ">
                                I agree to Enlumi{" "}
                                <Link to="/terms-conditions">
                                    Terms &amp; Conditions
                                </Link>{" "}
                                and{" "}
                                <Link to="/privacy-policy">Privacy Policy.</Link>
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="btn-section mt-4">
                    <Button
                        onClick={() => setActiveStep(1)}
                        className="btn prev-btn"
                    >
                        Previous
                    </Button>
                    {registrationType === "google" ||
                        registrationType === "facebook" ? (
                        // <Button
                        //     disabled={!agreeTerms || loading}
                        //     className="btn btn--primary next-btn"
                        //     onClick={handleSubmit(onSubmit)}
                        // >
                        //     {loading ? (
                        //         <>
                        //             <FontAwesomeIcon icon="spinner" className="fa-spin mr-2" />
                        //             Submiting...
                        //         </>
                        //     ) : (
                        //         "Submit"
                        //     )}
                        // </Button>
                        <Button onClick={handleSubmit((data) => {
                            onSubmit(data);
                            setActiveStep(4);
                        })} className="btn btn--primary next-btn">Next</Button>
                    ) : (
                        <Button className="btn btn--primary next-btn" onClick={handleSubmit(onSubmit)}>Next</Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LocationForm;
