import Types from "../constants";

const {
    formTypes: { SET_BIODATA, SET_LOCATION, SET_PASSWORD_TERMS },
} = Types;

const initialState = {
    email: "",
    username: "",
    phone_number: "",
    state: "",
    local_government: "",
    city: "",
    registration_type: "enlumi",
    password: "",
    confirm_password: "",
    subscribe_update: false,
    agree_terms: false,
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BIODATA:
            return { ...state, ...action.payload };
        case SET_LOCATION:
            return { ...state, ...action.payload };
        case SET_PASSWORD_TERMS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
