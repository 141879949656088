import React, { Component } from 'react';
import Button from '../components/Button';

import not_found_img from '../assets/images/NotFound.png';

class ErrorHandler extends Component {
    state = {
        isError: false,
        message: '',
        error: null,
    };

    componentDidCatch = (error, info) => {
        this.setState({ isError: true, message: info, error });
    };

    render() {
        if (this.state.isError) {
            return (
                <div className="text-center px-4 pt-5">
                    <img src={not_found_img} alt="" />
                    <p style={{ maxWidth: '350px', margin: 'auto' }}>
                        We are currently experiencing technical issues.Our
                        engineers are working to fix this. Please try again
                        later.
                    </p>
                    <Button
                        onClick={() => {
                            window.location.href = '/dashboard';
                        }}
                        className=" mt-4"
                        style={{
                            background: '#766df4',
                            outline: 'none',
                            border: 'none',
                            color: 'white',
                            height: '45px',
                            width: '200px',
                            cursor: 'pointer',
                        }}
                    >
                        Explore other features
                    </Button>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default ErrorHandler;
