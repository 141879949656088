import Types from "../constants";

const { authTypes } = Types;

export const setAccessToken = (accessToken) => {
    return {
        accessToken: accessToken,
        type: "SET_ACCESS_TOKEN"
    }
}

export const setRequestingTokens = (requestingTokens) => {
    return {
        requestingTokens: requestingTokens,
        type: "SET_REQUESTING_TOKEN"
    }
}
 
export const fetchUserRequest = () => {
    return {
        type: authTypes.FETCH_USER_REQUEST,
    };
};

export const fetchUserSuccess = (payload) => {
    return {
        type: authTypes.FETCH_USER_SUCCESS,
        payload: payload,
    };
};

export const fetchUserFailure = (error) => {
    return {
        type: authTypes.FETCH_USER_FAILURE,
        payload: error,
    };
};

export const updateUserRequest = () => {
    return {
        type: authTypes.UPDATE_USER_REQUEST,
    };
};

export const updateUserSuccess = (payload) => {
    return {
        type: authTypes.UPDATE_USER_SUCCESS,
        payload: payload,
    };
};

export const updateUserFailure = (error) => {
    return {
        type: authTypes.UPDATE_USER_FAILURE,
        payload: error,
    };
};

export const logoutUser = () => {
    return {
        type: "LOGOUT_USER"
    }
}