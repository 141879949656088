import { getPolls } from '../../../redux/reducers/survey.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../components/Button';
import SurveyRow from '../../../components/SurveyRow';

import empty_state_img from '../../../assets/images/empty-state.svg';

import './daily-polls.scss';

const DailyPolls = () => {
    const dispatch = useDispatch();
    const surveys = useSelector((state) => state.survey);
    const profile_survey = useSelector(
        (state) => state.dashboard.data.has_completed_profile_survey
    );
    const [loadMore, setLoadMore] = useState(false);
    const [pollIsFinished, setPollIsFinished] = useState(false);

    useEffect(() => {
        if (surveys.polls.length === 0) {
            dispatch(getPolls(1));
        }
    }, [dispatch]);

    const loadMoreActivities = () => {
        if (
            surveys.pollsPagination.current_page <
            surveys.pollsPagination.last_page
        ) {
            setLoadMore(true);
            dispatch(getPolls(surveys.pollsPagination.next_page))
                .then(() => {
                    setLoadMore(false);
                })
                .catch(() => {
                    setLoadMore(false);
                });
        } else {
            setPollIsFinished(true);
            setTimeout(() => {
                setPollIsFinished(false);
            }, 3000);
        }
    };

    return (
        <div className="activities-polls-page">
            {!surveys.loading ? (
                <div>
                    {surveys.polls.map((poll, index) => (
                        <SurveyRow
                            key={index}
                            surveyDetails={{
                                name: `${poll.name}`,
                                id: `${poll.id}`,
                                point: poll.max_points,
                                minutes: poll.minutes,
                                path: '/activities/surveys',
                            }}
                            buttonState={profile_survey}
                        />
                    ))}
                </div>
            ) : (
                <div className="activities-polls-page__loader">
                    <FontAwesomeIcon icon="spinner" className="fa-spin" />
                </div>
            )}

            {pollIsFinished ? (
                <div>
                    <img
                        width={'200'}
                        className="mx-auto mt-2"
                        src={empty_state_img}
                        alt="no polls"
                    />
                    <h3 className="mt-4" style={{ textAlign: 'center' }}>
                        No polls available!
                    </h3>
                </div>
            ) : null}

            {loadMore ? (
                <div className="activities-polls-page__loader">
                    <FontAwesomeIcon icon="spinner" className="fa-spin" />
                </div>
            ) : null}

            <div className="activities-polls-page__bottom">
                {!loadMore && !pollIsFinished ? (
                    <Button
                        className="btn btn--outline btn--load-more"
                        onClick={() => {
                            loadMoreActivities();
                        }}
                    >
                        Load More
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default DailyPolls;
