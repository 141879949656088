import { Link } from "react-router-dom";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nFormatter } from "../helpers/nFormatter";
import survey_icon from "../assets/icons/positive-action-survey.svg";

const SurveyRow = ({ surveyDetails, buttonState }) => {

  return (
    <div>
      <div className="survey-row__item">
        <div className="survey-row__item__left-part">
          <span className="survey-logo">
            <img src={survey_icon} alt="" />
          </span>
          <h5 className="survey--text">{surveyDetails.name}</h5>
        </div>
        <div className="survey-row__item__right-part">
          <span className="points">
            +
            {surveyDetails.is_quiz ? (
              "1M "
            ) : (
              <>{nFormatter(surveyDetails.point, 1)} </>
            )}
            points
          </span>
          <p className="time survey--text">{surveyDetails.minutes}</p>
          <Link
            to={`${surveyDetails.path}/start?survey-id=${surveyDetails.id}`}
            style={{ position: "relative" }}
          >
            <Button
              className="btn btn--primary btn--activity"
              disabled={!buttonState || surveyDetails.hasEnded ? true : false}
            >
              {surveyDetails.is_quiz? "Take quiz": "Take survey" }
              {!buttonState || surveyDetails.hasEnded ? (
                <FontAwesomeIcon icon="lock" className="ml-2" />
              ) : null}
            </Button>
            <span className="survey-tooltip" style={{ color: "black" }}>
              {!buttonState
                ? "Please take profile survey to unlock other surveys"
                : "This survey is no longer accepting responses."}
            </span>
          </Link>
          {/* {renderSurveyButton()} */}
        </div>
        {surveyDetails.hasEnded ? (
          <span className="survey-completed-status">Survey closed</span>
        ) : null}
      </div>

      <div className="survey-row__item--mobile">
        <div className="survey-row__item--mobile__top-section mb-3">
          <span className="survey-logo">
            <img src={survey_icon} alt="" />
          </span>
          <span className="points">
            +
            {surveyDetails.is_quiz ? (
              "1M "
            ) : (
              <>{nFormatter(surveyDetails.point, 1)} </>
            )}
            pts | {surveyDetails.minutes}
          </span>
        </div>
        <h5 className="survey--text">{surveyDetails.name}</h5>

        <Link
          to={
            !buttonState || surveyDetails.hasEnded
              ? `${surveyDetails.path}`
              : `${surveyDetails.path}/start?survey-id=${surveyDetails.id}`
          }
          className="purple--text mt-2"
        >
          {!buttonState || surveyDetails.hasEnded ? (
            <FontAwesomeIcon
              icon="lock"
              className="mr-2 "
              style={{ color: "black" }}
            />
          ) : null}
          <span> {surveyDetails.is_quiz? "Take quiz": "Take survey" }</span>
          <span
            className="icon-arrow-right ml-2"
            style={{ fontSize: "10px" }}
          ></span>
        </Link>
        {surveyDetails.hasEnded ? (
          <span className="survey-completed-status">Survey closed</span>
        ) : null}
      </div>
    </div>
  );
};
export default SurveyRow;
