import { useEffect, useState } from "react";
import "./questionsInput.scss";
import SelectInput from "../../../../components/SelectInput";

const Dropdown = (props) => {
  const [answer, setAnswer] = useState(props.answer ? props.answer : "");
  const [optionIndex, setOptionIndex] = useState(0);

  useEffect(() => {
    setAnswer(props.answer ? props.answer : "");
    if (props.answer !== "" && props.answer !== undefined) {
      setOptionIndex(getIndexOfAnswer(props.answer));
    }
  }, [props.id]);

  useEffect(() => {
    setData(optionIndex);
  }, [answer, optionIndex]);

  const checkForNextRoute = (index) => {
    let nextRoute = props.options[index].next_route;
    return nextRoute;
  };

  const handleChange = (value) => {
    setAnswer(value);
    let currentOptionIndex = getIndexOfAnswer(value);
    setOptionIndex(currentOptionIndex);
  };

  const getIndexOfAnswer = (value) => {
    return props.options.findIndex((x) => x.value == value);
  };

  const setData = (index) => {
    props.onchange({
      answer: answer,
      nextRoute: checkForNextRoute(index),
    });
  };

  return (
    <SelectInput
      options={props.options}
      value={answer !== "" && answer !== undefined ? answer : undefined}
      onChange={(value) => {
        handleChange(value);
      }}
      placeholder="Choose option..."
      nameKey="value"
      idKey="value"
      arrayOfObject={true}
    />
  );
};

export default Dropdown;
