import Navbar from "../components/Navbar";
import Footer from "../components/WebsiteComponents/Footer";
import BottomNavigation from "../components/BottomNavigation";
import ModalClose from "../components/ModalClose";
import useModal from "../hooks/useModal";
import { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setErrorState } from "../redux/reducers/errorHandler.reducer";
import AuthorizationModal from "../components/AuthorizationModal";
import ResponseModal from "../components/ResponseModal";
import { getUserDetails } from "../redux/reducers/auth.reducer";
import { getDashboard } from "../redux/reducers/dashboard.reducer";
import BiodataSettings from "../webappPages/SettingsPage/BiodataSettings/BiodataSettings";
import useAuth from "../hooks/useAuth";
import { RedirectToAppNotice } from "../hooks/context";

const AppLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [, , , CustomModal] = useModal();
  const statusDialog = useSelector((state) => state.errorHandler.requestError);
  const statusMessage = useSelector((state) => state.errorHandler.message);
  const { user } = useSelector((state) => state.auth);
  const isAuth = useAuth();
  const { prompt_biodata_update, username } = useSelector(
    (state) => state.dashboard.data
  );
  const setShowAppNotice = useContext(RedirectToAppNotice);

  useEffect(() => {
    if (isAuth) {
      if (user.email == null || user.email === undefined) {
        dispatch(getUserDetails());
      }
      if (username == null || username === undefined) {
        dispatch(getDashboard());
      }
    }

    triggerAppNotice();
  }, []);

  const triggerAppNotice = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let presentDateTime = date + " " + time;

    if (!localStorage.getItem("lastAppNoticeSession")) {
      setShowAppNotice(true);
      localStorage.setItem("lastAppNoticeSession", presentDateTime);
      return;
    }

    const currentDateTime = new Date(presentDateTime);
    const previousDateTime = new Date(
      localStorage.getItem("lastAppNoticeSession")
    );
    const diff = Math.floor((currentDateTime.getTime() - previousDateTime.getTime()) / 1000);
    if (diff > 43000) {
      setShowAppNotice(true);
      localStorage.setItem("lastAppNoticeSession", presentDateTime);
    }
  };

  const setStatusDialog = () => {
    dispatch(
      setErrorState({
        message: "",
        requestError: false,
      })
    );
  };

  return (
    <>
      {(user.email !== undefined && username !== undefined) || !isAuth ? (
        <div>
          <div id="webapp">
            <Navbar />
          </div>
          <div id="webapp">
            <main>{children}</main>
            <BottomNavigation />
            <div className="footer-section">
              <Footer />
            </div>
            {/* this is the default modal that shows for a failure request*/}
            <CustomModal
              isActive={statusDialog}
              closeModal={() => setStatusDialog()}
              width={"350px"}
            >
              <div className="modal__container__content">
                <div className="modal__container__content__default-header mb-3">
                  <ModalClose onClick={() => setStatusDialog()} />
                </div>
                <ResponseModal message={statusMessage} statusImage="fail" />
              </div>
            </CustomModal>
            {/* modal that prompts when a users token expires when on session */}
            <AuthorizationModal />
            <CustomModal isActive={prompt_biodata_update} width={"630px"}>
              <div
                className="modal__container__content--migrate"
                style={{ textAlign: "left" }}
              >
                <p
                  className="mt-3 mb-2"
                  style={{
                    background: "#00D05B",
                    color: "white",
                    padding: "8px 10px 6px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Due to a system upgrade, and to enable us to serve you better,
                  we require that you update your bio information before you can
                  proceed with your activities.
                </p>
                {isAuth && (
                  <BiodataSettings
                    refreshPage={() => window.location.reload()}
                  />
                )}
              </div>
            </CustomModal>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AppLayout;
