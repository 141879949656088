import './dropdown.scss';

import Select from 'react-select';
import useSelect from '../../hooks/useSelect';

const Dropdown = ({
    value,
    onChange,
    options,
    idKey,
    nameKey,
    placeholder,
}) => {
    const { items, setSelectedItem, selectedItem } = useSelect({
        options,
        valueKey: idKey,
        labelKey: nameKey,
    });

    const handleChange = (selected) => {
        setSelectedItem(selected);
        onChange(selected);
    };

    return (
        <div className="custom-dropdown">
            <Select
                className="custom-dropdown__select"
                classNamePrefix="custom-dropdown__select"
                value={value}
                onChange={handleChange}
                options={items}
                placeholder={placeholder}
            />
        </div>
    );
};

export default Dropdown;
