import useModal from "../../hooks/useModal";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Button from "../../components/Button";
import VerifyAccount from "../../components/VerifyAccount";
import InviteFriends from "../../components/InviteFriends";

import "./MembershipPage.scss";
import UserAccountProgress from "../../components/UserAccountProgress/UserAccountProgress";
import UserAccountDetails from "../../components/UserAccountDetails/UserAccountDetails";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import RewardSummary from "../../components/RewardSummaryMobile";
import ModalClose from "../../components/ModalClose";
import MorePageLayout from "../../Layouts/MorePageLayout";
import { useState } from "react";

const MembershipPageHeader = () => {
  const { user } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const referrals = useSelector((state) => state.referrals);

  const { referees } = referrals.data !== null && referrals.data;
  const { lifetime_points, level, is_verified } = user !== null && user;
  const user_ref_count = referees.filter(
    ({ is_verified, has_completed_profile_survey }) =>
      is_verified && has_completed_profile_survey
  ).length;

  return (
    <>
      <UserProfileCard />
      <UserAccountDetails
        level={level}
        is_verified={is_verified}
        lifetime_points={lifetime_points}
        toggleModal={toggleModal}
      />
      <RewardSummary />
      <UserAccountProgress
        level={level}
        lifetime_points={lifetime_points}
        referrals={user_ref_count}
      />
      <CustomModal
        isActive={modalOpen}
        closeModal={() => setModalOpen(false)}
        width={"600px"}
      >
        <VerifyAccount setModalOpen={setModalOpen} />
      </CustomModal>
    </>
  );
};

const MembershipPage = () => {
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [copied, setCopied] = useState(false);
  const referrals = useSelector((state) => state.referrals);
  const {point_per_referral} = useSelector((state) => state.dashboard?.data);
  const {referees, points_earned, value} = referrals?.data;

  const user_ref_count = referees.filter(
    ({ is_verified, has_completed_profile_survey }) =>
        is_verified && has_completed_profile_survey
).length;

  const url = window.location.origin;

  return (
    <MorePageLayout
      page_title={"Membership and Points"}
      header={<MembershipPage.Header />}
    >
      <div className="membership-page mt-4">
        <h5>Your referral link</h5>
        <div className="membership-page__content">
          {/* copy referral link */}
          <div className="membership-page__content-referral__link">
            <input
              className="membership-page__content-referral__link__input"
              type="text"
              value={`${url}/signup?referral_id=${referrals?.data?.referral_code}`}
              readOnly
            />
            <CopyToClipboard
              onCopy={() => setCopied(true)}
              text={`${url}/signup?referral_id=${referrals?.data?.referral_code}`}
            >
              <button
                onClick={() => setCopied(!copied)}
                className="membership-page__content-referral__link__btn"
              >
                {copied ? "Copied" : "Copy"}
              </button>
            </CopyToClipboard>
          </div>

          <div className="membership-page__content__stats">
            <div className="stat">
              <span className="stat__count">{user_ref_count}</span>
              <span className="stat__title">Total Referrals</span>
            </div>
            <div className="stat">
              <span className="stat__count">{points_earned}</span>
              <span className="stat__title">Points earned via referrals</span>
            </div>
            <div className="stat">
              <span className="stat__count">{value.toFixed(2)}</span>
              <span className="stat__title">Value (NGN)</span>
            </div>
          </div>
          <div className="invite__btn-section">
            <Button onClick={() => toggleModal()} className="btn btn--primary">
              Invite friends ({point_per_referral.toLocaleString()} Points)
            </Button>
          </div>
        </div>
        <CustomModal
          isActive={modalOpen}
          handleClose={() => setModalOpen(false)}
          width="600px"
        >
          <div className="modal__container__content--invite">
            <div className="modal__container__content__default-header mb-3">
              <ModalClose onClick={() => setModalOpen(false)} />
            </div>
            <InviteFriends />
          </div>
        </CustomModal>
      </div>
    </MorePageLayout>
  );
};

MembershipPage.Header = MembershipPageHeader;

export default MembershipPage;