import { Link } from "react-router-dom";
import Button from "./Button";

const PayBillsRow = ({ billDetails }) => {
    // all css classes are present on pay-bills-row.scss this is due to sharing of class properties
    return (
        <div>
            <div className="pay-bills-row__item">
                <div className="pay-bills-row__item__left-part">
                    <span className="survey-logo no-border-radius">
                        <img src={billDetails.icon} alt="" />
                    </span>
                    <div>
                        <h5 className="survey--text">{billDetails.name}</h5>
                        <p className="description">{billDetails.description}</p>
                    </div>
                </div>
                <div className="pay-bills-row__item__right-part">
                    <span className="points">+{billDetails.point} points</span>
                    <Link to={`${billDetails.path}/${billDetails.id}`}>
                        <Button className="btn btn--primary btn--activity">
                            Pay now
                        </Button>
                    </Link>
                </div>
            </div>

            <div className="pay-bills-row__item--mobile">
                <div className="pay-bills-row__item--mobile__top-section mb-3">
                    <span className="survey-logo no-border-radius">
                        <img src={billDetails.icon} alt="" />
                    </span>
                    <span className="points">+{billDetails.point} pts</span>
                </div>
                <h5 className="survey--text">{billDetails.name}</h5>
                <p className="description">{billDetails.description}</p>
                <Link
                    to={`${billDetails.path}/${billDetails.id}`}
                    className="purple--text mt-2"
                >
                    <span>Pay now</span>
                    <span
                        className="icon-arrow-right ml-2"
                        style={{ fontSize: "10px" }}
                    ></span>
                </Link>
            </div>
        </div>
    );
};
export default PayBillsRow;
