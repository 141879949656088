export const setError = (payload) => {
    return {
        type: "DEFAULT",
        payload: payload,
    };
};
export const setAuthModal = (payload) => {
    return {
        type: "AUTH_MODAL",
        payload: payload,
    };
};
