import { useRouteMatch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useModal from "../../../../hooks/useModal";
import { getPrivacySettings } from "../../../../redux/reducers/privacy.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDiscosList,
  getPoints,
} from "../../../../redux/reducers/electricity.reducer";
import { resolveMeterNumber } from "../../../../services/topup-service";
import {
  setElectricityForm,
  setMeterSummary,
} from "../../../../redux/actions/electricity.actions";
import "./electricity.scss";
import { secondaryComponents } from "../../../../components/secondaryComponents";
import Form from "../../../../components/Form";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import InputField from "../../../../components/InputField";
import TopUpWallet from "../../../../components/TopUpWallet";
import ModalClose from "../../../../components/ModalClose";
import ErrorText from "../../../../components/ErrorText";
import SelectInput from "../../../../components/SelectInput";
import ResponseModal from "../../../../components/ResponseModal";
// import VerifyAccount from '../../../../components/VerifyAccount';
// import PhoneVerification from '../../../../components/VerificationModals/PhoneVerification/PhoneVerification';
import NubanVerification from "../../../../components/VerificationModals/NubanVerification/NubanVerification";
import FundWallet from "../../../../components/FundWalletModal/FundWallet";
import TransactionPin from "../../../../components/VerificationModals/TransactionPin/TransactionPin";

import RewardWalletLock from "../../../../components/VerificationModals/RewardWalletLock/RewardWalletLock";

import arrow_left from "../../../../assets/icons/arrow-left.svg";
import star from "../../../../assets/icons/star.svg";
// import debounce from '../../../../helpers/debounce';
import { getUserDetails } from "../../../../redux/reducers/auth.reducer";
import useNairaWallet from "../../../../hooks/core/FundTransfer/useNairaWallet";

const Electricity = () => {
  const electricitySchema = yup.object().shape({
    wallet: yup.string().required("Select a wallet"),
    disco: yup.string().required("Select a biller"),
    amount: yup.string().required("Enter Amount"),
    meter_number: yup.string().required("Enter your meter number"),
    meter_type: yup.string().required("Select a meter type"),
  });

  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();

  const [transactionPinOpen, setTransactionPinOpen, toggleTransactionPin] =
    useModal();

  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashboard, electricity, auth } = useSelector((state) => state);

  const [selectedWallet, setSelectedWallet] = useState("cash wallet");
  // const [disco, setDisco] = useState('');
  // const [amount, setAmount] = useState('');
  // const [meterNumber, setMeterNumber] = useState('');
  // const [meterType, setMeterType] = useState('');
  // const [pin, setPin] = useState('');

  const [loading, setLoading] = useState(false);
  // const [points, setPoints] = useState(0);
  const [fetchingMeterName, setFetchingMeterName] = useState(false);
  const [meterNumberError, setMeterNumberError] = useState(false);
  const [meterName, setMeterName] = useState("");
  const [meterDetails, setMeterDetails] = useState({
    disco: "",
    meter_number: "",
    meter_type: "",
  });
  const [purchaseError, setPurchaseError] = useState(false);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState("");
  const [retry, setRetry] = useState(false);
  const [isRewardWalletLocked, setIsRewardWalletLocked] = useState(false);

  const {
    enlumi_rewards_wallet_balance,
    lock_wallet,
    use_lock_wallet,
    has_pin,
  } = dashboard.data !== null && dashboard.data;

  const { data } = useNairaWallet();
  const { withdrawable_balance } = data !== null && data;
  const naira_wallet_balance = parseFloat(withdrawable_balance);

  const { has_kyc } = auth.user !== null && auth.user;

  const { discos, formData, meterSummary } = electricity;

  const walletLocked = lock_wallet && use_lock_wallet;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      wallet: "CASH_WALLET",
      disco: formData.disco,
      amount: formData.amount,
      meter_number: meterDetails.meter_number
        ? meterDetails.meter_number
        : formData.meter_number,
      meter_type: meterDetails.meter_type
        ? meterDetails.meter_type
        : formData.meter_type,
    },
    resolver: yupResolver(electricitySchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const validateWalletBalance = (data) => {
    if (
      selectedWallet === "cash wallet" &&
      Number(data.amount) > naira_wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Topup or Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    } else if (
      selectedWallet === "reward wallet" &&
      Number(data.amount) > enlumi_rewards_wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    }
  };

  const validatePurchaseAmount = (data) => {
    if (Number(data.amount) < 1000) {
      return setError(
        "amount",
        {
          type: "validate",
          message: "Minimum amount is 1000 naira",
        },
        { shouldFocus: true }
      );
    }
  };

  const onSubmit = (data) => {
    validateWalletBalance(data);

    const { disco, meter_number, meter_type, wallet, amount } = data;

    validatePurchaseAmount(data);

    if (!meterNumberError) {
      dispatch(
        setElectricityForm({
          disco,
          meter_number,
          meter_type,
          amount,
          funding_channel: wallet,
        })
      );
    }

    setLoading(true);
    dispatch(
      getPoints({
        disco,
        meter_number,
        meter_type,
        amount,
        funding_channel: wallet,
      })
    )
      .then((response) => {
        setPurchaseError(false);
        const { point } = response;
        dispatch(
          setMeterSummary({
            ...meterSummary,
            amount: data.amount,
            points: point,
          })
        );

        resolveMeterNumber({ ...data, retry })
          .then((response) => {
            setLoading(false);
            if (response.data) {
              const { address, name, meterNo } = response.data;

              if (selectedWallet === "reward wallet" && walletLocked) {
                return setIsRewardWalletLocked(true);
              }

              if (selectedWallet === "reward wallet" && !has_kyc) {
                return setVerifyModalOpen(true);
              }

              if (selectedWallet === "cash wallet" && !has_kyc) {
                return setVerifyModalOpen(true);
              }

              if (has_pin) {
                dispatch(
                  setMeterSummary({
                    ...meterSummary,
                    ...meterDetails,
                    recipient_address: address,
                    recipient: name,
                    meter_number: meterNo,
                    points: point,
                  })
                );
                setMeterName(name);
                history.push(`${path}/summary`);
              } else {
                toggleTransactionPin();
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            setMeterName("");
            setRetry(true);
            if (error.response) {
              let message = "";
              if (error.response.data?.detail) {
                message = error.response.data?.detail;
              }
              if (error.response.data?.message) {
                message = error.response.data?.message;
              }
              setError(
                "meter_number",
                {
                  type: "validate",
                  message: message,
                },
                { shouldFocus: true }
              );
            } else {
              setError(
                "meter_number",
                {
                  type: "validate",
                  message: "Error processing request",
                },
                { shouldFocus: true }
              );
            }
          });
      })
      .catch((error) => {
        setLoading(false);
        setPurchaseError(true);
        if (error.response) {
          let message = "";
          if (error.response.data?.detail) {
            message = error.response.data?.detail;
          }
          if (error.response.data?.message) {
            message = error.response.data?.message;
          }
          setPurchaseErrorMessage(message);
        }
      });
  };

  const disco_val = useWatch({
    name: "disco",
    control: control,
  });

  const meter_type_val = useWatch({
    name: "meter_type",
    control: control,
  });

  const meter_num_val = useWatch({
    name: "meter_number",
    control: control,
  });

  useEffect(() => {
    // handleRecentPurchase();
    dispatch(getDiscosList());
    dispatch(getPrivacySettings());
    dispatch(getUserDetails());

    if (disco_val) {
      setMeterDetails((prevDetails) => ({
        ...prevDetails,
        disco: disco_val,
      }));
    }

    if (meter_type_val) {
      setMeterDetails((prevDetails) => ({
        ...prevDetails,
        meter_type: meter_type_val,
      }));
    }

    if (meter_num_val) {
      setMeterDetails((prevDetails) => ({
        ...prevDetails,
        meter_number: meter_num_val,
      }));
    }
  }, [dispatch, disco_val, meter_type_val, meter_num_val]);

  return (
    <div className="electricity">
      <div onClick={() => history.goBack()} className="electricity__header">
        <img src={arrow_left} alt="back" />
        <div>
          <h2 className="electricity__heading">Electricity Bill</h2>
        </div>
      </div>
      <p className="electricity__description">
        Kindly provide the details for your recharge.{" "}
      </p>
      <Form onSubmit={handleSubmit(onSubmit)} className="electricity__form">
        <div className="wallet__group">
          <h4 className="group__heading">Select wallet:</h4>
          <div className="wallet__group-container">
            <label
              onClick={() => setSelectedWallet("cash wallet")}
              htmlFor="cash--wallet"
              className={`cash__wallet ${
                selectedWallet === "cash wallet" ? "selected" : ""
              }`}
            >
              <div className="wallet__top">
                <p className="wallet__name">Naira Wallet</p>
                <input
                  type="radio"
                  className="radio__input"
                  value="CASH_WALLET"
                  id="cash--wallet"
                  {...register("wallet")}
                />
                <div className="custom__radio-input"></div>
              </div>
              <div className="wallet__details">
                <p className="wallet__name">Naira Wallet</p>
                <h2 className="wallet__balance">
                  N
                  {naira_wallet_balance
                    ? naira_wallet_balance.toLocaleString()
                    : 0}
                </h2>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (!has_kyc) {
                    toggleVerifyModal();
                  } else {
                    setModalOpen(true);
                  }
                }}
                className="wallet__topup-mobile"
              >
                <span className="wallet__name-mobile">Topup wallet</span>
                <span className="icon-arrow-right"></span>
              </div>
              <Button
                className="btn btn__topup"
                onClick={(e) => {
                  e.preventDefault();
                  if (!has_kyc) {
                    toggleVerifyModal();
                  } else {
                    setModalOpen(true);
                  }
                }}
              >
                Top up wallet
              </Button>
              <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
              >
                <div className="modal__container__content">
                  <div className="modal__container__content__default-header mb-3">
                    <ModalClose onClick={() => setModalOpen(false)} />
                  </div>
                  <TopUpWallet toggleModal={toggleModal} />
                </div>
              </CustomModal>
            </label>
            <label
              onClick={() => setSelectedWallet("reward wallet")}
              htmlFor="reward--wallet"
              className={`reward__wallet ${
                selectedWallet === "reward wallet" ? "selected" : ""
              }`}
            >
              <div className="wallet__top">
                <p className="wallet__name">Rewards Wallet</p>
                <input
                  type="radio"
                  className="radio__input"
                  value="REWARDS_WALLET"
                  id="reward--wallet"
                  {...register("wallet")}
                />
                <div className="custom__radio-input"></div>
              </div>
              <div className="wallet__details">
                <p className="wallet__name">Rewards Wallet</p>
                <h2 className="wallet__balance">
                  N
                  {enlumi_rewards_wallet_balance &&
                    enlumi_rewards_wallet_balance.toFixed(2).toLocaleString()}
                </h2>
              </div>
            </label>
          </div>
          {errors.wallet && <ErrorText message={errors.wallet.message} />}
        </div>
        <div className="electricity__details-group">
          <div className="electricity__details-group-container">
            <div className="biller">
              <FormGroup>
                <h4 className="input__heading">Biller:</h4>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <SelectInput
                      arrayOfObject={true}
                      options={discos}
                      nameKey={"shortName"}
                      idKey={"key"}
                      placeholder="Select your preferred biller"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  name="disco"
                  defaultValue=""
                />
                {errors.disco && <ErrorText message={errors.disco.message} />}
              </FormGroup>
              <FormGroup>
                <h4 className="input__heading">Meter Type:</h4>
                <Controller
                  render={({ field: { value, onChange } }) => (
                    <SelectInput
                      options={["PREPAID", "POSTPAID"]}
                      placeholder="Select your meter type"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  name="meter_type"
                  defaultValue=""
                />
                {errors.meter_type && (
                  <ErrorText message={errors.meter_type.message} />
                )}
              </FormGroup>
            </div>
            <div className="group__one">
              <FormGroup>
                <h4 className="input__heading">Meter No.</h4>
                <div className="meter__input">
                  <InputField
                    inputMode="numeric"
                    register={register}
                    label="meter_number"
                    placeholder="Enter your Meter No."
                    error={errors.meter_number}
                    required
                  />
                  <span className="meter__input-loader">
                    {fetchingMeterName ? (
                      <FontAwesomeIcon icon="spinner" className="fa-spin" />
                    ) : null}
                  </span>
                  {meterName && (
                    <p className={`meter__number-resolve`}>{meterName}</p>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <h4 className="input__heading">Amount</h4>
                <InputField
                  inputMode="numeric"
                  register={register}
                  label="amount"
                  placeholder="Enter Amount"
                  error={errors.amount}
                  required
                />
              </FormGroup>
            </div>
            {selectedWallet === "cash wallet" && (
              <div className="points__earned">
                <div className="points__detail">
                  <img src={star} alt="star" />
                  <p>Points to be earned</p>
                </div>
                <p>+21000</p>
              </div>
            )}
          </div>
        </div>

        <Button type={"submit"} className="btn btn--primary btn__electricity">
          {loading ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : (
            "Proceed"
          )}
        </Button>

        <CustomModal
          isActive={purchaseError}
          closeModal={() => setPurchaseError(false)}
          width={"25%"}
        >
          <div className="modal__container__content">
            <div className="modal__container__content__default-header mb-3">
              <ModalClose onClick={() => setPurchaseError(false)} />
            </div>
            <ResponseModal status={"fail"} message={purchaseErrorMessage} />
          </div>
        </CustomModal>

        <CustomModal
          isActive={transactionPinOpen}
          closeModal={() => setTransactionPinOpen(false)}
          width={"400px"}
        >
          <div className="modal__container__content">
            <div className="modal__container__content__default-header mb-3">
              <ModalClose
                onClick={() => {
                  setTransactionPinOpen(false);
                }}
              />
            </div>
            <TransactionPin setPinModalOpen={setTransactionPinOpen} />
          </div>
        </CustomModal>

        <NubanVerification
          verifyModalOpen={verifyModalOpen}
          setVerifyModalOpen={setVerifyModalOpen}
        />

        {modalOpen && (
          <secondaryComponents.TopupOptionModal
            showTopUpModal={modalOpen}
            setShowTopUpModal={setModalOpen}
          />
        )}

        <RewardWalletLock
          isLocked={isRewardWalletLocked}
          setIsRewardWalletLocked={setIsRewardWalletLocked}
        />
      </Form>
    </div>
  );
};

export default Electricity;
