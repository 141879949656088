import Types from "../constants";

const {
    mobileDataTypes: {
        GET_ALL_PLANS,
        SET_MOBILE_DATA_FORM,
        SET_SELECTED_PLAN,
        SET_SUMMARY,
        BUY_DATA,
    },
} = Types;

export const getAllPlans = (payload) => ({
    type: GET_ALL_PLANS,
    payload: payload,
});

export const setMobileDataForm = (payload) => ({
    type: SET_MOBILE_DATA_FORM,
    payload: payload,
});

export const getSelectedPlan = (payload) => ({
    type: SET_SELECTED_PLAN,
    payload: payload,
});

export const setSummary = (payload) => ({
    type: SET_SUMMARY,
    payload: payload,
});

export const buyMobileData = (payload) => ({
    type: BUY_DATA,
    payload: payload,
});
