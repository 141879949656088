import dropdown_icon from "../assets/icons/drop-down.svg";
import search_icon from "../assets/images/search-icon.svg";
import Button from "./Button";

import { useState, useEffect, useRef } from "react";

const BankSelectInput = ({
    options = [],
    placeholder,
    nameKey,
    valueKey,
    value,
    bankName,
    onChange,
    bankListError,
    bankListErrorMessage,
    arrayOfObject,
    ...rest
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [searchValue, setSearchvalue] = useState("");
    const [bankList, setBankList] = useState(options);

    const inputRef = useRef(null);

    useEffect(() => {
        const newBankList =  options.filter((item) => {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        setBankList(newBankList)
    }, [searchValue]);

    const toggle = (event) => {
        event.preventDefault();
        setShowOptions(!showOptions);
    };

    const handleSelectedOption = (event) => {
        onChange({ value: event.target.getAttribute("value"), bankName: event.target.getAttribute("name") });
        setShowOptions(!showOptions);
    };

    const updateBankList = (event) => {
        setSearchvalue(event.target.value);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
    }, [inputRef]);

    return (
        <div ref={inputRef} className="custom--select">
            <Button onClick={toggle} className="btn custom--select-btn">
                <span>{bankName ? bankName : placeholder}</span>
                <span className="custom--arrow">
                    <img src={dropdown_icon} alt="drop down" />
                </span>
            </Button>
            <div
                className={`custom--select-items ${showOptions ? "show" : ""}`}
            >
                <div className="custom--select--search-bar">
                    <input type="text" defaultValue={searchValue} onChange={updateBankList} />
                    <img src={search_icon} alt="" />
                </div>
                {bankList &&
                    bankList.map((option, index) => (
                        <div
                            key={index}
                            onClick={handleSelectedOption}
                            className="custom--select-item"
                            name={option[nameKey]}
                            value={option[valueKey]}
                            {...rest}
                        >
                            {option[nameKey]}
                        </div>
                    ))}
            </div>
            <p className={`mb-0 mt-1 ${(bankListError) ? 'error--text' : ''}`}>
                {bankListError ? bankListErrorMessage: ""}
            </p>
        </div>
    );
};

export default BankSelectInput;
