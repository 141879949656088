import dropdown_icon from "../assets/icons/drop-down.svg";
//import search_icon from "../assets/images/search-icon.svg";
import Button from "./Button";

import { useState, useEffect, useRef } from "react";

const BankSelectInput = ({
    options = [],
    placeholder,
    nameKey,
    valueKey,
    value,
    planName,
    onChange,
    planListError,
    planListErrorMessage,
    arrayOfObject,
    ...rest
}) => {
    const [showOptions, setShowOptions] = useState(false);
    //const [searchValue, setSearchvalue] = useState("");
    //const [planList, setplanList] = useState(options);

    const inputRef = useRef(null);

    // useEffect(() => {
    //     const newplanList =  options.filter((item) => {
    //         return item.name.toLowerCase().includes(searchValue.toLowerCase());
    //     });
    //     setplanList(newplanList)
    // }, [searchValue]);

    const toggle = (event) => {
        event.preventDefault();
        setShowOptions(!showOptions);
    };

    const handleSelectedOption = (event) => {
        onChange({ value: event.target.getAttribute("value"), planName: event.target.getAttribute("name"), amount: event.target.getAttribute("amount")});
        setShowOptions(!showOptions);
    };

    // const updateplanList = (event) => {
    //     setSearchvalue(event.target.value);
    // }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
    }, [inputRef]);

    return (
        <div ref={inputRef} className="custom--select">
            <Button onClick={toggle} className="btn custom--select-btn">
                <span>{planName ? planName : placeholder}</span>
                <span className="custom--arrow">
                    <img src={dropdown_icon} alt="drop down" />
                </span>
            </Button>
            <div
                className={`custom--select-items ${showOptions ? "show" : ""}`}
            >
                {/* <div className="custom--select--search-bar">
                    <input type="text" defaultValue={searchValue} onChange={updateplanList} />
                    <img src={search_icon} alt="" />
                </div> */}
                {options &&
                    options.map((option, index) => (
                        <div
                            key={index}
                            onClick={handleSelectedOption}
                            className="custom--select-item"
                            name={option[nameKey]}
                            value={option[valueKey]} 
                            amount={option.variation_amount}
                            {...rest}
                        >
                            {option[nameKey]}
                        </div>
                    ))}
            </div>
            <p className={`mb-0 mt-1 error--text ${(!planListError) ? 'hidden' : ''}`}>
                {planListErrorMessage}
            </p>
        </div>
    );
};

export default BankSelectInput;
