import Label from '../../../../components/Label';
import Button from '../../../../components/Button';
import failed_icon from '../../../../assets/images/failure-icon.svg';
import ModalClose from '../../../../components/ModalClose';
import PayBillSuccessModalContent from '../../../../components/PayBillSuccessModalContent';
import useModal from '../../../../hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { payForCable } from '../../../../services/cable.services';

const CableSummary = ({ cableDetails }) => {
    const history = useHistory();
    const passwordError = useRef(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [pin, setPin] = useState(null);
    const [statusDialog, setStatusDialog] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusImage, setStatusImage] = useState(failed_icon);
    const [payNowLoader, setPayNowLoader] = useState(false);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [successCablePaymentDialog, setSuccessCablePaymentDialog] =
        useState(false);

    useEffect(() => {
        if (pin != null) {
            validatePassword();
        }
    }, [pin]);

    const handlePassword = (event) => {
        setPin(event.target.value);
    };

    const validatePassword = () => {
        if (pin === null || pin === '') {
            passwordError.current = true;
            setPasswordErrorMessage('please enter a valid transaction pin');
        } else {
            setPasswordErrorMessage('');
            passwordError.current = false;
        }
    };

    const payCableNow = () => {
        validatePassword();
        if (!passwordError.current) {
            setPayNowLoader(true);
            payForCable({
                pin: pin,
                funding_channel:
                    cableDetails.walletType === 'reward-wallet'
                        ? 'REWARDS_WALLET'
                        : 'CASH_WALLET',
                service_id: cableDetails.biller,
                variation_code: cableDetails.billerPlanCode,
                customer_number: cableDetails.IUC,
            })
                .then((response) => {
                    if (response.data?.reward === 'first_cable') {
                        const purchaseObj = {
                            isFirstArtime: false,
                            isFirstMobileData: false,
                            isNewSignup: false,
                            isFirstCable: true,
                            isFirstPower: false,
                        };

                        localStorage.setItem(
                            'isFirstTimePurchase',
                            JSON.stringify(purchaseObj)
                        );
                    }

                    setPayNowLoader(false);
                    setSuccessCablePaymentDialog(true);
                })
                .catch((error) => {
                    setPayNowLoader(false);
                    setStatusImage(failed_icon);
                    setStatusDialog(true);
                    if (error.response) {
                        if (
                            error.response.status === 422 ||
                            error.response.status === 400
                        ) {
                            setStatusMessage(error.response.data.detail);
                        }
                    }
                });
        }
    };
    return (
        <div className="cable-page__summary">
            <p className="cable-page__description">
                Find summary of your cable payment below:
            </p>
            <div className="cable-page__summary__details-card mt-5">
                <p
                    className="cable-details mb-2"
                    style={{ textAlign: 'right' }}
                >
                    From:
                    <span className="purple--text">
                        {cableDetails.walletType === 'cash-wallet'
                            ? 'Naira wallet'
                            : 'Rewards wallet'}
                        <br />
                        <span style={{ color: 'black' }}>
                            Balance: N{cableDetails.balance.toLocaleString()}
                        </span>
                    </span>
                </p>
                <p className="cable-details mb-2">
                    Biller:<span>{cableDetails.biller}</span>
                </p>
                <p className="cable-details mb-2">
                    Package:<span>{cableDetails.billerPlanName}</span>
                </p>
                <p className="cable-details mb-2">
                    IUC Number:<span>{cableDetails.IUC}</span>
                </p>
                <p className="cable-details mb-2">
                    Recipient:<span>{cableDetails.recipient}</span>
                </p>
                <p className="cable-details mb-2">
                    Amount:<span>N{cableDetails.amount}</span>
                </p>
                <p className="cable-details mb-2">
                    conveniency fee:<span>{cableDetails.conveniencyFee}</span>
                </p>
                <p className="cable-details mb-2">
                    Total amount:
                    <span>
                        {cableDetails.amount + cableDetails.conveniencyFee}
                    </span>
                </p>
                <p className="cable-details mb-2">
                    Points to earn:<span>{cableDetails.points}</span>
                </p>
            </div>

            <div className="cable-page__summary__password-form mt-5">
                <div>
                    <Label>Enter Your Transaction Pin to continue*</Label>
                    <input
                        type="password"
                        className="input--field"
                        onChange={handlePassword}
                        inputMode="numeric"
                    />
                    <p
                        className={`mb-0 mt-1 ${
                            passwordError ? 'error--text' : 'purple--text'
                        }`}
                        style={{ maxWidth: '500px' }}
                    >
                        {passwordError ? passwordErrorMessage : null}
                    </p>
                </div>
                <Button
                    className="btn btn--primary mt-3"
                    onClick={() => payCableNow()}
                    disabled={payNowLoader ? true : false}
                >
                    {payNowLoader ? (
                        <span>
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin mr-1"
                            />
                            <span>Paying...</span>
                        </span>
                    ) : (
                        'Pay now'
                    )}
                </Button>
            </div>
            {/* this is the default modal that shows for a failure or success request*/}
            <CustomModal
                isActive={statusDialog}
                closeModal={() => setStatusDialog(false)}
                width={'350px'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setStatusDialog(false)} />
                    </div>

                    <img src={statusImage} alt="" className="status-image" />

                    <h4>{statusMessage}</h4>
                </div>
            </CustomModal>

            <CustomModal
                isActive={successCablePaymentDialog}
                closeModal={() => {
                    history.push('/dashboard');
                }}
                width={'420px'}
            >
                <PayBillSuccessModalContent points={cableDetails.points} />
            </CustomModal>
        </div>
    );
};
export default CableSummary;
