import { useState } from 'react';
import { makeTransafer } from '../../services/bank-transfer-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useModal from '../../hooks/useModal';

import congratulation_img from '../../assets/images/congratulation-image.svg';

import Button from '../../components/Button';
import Form from '../../components/Form';
import ResponseModal from '../../components/ResponseModal';
import ModalClose from '../../components/ModalClose';

const Switch = (props) => {
    const { isToggled, onToggle } = props;
    return (
        <label className="switch">
            <input checked={isToggled} onChange={onToggle} type="checkbox" />
            <span className="slider"></span>
        </label>
    );
};

const FundTransferCheckout = ({ accountDetails, bankDetails }) => {
    const { label } = bankDetails;
    const {
        account_name,
        amount,
        account_number,
        transfer_charge,
        bank,
        wallet_balance,
    } = accountDetails;
    const [saveBeneficiary, setSaveBeneficiary] = useState(true);
    const [loading, setLoading] = useState(false);
    const [transferError, setTransferError] = useState('');
    const [transferSuccess, setTransferSuccess] = useState(false);

    const schema = Yup.object().shape({
        pin: Yup.string()
            .min(6, 'Invalid pin format')
            .max(6, 'Invalid pin format')
            .required('field is required'),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        defaultValues: {
            pin: '',
        },
    });

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();

    const handleMakeTransfer = async (pin) => {
        setLoading(true);
        try {
            const transferDetails = {
                account_name: account_name,
                account_number: account_number,
                amount: parseFloat(amount),
                bank_code: bank,
                pin: pin,
                save_beneficiary: saveBeneficiary,
            };
            const response = await makeTransafer(transferDetails);
            if (response.status === 200) {
                setLoading(false);
                setTransferSuccess(true);
            }
        } catch (error) {
            setLoading(false);
            setTransferSuccess(false);
            if (error.response) {
                const { status } = error.response;
                if (status === 400 || status === 422) {
                    const { detail } = error.response.data;
                    setTransferError(detail);
                    toggleModal();
                }
            }
        }
    };

    const onSubmit = async (data) => {
        const { pin } = data;
        await handleMakeTransfer(pin);
    };

    return (
        <div className="fund-transfer__checkout">
            <div className="fund-transfer__checkout-container">
                <p className="fund-transfer__checkout-container__details mb-3">
                    From
                    <span className="purple--text text-right">
                        Wallet <br />
                        <span style={{ color: 'black' }}>
                            Balance: N{wallet_balance.toLocaleString()}
                        </span>
                    </span>
                </p>
                <p className="fund-transfer__checkout-container__details mb-3">
                    To
                    <span className="purple--text text-right">
                        {label} <br />
                        <span>{account_name}</span> <br />
                        <span>{account_number}</span>
                    </span>
                </p>
                <p className="fund-transfer__checkout-container__details mb-3">
                    Amount
                    <span className="purple--text text-right">
                        {parseInt(amount, 10).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'NGN',
                        })}
                    </span>
                </p>
                <p className="fund-transfer__checkout-container__details mb-3">
                    Charges
                    <span className="purple--text text-right">
                        {parseInt(transfer_charge, 10).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'NGN',
                        })}
                    </span>
                </p>
                <p className="fund-transfer__checkout-container__details mb-3">
                    Total to be debited
                    <span className="purple--text text-right">
                        {(
                            parseInt(amount, 10) + parseInt(transfer_charge, 10)
                        ).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'NGN',
                        })}
                    </span>
                </p>
                <p className="fund-transfer__checkout-container__details mb-3">
                    Save beneficiary details:
                    <span className="purple--text text-right">
                        <Switch
                            isToggled={saveBeneficiary}
                            onToggle={() =>
                                setSaveBeneficiary(!saveBeneficiary)
                            }
                        />
                    </span>
                </p>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="fund-transfer__checkout-password">
                    <h5 className="mb-3">
                        Enter your transaction pin to continue
                    </h5>
                    <input
                        type="password"
                        className="fund-wallet__checkout__input"
                        inputMode="numeric"
                        {...register('pin')}
                    />
                    {errors.pin && (
                        <p className="mt-2 text-danger">{errors.pin.message}</p>
                    )}
                </div>
                <div className="fund-transfer__checkout-button">
                    <Button disabled={loading} className="btn btn--primary">
                        {loading ? (
                            <FontAwesomeIcon
                                icon={['fas', 'spinner']}
                                className="mr-2"
                                spin
                            />
                        ) : null}
                        {loading ? 'Processing...' : 'Proceed'}
                    </Button>
                </div>
            </Form>

            <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width="320px"
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setModalOpen(false)} />
                    </div>
                    <ResponseModal status="fail" message={transferError} />
                </div>
            </CustomModal>
            <CustomModal
                isActive={transferSuccess}
                closeModal={() => setTransferSuccess(false)}
                width="320px"
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setTransferSuccess(false)} />
                    </div>
                    <div style={{ padding: '1.5em' }}>
                        <img
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                            }}
                            src={congratulation_img}
                            alt=""
                        />
                        <h2 className="mt-3">AWESOME!</h2>
                        <p className="text-center my-2">
                            Your transaction was successful.
                        </p>
                        {/* <h3 className="text-center my-4 purple--text">
                            50 <span style={{ color: '#333' }}>Points!</span>
                        </h3> */}
                        <Button
                            onClick={() => {
                                setTransferSuccess(false);
                                window.location.href = '/dashboard';
                            }}
                            className="btn btn--primary"
                        >
                            Return to Dashboard
                        </Button>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default FundTransferCheckout;
