import { useState } from 'react';

const useSelect = ({ options, valueKey, labelKey }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const serializeOptions = (options) => {
        return options.map((option) => {
            return {
                label: typeof option === 'object' ? option[labelKey] : option,
                value: typeof option === 'object' ? option[valueKey] : option,
            };
        });
    };

    return {
        selectedItem,
        setSelectedItem,
        items: serializeOptions(options),
    };
};

export default useSelect;
