import Types from "../constants";

const {
    electricityTypes: {
        FETCH_ALL_DISCOS,
        SELECTED_TELCO,
        SET_ELECTIRICY_FORM,
        SET_METER_SUMMARY,
        PAY_ELECTRICITY_BILL,
    },
} = Types;

export const getTelcos = (payload) => ({
    type: FETCH_ALL_DISCOS,
    payload: payload,
});

export const setElectricityForm = (payload) => ({
    type: SET_ELECTIRICY_FORM,
    payload: payload,
});

export const setMeterSummary = (payload) => ({
    type: SET_METER_SUMMARY,
    payload: payload,
});

export const payBill = (payload) => ({
    type: PAY_ELECTRICITY_BILL,
    payload: payload,
});
