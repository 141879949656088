import FreestarAds from "./FreestarAds/FreestarAds";
import Modal from "./Modal/Modal";
import TopupOptionModal from "./TopupOptionModal/TopupOptionModal";
import AccountDetails from "./TopupOptionModal/AccountDetails/AccountDetails";
import Ussd from "./TopupOptionModal/Ussd/Ussd";
import AppNotice from "./AppNotice/AppNotice";

export const secondaryComponents = {
  FreestarAds,
  Modal,
  TopupOptionModal,
  AccountDetails,
  Ussd,
  AppNotice
};
