export const newsTypes = {
  FETCH_ALL_ARTICLES: "FETCH_ALL_ARTICLES",
  FETCH_ARTICLES: "FETCH_ARTICLES",
  FETCH_ARTICLE: "FETCH_ARTICLE",
  FETCH_COMMENTS: "FETCH_COMMENTS",
  LIKE_ARTICLE: "LIKE_ARTICLE",
  FETCH_ARTICLE_LIKES_COUNT: "FETCH_ARTICLE_LIKES_COUNT",
  ADD_COMMENT: "ADD_COMMENT",
  NEWS_REQUEST_FAILURE: "NEWS_REQUEST_FAILURE",
  ARTICLES_FINISHED: "ARTICLES_FINISHED",
  CLEAR_ARTICLES: "CLEAR_ARTICLES",
  LOAD_MORE_ARTICLES: "LOAD_MORE_ARTICLES",
};
