import { nFormatter } from "../helpers/nFormatter";
import Button from "./Button";
import star_icon from "../assets/icons/star-icon2.svg";
import time_icon from "../assets/icons/time-icon.svg";
import sound_icon from "../assets/icons/sound-icon.svg";

const SurveyDescription = ({
  setStartedAnswering,
  surveyDetails,
  isPreview,
}) => {
  const params = new URLSearchParams(window.location.search);

  return (
    <div className="activities-questions-page__content__details">
      <h2 className="title">{surveyDetails.name}</h2>
      <p className="instruction">
        {params.get("survey-id") == "000000"
          ? "Complete this intro survey to optimize your profile and unlock unlimited rewards. Answer honestly! Your responses will be used to pair you with available rewards."
          : "Respond carefully to this survey and unlock amazing rewards for yourself."}
      </p>
      {isPreview ? null : (
        <div className="survey-status">
          <div className="survey-status__point">
            <img className="me-2" src={star_icon} alt="" />
            <p>{nFormatter(surveyDetails.max_points, 1)} POINTS </p>
          </div>
          <div className="survey-status__time">
            <img className="me-2" src={time_icon} alt="" />
            <p>{surveyDetails.minutes}</p>
          </div>
        </div>
      )}
      <div className="more-info">
        <div className="sound">
          <img src={sound_icon} alt="" />
        </div>
        <p>{surveyDetails.description}</p>
      </div>
      <Button
        className="btn btn--primary take-survey-btn"
        onClick={() => setStartedAnswering(true)}
      >
        Take survey
      </Button>
    </div>
  );
};

export default SurveyDescription;
