import { useEffect, useState } from "react";
import { previewSurvey } from "../../services/survey.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "../../hooks/useModal";
import ResponseModal from "../../components/ResponseModal";
import MainPreview from "./MainPreview";
import SurveyDescription from "../../components/SurveyDescription";
import desktop_view from "../../assets/images/monitor.svg";
import mobile_view from "../../assets/images/minus-square.svg";
import "./preview.scss";

const Questions = () => {
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const params = new URLSearchParams(window.location.search);
  const [surveyDetails, setSurveyDetails] = useState({
    questions: [{}],
    thankyoupages: [{}],
    max_points: 0,
  });
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusImage, setStatusImage] = useState("fail");
  const [activeView, setActiveView] = useState("desktop");
  const [startedAnswering, setStartedAnswering] = useState(false);

  useEffect(() => {
    setLoading(true);
    previewSurvey(params.get("preview-code")) 
      .then((response) => {
        if (
          response.data.data.questions.length > 0 &&
          response.data.data.thankyoupages.length > 0
        ) {
          setSurveyDetails(response.data.data);
        } else {
          setModalOpen(true);
          setStatusMessage(
            "Please complete your question section to start previewing."
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setModalOpen(true);
          if (error.response.status === 400) {
            setStatusMessage(error.response.data.message);
          } else if (error.response.status === 404) {
            setStatusMessage(
              "This resource does not exist or might have been removed"
            );
          } else if (error.response.status === 403) {
            setStatusMessage("You are not Authorized to view this resource.");
          } else if (error.response.status === 500) {
            setStatusMessage(
              "Sorry this service is currently unavailable, please try again later"
            );
          } else if (!navigator.onLine) {
            setStatusMessage(
              "No internet connection, please check your network connectivity"
            );
          }
        }
      });
  }, []);

  return (
    <div id="webapp">
      <div className="preview-page">
        <div
          className={`preview-page__content ${
            activeView === "mobile" ? "mobile-view" : ""
          }`}
        >
          {startedAnswering ? (
            <>
              <h2 className="text-center mb-2">Preview your Questions</h2>
              <p className="text-center mb-4">
                Have a feel of how the end users would experience this survey.
              </p>
              <div className="view-type mb-4">
                <div
                  className={`view-type__item ${
                    activeView === "desktop" ? "active" : ""
                  }`}
                  onClick={() => setActiveView("desktop")}
                >
                  <img
                    className="monitor mx-auto"
                    src={desktop_view}
                    alt="desktop-view"
                  />
                  <span>Desktop</span>
                </div>
                <div
                  className={`view-type__item ${
                    activeView === "mobile" ? "active" : ""
                  }`}
                  onClick={() => setActiveView("mobile")}
                >
                  <img
                    className="mobile mx-auto"
                    src={mobile_view}
                    alt="mobile-view"
                  />
                  <span>Mobile</span>
                </div>
              </div>
            </>
          ) : null}
          {loading ? (
            <div className="preview-page__loader d-flex justify-content-center">
              <FontAwesomeIcon
                icon="spinner"
                className="fa-spin purple--text"
                style={{ fontSize: "25px" }}
              />
            </div>
          ) : (
            <>
              {startedAnswering ? (
                <MainPreview {...surveyDetails} />
              ) : (
                <div className="survey-description">
                  <SurveyDescription
                    setStartedAnswering={setStartedAnswering}
                    surveyDetails={surveyDetails}
                    isPreview={true}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* this is the default modal that shows for a failure or success request*/}
      <CustomModal isActive={modalOpen} width={"400px"}>
        <div className="modal__container__content">
          <ResponseModal message={statusMessage} status={statusImage} />
        </div>
      </CustomModal>
    </div>
  );
};

export default Questions;
