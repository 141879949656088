import httpClient from '../axiosBase/axiosHandler';

export const updatePin = ({ old_pin, new_pin, confirm_pin }) => {
    const response = httpClient.post('/users/pin/update/', {
        old_pin,
        new_pin,
        confirm_pin,
    });
    return response;
};

export const resetPin = ({ code, new_pin, confirm_pin }) => {
    const response = httpClient.post('/users/pin/reset/', {
        code,
        new_pin,
        confirm_pin,
    });

    return response;
};

export const sendTransactionPinOtp = () => {
    const response = httpClient.post('/users/pin/send-otp/', null);
    return response;
};


export const setNewTransactionPin = (data) => {
    const response = httpClient.post('/users/pin/set/', data);
    return response;
}