import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlutterwave } from "flutterwave-react-v3";
import { useMonnifyPayment } from "react-monnify";
import { initiateTopUp } from "../services/topup-service";

import Button from "./Button";
import Form from "./Form";
import FormGroup from "./FormGroup";
import InputField from "./InputField";
import Label from "./Label";

const useMonnify = (properties) => {
  const [config, setConfig] = useState(properties);
  return [setConfig];
};

const TopUpWallet = ({ toggleModal }) => {
  const topUpSchema = yup.object().shape({
    amount: yup
      .string()
      .test("num", "Maximum wallet amount is 50000", (val) => val < 50000)
      .required("Amount is required"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaltValues: {
      amount: 0,
    },
    mode: "all",
    resolver: yupResolver(topUpSchema),
  });

  const { auth } = useSelector((state) => state);
  const { email, fullname, phone_number } = auth.user !== null && auth.user;
  const [transactionError, setTransactionError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transactionConfigFlutterwave, setTransactionConfigFlutterwave] =
    useState({
      currency: "NGN",
      payment_options: "card",
      amount: 0,
      customer: {
        email: email,
        phonenumber: `+234${phone_number}`,
        name: fullname,
      },
      customizations: {
        title: "Lumi Topup",
        description: "FUND CASH WALLET",
      },
      redirect_url: `${window.location.href}`,
    });

  const [transactionConfigMonnify, setTransactionConfigMonnify] = useState({
    amount: 2000,
    currency: "NGN",
    customerName: fullname,
    customerEmail: email,
    paymentDescription: "Lumi Topup",
    onComplete: () => window.location.reload(),
    onClose: () => window.location.reload(),
    redirectUrl: `${window.location.href}`
  });

  useEffect(() => {
    if (transactionConfigFlutterwave.amount !== 0) {
      handleFlutterwavePayment({
        callback: (response) => {
          if (response.status === "successful") {
            toggleModal();
          }
        },
      });
    }
  }, [transactionConfigFlutterwave]);

  useEffect(() => {
    if (transactionConfigMonnify.amount !== 0) {
      handleMonnifyPayment();
    }
  }, [transactionConfigMonnify]);

  const handleFlutterwavePayment = useFlutterwave(transactionConfigFlutterwave);
  const handleMonnifyPayment = useMonnifyPayment(transactionConfigMonnify);

  return (
    <div className="wallet--topup">
      <div>
        <h2 className="wallet--topup-heading">Top-up Wallet</h2>
        <p className="topup--description">
          Enter the amount you wish to top up your wallet with.
        </p>
      </div>
      <Form
        onSubmit={handleSubmit((data) => {
          setLoading(true);
          initiateTopUp({ amount: parseInt(data.amount, 10) })
            .then((response) => {
              if (response.data.service === "flutterwave") {
                let newTransactionConfigFlutterwave =
                  transactionConfigFlutterwave;
                newTransactionConfigFlutterwave.amount = data.amount;
                newTransactionConfigFlutterwave.public_key =
                  response.data.public_key;
                newTransactionConfigFlutterwave.tx_ref = response.data.tx_ref;
                setTransactionConfigFlutterwave((prevConfig) => {
                  return {
                    ...prevConfig,
                    ...newTransactionConfigFlutterwave,
                  };
                });
              } else if (response.data.service === "monnify") {
                let newTransactionConfigMonnify = transactionConfigMonnify;
                newTransactionConfigMonnify.amount = data.amount;
                newTransactionConfigMonnify.apiKey = response.data.public_key;
                newTransactionConfigMonnify.reference = response.data.tx_ref;
                newTransactionConfigMonnify.isTestMode =
                  response.data.isTestMode;
                newTransactionConfigMonnify.contractCode =
                  response.data.contractCode;
                setTransactionConfigMonnify((prevConfig) => {
                  return {
                    ...prevConfig,
                    ...newTransactionConfigMonnify,
                  };
                });
              }
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              if (error.response) {
                setTransactionError(error.response.data.detail);
              }
            });
        })}
      >
        <FormGroup>
          <p className="error--text mb-1">{transactionError}</p>
          <Label>Enter amount</Label>
          <InputField
            type="number"
            register={register}
            label="amount"
            error={errors.amount}
            required
          />
        </FormGroup>
        <Button disabled={loading} className="btn btn--primary btn--topup">
          {loading ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin mr-2" />
          ) : null}
          {loading ? "Loading..." : "Next"}
        </Button>
      </Form>
    </div>
  );
};

export default TopUpWallet;
