import Types from "../constants";
import {
    fetchBankListRequest,
    fetchBankListSuccess,
    fetchBankListFailure,
} from "../actions/bankServices.action";

import { fetchBankList } from "../../services/bankServices";

const {
    FETCH_BANKLIST_REQUEST,
    FETCH_BANKLIST_SUCCESS,
    FETCH_BANKLIST_FAILURE,
} = Types.bankServicesTypes;

const initialState = {
    loading: false,
    bankList: [],
    error: null,
};

export const bankServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BANKLIST_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_BANKLIST_SUCCESS:
            return { ...state, loading: false, error: null, bankList: action.payload.data };
        case FETCH_BANKLIST_FAILURE:
            return { ...state, loading: false, error: null };
        default:
            return state;
    }
};

export const getBankList = () => {
    return (dispatch) => {
        dispatch(fetchBankListRequest());
        return new Promise((resolve, reject) => {
            fetchBankList()
                .then((response) => {
                    dispatch(fetchBankListSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch(fetchBankListFailure(error));
                    reject();
                });
        });
    };
};
