import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getTransactions } from '../../redux/reducers/transaction.reducer';

import useFetchTransactions from '../../hooks/core/Transactions/useFetchTransactions';

import './transactions.scss';

import Container from '../../components/Container';
import TransactionsHeader from './TransactionsHeader';
import TransactionSearch from './TransactionSearch';
// import TransactionsTable from "./TransactionsTable";
import Pagination from '../../components/Pagination';
import TransactionsMobile from "./TransactionsMobile";
import Table from './Table';

const wallet_options = [
    { label: 'Reward Wallet', value: 'reward' },
    { label: 'Naira Wallet', value: 'naira' },
];

const Transactions = () => {
    const dispatch = useDispatch();
    const [wallet, setWallet] = useState('reward');
    const { data: transactions, status, error, filterTransactions } = useFetchTransactions(wallet);

    const handleWalletChange = ({ value }) => {
        setWallet(value);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // const { transactions } = useSelector((state) => state);
    // const { filteredTransactions, loading } = transactions;

    // const currentTableData = useMemo(() => {
    //     const firstPageIndex = (currentPage - 1) * itemsPerPage;
    //     const lastPageIndex = firstPageIndex + itemsPerPage;

    //     const currentItem =
    //         filteredTransactions !== null
    //             ? filteredTransactions.slice(firstPageIndex, lastPageIndex)
    //             : [];

    //     return currentItem;
    // }, [currentPage]);

    const firstPageIndex = (currentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;

    const currentItem =
        transactions !== null
            ? transactions.slice(firstPageIndex, lastPageIndex)
            : [];

    const table_columns = [
        'DATE',
        'AMOUNT (NGN)',
        'TYPE',
        'WALLET',
        'STATUS',
        'REFERENCE',
        'DESCRIPTION',
        '',
    ];

    // useEffect(() => {
    //     dispatch(getTransactions());
    // }, [dispatch]);

    return (
        <div className="transactions">
            <Container>
                <TransactionsHeader
                    wallet_options={wallet_options}
                    handleWalletChange={handleWalletChange}
                    transactions={transactions}
                    filterTransactions={filterTransactions}
                />
                <TransactionsMobile transactions={transactions} />
                <div className="transactions--main">
                    <TransactionSearch />
                    {status === "pending" ? (
                        <div className="transaction__loader">
                            <FontAwesomeIcon
                                icon={'spinner'}
                                className="fa-spin"
                                size="2x"
                            />
                        </div>
                    ) : (
                        <Table columns={table_columns} rows={currentItem} />
                    )}
                    {/* <TransactionsTable tableData={currentItem} /> */}
                    {/* <TransactionsPagination /> */}
                    <Pagination
                        currentPage={currentPage}
                        totalCount={
                            transactions !== null &&
                            transactions.length
                        }
                        pageSize={itemsPerPage}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </Container>
        </div>
    );
};

export default Transactions;
