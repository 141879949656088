import "./rewards-page.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import arrow_left from "../../assets/icons/arrow-left.svg";
import { useHistory } from "react-router-dom";
import phone from "../../assets/images/phone-icon.svg";
import giftcard from "../../assets/images/giftcard-icon.svg";
import cable_tv from "../../assets/icons/cable-tv-icon.svg";
import data_purchase from "../../assets/icons/wifi-icon.svg";
import electricity_bill from "../../assets/icons/electricity-bill-icon.svg";
import ModalClose from "../../components/ModalClose";
import ResponseModal from "../../components/ResponseModal";
import useModal from "../../hooks/useModal";
import LumiAd from "../../components/LumiAd/LumiAd";
import { secondaryComponents } from "../../components/secondaryComponents";

const RewardsPage = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const membershipLevel = useSelector((state) => state.dashboard.data.level);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusImage, setStatusImage] = useState("fail");

  const validateMembership = () => {
    if (membershipLevel === "member" || membershipLevel === "bronze") {
      setStatusDialog(true);
      setStatusMessage(
        "Cash withdrawal are allowed for silver members and above."
      );
    } else {
      history.push("/rewards/cash/withdraw?wallet=reward-wallet");
    }
  };
  return (
    <div className="rewards-page">
      {/* header section */}
      <div>
        <div className="rewards-page__header" onClick={() => history.goBack()}>
          <img src={arrow_left} alt="arrow left" />
          <h2 className="back-btn">Claim rewards</h2>
        </div>
        <p className="rewards-page__description">
          Exchange your points for these rewards
        </p>
      </div>
      <div className="rewards-page__content">
        {/* link to buy airtime */}
        <Link
          to={{
            pathname: `/activities/pay-bills/airtime`,
            state: { fromRewards: true },
          }}
          className="rewards-page__content__item"
        >
          <img src={phone} alt="" />
          <div className="details">
            <h4 className="mb-2">Airtime</h4>
            <p>
              Redeem your rewards as airtime sent to your selected phone number
            </p>
          </div>
        </Link>
        {/* link to buy data */}
        <Link
          to={{
            pathname: `/activities/pay-bills/bills/mobile-data`,
            state: { fromRewards: true },
          }}
          className="rewards-page__content__item"
        >
          <img src={data_purchase} alt="" />
          <div className="details">
            <h4 className="mb-2">Data</h4>
            <p>
              Redeem your rewards as internet data sent to your selected phone
              number
            </p>
          </div>
        </Link>
        {/* link to buy buy electricity */}
        <Link
          to={{
            pathname: `/activities/pay-bills/bills/electricity`,
            state: { fromRewards: true },
          }}
          className="rewards-page__content__item"
        >
          <img src={electricity_bill} alt="" />
          <div className="details">
            <h4 className="mb-2">Electricity</h4>
            <p>
              Redeem your rewards as electricity token sent to your selected
              meter number
            </p>
          </div>
        </Link>
        {/* link to buy cable subscription */}
        <Link
          to={{
            pathname: `/activities/pay-bills/bills/cable`,
            state: { fromRewards: true },
          }}
          className="rewards-page__content__item"
        >
          <img src={cable_tv} alt="" />
          <div className="details">
            <h4 className="mb-2">Cable</h4>
            <p>
              Redeem your rewards as cable subscription sent to your selected
              decoder
            </p>
          </div>
        </Link>

        <div className="rewards-page__content__item">
          <img src={giftcard} alt="" />
          <div className="details">
            <h4 className="mb-2">Giftcards</h4>
            <p>Coming soon...</p>
          </div>
        </div>
        <LumiAd slot={"Rewards"}>
          <LumiAd.Media>
            {({ media, advert, handleClickAd, user_id }) => (
              <Link
                className={advert && "rewards-page__ad"}
                to={{ pathname: advert?.url }}
                target="_blank"
              >
                <img
                  onClick={() =>
                    handleClickAd({
                      media_id: media.id,
                      advert_id: advert.id,
                      user_id,
                    })
                  }
                  src={media?.url}
                  alt={media?.file_name}
                />
              </Link>
            )}
          </LumiAd.Media>
        </LumiAd>
        {/* Freestar ads slot */}
        <div className="text-center" style={{ width: "100%" }}>
          {window.innerWidth <= 450 && (
            <secondaryComponents.FreestarAds
              placementName="getlumi_medrec_3"
              slotId="getlumi_medrec_3"
              publisher="getlumi-io"
              classNames={["mt-2 d-flex justify-content-center"]}
            />
          )}
        </div>
      </div>

      {/* this is the default modal that shows for a failure or success request*/}
      <CustomModal
        isActive={statusDialog}
        closeModal={() => setStatusDialog(false)}
        width={"350px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose onClick={() => setStatusDialog(false)} />
          </div>
          <ResponseModal message={statusMessage} status={statusImage} />
        </div>
      </CustomModal>
    </div>
  );
};

export default RewardsPage;
