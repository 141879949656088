import activity_header_img_1 from "../assets/images/banner-image-03.png";
import activity_header_img_2 from "../assets/images/Next Level Rewards.svg";

import SearchActivities from "../components/SearchActivities";

const ActivitiesHeaderNextLevel = () => {
    return (
        <div className="activities-page__header__next-level">
            <div className="activities-page__header__next-level__left-img">
                <img src={activity_header_img_1} alt="" />
            </div>
            {/* this contains the main header content */}
            <div className="activities-page__header__next-level__center">
                <div className="activities-page__header__next-level__center__item">
                    <h2 className="white--text">Next Level Rewards</h2>
                    <p className="white--text">Get rewarded at places you shop, dine, or make purchases</p>
                    <SearchActivities />
                </div>
            </div>
            <div className="activities-page__header__next-level__right-img">
                <img src={activity_header_img_2} alt="" />
            </div>
        </div>
    );
}

export default ActivitiesHeaderNextLevel