import {
    getPrivacySettingsFailure,
    getPrivacySettingsRequest,
    getPrivacySettingsSuccess,
    updatePrivacySettingsFailure,
    updatePrivacySettingsRequest,
    updatePrivacySettingsSuccess,
} from "../actions/privacy.actions";

import {
    getPrivacyDetails,
    updatePrivacyDetails,
} from "../../services/privacy.service";

import Types from "../constants";


const {
    privacyTypes: {
        GET_PRIVACY_SETTINGS_FAILURE,
        GET_PRIVACY_SETTINGS_REQUEST,
        GET_PRIVACY_SETTINGS_SUCCESS,
        UPDATE_PRIVACY_SETTINGS_FAILURE,
        UPDATE_PRIVACY_SETTINGS_REQUEST,
        UPDATE_PRIVACY_SETTINGS_SUCCESS,
    },
} = Types;

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const privacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRIVACY_SETTINGS_REQUEST:
            return { ...state, loading: true, error: null, data: null };
        case GET_PRIVACY_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload,
            };
        case GET_PRIVACY_SETTINGS_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_PRIVACY_SETTINGS_REQUEST:
            return { ...state, loading: true };
        case UPDATE_PRIVACY_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case UPDATE_PRIVACY_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getPrivacySettings = () => {
    return (dispatch) => {
        dispatch(getPrivacySettingsRequest());
        getPrivacyDetails()
            .then((response) => {
                dispatch(getPrivacySettingsSuccess(response.data));
                
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(getPrivacySettingsFailure(error.response.data));
                    ;
                } else {
                    dispatch(getPrivacySettingsFailure(error.message));
                }
            });
    };
};

export const updatePrivacySettings = (payload) => {
    return (dispatch) => {
        dispatch(updatePrivacySettingsRequest());
        return new Promise((resolve, reject) => {
            updatePrivacyDetails(payload)
                .then((response) => {
                    dispatch(updatePrivacySettingsSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch(
                            updatePrivacySettingsFailure(error.response.data)
                        );
                        ;
                        reject(error.response.data);
                    } else {
                        dispatch(updatePrivacySettingsFailure(error.message));
                        reject(updatePrivacySettingsFailure(error.message));
                    }
                });
        });
    };
};
