import { useReducer, useState, useEffect } from 'react';
import NewsService from '../../../services/news.service';

const reducer = (state, action) => {
    switch (action.type) {
        case 'idle':
            return { ...state, status: 'idle' };
        case 'pending':
            return { ...state, status: 'pending' };
        case 'resolved':
            return {
                ...state,
                status: 'resolved',
                data:
                    action.page > 1
                        ? [...state.data, ...action.data]
                        : action.data,
            };
        case 'rejected':
            return { ...state, status: 'rejected', error: action.error };
        case 'loadmore':
            return { ...state, status: 'loadmore', page: action.page };
        default:
            return state;
    }
};

const initialState = {
    status: 'idle',
    data: [],
    error: null,
    page: 1,
};

const useFetchArticles = (page) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {data} = state;

    // const loadMoreArticles = () => {
    //     dispatch({ ...state, status: 'loadmore', page: state.page + 1 });
    // };

    useEffect(() => {
        dispatch({ type: 'pending' });
        NewsService.fetchArticles(page).then(onFulfilled, onRejected);
    }, [page]);

    function onFulfilled(response) {
        dispatch({ type: 'resolved', data: [...data, ...response.data?.data] });
    }

    function onRejected(error) {
        dispatch({ type: 'rejected', error: error?.response?.data?.detail });
    }

    return { ...state };
};

export default useFetchArticles;
