import Types from "../constants";

import {
    forgotPasswordFailure,
    forgotPasswordRequest,
    forgotPasswordSuccess,
} from "../actions/forgot-password.actions";

import { forgotPasswordService } from "../../services/auth.service";


const {
    forgortPasswordTypes: {
        FORGOT_PASSWORD_FAILURE,
        FORGOT_PASSWORD_REQUEST,
        FORGOT_PASSWORD_SUCCESS,
    },
} = Types;

const initialState = {
    loading: false,
    error: null,
    data: null,
};

export const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true, data: null };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const forgotPassword = (payload) => {
    return (dispatch) => {
        dispatch(forgotPasswordRequest());
        return new Promise((resolve, reject) => {
            forgotPasswordService(payload)
                .then((response) => {
                    dispatch(forgotPasswordSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    if (error.response) {
                        dispatch(forgotPasswordFailure(error.response.data));
                        ;
                        reject(error.response.data);
                    } else {
                        dispatch(forgotPasswordFailure(error.message));
                        reject(error.message);
                    }
                });
        });
    };
};
