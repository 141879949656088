
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  submitProfileSurvey,
  submitSurveyData,
} from "../../../services/survey.service";
import Time from "./Inputs/Time";
import Date from "./Inputs/Date";
import Paragraph from "./Inputs/Paragraph";
import ShortAnswer from "./Inputs/ShortAnswer";
import LinearScale from "./Inputs/LinearScale";
import Dropdown from "./Inputs/Dropdown";
import Checkboxes from "./Inputs/Checkboxes";
import MultipleChoice from "./Inputs/MultipleChoice";
import Button from "../../../components/Button";
import ModalClose from "../../../components/ModalClose";
import Modal from "../../../components/Modal";
import ResponseModal from "../../../components/ResponseModal";
import SurveySucessModalContent from "../../../components/SurveySuccessModalContent";
import { leadQuestion } from "../../../localdata/leadQuestion.js";

const findObject = (ArrayOfObject, value) => {
  // eslint-disable-next-line array-callback-return
  let object = ArrayOfObject.find((item, index) => {
    if (item.my_route === value.replace(/\s/g, "")) {
      return ArrayOfObject[index];
    }
  });
  return object;
};

class MainQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      questions: [],
      thankyoupages: props.thankyoupages,
      thankyoupageToUse: props.thankyoupages[0],
      presentQuestionIndex: 0,
      questionNumber: 1,
      currentQuestion: props.questions[0],
      routedPaths: [0],
      surveyType: props.category,
      nextRoute: "Q0",
      answerError: {
        error: false,
        message: "Please answer this question before proceeding",
      },
      disableNextBtn:
        props.questions.length === 1 && props.category !== 500 ? true : false,
      disablePrevBtn: true,
      submitLoader: false,
      requestStatusDialog: false,
      statusMessage: "",
      successModal: false,
      haveSeenWarningModal: false,
      promptWarningModal: false,
      countDown: parseInt(process.env.REACT_APP_QUIZ_TIME, 10),
      intervalID: 0,
      quiz_submitted: false
    };
    this.gotoNextQuestion = this.gotoNextQuestion.bind(this);
  }

  componentDidMount() {
    let newQuestions = [...this.props.questions];
    if (this.state.surveyType === 500) newQuestions.push(leadQuestion);
    this.setState({ questions: [...newQuestions] }, () => {
      if (this.props.is_quiz) {
        const newIntervalID = setInterval(() => {
          this.startCountDown();
        }, 1000);
        this.setState({ intervalID: newIntervalID });
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  startCountDown() {
    this.setState({ countDown: this.state.countDown - 1 }, () => {
      if (this.state.countDown <= 0) {
        if (this.validateAnswer()) {
          this.setEntities({
            nextRoute: "Q0",
            answer: "None",
          });
        }

        if (
          this.state.presentQuestionIndex + 1 ===
          this.state.questions.length
        ) {
          this.setState({ disableNextBtn: true }, () => {
            this.submitSurvey();
          });
        } else {
          this.gotoNextQuestion();
        }
      }
    });
  }

  resetCountDown() {
    this.setState({ countDown: parseInt(process.env.REACT_APP_QUIZ_TIME, 10) });
  }

  updatePresentQuestionIndex(value, questionAction) {
    this.setState(
      {
        presentQuestionIndex: value,
      },
      () => {
        this.setState({
          currentQuestion:
            this.state.questions[this.state.presentQuestionIndex],
        });
        let questionRegulator = questionAction === "increase" ? 1 : -1;
        this.setState({
          questionNumber: this.state.questionNumber + questionRegulator,
        });
        this.setState({ answerError: { error: false, message: "" } });

        let newRoutedPaths = [...this.state.routedPaths];
        if (questionAction === "increase") {
          newRoutedPaths.push(this.state.presentQuestionIndex);
        } else {
          newRoutedPaths.pop();
        }
        this.setState(
          {
            routedPaths: newRoutedPaths,
          },
          () => {
            this.setState({ disablePrevBtn: false });
            if (this.state.routedPaths.length === 1)
              this.setState({ disablePrevBtn: true });
          }
        );

        if (
          this.state.presentQuestionIndex + 1 ===
          this.state.questions.length
        ) {
          this.setState({ disableNextBtn: true });
        } else {
          this.setState({ disableNextBtn: false });
        }

        this.setState({ haveSeenWarningModal: false });
        if (this.props.is_quiz) this.resetCountDown();
      }
    );
  }

  gotoNextQuestion() {
    if (
      this.state.presentQuestionIndex + 1 < this.state.questions.length &&
      !this.validateAnswer()
    ) {
      //show warning modal for users answering paragragh or short answer question type
      if (
        this.state.currentQuestion.type === "SHORT_ANSWER" ||
        this.state.currentQuestion.type === "PARAGRAPH"
      ) {
        if (!this.state.haveSeenWarningModal) {
          this.setState({ promptWarningModal: true });
          return;
        }
      }

      if (this.state.nextRoute === "Q0") {
        this.updatePresentQuestionIndex(
          this.state.presentQuestionIndex + 1,
          "increase"
        );
      } else if (this.state.nextRoute.charAt(0) === "Q") {
        let routeToQuestion = findObject(
          this.state.questions,
          this.state.nextRoute
        );
        if (routeToQuestion !== undefined) {
          let nextQuestionIndex = this.state.questions.findIndex(
            (x) => x.my_route === routeToQuestion.my_route
          );
          this.updatePresentQuestionIndex(nextQuestionIndex, "increase");
        }
      } else if (this.state.nextRoute.charAt(0) === "T") {
        this.setState({
          thankyoupageToUse:
            this.state.thankyoupages[
              parseInt(this.state.nextRoute.substr(1, 1)) - 1
            ],
        });

        if (this.state.surveyType === 500) {
          this.updatePresentQuestionIndex(
            this.state.questions.length - 1,
            "increase"
          );
        } else {
          this.setState({ disableNextBtn: true }, () => {
            this.submitSurvey();
          });
        }
      }
    } else {
      this.setState({
        answerError: {
          error: true,
          message: "Please answer this question before proceeding",
        },
      });
    }
  }

  gotoPrevQuestion() {
    if (this.state.routedPaths.length > 1) {
      this.updatePresentQuestionIndex(this.state.routedPaths.length - 2);
    }
  }

  setEntities(data) {
    this.setState({ nextRoute: data.nextRoute });
    let presentQuestion = this.state.currentQuestion;
    presentQuestion.answer = data.answer;
    let newQuestions = [...this.state.questions];
    newQuestions[this.state.presentQuestionIndex] = presentQuestion;
    this.setState({
      answerError: {
        error: false,
        message: "",
      },
    });
  }

  validateAnswer() {
    if (this.state.currentQuestion.answer !== undefined) {
      if (
        this.state.currentQuestion.answer === "" ||
        this.state.currentQuestion.answer.length === 0
      )
        return true;
    }
    return false;
  }

  submitSurvey() {
    if(this.props.is_quiz) {
      clearInterval(this.state.intervalID);
      this.setState({quiz_submitted: true});
    }

    if (this.validateAnswer() === false) {
      this.setState(
        {
          thankyoupageToUse: {
            ...this.state.thankyoupageToUse,
            totalQuestionAnswered: this.state.routedPaths.length,
          },
        },
        () => {
          this.setState({ submitLoader: true });
          if (!this.props.profile_survey_status) {
            this.sendProfileSurvey();
          } else {
            this.sendSurvey();
          }
        }
      );
    } else {
      this.setState({
        answerError: {
          error: true,
          message: "Please answer this question before proceeding",
        },
      });
    }
  }

  sendSurvey = () => {
    let payload = [];
    let submissionData = {};
    this.state.questions.forEach((element, index) => {
      let data = {};
      data.question_id = element.id;
      data.answer = element.answer;
      if (
        element.answer !== "" &&
        element.answer !== undefined &&
        element.answer !== null
      )
        payload.push(data);

      if (
        this.state.surveyType === 500 &&
        this.state.questions.length === index + 1
      ) {
        submissionData.consent = payload[payload.length - 1].answer;
        payload.pop();
      }
    });

    submissionData.responses = payload;

    submitSurveyData(submissionData, this.props.id)
      .then((response) => {
        this.setState({ submitLoader: false });
        this.setState({ successModal: true });
      })
      .catch((error) => {
        this.setState({ submitLoader: false });
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 422) {
            this.setState({ requestStatusDialog: true });
            this.setState({
              statusMessage: error.response.data.detail,
            });
          }
        }
      });
  };

  sendProfileSurvey() {
    let payload = {};
    this.state.questions.forEach((element) => {
      payload[`${element.key}`] = element.answer;
    });

    submitProfileSurvey(payload)
      .then((response) => {
        this.setState({ submitLoader: false });
        this.setState({ successModal: true });
      })
      .catch((error) => {
        this.setState({ submitLoader: false });
        if (error.response) {
          if (error.response.status === 422 || error.response.status === 400) {
            this.setState({ requestStatusDialog: true });
            this.setState({
              statusMessage: error.response.data.detail,
            });
          }
        }
      });
  }

  render() {
    return (
      <div>
        <div className="activities-questions-page__content__question">
          {this.state.quiz_submitted ? (
            <h3
              className="text-center secondary--text"
              style={{ width: "100%" }}
            >
              <FontAwesomeIcon icon="spinner" className="fa-spin mr-1" />
              Submitting...
            </h3>
          ) : (
            <div className="activities-questions-page__content__question__container">
              <h5 className="question-number--mobile d-flex justify-content-between">
                Question {this.state.questionNumber}
                {this.props.is_quiz ?(
                  <span
                    className="white--text d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: "40px",
                      background: "#766df4",
                      width: "40px",
                      paddingTop: "5px",
                      marginTop: "-15px",
                      height: "40px",
                      fontSize: "25px",
                    }}
                  >
                    {this.state.countDown}
                  </span>
                ):null}
              </h5>
              {this.state.currentQuestion.media_source !== "" &&
              this.state.currentQuestion.media_source !== undefined ? (
                <div className="activities-questions-page__content__question__container__image">
                  <img src={this.state.currentQuestion.media_source} alt="" />
                </div>
              ) : null}
              <div className="activities-questions-page__content__question__container__text">
                <div className="question-container">
                  {/* error message */}
                  {this.state.answerError.error === true ? (
                    <p className="question-error-message">
                      {this.state.answerError.message}
                    </p>
                  ) : null}

                  <h5 className="question-number d-flex justify-content-between">
                    Question {this.state.questionNumber}
                    {this.props.is_quiz ?(
                      <span
                        className="white--text d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: "40px",
                          background: "#766df4",
                          width: "40px",
                          paddingTop: "5px",
                          marginTop: "-15px",
                          height: "40px",
                          fontSize: "25px",
                        }}
                      >
                        {this.state.countDown}
                      </span>
                    ):null}
                  </h5>
                  <h5 className="question">
                    {this.state.surveyType === 500 &&
                    this.state.questions.length ===
                      this.state.presentQuestionIndex + 1
                      ? `${
                          this.props.business_name
                        } would love to connect with you. Do you consent to sharing your information
                  (${this.props.lead_types.join(", ").toLowerCase()}) with ${
                          this.props.business_name
                        }?
                    `
                      : `${this.state.currentQuestion.question}`}
                  </h5>

                  {this.state.currentQuestion.type === "TIME" ? (
                    <Time
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "DATE" ? (
                    <Date
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "SHORT_ANSWER" ? (
                    <ShortAnswer
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "PARAGRAPH" ? (
                    <Paragraph
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "DROPDOWN" ? (
                    <Dropdown
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "LINEAR_SCALE" ? (
                    <LinearScale
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "MULTIPLE_CHOICE" ? (
                    <MultipleChoice
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {this.state.currentQuestion.type === "CHECKBOXES" ? (
                    <Checkboxes
                      {...this.state.currentQuestion}
                      onchange={(e) => {
                        this.setEntities(e);
                      }}
                    />
                  ) : null}

                  {/* lead notification */}
                  {this.state.surveyType === 500 &&
                  this.state.questions.length ===
                    this.state.presentQuestionIndex + 1 ? (
                    <i style={{ fontStyle: "italics" }}>
                      Note: {this.props.lead_description}
                    </i>
                  ) : null}

                  <div
                    className={`btn-section ${
                      this.state.routedPaths.length === 1 || this.props.is_quiz
                        ? "justify-content-end"
                        : ""
                    }`}
                  >
                    {this.state.disablePrevBtn || this.props.is_quiz ? null : (
                      <Button
                        className="btn btn--primary prev-btn"
                        onClick={() => {
                          this.gotoPrevQuestion();
                        }}
                      >
                        <FontAwesomeIcon
                          className="mr-2 mb-1"
                          icon="angle-left"
                        />{" "}
                        Previous
                      </Button>
                    )}

                    {this.state.disableNextBtn ? (
                      <Button
                        className="btn btn--primary next-btn"
                        onClick={() => {
                          this.submitSurvey();
                        }}
                        disabled={this.state.submitLoader}
                      >
                        {this.state.submitLoader ? (
                          <span>
                            <FontAwesomeIcon
                              icon="spinner"
                              className="fa-spin mr-1"
                            />
                            <span>Submitting...</span>
                          </span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn btn--primary next-btn"
                        onClick={() => {
                          this.gotoNextQuestion();
                        }}
                      >
                        Next{" "}
                        <FontAwesomeIcon
                          className="ml-2 mb-1"
                          icon="angle-right"
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* success modal for survey creation */}
        <Modal
          isActive={this.state.successModal}
          closeModal={() => {
            const baseUrl = window.location.origin;
            window.location.replace(`${baseUrl}/activities/surveys`);
          }}
          width={"650px"}
        >
          <div className="activities-questions-page__content__question__status-modal">
            <div className="header-section">
              <ModalClose
                onClick={() => {
                  const baseUrl = window.location.origin;
                  window.location.replace(`${baseUrl}/activities/surveys`);
                }}
              />
            </div>
            <SurveySucessModalContent {...this.state.thankyoupageToUse} />
          </div>
        </Modal>
        {/* this is the default modal that shows for a failure or success request*/}
        <Modal
          isActive={this.state.requestStatusDialog}
          closeModal={() => this.setState({ requestStatusDialog: false })}
          width={"400px"}
        >
          <div className="modal__container__content">
            <div className="modal__container__content__default-header mb-3">
              <ModalClose
                onClick={() =>
                  this.setState({
                    requestStatusDialog: false,
                  })
                }
              />
            </div>
            <ResponseModal message={this.state.statusMessage} status="fail" />
          </div>
        </Modal>
        {/* warning modal for short answers and paragraphs */}
        <Modal
          isActive={this.state.promptWarningModal}
          closeModal={() => this.setState({ promptWarningModal: false })}
          width={"500px"}
        >
          <div
            className="modal__container__content"
            style={{ textAlign: "left", padding: "40px 30px" }}
          >
            <h3 className="purple--text">
              Please make sure you have read and answered this survey question
              carefully.
            </h3>
            <i>
              Note: Users who write nonsense here instead of answering the
              question carefully will be DEACTIVATED with no chance for appeal.
            </i>

            <h4 className="mt-4">
              Are you sure you want to continue to other questions?
            </h4>

            <div
              className="d-flex justify-content-between mx-auto mt-4"
              style={{ maxWidth: "370px" }}
            >
              <Button
                className="btn btn--primary next-btn"
                style={{
                  maxWidth: "200px",
                  background: "#ebe7ff",
                  color: "#766df4",
                }}
                onClick={() => {
                  this.setState({ promptWarningModal: false });
                }}
              >
                Review response
              </Button>
              <Button
                className="btn btn--primary next-btn"
                style={{ maxWidth: "150px" }}
                onClick={() => {
                  this.setState({ haveSeenWarningModal: true }, () => {
                    this.setState({ promptWarningModal: false });
                    this.gotoNextQuestion();
                  });
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  profile_survey_status: state.dashboard.data.has_completed_profile_survey,
});

export default connect(mapStateToProps)(MainQuestions);