import Types from "../constants";

const { notificationTypes } = Types;
const { GET_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_NOTIFICATION } =
    notificationTypes;

export const getNotifications = (payload) => ({
    type: GET_NOTIFICATIONS,
    payload: payload,
});

export const readAllNotifications = (payload) => ({
    type: READ_ALL_NOTIFICATIONS,
    payload: payload,
});

export const readNotification = (notification) => ({
    type: READ_NOTIFICATION,
    payload: notification,
});
