import { FaAngleLeft } from 'react-icons/fa';
import { useStep } from 'react-hooks-helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import * as yup from 'yup';
import { accountVerificationService } from '../../services/verification.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Form from '../Form';
import Button from '../Button';
import Label from '../Label';
import InputField from '../InputField';
import FormGroup from '../FormGroup';

const BVNVerificationForm = ({ navigation }) => {
    const schema = yup.object().shape({
        bvn: yup
            .string()
            .min(10, 'Invalid BVN format')
            .max(11, 'Invalid BVN format')
            .required('Field is required'),
    });

    const [verificationError, setVerificationError] = useState('');
    const [loading, setLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            bvn: '',
        },
    });

    const handleVerify = async (data) => {
        setLoading(true);
        try {
            const response = await accountVerificationService(data);
            if (response.status === 200) {
                setLoading(false);
                navigation.go(3);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 404
                ) {
                    const { detail } = error.response.data;
                    setVerificationError(detail);
                }
            }
        }
    };

    const onSubmit = async (data) => {
        const verification_type = 'BVN';
        const { bvn } = data;
        const payload = { verification_type, identification_number: bvn };
        await handleVerify(payload);
    };

    return (
        <>
            {verificationError && (
                <p className="text-center my-3 text-danger">
                    {verificationError}
                </p>
            )}
            <Form
                className="bvn-verification__form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormGroup>
                    <Label>BVN*</Label>
                    <InputField
                        register={register}
                        label="bvn"
                        required
                        type="text"
                        error={errors.bvn}
                        placeholder="Enter your BVN"
                    />
                </FormGroup>
                <Button disabled={loading} className="btn btn--primary">
                    {loading ? (
                        <FontAwesomeIcon className="mr-2" icon="spinner" spin />
                    ) : null}
                    {loading ? 'Verifying...' : 'Verify'}
                </Button>
            </Form>
        </>
    );
};

const BVNVerification = ({ navigation }) => {
    return (
        <div className="bvn-verification">
            <h3 onClick={() => navigation.previous()} className="d-flex mb-2">
                <FaAngleLeft className="mr-2" />
                <span>Verify your BVN</span>
            </h3>
            <p>
                Your BVN is used to verify your identity. Dial *565*0# to get
                your BVN
            </p>
            <BVNVerificationForm navigation={navigation} />
        </div>
    );
};

export default BVNVerification;
