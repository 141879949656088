import { useHistory } from "react-router-dom";
import LoginForm from "./LoginForm";
import TextDivider from "../../components/TextDivider";
import SocialButtons from "../../components/SocialButtons";
import LoginLayout from "../../Layouts/LoginLayout";
import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setAccessToken } from "../../redux/actions/auth.actions";
import { RedirectToAppNotice } from "../../hooks/context";

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const setShowAppNotice = useContext(RedirectToAppNotice)

  const showError = (error) => {
    if (!error) return null;
    setErrorMessage(error);
  };

  const grantUserAccess = (response) => {
    localStorage.setItem("refresh_token", response.refresh);
    dispatch(setAccessToken(response.access));
    let path = localStorage.getItem("next_route");
    localStorage.removeItem("next_route");
    setShowAppNotice(true)
    if (path) return history.push(path);
    history.push("/dashboard");
  };

  return (
    <LoginLayout
      content1="A few clicks away from amazing rewards!"
      content2="Shop, Pay bills, take surveys, & automatically get rewarded."
      content3="Kick off your earnings!"
      showHeadings={true}
      title="Log in to Enlumi"
      description="Shop, pay bills, take surveys and get rewarded instantly."
      linkedPage={
        <span className="big-text-size">
          Don't have an account?{" "}
          <Link className="big-text-size purple--text ml-1" to="/signup">
            Create account
          </Link>
        </span>
      }
    >
      <div className="login-layout__right__content__form">
        {/* {error && <ErrorText message={error} />} */}
        <SocialButtons
          googleText={"Login with Google"}
          facebookText={"Login with facebook"}
          setErrorMessage={showError}
          grantUserAccess={grantUserAccess}
        />
        <TextDivider />
        {/* error message */}
        <p className="error--text error-section mb-2">{errorMessage}</p>
        <LoginForm showError={showError} grantUserAccess={grantUserAccess} />
      </div>
    </LoginLayout>
  );
};

export default LoginPage;
