import httpClient from "../axiosBase/axiosHandler.js";

export const fetchBankList = () => {
   const response = httpClient.get(`/banks/`,);
    return response;
};

export const resoloveAccountName = (payload) => {
    const response = httpClient.post(`/bank-accounts/resolve/`, payload,);
    return response;
};

export const addBankAccount = (payload) => {
    const response = httpClient.post(`/bank-accounts/create/`, payload,);
    return response;
};

export const updateBankAccount = (payload) => {
    const response = httpClient.post(`/bank-accounts/update/`, payload,);
    return response;
};

export const getUserBankDetails = () => {
    const response = httpClient.get(`/bank-accounts/`);
    return response;
};

export const withdrawFunds = (payload) => {
    let resquestPath = (payload.walletType == "cash-wallet")? "/withdraw/": "/claim-rewards/cash/"
    const response = httpClient.post(`${resquestPath}`, payload,);
    return response;
}