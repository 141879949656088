import PinInput from 'react-pin-input';
import { Controller } from 'react-hook-form';

const ConfirmPin = ({ control, errors }) => {
    return (
        <div className="confirm-pin">
            <h2>Confirm your Pin</h2>
            <p>This will be used for all transactions</p>
            <Controller
                render={({ field: { value, onChange } }) => (
                    <PinInput
                        length={6}
                        initialValue={value}
                        onChange={onChange}
                        secret
                        type="numeric"
                        inputMode="numeric"
                    />
                )}
                control={control}
                name="confirm_pin"
                defaultValue=""
            />
            {errors.confirm_pin && (
                <p className="mt-3 text-danger">{errors.confirm_pin.message}</p>
            )}
        </div>
    );
};

export default ConfirmPin;
