import ArticleImage from "./ArticleImage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsService from "../../../services/news.service";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import useLikeArticle from "../../../hooks/useLikeArticle";
import useModal from "../../../hooks/useModal";

import ModalClose from "../../../components/ModalClose";

import share_icon from "../../../assets/icons/share-icon.svg";
import comment_icon from "../../../assets/icons/comment-icon.svg";

function Article({
  published,
  articleId,
  image,
  articleImage,
  articleTitle,
  comments,
  likes,
}) {
  const { url } = useRouteMatch();
  const { handleLike, isLiked, likes: likesCount } = useLikeArticle(articleId);
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [commentsCount, setCommentsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getArticleCommentsCount = async () => {
    try {
      const res = await NewsService.fetchArticleCommentsCount(articleId);
      if (res) {
        setLoading(false);
        setCommentsCount(res.data.data[0].count.id);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getArticleCommentsCount();
  }, []);

  return (
    <div className="news__article">
      <Link
        to={{
          pathname: `${url}/${articleId}`,
          hash: articleTitle,
        }}
        className="news__article__header"
      >
        <ArticleImage image={image} imageId={articleImage} />
      </Link>
      <div className="news__article__body">
        <Link
          to={{
            pathname: `${url}/${articleId}`,
            hash: articleTitle,
          }}
        >
          <h3 className="article__title">{articleTitle}</h3>
        </Link>
        <p className="published__date">
          <span>Published:</span>{" "}
          <span>{new Date(published).toDateString()}</span>{" "}
          <span>{new Date(published).toLocaleTimeString()}</span>
          {/* new Date(article.date_created).toLocaleTimeString() */}
        </p>
        <div className="news__article__actions">
          <div onClick={handleLike} className="article__likes">
            <FontAwesomeIcon
              className="article__likes-icon"
              icon={[`${isLiked ? "fas" : "far"}`, "heart"]}
            />
            {/* <span className="likes__icon"></span> */}
            <span className="likes__count">
              {!isLiked && likesCount === 0 ? likes.length : likesCount} likes
            </span>
          </div>
          <div className="article__comments">
            <span className="comments__icon">
              <img src={comment_icon} alt="comment" />
            </span>
            <span className="comments__count">{commentsCount <= 0 ? comments.length : commentsCount} comments</span>
          </div>
          <button onClick={toggleModal} className="share__article__btn">
            <span className="share__icon">
              <img src={share_icon} alt="share" />
            </span>
            <span className="share__text">Share</span>
          </button>
          <CustomModal
            isActive={modalOpen}
            closeModal={() => setModalOpen(false)}
            width={"300px"}
          >
            <div className="modal__container__content">
              <div className="modal__container__content__default-header mb-3">
                <ModalClose onClick={() => setModalOpen(false)} />
              </div>
              <div className="share__article__modal">
                <h4 className="share__article__modal__heading">
                  Share article
                </h4>
                <div className="share__article__modal__container">
                  <FacebookShareButton
                    quote={articleTitle}
                    url={`${window.location.href}/${articleId}#${articleTitle}`}
                    onShareWindowClose={() => setModalOpen(false)}
                  >
                    <FacebookIcon size={45} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={articleTitle}
                    url={`${window.location.href}/${articleId}#${articleTitle}`}
                    onShareWindowClose={() => setModalOpen(false)}
                  >
                    <TwitterIcon size={45} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    title={articleTitle}
                    separator="-"
                    url={`${window.location.href}/${articleId}#${articleTitle}`}
                    onShareWindowClose={() => setModalOpen(false)}
                  >
                    <WhatsappIcon size={45} round={true} />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    </div>
  );
}

export default Article;
