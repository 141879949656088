import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppLayout from './Layouts/AppLayout';
import DashboardPage from './webappPages/Dashboard/dashboard';
import RewardsPage from './webappPages/RewardsPage/RewardsPage';
import AddBankAccount from './webappPages/CashReward/AddBankAccount';
import EditBankAccount from './webappPages/CashReward/EditBankAccount';
import WithdrawFunds from './webappPages/CashReward/WithdrawFunds';
import CashReward from './webappPages/CashReward/CashReward';
import SettingsPage from './webappPages/SettingsPage/SettingsPage';
import ActivityLayout from './Layouts/ActivityLayout';
import NotFoundPage from './webappPages/notfound.page';
import MorePage from './webappPages/MorePage/MorePage';
import ProtectRoute from './components/PrivateRoute/PrivateRoute';
import Transactions from './webappPages/Transactions/Transactions';
import ScrollToTop from './components/ScrollToTop';
import FaqPage from './webappPages/FaqPage/FaqPage';
import MembershipPage from './webappPages/MembershipPage/MembershipPage';
import FundWallet from './components/FundWalletModal/FundWallet';
import FundTransferPage from "./webappPages/FundTransferPage/FundTransferPage";
import EmailUnsubscribe from './webappPages/EmailUnsubscribe/EmailUnsubscribe';

const App = () => {
    const hasBank = useSelector((state) => state.dashboard.data.has_bank);
    return (
        <AppLayout>
            <ScrollToTop />
            <Switch>
                <ProtectRoute path="/dashboard" component={DashboardPage} />
                <Route path="/activities" component={ActivityLayout} />
                <ProtectRoute path="/more" component={MorePage } />
                <ProtectRoute path="/membership" component={MembershipPage} />
                <ProtectRoute path="/settings" component={SettingsPage} />
                <ProtectRoute path="/wallet-history" component={Transactions} />
                <ProtectRoute exact path={`/rewards/cash/add-bank-account`}>
                    {hasBank ? (
                        <Redirect to="/rewards/cash/withdraw?wallet=cash-wallet" />
                    ) : (
                        <AddBankAccount />
                    )}
                </ProtectRoute>
                <ProtectRoute exact path={`/rewards/cash/edit-bank-account`}>
                    {!hasBank ? (
                        <Redirect to="/rewards/cash/add-bank-account" />
                    ) : (
                        <EditBankAccount />
                    )}
                </ProtectRoute>
                <ProtectRoute exact path={`/rewards/cash/withdraw`}>
                    {!hasBank ? (
                        <Redirect to="/rewards/cash/add-bank-account" />
                    ) : (
                        <WithdrawFunds />
                    )}
                </ProtectRoute>
                <ProtectRoute path="/rewards/cash">
                    {hasBank ? <Redirect to="/rewards/cash" /> : <CashReward />}
                </ProtectRoute>
                <ProtectRoute path="/rewards" component={RewardsPage} />
                <ProtectRoute path="/faq" component={FaqPage} />
                <ProtectRoute path="/fund-transfer" component={FundTransferPage} />
                <ProtectRoute path="/email/unsubscribe" component={EmailUnsubscribe}/>
                <Route component={NotFoundPage} />
                <ProtectRoute path="/" component={FundWallet} />
            </Switch>
        </AppLayout>
    );
};

export default App;