import './cable.scss';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import useModal from '../../../../hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import ModalClose from '../../../../components/ModalClose';
import PageLoader from '../../../../components/PageLoader';
import PlanSelectField from '../../../../components/CableSelectInput';
import CableSummary from './CableSummary';
import arrow_left from '../../../../assets/icons/arrow-left.svg';
import star from '../../../../assets/icons/star.svg';
import dstv_icon from '../../../../assets/images/dstv-icon.png';
import gotv_icon from '../../../../assets/images/gotv-icon.png';
import startimes_icon from '../../../../assets/images/startimes-icon.png';
import {
    fetchBillers,
    resoloveRefName,
} from '../../../../services/cable.services';
import { getPrivacySettings } from '../../../../redux/reducers/privacy.reducer';
import { getUserDetails } from '../../../../redux/reducers/auth.reducer';
import RewardWalletLock from '../../../../components/VerificationModals/RewardWalletLock/RewardWalletLock';
import NubanVerification from '../../../../components/VerificationModals/NubanVerification/NubanVerification';
import useNairaWallet from '../../../../hooks/core/FundTransfer/useNairaWallet';
import TransactionPin from '../../../../components/VerificationModals/TransactionPin/TransactionPin';
import { secondaryComponents } from '../../../../components/secondaryComponents';

const Cable = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
    const [transactionPinOpen, setTransactionPinOpen, toggleTransactionPin] =
        useModal();

    const { data } = useNairaWallet();
    const { withdrawable_balance } = data !== null && data;

    const enlumiRewardsWalletBalance = useSelector(
        (state) => state.dashboard.data.enlumi_rewards_wallet_balance
    );
    const cashWalletBalance = parseFloat(withdrawable_balance) || 0;
    const { lock_wallet, use_lock_wallet, has_pin } = useSelector(
        (state) => state.dashboard?.data
    );

    const { auth } = useSelector((state) => state);
    const privacyDetails = useSelector((state) => state.privacy);
    const [selectedWallet, setSelectedWallet] = useState('cash-wallet');
    const [billerName, setBillerName] = useState(null);
    const [biller, setBiller] = useState(null);
    const billerError = useRef(false);
    const [billerErrorMessage, setBillerErrorMessage] =
        useState('Select a biller');
    const [billerPlanList, setBillerPlanList] = useState([]);
    const [billerPlanCode, setBillerPlanCode] = useState(null);
    const [billerPlanName, setBillerPlanName] = useState(null);
    const [amount, setAmount] = useState(null);
    const amountError = useRef(false);
    const [amountErrorMessage, setAmountErrorMessage] = useState('');
    const billerPlanError = useRef(false);
    const [billerPlanErrorMessage, setBillerPlanErrorMessage] =
        useState('Select a package');
    //const [pageLoader, setPageLoader] = useState(false);
    const [refNumber, setRefNumber] = useState('');
    const [fetchingRefName, setFetchingRefName] = useState(false);
    const refNameError = useRef(false);
    const wrongRefNumber = useRef(false);
    const [refNameErrorMessage, setRefNameErrorMessage] = useState('');
    const [showCableSummary, setShowCableSummary] = useState(false);
    const [points, setPoints] = useState(750);
    const [recipient, setRecipient] = useState('');
    const [conveniencyFee, setConveniencyFee] = useState('');
    const [isRewardWalletLocked, setIsRewardWalletLocked] = useState(false);

    const { has_kyc } = auth.user !== null && auth.user;

    const walletLocked = lock_wallet && use_lock_wallet;

    useEffect(() => {
        dispatch(getUserDetails());
        if (privacyDetails.data == null) {
            dispatch(getPrivacySettings());
        }
    }, [dispatch]);

    useEffect(() => {
        if (biller !== null) {
            getBillersPlan(biller);
        }
    }, [biller]);

    useEffect(() => {
        if (amount !== null) {
            validateAmount();
        }
    }, [selectedWallet]);

    useEffect(() => {
        validateAmount();
    }, [amount]);

    useEffect(() => {
        if (refNumber !== '') {
            validateRefNumber();
            getRefDetails();
        }
    }, [refNumber, billerPlanCode]);

    const getBillersPlan = (biller) => {
        fetchBillers(biller).then((response) => {
            setBillerPlanList(response.data.list.varations);
            setBillerName(response.data.list.ServiceName);
            //setPoints(response.data.point_factor);
            setConveniencyFee(response.data.conveniency_fee);
            setBillerPlanCode(null);
            setBillerPlanName(null);
            setAmount(null);
        });
    };

    const checkAccountType = () => {
        if (selectedWallet === 'reward-wallet' && walletLocked) {
            return setIsRewardWalletLocked(true);
        }

        if (selectedWallet === 'reward-wallet' && !has_kyc) {
            return setVerifyModalOpen(true);
        }

        if (selectedWallet === 'cash-wallet' && !has_kyc) {
            return setVerifyModalOpen(true);
        }

        if (!has_pin) {
            return setTransactionPinOpen(true);
        } else {
            setShowCableSummary(true);
        }
    };

    const validateFields = () => {
        validateBiller();
        validateAmount();
        validateBillerPlan();
        validateRefNumber();
    };

    const validateBiller = () => {
        if (biller == null) {
            billerError.current = true;
        } else {
            billerError.current = false;
        }
    };

    const validateBillerPlan = () => {
        if (billerPlanCode == null) {
            billerPlanError.current = true;
        } else {
            billerPlanError.current = false;
        }
    };

    const validateAmount = () => {
        let balance =
            selectedWallet === 'cash-wallet'
                ? cashWalletBalance
                : enlumiRewardsWalletBalance;
        let wallet =
            selectedWallet === 'cash-wallet' ? 'cash wallet' : 'rewards wallet';
        if (parseInt(amount, 10) > balance && amount !== null) {
            amountError.current = true;
            setAmountErrorMessage(`Insufficient funds in ${wallet}`);
        } else {
            amountError.current = false;
            setAmountErrorMessage('');
        }
    };

    const validateRefNumber = () => {
        if (refNumber.length >= 10 && refNumber.length <= 12) {
            if (!wrongRefNumber.current) {
                refNameError.current = false;
                setRefNameErrorMessage('');
            }
        } else {
            refNameError.current = true;
            setRefNameErrorMessage(
                'IUC number should be between 10 to 12 digits'
            );
        }
    };

    const getRefDetails = () => {
        if (
            billerPlanCode !== null &&
            refNumber.length >= 10 &&
            refNumber.length <= 12
        ) {
            setFetchingRefName(true);
            resoloveRefName({
                service_id: biller,
                customer_number: refNumber,
            })
                .then((response) => {
                    setFetchingRefName(false);
                    refNameError.current = false;
                    wrongRefNumber.current = false;
                    setRefNameErrorMessage(response.data.Customer_Name);
                    setRecipient(response.data.Customer_Name);
                })
                .catch((error) => {
                    setRecipient('');
                    setFetchingRefName(false);
                    if (error.response) {
                        if (error.response.status === 400) {
                            refNameError.current = true;
                            wrongRefNumber.current = true;
                            setRefNameErrorMessage(error.response.data.error);
                        }
                    }
                });
        } else {
            setFetchingRefName(false);
        }
    };

    return (
        <div className="cable-page">
            <div
                onClick={() => {
                    if (showCableSummary === true) {
                        setShowCableSummary(false);
                    } else {
                        history.replace('/activities/pay-bills/bills');
                    }
                }}
                className="cable-page__header"
            >
                <img src={arrow_left} alt="back" />
                <div>
                    <h2>Cable TV</h2>
                </div>
            </div>
            {!showCableSummary ? (
                <div>
                    <p className="cable-page__description">
                        Kindly provide the details for your recharge
                    </p>
                    {!privacyDetails.loading ? (
                        <div className="cable-page__content mt-5">
                            {/* wallet section */}
                            <div className="cable-page__content__wallet-group">
                                <h4 className="cable-page__content__wallet-group__heading">
                                    Select wallet:
                                </h4>
                                <div className="cable-page__content__wallet-group__group-container mb-3">
                                    <label
                                        htmlFor="cash--wallet"
                                        className={`mb-3 cash-wallet ${
                                            selectedWallet === 'cash-wallet'
                                                ? 'selected'
                                                : ''
                                        }`}
                                    >
                                        <div className="top-section">
                                            <p className="white--text wallet-name mr-2">
                                                Cash Wallet
                                            </p>
                                            <input
                                                type="radio"
                                                className="radio--input"
                                                value="CASH_WALLET"
                                                id="cash--wallet"
                                                name="wallet_type"
                                                checked={
                                                    selectedWallet ===
                                                    'cash-wallet'
                                                }
                                                onChange={() => {
                                                    setSelectedWallet(
                                                        'cash-wallet'
                                                    );
                                                }}
                                            />
                                            <div className="custom--radio-input"></div>
                                        </div>
                                        <div className="cash-wallet__details">
                                            <p className="white--text cash-wallet__details__name mr-2">
                                                Cash Wallet Balance
                                            </p>
                                            <h2 className="white--text cash-wallet__details__balance">
                                                N
                                                {cashWalletBalance &&
                                                    cashWalletBalance.toLocaleString()}
                                            </h2>
                                        </div>
                                        <Button
                                            className="btn btn--topup"
                                            onClick={() => {
                                                if (!has_kyc) {
                                                    toggleVerifyModal();
                                                } else {
                                                    setModalOpen(true);
                                                }
                                            }}
                                        >
                                            Top up wallet
                                        </Button>
                                        <span
                                            className="btn--topup--mobile white--text"
                                            onClick={() => {
                                                if (!has_kyc) {
                                                    toggleVerifyModal();
                                                } else {
                                                    setModalOpen(true);
                                                }
                                            }}
                                        >
                                            <span
                                                className="white--text"
                                                style={{
                                                    textDecoration: 'underline',
                                                }}
                                            >
                                                Top up wallet
                                            </span>
                                            <span
                                                className="icon-arrow-right ml-2"
                                                style={{ fontSize: '10px' }}
                                            ></span>
                                        </span>
                                    </label>
                                    <label
                                        htmlFor="reward--wallet"
                                        className={`mb-3 reward-wallet ${
                                            selectedWallet === 'reward-wallet'
                                                ? 'selected'
                                                : ''
                                        }`}
                                    >
                                        <div className="top-section">
                                            <p className="white--text wallet-name mr-2">
                                                Rewards Wallet
                                            </p>
                                            <input
                                                type="radio"
                                                className="radio--input"
                                                value="REWARDS_WALLET"
                                                id="reward--wallet"
                                                name="wallet_type"
                                                checked={
                                                    selectedWallet ===
                                                    'reward-wallet'
                                                }
                                                onChange={() => {
                                                    setSelectedWallet(
                                                        'reward-wallet'
                                                    );
                                                }}
                                            />
                                            <div className="custom--radio-input"></div>
                                        </div>
                                        <div className="reward-wallet__details">
                                            <p className="white--text reward-wallet__details__name mr-2">
                                                Rewards Wallet Balance
                                            </p>
                                            <h2 className="white--text reward-wallet__details__balance">
                                                N
                                                {enlumiRewardsWalletBalance &&
                                                    enlumiRewardsWalletBalance.toLocaleString()}
                                            </h2>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {/* form section */}
                            <div className="cable-page__content__form mt-1">
                                {/* billers */}
                                <div className="mb-1">
                                    <h4 className="mb-2">Biller:</h4>
                                    <div className="cable-page__content__form__billers">
                                        {/* dstv */}
                                        <div
                                            className={`biller mr-2 ${
                                                biller === 'dstv'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setBiller('dstv');
                                                billerError.current = false;
                                            }}
                                        >
                                            <img src={dstv_icon} alt="dstv" />
                                        </div>
                                        {/* gotv */}
                                        <div
                                            className={`biller mr-2 ${
                                                biller === 'gotv'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setBiller('gotv');
                                                billerError.current = false;
                                            }}
                                        >
                                            <img src={gotv_icon} alt="gotv" />
                                        </div>
                                        {/* startimes */}
                                        <div
                                            className={`biller ${
                                                biller === 'startimes'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setBiller('startimes');
                                                billerError.current = false;
                                            }}
                                        >
                                            <img
                                                src={startimes_icon}
                                                alt="startimes"
                                            />
                                        </div>
                                    </div>
                                    <p
                                        className={`mb-0 mt-1 error--text ${
                                            !billerError.current ? 'hidden' : ''
                                        }`}
                                    >
                                        {billerErrorMessage}
                                    </p>
                                </div>
                                {/* plan */}
                                <div style={{ width: '100%' }} className="mb-2">
                                    <div className="cable-page__content__form__input">
                                        <Label>
                                            Choose plan:
                                            <span className="purple--text">
                                                {' '}
                                                {billerName}
                                            </span>
                                        </Label>
                                        <PlanSelectField
                                            options={billerPlanList}
                                            value={billerPlanCode}
                                            onChange={(parameter) => {
                                                setBillerPlanCode(
                                                    parameter.value
                                                );
                                                setBillerPlanName(
                                                    parameter.planName
                                                );
                                                setAmount(parameter.amount);
                                                billerPlanError.current = false;
                                            }}
                                            placeholder="Choose plan..."
                                            planName={billerPlanName}
                                            nameKey="name"
                                            valueKey="variation_code"
                                            planListError={
                                                billerPlanError.current
                                            }
                                            planListErrorMessage={
                                                billerPlanErrorMessage
                                            }
                                            arrayOfObject={true}
                                        />
                                    </div>
                                </div>
                                {/* amount field */}
                                <div className="cable-page__content__form__input mb-4">
                                    <Label>Amount*</Label>
                                    <input
                                        type="number"
                                        className="input--field"
                                        placeholder="Enter amount"
                                        defaultValue={amount}
                                        disabled={true}
                                    />
                                    <p
                                        className={`mb-0 mt-1 error--text ${
                                            !amountError.current ? 'hidden' : ''
                                        }`}
                                    >
                                        {amountErrorMessage}
                                    </p>
                                </div>
                                {/* reference field */}
                                <div className="cable-page__content__form__input mb-4">
                                    <Label>IUC number*</Label>
                                    <input
                                        type="number"
                                        className="input--field"
                                        placeholder="Enter reference (IUC number)"
                                        value={refNumber}
                                        onChange={(e) => {
                                            setRefNumber(e.target.value);
                                        }}
                                    />
                                    <span className="ref-name-loader-body">
                                        {fetchingRefName ? (
                                            <FontAwesomeIcon
                                                icon="spinner"
                                                className="fa-spin"
                                            />
                                        ) : null}
                                    </span>
                                    <p
                                        className={`mb-0 mt-1 ${
                                            refNameError.current
                                                ? 'error--text'
                                                : 'purple--text'
                                        }`}
                                    >
                                        {refNameErrorMessage}
                                    </p>
                                </div>
                                {/* point to be rewarded section */}
                                {selectedWallet === 'cash-wallet' && (
                                    <div className="cable-page__content__form__points mt-3 mb-4">
                                        <div className="points__detail">
                                            <img src={star} alt="star" />
                                            <p>Points to be earned</p>
                                        </div>
                                        <p>+30000</p>
                                    </div>
                                )}
                                <div className="cable-page__content__form__btn-section">
                                    <Button
                                        className="btn btn--primary"
                                        onClick={() => {
                                            validateFields();
                                            if (
                                                !amountError.current &&
                                                !billerError.current &&
                                                !billerPlanError.current &&
                                                !refNameError.current &&
                                                !wrongRefNumber.current
                                            ) {
                                                checkAccountType();
                                            }
                                        }}
                                    >
                                        Proceed
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <PageLoader />
                    )}
                </div>
            ) : (
                <CableSummary
                    cableDetails={{
                        walletType: selectedWallet,
                        balance:
                            selectedWallet == 'cash-wallet'
                                ? cashWalletBalance
                                : enlumiRewardsWalletBalance,
                        billerPlanName: billerPlanName,
                        billerPlanCode: billerPlanCode,
                        biller: biller,
                        amount: amount,
                        points: points,
                        IUC: refNumber,
                        recipient: recipient,
                        conveniencyFee: conveniencyFee,
                    }}
                />
            )}
            
            <NubanVerification
                verifyModalOpen={verifyModalOpen}
                setVerifyModalOpen={setVerifyModalOpen}
            />
            
            {modalOpen && (
        <secondaryComponents.TopupOptionModal
          showTopUpModal={modalOpen}
          setShowTopUpModal={setModalOpen}
        />
      )}

            <RewardWalletLock
                isLocked={isRewardWalletLocked}
                setIsRewardWalletLocked={setIsRewardWalletLocked}
            />
            <CustomModal
                isActive={transactionPinOpen}
                closeModal={() => setTransactionPinOpen(false)}
                width={'400px'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose
                            onClick={() => {
                                setTransactionPinOpen(false);
                            }}
                        />
                    </div>
                    <TransactionPin setPinModalOpen={setTransactionPinOpen} />
                </div>
            </CustomModal>
        </div>
    );
};

export default Cable;
