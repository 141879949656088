import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useEffect } from "react";

import Button from "../../components/Button";
import InputField from "../../components/InputField";
import PasswordInput from "../../components/PasswordInput";
import Form from "../../components/Form";
import FormGroup from "../../components/FormGroup";
import Label from "../../components/Label";

import "./login-form.scss";
import { verifyUser } from "../../services/auth.service";
import { fetchToken } from "../../services/auth.service";
import GoogleRecaptchaV3 from "../../components/GoogleRecaptchaV3";

const LoginForm = ({ showError, grantUserAccess }) => {
  const loginSchema = yup.object().shape({
    email: yup.string().required("Email or Username is required"),
    password: yup.string().required("password is required"),
  });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({});
  const recaptchaRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema), mode: "all" });

  useEffect(() => {
    if (loginData.email !== undefined) {
      setLoading(true);
      recaptchaRef.current.handleLoaded();
    }
  }, [loginData]);

  const handleLogin = (recaptchaToken) => {
    if (loginData.email === undefined) return;
    fetchToken({
      email: loginData.email,
      password: loginData.password,
      "g-recaptcha-response": recaptchaToken,
    })
      .then((response) => {
        grantUserAccess(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 403) {
            verifyUser(loginData.email).then((response) => {
              if (!response.data["email verified"]) {
                history.push("/verify");
              }
            });
          }
          showError(error.response.data.detail);
        }
        if (!navigator.onLine) {
          showError("No internet connection, please check your network connectivity")
        }
      });
  };

  const setLoginDetails = (data) => {
    setLoginData((prevConfig) => {
      return {
        ...prevConfig,
        ...{ email: data.email, password: data.password },
      };
    });
  };
  return (
    <Form onSubmit={handleSubmit(setLoginDetails)} className="login--form">
      {/* google recaptcha */}
      <GoogleRecaptchaV3
        ref={recaptchaRef}
        resolved={handleLogin}
        onReset={()=> setLoading(false)}
        page="login"
      />

      <FormGroup>
        <Label htmlFor="email">Email or Username</Label>
        <InputField
          type="text"
          id="email"
          placeholder="Enter Email or Username"
          register={register}
          required
          label="email"
          error={errors.email}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="password">Password</Label>
        <PasswordInput
          placeholder="Enter password"
          id="password"
          register={register}
          label="password"
          error={errors.password}
          required
        />
      </FormGroup>
      <Link className="forgot--password-link" to="/forgot-password">
        Forgot Password?
      </Link>
      <Button disabled={loading} className="btn btn--primary btn--login">
        {loading ? (
          <FontAwesomeIcon icon="spinner" className="fa-spin mr-2" />
        ) : null}
        {loading ? "Logging In" : "Log in"}
      </Button>
    </Form>
  );
};

export default LoginForm;