//import { nFormatter } from "../helpers/nFormatter";
import Button from "./Button";
import star_icon from "../assets/icons/star-icon2.svg";
import time_icon from "../assets/icons/time-icon.svg";
import sound_icon from "../assets/icons/sound-icon.svg";
import useModal from "../hooks/useModal";
import ModalClose from "./ModalClose";
import {
  startQuizRequest,
  checkQualification,
} from "../services/survey.service";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuizDescription = ({
  setStartedAnswering,
  surveyDetails,
  setStatusMessage,
  showErrorModal,
  resetSurveyDetails,
  setLoader,
}) => {
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [loading, setLoading] = useState(false);
  const [userIsQualified, setUserIsQualified] = useState(null);

  useEffect(() => {
    checkQualification().then((response) => {
      setUserIsQualified(response.data);
    });
  }, []);

  const startQuiz = () => {
    setLoading(true);
    startQuizRequest({
      survey_id: surveyDetails.id,
      time: `${surveyDetails.total_questions * parseInt(process.env.REACT_APP_QUIZ_TIME, 10)}`,
    })
      .then((response) => {
        resetSurveyDetails(response.data);
        setLoading(false);
        setModalOpen(false);
        setStartedAnswering(true);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 400 || error.response.status === 422) {
            setStatusMessage(error.response.data.detail);
            showErrorModal(true);
          }
        }
      });
  };

  return (
    <div className="activities-questions-page__content__details">
      <h2 className="title">Quiz start</h2>
      <p className="instruction">
        Complete this quiz to unlock unlimited rewards for yourself.
      </p>
      <div className="survey-status">
        <div className="survey-status__point">
          <img className="me-2" src={star_icon} alt="" />
          <p>+1M POINTS </p>
        </div>
        <div className="survey-status__time">
          <img className="me-2" src={time_icon} alt="" />
          <p>
            {surveyDetails.total_questions *
              parseInt(process.env.REACT_APP_QUIZ_TIME, 10)}
            secs
          </p>
        </div>
      </div>
      <div className="more-info">
        <div className="sound">
          <img src={sound_icon} alt="" />
        </div>
        <p>{surveyDetails.description}</p>
      </div>
      <Button
        className="btn btn--primary take-survey-btn"
        onClick={() => setModalOpen(true)}
      >
        Start quiz
      </Button>
      {/* this is the default modal that shows for a failure or success request*/}
      <CustomModal
        isActive={modalOpen}
        closeModal={() => setModalOpen(false)}
        width={"450px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose onClick={() => setModalOpen(false)} />
          </div>

          <div className="text-center px-4">
            <h3 className="mb-2">TAKE NOTE</h3>
            <p className="mb-3">
              This is a quiz. You will only have{" "}
              {process.env.REACT_APP_QUIZ_TIME} seconds to answer each question.
              If you press START QUIZ and close or refresh the page YOU WILL NOT
              BE ABLE to return to the quiz. <br />
              <br />
              We are giving up to 1 MILLION bonus points for answering the
              questions correctly but this only applied to users who qualified
              for quiz week.
            </p>
            {userIsQualified ? (
              <h4 className="text-success">You are qualified for quiz week!</h4>
            ) : (
              <>
                <h4 className="text-danger mb-2">
                  You are not qualified for the quiz bonus!
                </h4>
                <a
                  href="https://bit.ly/3KPWLpR"
                  className="purple--text"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Check How To Qualify
                </a>
              </>
            )}

            <Button
              className="btn btn--primary take-survey-btn mt-3"
              disabled={loading}
              onClick={startQuiz}
            >
              {loading ? (
                <span>
                  <FontAwesomeIcon icon="spinner" className="fa-spin mr-1" />
                  <span>Processing...</span>
                </span>
              ) : (
                "Start quiz now"
              )}
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default QuizDescription;
