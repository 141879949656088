import { useEffect } from "react";
import "./questionsInput.scss";

import { useState } from "react";

const MultipleChoice = (props) => {

    const initialAnswer = "";
    const [checkedOption, setCheckedOption] = useState((props.answer)? props.answer: initialAnswer);

    useEffect(() => {
        resetAnswer();
    }, [props.id]);

    useEffect(() => {
        setData();
    }, [checkedOption]);

    const resetAnswer = () => {
        setCheckedOption((props.answer)? props.answer: initialAnswer);
    }

    const checkForNextRoute = () => {
        if (checkedOption !== "") {
            let optionIndex = props.options.findIndex(x => x.value == checkedOption);
            let nextRoute = props.options[optionIndex].next_route;
            return nextRoute;
        } else {
            return "Q0"
        }
    }

    const handleOnChange = (event) => {
        setCheckedOption(event.target.value);
        setData();
    }
    const setData = () => {
        props.onchange({
            answer: checkedOption,
            nextRoute: checkForNextRoute()
        });
    }
    return (
        <div className="flex-answers">{
            props.options.map((optionValue, index) => (
                <label key={`${optionValue}${index}`} htmlFor={`option-${index}`} className={`questions-input ${optionValue.image != "" ? "questions-input--inline-block" : ""} ${checkedOption == optionValue.value ? "active" : ""}`}>
                    {optionValue.image != "" ? (
                        <img className="questions-input__image" src={optionValue.image} alt="" />
                    ) : null}

                    <div className="questions-input__section">
                        <input
                            className="questions-input__section__input-field"
                            type="radio"
                            id={`option-${index}`}
                            name="question"
                            value={optionValue.value}
                            checked={checkedOption == optionValue.value}
                            onChange={(e) => handleOnChange(e, index)}
                        />
                        <label htmlFor={`option-${index}`} className="questions-input__section__label">
                            {optionValue.value}
                            <span className="questions-input__section__label__dot">
                                <span className="dot"></span>
                            </span>
                        </label>
                    </div>
                </label>))
        }</div>
    )
}

export default MultipleChoice;

