import httpClient from '../axiosBase/axiosHandler.js';

export const verifyAccountService = (payload) => {
    const response = httpClient.post(`/users/verify/`, payload);
    return response;
};

export const getBankList = () => {
    const response = httpClient.get(`/users/verify/bank-list/`);
    return response;
};

export const accountVerificationService = (payload) => {
    const response = httpClient.post(`/verification/`, payload);
    return response;
};

export const accountVerificationServiceOTP = (payload) => {
    const response = httpClient.post(`/verification/otp/submit`, payload);
    return response;
};

export const verifyUserEmail = (payload) => {
    const response = httpClient.post(`/verify-email/otp`, payload);
    return response;
};


export const verifyUserEmailResendOTP = () => {
    const response = httpClient.post(`/verify-email/resend-otp`);
    return response;
}