import httpClient from '../axiosBase/axiosHandler.js';

export const fetchDashboard = () => {
    const response = httpClient.get(`/dashboard/`);
    return response;
};

export const fetchIncentives = () => {
    const response = httpClient.get(`/incentives/`);
    return response;
};

export const unSubscribeEmail = () => {
    const response = httpClient.post(`/users/marketing/unsubscribe/`, {});
    return response;
};