import { setError, setAuthModal } from "../actions/errorHandler.actions";

const initialState = {
    requestError: false,
    showAuthModal: false,
    message: "",
    error: null,
};

export const errorHandlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DEFAULT":
            return { ...state, requestError: action.payload.requestError, message: action.payload.message };
        case "AUTH_MODAL":
            return { ...state, showAuthModal: action.payload.showAuthModal };
        default:
            return state
    }
};

export const setErrorState = (payload) => {
    return (dispatch) => {
        dispatch(setError(payload));
    }
}

export const setAuthModalState = (payload) => {
    return (dispatch) => {
        dispatch(setAuthModal(payload));
    }
}
