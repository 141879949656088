import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { nFormatter } from '../helpers/nFormatter';

const RewardSummary = () => {
    const dashboard = useSelector((state) => state.dashboard);
    const rewards_summary = dashboard.data !== null && dashboard.data;

    const {
        enlumi_points = 0,
        enlumi_rewards_wallet_balance = 0,
        referrals = 0,
        claimed_rewards = 0,
    } = rewards_summary;

    return (
        <div className="reward__summary__desktop">
            <div className="summary">
                <p className="summary--title">Lumi Points</p>
                <h2 className="summary--value">
                    {/* 4,210 */}
                    {nFormatter(enlumi_points, 1)}
                </h2>
            </div>
            <div className="summary">
                <p className="summary--title">Reward Wallet</p>
                <h2 className="summary--value">
                    N{enlumi_rewards_wallet_balance.toLocaleString()}
                </h2>
                <Link
                    to="rewards"
                    className="summary--link white--text"
                >
                    <span
                        className="white--text"
                        style={{ textDecoration: 'underline' }}
                    >
                        Claim rewards
                    </span>
                    <span
                        className="icon-arrow-right ml-2"
                        style={{ fontSize: '10px' }}
                    ></span>
                </Link>
            </div>
            <div className="summary">
                <p className="summary--title">Refferals</p>
                <h2 className="summary--value">{referrals.toLocaleString()}</h2>
            </div>
            <div className="summary">
                <p className="summary--title">Claimed Rewards</p>
                <h2 className="summary--value">N{claimed_rewards}</h2>
            </div>
        </div>
    );
};

export default RewardSummary;
