const TableBody = ({ rows }) => {
    if (rows && rows.length > 0) {
        // const _rows = rows.map((row, idx) => (
        //     <tr key={idx} className="table__row">
        //         {Object.keys(row).map((cell, _idx) => (
        //             <td className="table__data" key={_idx}>
        //                 {row.description ? null : row[cell]}
        //             </td>
        //         ))}
        //     </tr>
        // ));

        const _rows = rows.map((row, idx) => {
            const {
                status,
                created_at,
                amount,
                type,
                tag,
                wallet,
                description,
                id,
                reference_no,
            } = row;

            return (
                <tr key={id || reference_no} className="table__row">
                    <td data-label="DATE" className="table__data">
                        {/* <input type="checkbox" name="" id="" /> */}
                        {new Date(created_at).toDateString()}
                    </td>
                    <td data-label="AMOUNT" className="table__data">
                        {amount}
                    </td>
                    <td data-label="TYPE" className="table__data">
                        {type}
                    </td>
                    <td data-label="WALLET" className="table__data">
                        {wallet || 'Naira Wallet'}
                    </td>
                    <td data-label="STATUS" className="table__data">
                        {status}
                    </td>
                    <td data-label="REFERENCE" className="table__data">
                        {tag || reference_no}
                    </td>
                    <td data-label="DESCRIPTION" className="table__data">
                        {description}
                    </td>
                    <td className="table__data">
                        <span className="material-icons-round">more_vert</span>
                    </td>
                </tr>
            );
        });

        return <tbody className="table__body">{_rows}</tbody>;
    } else {
        return null;
    }
};

export default TableBody;
