import httpClient from "../axiosBase/axiosHandler.js";

export const fetchBillers = (serviceId) => {
    const response = httpClient.get(`/billing/cable/list/?service_id=${serviceId}`);
    return response;
};

export const resoloveRefName = (payload) => {
    const response = httpClient.post(`/billing/cable/verify/`, payload);
    return response;
};

export const payForCable = (payload) => {
    const response = httpClient.post(`/billing/cable/pay/`, payload);
    return response;
};