import { useEffect, useState } from "react";
import "./questionsInput.scss";

const Paragraph = (props) => {
    const initialAnswer = "";
    const [answer, setAnswer] = useState((props.answer) ? props.answer : initialAnswer);

    useEffect(() => {
        setAnswer((props.answer) ? props.answer : initialAnswer)
    }, [props.id]);

    useEffect(() => {
        setData();
    }, [answer]);

    const checkForNextRoute = () => {
        let nextRoute = props.next_route;
        return nextRoute;
    }
    const handleChange = (e) => {
        setAnswer(e.target.value)
    }
    const setData = () => {
        props.onchange({
            answer: answer,
            nextRoute: checkForNextRoute()
        });
    }
    return (
        <textarea className="questions-input__input-field questions-input__input-field--paragraph" rows="1" onChange={handleChange} value={answer}></textarea>
    )
}

export default Paragraph;