import './TransactionPinSettings.scss';

import { FaAngleRight, FaKey, FaRedoAlt } from 'react-icons/fa';
import { Link, Switch, Route, useRouteMatch } from 'react-router-dom';

import ChangePin from './ChangePin/ChangePin';
import ResetPin from './ResetPin/ResetPin';

const TransactionPinSettings = () => {
    const { url, path } = useRouteMatch();

    return (
        <div className="transaction-pin-page">
            <Switch>
                <Route exact path={path}>
                    <div className="transaction-pin-page__options">
                        <Link
                            to={`${url}/change-pin`}
                            className="transaction-pin-page__options__change-pin"
                        >
                            <FaKey className="option__header-icon" />
                            <div className="option__details">
                                <h5>Change PIN</h5>
                                <FaAngleRight className="option__icon" />
                            </div>
                        </Link>
                        <Link
                            to={`${url}/reset-pin`}
                            className="transaction-pin-page__options__reset-pin"
                        >
                            <FaRedoAlt className="option__header-icon" />
                            <div className="option__details">
                                <h5>Reset PIN</h5>
                                <FaAngleRight className="option__icon" />
                            </div>
                        </Link>
                    </div>
                </Route>
                <Route path={`${path}/change-pin`}>
                    <ChangePin />
                </Route>
                <Route path={`${path}/reset-pin`}>
                    <ResetPin />
                </Route>
            </Switch>
        </div>
    );
};

export default TransactionPinSettings;
