import { Link } from "react-router-dom";

import primary_logo from "../assets/websiteImages/primary-logo.svg";
import bottomBackgorund from "../assets/images/onboarding-sidebar-design.svg";
import check_mark_lg from "../assets/icons/check-mark-lg.svg";

const SignupLayout = ({ children, activeStep }) => {
    const steps = [
        { id: 1, title: "Bio-data" },
        { id: 2, title: "Location" },
        { id: 3, title: "Create Password" },
        { id: 4, title: "Transaction Pin" },
    ];

    return (
        <div className="signup-layout">
            <div className="signup-layout__left">
                {/* logo */}
                <Link to="/">
                    <img src={primary_logo} style={{ width: '120px' }} alt="enlumi logo" />
                </Link>

                <div className="step--indicators">
                    {steps.map((step) => (
                        <div
                            className={`step ${activeStep === step.id ? "step--active" : ""
                                } ${activeStep > step.id ? "step--complete" : ""
                                }`}
                            key={step.id}
                        >
                            <div className="step--number">
                                {activeStep > step.id ? (
                                    <img
                                        src={check_mark_lg}
                                        alt="check-mark icon"
                                    />
                                ) : (
                                    step.id
                                )}
                            </div>
                            <p className="step--title">{step.title}</p>
                        </div>
                    ))}
                </div>

                <img src={bottomBackgorund} className="bottom-bg" alt="" />
            </div>
            <div className="signup-layout__right">
                {/* mobile logo, display on mobile view */}
                <Link to="/" className="signup-layout__right__logo">
                    <img src={primary_logo} style={{ width: '70px' }} alt="enlumi logo" />
                </Link>
                <div className="signup-layout__right__nav d-flex justify-content-end">
                    <span className="big-text-size">Already have an account? <Link className='big-text-size purple--text ml-1' to='/login'>Log in</Link></span>
                </div>

                <div className="signup-layout__right__content">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default SignupLayout;