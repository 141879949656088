import "./termsAndCondition.scss";

const TermsAndCondition = () => {
    return (
        <div className="terms-condition-container">
            <div className="title-section">
                <h2>Enlumi Terms and Condition</h2>
            </div>
            <div className="content-section">
                <div className="sidenav">
                    <div className="sidenav-body">
                        <h5>Introduction</h5>
                        <div className="mt-3">
                            <a href="#definitions" className="d-block mb-2">Definitions</a>
                            <a href="#acceptance-of-pluralsight" className="d-block mb-2">Acceptance of Terms</a>
                            <a href="#scope-of-enlumi" className="d-block mb-2">Scope of Enlumi Insights’ Services</a>
                            <a href="#accounts" className="d-block mb-2">Accounts</a>
                            <a href="#Users-Representations-Warranties" className="d-block mb-2">Users’ Representations &
                                Warranties</a>
                            <a href="#Users-Verification" className="d-block mb-2">Users Verification</a>
                            <a href="#Rewards-Payment-System" className="d-block mb-2">Rewards and Payment System</a>
                            <a href="#Business-Clients" className="d-block mb-2">Business Clients</a>
                            <a href="#Intellectual-Property" className="d-block mb-2">Intellectual Property</a>
                            <a href="#Prohibited-Activities" className="d-block mb-2">Prohibited Activities</a>
                            <a href="#Links-Websites" className="d-block mb-2">Links to Other Websites</a>
                            <a href="#Communications" className="d-block mb-2">Communications</a>
                            <a href="#Termination" className="d-block mb-2">Termination</a>
                            <a href="#Indemnity" className="d-block mb-2">Indemnity</a>
                            <a href="#Limitation-Liability" className="d-block mb-2">Limitation of Liability</a>
                            <a href="#Disclaimer" className="d-block mb-2">Disclaimer</a>
                            <a href="#Governing-Law" className="d-block mb-2">Governing Law</a>
                            <a href="#Dispute-Resolution" className="d-block mb-2">Dispute Resolution</a>
                            <a href="#Feedback" className="d-block mb-2">Feedback</a>
                            <a href="#Changes-Terms-Conditions" className="d-block mb-2">Changes to Terms & Conditions</a>
                            <a href="#Questions" className="d-block mb-2">Questions</a>
                        </div>
                    </div>
                </div>
                <div className="py-2 content">
                    <h3 className="purple--text mb-2">Introduction</h3>
                    <p className="mb-0">
                        Please read these Terms of Use (“Terms”), which set forth the legally binding terms and
                        conditions
                        between you and Enlumi Insights Ltd (“Enlumi Insights”). It governs your access to and the use
                        of
                        our Website (the “Website”), mobile application (the “App”) and all or any related services
                        (collectively referred to as the “Service”) offered by Enlumi Insights.<br /><br />

                        Our collection and use of personal information in connection with your access to and use of the
                        Service is described in our <a href="privacy-policy.html" className="purple--text"
                            style={{fontSize: '16px'}}>Privacy Policy.</a><br /><br />

                        Your access to and use of the Service is conditioned on your acceptance of and compliance with
                        these
                        Terms. These Terms apply to all visitors, users and others who access or use our Website, App
                        and
                        the Service.<br /><br />

                        By accessing or using the Service you agree to be bound by these Terms. If you disagree with any
                        part of the terms then you may not access the Service.
                    </p>
                    {/* definitions */}
                    <h6 id="definitions" className="mb-4 mt-4 purple--text">1. Definitions</h6>
                    <p className="mb-0">
                        In these Terms,<br /><br />

                        “Account” means unique personified account registered in the name of the User and which contains
                        details of the User’s transactions/operations on the Platform (as defined below). Account
                        holders
                        and visitors to the website are referred to collectively as “Users.”<br /><br />

                        “Platform” refers to the platform on which surveys and reviews are completed.<br /><br />

                        “Service(s)” refers to all products and services provided to you by Enlumi Insights.<br /><br />

                        “VerifyMe” refers to our digital identity and verification partner.<br /><br />

                        “Website” refers to the website for the services rendered by Enlumi Insights which can be found
                        at
                        www.enlumi.io
                    </p>
                    {/* Acceptance of terms */}
                    <h6 id="acceptance-of-pluralsight" className="mb-4 mt-5 purple--text">2. Acceptance of Terms</h6>
                    <p className="mb-0">
                        The Service is offered subject to the acceptance of all the terms and
                        conditions contained in these
                        Terms and all other operating rules, policies, and procedures that may be published on the
                        Website
                        by Enlumi Insights, which are incorporated by reference, including operating rules, policies,
                        and
                        procedures of third-party service providers to the Website and App that are referenced herein.
                        These
                        Terms apply to every user of the Service.<br /><br />

                        Enlumi Insights reserves the right, at its sole discretion, to modify or replace these Terms
                        from
                        time to time by posting the updated terms on the Website. It is your responsibility to check the
                        Terms periodically for changes. If you object to any such changes, your sole recourse will be to
                        cease using the Website, App and Services. Your continued use of the Website and App following
                        the
                        posting of any changes to the Terms will indicate your acknowledgement of such changes and
                        agreement
                        to be bound by the terms and conditions of such changes.<br /><br />

                        Enlumi Insights reserves the right to change, suspend, or discontinue the Service (including,
                        but
                        not limited to, the availability of any feature, database, or content) at any time for any
                        reason.
                        Enlumi Insights may also impose limits on certain features and Services or restrict or deny your
                        access to parts of or the entire Website and App without notice or liability.
                    </p>
                    {/* Scope of Enlumi Insights’ Services */}
                    <h6 id="scope-of-enlumi" className="mb-4 mt-5 purple--text">3. Scope of Enlumi Insights’ Services</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">3.1. </span>The Service is offered subject to the acceptance of all
                            the
                            terms and conditions contained in these
                            Terms and all other operating rules, policies, and procedures that may be published on the
                            Website
                            by Enlumi Insights, which are incorporated by reference, including operating rules, policies,
                            and
                            procedures of third-party service providers to the Website and App that are referenced herein.
                            These
                            Terms apply to every user of the Service.<br /><br />

                            <span className="purple--text">3.2. </span>Enlumi Insights reserves the right, at its sole
                            discretion,
                            to modify or replace these Terms from
                            time to time by posting the updated terms on the Website. It is your responsibility to check the
                            Terms periodically for changes. If you object to any such changes, your sole recourse will be to
                            cease using the Website, App and Services. Your continued use of the Website and App following
                            the
                            posting of any changes to the Terms will indicate your acknowledgement of such changes and
                            agreement
                            to be bound by the terms and conditions of such changes.<br /><br />

                            <span className="purple--text">3.3. </span>Enlumi Insights reserves the right to change, suspend, or
                            discontinue the Service (including, but
                            not limited to, the availability of any feature, database, or content) at any time for any
                            reason.
                            Enlumi Insights may also impose limits on certain features and Services or restrict or deny your
                            access to parts of or the entire Website and App without notice or liability.</span>
                    </p>
                    {/* Accounts */}
                    <h6 id="accounts" className="mb-4 mt-5 purple--text">4. Accounts</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">4.1. </span>To use and access the Service, all Users must register an
                            account (“Account”).<br /><br />

                            <span className="purple--text">4.2. </span>You can register an Account using an email address, phone
                            number and creating a password.<br /><br />

                            <span className="purple--text">4.3. </span>You are only permitted to register one Account and Users
                            who
                            attempt to register multiple
                            accounts shall be prevented from using the Service.<br /><br />

                            <span className="purple--text">4.4. </span>If you are registering an Account for a company or other
                            legal entity, you represent and
                            warrant that you have the authority to legally bind that entity and grant us all permissions and
                            licenses provided in these Terms.<br /><br />

                            <span className="purple--text">4.5. </span>Accounts opened on the Website and App can only be used
                            by
                            the person in whose name they are
                            registered under. The User is granted an exclusive and non-assignable right to the use of and to
                            access the Account and it is the User’s responsibility to ensure that no other third-party gains
                            access to the Account assigned to the User.<br /><br />

                            <span className="purple--text">4.6. </span>You must provide accurate, current and complete
                            information
                            during the registration process and
                            keep the information on your profile page information up-to-date at all times. Failure to do so
                            constitutes a breach of these Terms, which may result in immediate termination of your account
                            on
                            our Website and App.<br /><br />

                            <span className="purple--text">4.7. </span>You are responsible for safeguarding the password that
                            you
                            use to access the Website and App
                            and for any activities or actions under your password, whether your password is with our Service
                            or
                            a third-party service.<br /><br />

                            <span className="purple--text">4.8. </span>You agree not to disclose your password to any third
                            party.
                            You must notify us immediately upon
                            becoming aware of any breach of security or unauthorized use of your account.<br /><br />

                            <span className="purple--text">4.9. </span>You may not use as a username the name of another person
                            or
                            entity or that is not lawfully
                            available for use, a name or trade mark that is subject to any rights of another person or
                            entity
                            other than you without appropriate authorization, or a name that is otherwise offensive, vulgar
                            or
                            obscene.<br /><br />

                            <span className="purple--text">4.10. </span>You are liable for any and all activities conducted
                            through
                            your Account, unless such
                            activities are not authorized by you and you are not otherwise negligent (such as failing to
                            report
                            the unauthorized use or loss of your credentials).<br /><br />

                            <span className="purple--text">4.11. </span>You agree to strictly observe the security and
                            authentication procedures of the Platform and
                            you will log out from the Platform at the end of every visit.</span>
                    </p>
                    {/* Users’ Representations & Warranties */}
                    <h6 id="Users-Representations-Warranties" className="mb-4 mt-5 purple--text">5. Users’ Representations &
                        Warranties</h6>
                    <p className="mb-0">
                        Representations and warranties are statements and promises made by you to Enlumi Insights, which we
                        rely on as being accurate in our dealings with you. You make the following representations and
                        warranties to us at the time of agreeing to these Terms and every time you use the Platform;<br /><br />
                        <span className="d-block ms-4">
                            <span className="purple--text">5.1. </span>you are over the age of 15 years;<br /><br />

                            <span className="purple--text">5.2. </span>you are of sound mind and have the capacity to enter into
                            a
                            legally binding contract;<br /><br />

                            <span className="purple--text">5.3. </span>all personal information that you provide about yourself
                            is
                            accurate and true to the best of
                            your knowledge;<br /><br />

                            <span className="purple--text">5.4. </span>all information you provide about your company is
                            accurate
                            and true to the best of your
                            knowledge;<br /><br />

                            <span className="purple--text">5.5. </span>you have carefully considered the risks involved in using
                            the
                            Service;<br /><br />

                            <span className="purple--text">5.6. </span>if you are registering or accepting these Terms on behalf
                            of
                            a legal entity such as a company,
                            trust or partnership, you are legally authorised to do so and we may request evidence of such
                            legal
                            entitlement (by way of a copy of any document which shows the valid and subsisting
                            authorisation);<br /><br />

                            <span className="purple--text">5.7. </span>you shall be solely responsible for the use of the
                            Services
                            you subscribe for on our Website
                            and App;<br /><br />

                            <span className="purple--text">5.8. </span>you shall use the Services only in accordance with these
                            Terms, our Privacy Policy, and
                            applicable law or regulation;<br /><br />

                            <span className="purple--text">5.9. </span>you shall obtain such professional advice as is
                            appropriate
                            to protect your interests,
                            including legal, tax, accounting and other advice; and <br /><br />

                            <span className="purple--text">5.10. </span>you are not breaching any laws or regulations that are
                            applicable to you or any company, trust
                            or partnership upon whose instructions you are acting.</span>
                    </p>
                    {/* Users Verification */}
                    <h6 id="Users-Verification" className="mb-4 mt-5 purple--text">6. Users Verification</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4"><span className="purple--text">6.1. </span>Before we credit your Account with
                            your reward, we will
                            verify
                            your identity with our digital
                            identity and verification partner, VerifyMe.<br /><br />

                            <span className="purple--text">6.2. </span>You agree to provide us with a valid government issued ID
                            including but not limited to your
                            driver’s licence, national identity number (NIN), passport or permanent voter’s card to verify
                            your
                            identity.<br /><br />

                            <span className="purple--text">6.3. </span>When you create an account on our Website and App, we may
                            also
                            conduct a background check on
                            you to verify the personal information you have provided us.</span>
                    </p>
                    {/*  Rewards and Payment System */}
                    <h6 id="Rewards-Payment-System" className="mb-4 mt-5 purple--text">7. Rewards and Payment System</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">7.1.</span> When you successfully complete a survey or review on our
                            Platform, your Account will be
                            credited with points.<br /><br />

                            <span className="purple--text">7.2.</span> Your points balance will increase and is indicated on
                            your
                            dashboard after the completion of
                            every survey.<br /><br />

                            <span className="purple--text">7.3.</span> The progress bars on your dashboard will let you know
                            when
                            you are eligible to receive a
                            reward. Once your bar is full, you will receive the associated reward.<br /><br />

                            <span className="purple--text">7.4.</span> Upon the successful verification of your identity, we
                            will
                            send you your associated reward by
                            contacting you on your phone number or email address.<br /><br />

                            <span className="purple--text">7.5.</span> You may only claim your reward if your Account is active
                            and
                            has not been terminated or
                            suspended.<br /><br />

                            <span className="purple--text">7.6.</span> You agree that you are not entitled to and will not
                            receive
                            any interest or earnings on any
                            unclaimed rewards in your Account.</span>
                    </p>
                    {/*  Business Clients */}
                    <h6 id="Business-Clients" className="mb-4 mt-5 purple--text">8. Business Clients</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">8.1.</span> When you register an account on our Website and App, you
                            must
                            provide a description of your
                            product for review and all necessary information to use our Service.<br /><br />

                            <span className="purple--text">8.2.</span> You agree that the Service will be provided to you for a
                            fee
                            (the “Service Fee”) and a product
                            survey will be created based on the information you provided on the product.<br /><br />

                            <span className="purple--text">8.3.</span> You agree that the result of the product survey and or
                            review
                            shall be compiled and sent to you
                            as a report.<br /><br />

                            <span className="purple--text">8.4.</span> You agree that the report shall only contain generic and
                            non-personal information of other
                            Users who participated in the survey.</span>
                    </p>
                    {/*  Rewards and Payment System */}
                    <h6 id="Intellectual-Property" className="mb-4 mt-5 purple--text">9. Intellectual Property</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">9.1.</span> Our Website, App, the Service and its original content,
                            features and functionality are and will
                            remain the exclusive property of Enlumi Insights. Our trademarks may not be used in connection
                            with
                            any product or service without our prior written consent.<br /><br />

                            <span className="purple--text">9.2.</span> Enlumi Insights may, at its sole discretion, enable Users
                            to
                            access and view any content that
                            we make available on or through our Website, and App, including our proprietary content and any
                            content licensed or authorized for use by or through Enlumi Insights from a third party
                            ("Content").<br /><br />

                            <span className="purple--text">9.3.</span> You will not use, copy, adapt, modify, prepare derivative
                            works of, distribute, license, sell,
                            transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the
                            Website,
                            App or Content, except to the extent that you are the legal owner of certain content or as
                            expressly
                            permitted in these Terms. No licenses or rights are granted to you by implication or otherwise
                            under
                            any intellectual property rights owned or controlled by Enlumi Insights or its licensors, except
                            for
                            the licenses and rights expressly granted in these Terms.<br /><br />

                            <span className="purple--text">9.4.</span> Subject to your compliance with these Terms, Enlumi
                            Insights
                            grants you a limited,
                            non-exclusive, non-sub licensable, revocable, non-transferable license to access and view any
                            Content made available on or through the Website and App and accessible to you, solely for your
                            personal and non-commercial use.

                            9.5. Users shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse
                            engineer,
                            or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of
                            the
                            Website and App, except to the extent applicable laws specifically prohibit such restriction;
                            (ii)
                            modify, translate, or otherwise create derivative works of any part of the Website and App; or
                            (iii)
                            copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive
                            hereunder.</span>
                    </p>
                    {/*  Prohibited-Activities */}
                    <h6 id="Prohibited-Activities" className="mb-4 mt-5 purple--text">10. Prohibited Activities</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">10.1.</span> You shall not use the Services for any purpose that is
                            prohibited by these Terms. You are
                            responsible for all of your activity in connection with the Services. Violation of our rules may
                            result in the termination and cancellation of your account. You acknowledge and agree that we
                            may
                            terminate any Account at any time for any reason (including, but not limited to, our independent
                            assessment or the receipt of claims or allegations from third parties or authorities).<br /><br />

                            <span className="purple--text">10.2.</span> Users shall not utilize the Website, App or the Services
                            for
                            any illegal purpose<br /><br />

                            <span className="purple--text">10.3.</span> You are solely responsible for compliance with any and
                            all
                            laws, rules, regulations, and
                            obligations that may apply to your use of the Services. In connection with your use of the
                            Website
                            and App, you will not and will not assist or enable others to:<br /><br />

                            <span className="d-block ms-4"><span className="purple--text ">10.3.1 </span>breach or circumvent any
                                applicable laws or regulations, agreements with third-parties, third-party
                                rights, or our Terms;<br />
                                <span className="purple--text">10.3.2 </span>use the Website, App or Content for any commercial
                                or
                                other purposes that are not expressly
                                permitted by these Terms or in a manner that falsely implies endorsement, partnership or
                                otherwise
                                misleads others as to your affiliation with Enlumi Insights;<br />
                                <span className="purple--text ">10.3.3 </span>copy, store or otherwise access or use any
                                information, including personally identifiable
                                information about any other User, contained on the Website and App in any way that is
                                inconsistent
                                with Enlumi Insights’s Privacy Policy or these Terms or that otherwise violates the privacy
                                rights
                                of Users or third parties;<br />
                                <span className="purple--text ">10.3.4 </span>create multiple accounts;<br />
                                <span className="purple--text ">10.3.5 </span>use a VPN to disguise, mask or misrepresent your
                                location;<br />
                                <span className="purple--text ">10.3.6 </span>discriminate against or harass anyone on the basis
                                of
                                race, tribe, origin, religion, gender,
                                physical or mental disability, medical condition, marital status, or age, or otherwise
                                engage in
                                any abusive or disruptive behaviour;<br />
                                <span className="purple--text ">10.3.7 </span>use, display, mirror or frame the Website, App or
                                Content, or any individual element within the
                                Website or App, Enlumi Insights’ name, any trademark, logo or other proprietary information
                                belonging to Enlumi Insights, or the layout and design of any page or form contained on a
                                page
                                in
                                the Website and App, without Enlumi Insight’ express written consent;<br />
                                <span className="purple--text ">10.3.8 </span>dilute, tarnish or otherwise harm Enlumi Insights’
                                brand in any way, including through unauthorized
                                use of Content, registering and/or using derivative terms in domain names, trade names,
                                trademarks
                                or other source identifiers, or registering and/or using domains names, trade names,
                                trademarks
                                or
                                other source identifiers that closely imitate or are confusingly similar to Enlumi
                                Insights’domains, trademarks, taglines, promotional campaigns
                                or Content;<br />
                                <span className="purple--text ">10.3.9 </span>use any robots, spider, crawler, scraper or other
                                automated means or processes to access,
                                collect data or other content from or otherwise interact with
                                the Website and App for any purpose;<br />

                                <span className="purple--text ">10.3.10 </span>avoid, bypass, remove, deactivate, impair,
                                descramble, or otherwise attempt to circumvent any
                                technological measure implemented by Enlumi Insight or any of Enlumi Insights’ service
                                providers
                                or any other third party to protect the Website and
                                App;<br />
                                <span className="purple--text ">10.3.11 </span>attempt to decipher, decompile, disassemble or
                                reverse engineer any of the software used to
                                provide
                                the Platform;<br />
                                <span className="purple--text ">10.3.12 </span>take any action that damages or adversely
                                affects, or
                                could damage or adversely affect the performance or proper functioning of the Website and
                                App;<br />
                                <span className="purple--text ">10.3.13 </span>violate or infringe anyone else’s rights or
                                otherwise
                                cause harm to anyone.</span><br />
                            <span className="purple--text">10.4.</span> You acknowledge that Enlumi Insights has no obligation
                            to
                            monitor the access to or use of the
                            Website and App by any User or to review, disable access to, or edit any Content, but has the
                            right
                            to do so to (i) operate, secure and improve the Website and App (including without limitation
                            for
                            fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure
                            Users’
                            compliance with these Terms; (iii) comply with applicable law or the order or requirement of a
                            court, law enforcement or other administrative agency or governmental body; (iv) respond to User
                            Content that it determines is harmful or objectionable; or (v) as otherwise set forth in these
                            Terms. Users agree to cooperate with and assist Enlumi Insights in good faith, and to provide
                            Enlumi
                            Insights with such information and take such actions as may be reasonably requested by Enlumi
                            Insights with respect to any investigation undertaken by Enlumi Insights or a representative of
                            Enlumi Insights regarding the use or abuse of the Website and App.<br /><br />

                            <span className="purple--text">10.5.</span> If you feel that any User you interact with, whether
                            online
                            or in person, is acting or has
                            acted inappropriately, including but not limited to anyone who (i) engages in offensive, violent
                            or
                            sexually inappropriate behaviour, (ii) you suspect of stealing from you, or (iii) engages in any
                            other disturbing conduct, you should immediately report such person to the appropriate
                            authorities
                            and then to En Enlumi Insights by contacting us with your police station and report number (if
                            available); provided that your report will not obligate us to take any action beyond that
                            required
                            by law (if any) or cause us to incur any liability to you.</span>
                    </p>
                    {/*  Links to Other Websites */}
                    <h6 id="Links-Websites" className="mb-4 mt-5 purple--text">11. Links to Other Websites</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">11.1.</span> The Website and App may contain links to third-party
                            websites or services that are not owned
                            or controlled by Enlumi Insights. Enlumi Insights may, at its sole discretion, enable Users to
                            access and view any content that we make available on or through our Website, and App, including
                            our
                            proprietary content and any content licensed or authorized for use by or through Enlumi Insights
                            from a third party ("Content").<br /><br />

                            <span className="purple--text">11.2.</span> Elumi Insights has no control over, and assumes no
                            responsibility for, the content, privacy
                            policies, or practices of any third-party websites or services. You further acknowledge and
                            agree
                            that Enlumi Insights shall not be responsible or liable, directly or indirectly, for any damage
                            or
                            loss caused or alleged to be caused by or in connection with use of or reliance on any such
                            content,
                            goods or services available on or through any such websites or services. We strongly advise you
                            to
                            read the terms and conditions and privacy policies of any third-party websites or services that
                            you
                            visit.</span>
                    </p>
                    {/*  Communications */}
                    <h6 id="Communications" className="mb-2 mt-4 purple--text">12. Communications</h6>
                    <p className="mb-0">
                        You agree to indemnify and hold harmless Enlumi Insights, its affiliates and subsidiaries, its
                        officers, directors, employees and agents, against all liabilities, costs, expenses, damages and
                        losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation
                        and all interest penalties and legal and other reasonable professional costs and expenses) suffered
                        or incurred as a result of:<br /><br />

                        <span className="d-block ms-4">
                            <span className="purple--text">12.1.</span> By signing up and creating an Account on our Website,
                            you consent to receive
                            communications
                            from Enlumi Insights. These communications may involve sending messages and emails to the phone
                            number and email address you provided when you registered your Account.<br /><br />

                            <span className="purple--text">12.2.</span> You agree that any communications we send to you will
                            satisfy any legal requirements for
                            the
                            delivery of communications in writing or other appropriate methods.

                            12.3. If you no longer want to receive certain communications via email, please click on the
                            Unsubscribe link contained in any email.</span>
                    </p>
                    {/*  Termination */}
                    <h6 id="Termination" className="mb-4 mt-5 purple--text">13. Termination</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">13.1.</span> We may terminate or suspend your Account immediately,
                            without prior notice or liability, for
                            any reason whatsoever, including without limitation if you breach these Terms.<br /><br />

                            <span className="purple--text">13.2.</span> Upon termination, your right to use the Website, App and
                            all
                            our Services will immediately
                            cease. If you wish to terminate your Account, you may simply delete your Account and discontinue
                            using the Website and App.<br /><br />

                            <span className="purple--text">13.3.</span> If you delete your Account, you agree that you are
                            solely
                            responsible for claiming all your
                            rewards till the date of termination. Enlumi Insights has no obligation to deliver your rewards
                            to
                            you after your Account is terminated.
                        </span>
                    </p>
                    {/*  Indemnity */}
                    <h6 id="Indemnity" className="mb-4 mt-5 purple--text">14. Indemnity</h6>
                    <p className="mb-0">
                        You agree to indemnify and hold harmless Enlumi Insights, its affiliates and subsidiaries, its
                        officers, directors, employees and agents, against all liabilities, costs, expenses, damages and
                        losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation
                        and all interest penalties and legal and other reasonable professional costs and expenses) suffered
                        or incurred as a result of:<br /><br />

                        <span className="d-block ms-4">
                            <span className="purple--text">14.1.</span> your fraudulent or illegal use of the Website and
                            App;<br /><br />

                            <span className="purple--text">14.2.</span> our negligence or any default by you of any of these
                            Terms;<br /><br />

                            <span className="purple--text">14.3.</span> any inaccurate or incomplete information that you have
                            knowingly provided to us;<br /><br />

                            <span className="purple--text">14.4.</span> your allowing any other person to access your account
                            either with your permission or as a
                            result of your failure to keep your username and password private;<br /><br />

                            <span className="purple--text">14.5.</span> any service that you have offered, whether with or
                            without our permission, to another
                            third-party using Enlumi Insights’ Services;<br /><br />

                            <span className="purple--text">14.6.</span> any claim made against you for actual or alleged
                            infringement of Enlumi Insights’ intellectual
                            property rights or any actual or alleged infringement of a third party’s intellectual property
                            rights arising out of or in connection with the Services or your use of the Website and
                            App.</span>

                    </p>
                    {/*  Limitation of Liability */}
                    <h6 id="Limitation-Liability" className="mb-4 mt-5 purple--text">15. Limitation of Liability</h6>
                    <p className="mb-0">
                        In no event shall Enlumi Insights, its directors, employees, partners, agents, suppliers, or
                        affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                        including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                        resulting from:<br /><br />

                        <span className="d-block ms-4">
                            <span className="purple--text">15.1.</span> your use or inability to use the Website and
                            App;<br /><br />

                            <span className="purple--text">15.2.</span> any conduct or content of any third party on the Website
                            and App;<br /><br />

                            <span className="purple--text">15.3.</span> any unauthorized access, use or alteration of your
                            transmissions or content, whether based
                            on warranty, contract, tort (including negligence) or any other legal theory, whether or not we
                            have been informed of the possibility of such damage, and even if a remedy set forth herein is
                            found to have failed of its essential purpose;<br /><br />

                            <span className="purple--text">15.4.</span> any legal proceedings between the you and any third
                            parties</span>
                    </p>
                    {/* Disclaimer */}
                    <h6 id="Disclaimer" className="mb-4 mt-5 purple--text">16. Disclaimer</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">16.1.</span> your use of the Website and App is at your sole risk.
                            The
                            Website and App is provided on an
                            “AS IS” and “AS AVAILABLE” basis. The Website and App is provided without warranties of any
                            kind,
                            whether express or implied, including, but not limited to, implied warranties of
                            merchantability,
                            fitness for a particular purpose, non-infringement or course of performance.<br /><br />

                            <span className="purple--text">16.2.</span> Enlumi Insights, its subsidiaries, affiliates, and its
                            licensors do not warrant that a) the
                            Website and App will function uninterrupted, secure or available at any particular time or
                            location;
                            b) any errors or defects will be corrected; c) the Website and App is free of viruses or other
                            harmful components; or d) the results of using the Website and App will meet your
                            requirements.</span>
                    </p>
                    {/*  Governing Law */}
                    <h6 id="Governing-Law" className="mb-4 mt-5 purple--text">17. Governing Law</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">17.1.</span> These Terms shall be governed and construed in
                            accordance
                            with the laws of the Federal
                            Republic of Nigeria, without regard to its conflict of law provisions.<br /><br />
                            <span className="purple--text">17.2.</span> Our failure to enforce any right or provision of these
                            Terms
                            will not be considered a waiver
                            of those rights. If any provision of these Terms is held to be invalid or unenforceable by a
                            court,
                            the remaining provisions of these Terms will remain in effect. These Terms constitute the entire
                            agreement between us regarding our Service, and supersede and replace any prior agreements we
                            might
                            have between us regarding the Service.</span>
                    </p>
                    {/* Dispute Resolution */}
                    <h6 id="Dispute-Resolution" className="mb-4 mt-5 purple--text">18. Dispute Resolution</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            <span className="purple--text">18.2.</span> Any disputes arising under or in connection with the
                            validity, interpretation and performance
                            of these Terms between Enlumi Insights and any third parties shall initially be resolved
                            amicably by
                            the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the
                            Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration
                            and
                            Conciliation Act, Cap A18, Laws of the Federation of Nigeria.<br /><br />

                            <span className="purple--text">18.2.</span> The Parties shall endeavour in good faith to mutually
                            agree
                            on the selection of an arbitrator.
                            If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of
                            the
                            request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be
                            conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days
                            and
                            to provide, in writing the reasoning for the award. The decision of any such arbitrator shall be
                            final and binding on the parties.<br /><br />

                            <span className="purple--text">18.3.</span> Each party shall bear its cost in connection with the
                            Arbitration and the arbitrator’s fees
                            shall be split equally between both parties.</span>
                    </p>
                    {/* 19. Feedback */}
                    <h6 id="Feedback" className="mb-4 mt-5 purple--text">19. Feedback</h6>
                    <p className="mb-0">
                        We welcome and encourage you to provide feedback, comments and suggestions for improvements to our
                        Website or Services. You may submit your feedback by emailing us at info@enlumidata.com. Any
                        feedback you submit to us will be considered non-confidential and non-proprietary to you. By
                        submitting any feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable,
                        sub-licensable, perpetual license to use and publish those ideas and materials for any purpose,
                        without compensation to you.
                    </p>
                    {/*  Changes to Terms & Conditions */}
                    <h6 id="Changes-Terms-Conditions" className="mb-4 mt-5 purple--text">20. Changes to Terms & Conditions</h6>
                    <p className="mb-0">
                        Enlumi Insights reserves the right, in its sole discretion, to change the Terms under which the
                        Website, App and Service is offered. The most current version of the Terms will supersede all
                        previous versions. Enlumi Insights encourages you to periodically review the Terms to stay informed
                        of our updates.
                    </p>
                    {/*  Questions */}
                    <h6 id="Questions" className="mb-4 mt-5 purple--text">21. Questions</h6>
                    <p className="mb-0">
                        If you have any questions about these Terms of Use, please contact us at
                        info@enlumidata.com.<br /><br />

                        This document was last updated on 23rd of March 2021.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default TermsAndCondition;