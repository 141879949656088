import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchSurveyDetails } from "../../../services/survey.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrow_left from "../../../assets/icons/arrow-left.svg";
import useModal from "../../../hooks/useModal";
import ModalClose from "../../../components/ModalClose";
import ResponseModal from "../../../components/ResponseModal";
import MainQuestions from "./MainQuestions";
import SurveyDescription from "../../../components/SurveyDescription";
import QuizDescription from "../../../components/QuizDescription";
import { secondaryComponents } from "../../../components/secondaryComponents";

const Questions = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [startedAnswering, setStartedAnswering] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [surveyDetails, setSurveyDetails] = useState({
    questions: [{}],
    thankyoupages: [{}],
    max_points: 0,
  });
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusImage, setStatusImage] = useState("fail");
  const profile_survey_status = useSelector(
    (state) => state.dashboard.data.has_completed_profile_survey
  );

  useEffect(() => {
    if (profile_survey_status && params.get("survey-id") === "000000") {
      history.push("/activities/surveys");
    } else {
      setLoading(true);
      fetchSurveyDetails(params.get("survey-id"))
        .then((response) => {
          setSurveyDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            setModalOpen(true);
            setStatusMessage(error.response.data.detail);
          }
        });
    }
  }, []);

  return (
    <div>
      {/* Freestar ads slot */}
      {window.innerWidth <= 450 && (
        <secondaryComponents.FreestarAds
          placementName="getlumi_medrec_1"
          slotId="getlumi_medrec_1"
          publisher="getlumi-io"
          classNames={["mt-2 d-flex justify-content-center"]}
        />
      )}

      <div className="activities-questions-page">
        {/* <div style={{ margin: "-2em 0 30px -8%", width: "116%", paddingTop: "5px" }}>
                    <AdsenseMounter slot="8000315968" />
                </div> */}
        <div className="activities-questions-page__content">
          <div
            className="activities-questions-page__content__header"
            onClick={() => history.goBack()}
          >
            <img src={arrow_left} alt="arrow left" />
            <h2 className="">All surveys</h2>
          </div>
          {!loading ? (
            <div>
              {!startedAnswering ? (
                <div>
                  {!surveyDetails.is_quiz ? (
                    //   show if survey is not a quiz
                    <SurveyDescription
                      setStartedAnswering={setStartedAnswering}
                      surveyDetails={surveyDetails}
                    />
                  ) : (
                    //   show if survey is a quiz
                    <QuizDescription
                      setStartedAnswering={setStartedAnswering}
                      surveyDetails={surveyDetails}
                      showErrorModal={setModalOpen}
                      setStatusMessage={setStatusMessage}
                      resetSurveyDetails={setSurveyDetails}
                      setLoader={setLoading}
                    />
                  )}
                </div>
              ) : (
                <MainQuestions {...surveyDetails} />
              )}
            </div>
          ) : (
            <div className="activities-survey-page__loader">
              <FontAwesomeIcon icon="spinner" className="fa-spin" />
            </div>
          )}
        </div>
      </div>
      {/* this is the default modal that shows for a failure or success request*/}
      <CustomModal
        isActive={modalOpen}
        closeModal={() => history.push("/activities/surveys")}
        width={"350px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose onClick={() => history.push("/activities/surveys")} />
          </div>
          <ResponseModal message={statusMessage} status={statusImage} />
        </div>
      </CustomModal>
    </div>
  );
};

export default Questions;
