
const FormHeader = ({ title, subtitle }) => {
    return (
        <div className="form--header">
            <h2>{title}</h2>
            <p className="dark--text big-text-size" style={{ maxWidth: "500px" }}>{subtitle}</p>
        </div>
    );
};

export default FormHeader;
