import "./home.scss";
import app_img from "../../assets/websiteImages/app-img.svg";
//import app_img_copy from "../../assets/images/enlumi screen 2.png";
import lumi_img1 from "../../assets/websiteImages/lumi-img1.svg";
import lumi_img2 from "../../assets/websiteImages/lumi-img2.svg";
import lumi_img3 from "../../assets/websiteImages/lumi-img3.svg";
import activities_img from "../../assets/websiteImages/lumi-activities-img.svg";
import app_img2 from "../../assets/websiteImages/app-img2.svg";
import IOS_btn from "../../assets/websiteImages/IOS-btn-img.png";
import play_store_btn from "../../assets/websiteImages/play-store-btn-img.svg";
import MetaTags from "react-meta-tags";
import { Link, Redirect, useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Home = () => {
  const isAuth = useAuth();
  const history = useHistory();

  // redirect user to dashboard if user is already logged in
  if (isAuth) return <Redirect to={"/dashboard"} />;

  return (
    <div>
      <MetaTags>
        <title>
          Lumi | Shop or Share your Opinion to Earn Cash Rewards | Join Free
        </title>
        <meta
          name="description"
          content="Lumi app is a lifestyle app that rewards you with cash when shop at your favourite brand or share your opinion on product and services. "
        />
        <meta
          property="og:title"
          content="Lumi | Shop or Share your Opinion to Earn Cash Rewards | Join Free"
        />
        {/* <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
      <div id="homepage">
        {/* banner */}
        <div className="banner">
          <div className="banner__text-section">
            <div className="text-body">
              <h1 className="mb-0">
                The Easiest Way to Earn{" "}
                <span className="bigger-text purple--text">Cashback</span>
              </h1>
              <p className="mb-4">
                Lumi helps you grow your savings with cashback on every purchase
              </p>
              {isAuth ? (
                <Link className="primary-btn" to={{ pathname: "/dashboard" }}>
                  Goto Dashboard
                </Link>
              ) : (
                <div className="banner__buttons">
                  <Link className="secondary-btn" to={{ pathname: "/signup" }}>
                    Create Account
                  </Link>
                  <Link
                    className="primary-btn"
                    to={{ pathname: "https://play.google.com/store/apps/details?id=com.getlumi" }}
                    target="_blank"
                  >
                    Download App
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="banner__img-section">
            <img src={app_img} alt="" />
          </div>
        </div>
        {/* more with lumi section */}
        <div className="more-with-lumi">
          {/* headline */}
          <div className="more-with-lumi__headline">
            <h2>
              <span className="purple--text">Do more</span> with the Lumi App
            </h2>
            <p>
              Lumi makes it rewarding to be you with quick and easy cashback on
              everyday purchases and for sharing your opinions and experiences.
            </p>
          </div>
          <div className="more-with-lumi__sectioned">
            <div className="text-section">
              <h3>Earn cashback when you shop at brands you love</h3>
              <p>
                Automatically get rewarded when you shop online and in-store at
                your favourite retailers from food and clothing to books and
                electronics.
              </p>
              <Link className="purple__link" to={{ pathname: "https://play.google.com/store/apps/details?id=com.getlumi" }}
                    target="_blank">
                <span>Start Earning Cashback</span>
                <span className="icon icon-arrow-right"></span>
              </Link>
            </div>
            <div className="img-section">
              <img src={lumi_img1} alt="" />
            </div>
          </div>
          <div className="more-with-lumi__sectioned more-with-lumi__sectioned--inverse">
            <div className="img-section">
              <img src={lumi_img2} alt="" />
            </div>
            <div className="text-section">
              <h3>Save on Airtime & Bills</h3>
              <p>
                Save up to N5,000 monthly when you pay your bills using the Lumi
                app. Buy airtime and data, pay for electricity & cable.
              </p>
              <Link className="purple__link" to={{ pathname: "https://play.google.com/store/apps/details?id=com.getlumi" }}
                    target="_blank">
                <span>Start Earning Cashback</span>
                <span className="icon icon-arrow-right"></span>
              </Link>
            </div>
          </div>
          <div className="more-with-lumi__sectioned">
            <div className="text-section">
              <h3>Get Rewarded For Sharing Your Experiences & Opinions </h3>
              <p>
                Your opinion can help businesses build the products of the
                future. Earn Lumi points for every time you share your
                experiences or opinions!
              </p>
              <Link className="purple__link" to={{ pathname: "https://play.google.com/store/apps/details?id=com.getlumi" }}
                    target="_blank">
                <span>Start Earning Cashback</span>
                <span className="icon icon-arrow-right"></span>
              </Link>
            </div>
            <div className="img-section">
              <img src={lumi_img3} alt="" />
            </div>
          </div>
        </div>
        {/* app guide section */}
        <div className="app-guide-section">
          <div className="app-guide-section__get-started">
            <div className="text-section">
              <p className="mb-0">HOW IT WORKS</p>
              <h3 className="mb-5">Lumi App in 3 Steps</h3>
              {/* step 1 */}
              <div className="steps-card d-flex">
                <div className="step-number mr-4">01</div>
                <div className="mt-2">
                  <h4>Create Account</h4>
                  <p>
                    It takes less than 1 minute to create an account on the Lumi
                    app
                  </p>
                </div>
              </div>
              {/* step 2 */}
              <div className="steps-card d-flex">
                <div className="step-number mr-4">02</div>
                <div className="mt-2">
                  <h4>Start Earning Points</h4>
                  <p>
                    Earn points when you shop, pay your bills or share
                    experiences or opinions
                  </p>
                </div>
              </div>
              {/* step 3 */}
              <div className="steps-card d-flex">
                <div className="step-number mr-4">03</div>
                <div className="mt-2">
                  <h4>Redeem your points</h4>
                  <p>Exchange your points for cash, airtime or gift cards</p>
                </div>
              </div>
            </div>
            <div className="img-section">
              <img src={activities_img} alt="" />
            </div>
          </div>
          {/* application details section */}
          <div className="app-guide-section__application-details">
            <div className="img-section">
              <img src={app_img2} alt="" />
            </div>
            <div className="text-section">
              <a
                href="https://zy8ka.app.link/lumirewards"
                className="purple--text"
                style={{ fontSize: "17px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download the Lumi app
              </a>
              <h4 className="mt-3 mb-0">
                Join over 50,000 users earning cashback with Lumi app
              </h4>
              <p className="mb-3">
                Your favourite brands choose to give you cashback offers via the
                Lumi app, instead of spending it on disruptive advertising. How
                cool is that?
              </p>

              <div className="btn-section">
                {/* temporary button. subject to replacement */}
                {/* <Link className="primary-btn" to={{ pathname: '/signup' }}>Start Earning Cashback</Link> */}
                {/* IOS btn */}
                <a
                  href="https://apps.apple.com/ng/app/lumi-rewards/id1622786806"
                  target="_blank"
                  rel="noreferrer"
                  className="app-btn "
                >
                  <img src={IOS_btn} alt="" />
                </a>
                {/* play store btn */}
                <a
                  href="https://play.google.com/store/apps/details?id=com.getlumi"
                  target="_blank"
                  rel="noreferrer"
                  className="app-btn ml-3"
                >
                  <img src={play_store_btn} alt="" />
                </a>
              </div>
              {/* <p className="mt-2" style={{ fontSize: '14px' }}>Coming soon...</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
