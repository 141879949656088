import dropdown_icon from '../assets/icons/drop-down.svg';
import Button from './Button';

import { useState, useEffect, useRef } from 'react';

const SelectInput = ({
    options = [],
    placeholder,
    nameKey,
    idKey,
    value,
    onChange,
    arrayOfObject,
    ...rest
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const inputRef = useRef(null);

    const toggle = (event) => {
        event.preventDefault();
        setShowOptions(!showOptions);
    };

    const handleSelectedOption = (event) => {
        onChange(event.target.getAttribute('value'))
        setShowOptions(!showOptions);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
    }, [inputRef]);

    return (
        <div ref={inputRef} className="custom--select">
            <Button onClick={toggle} className="btn custom--select-btn">
                <span>{value !== undefined && value != "" ? value: placeholder}</span>
                <span className="custom--arrow">
                    <img src={dropdown_icon} alt="drop down" />
                </span>
            </Button>
            <div
                className={`custom--select-items ${showOptions ? 'show' : ''}`}
            >
                {options &&
                    options.map((option, index) => (
                        <div
                            key={index}
                            onClick={(event) => {
                                handleSelectedOption(event);
                            }}
                            className="custom--select-item"
                            value={arrayOfObject ? option[idKey] : option}
                            {...rest}
                        >
                            {nameKey ? option[nameKey] : option}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default SelectInput;
