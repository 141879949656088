import { useForm, useWatch, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fetchDataPlans,
  getRewardPoints,
} from "../../../../redux/reducers/mobile-data.reducer";
import {
  setSummary,
  setMobileDataForm,
} from "../../../../redux/actions/mobile-data.actions";
import useModal from "../../../../hooks/useModal";
import InputField from "../../../../components/InputField";
import SelectInput from "../../../../components/SelectInput";
import Button from "../../../../components/Button";
import FormGroup from "../../../../components/FormGroup";
import PhoneInput from "../../../../components/PhoneInput";
import ModalClose from "../../../../components/ModalClose";
import ErrorText from "../../../../components/ErrorText";
import Form from "../../../../components/Form";
import ResponseModal from "../../../../components/ResponseModal";
import NubanVerification from "../../../../components/VerificationModals/NubanVerification/NubanVerification";
import TransactionPin from "../../../../components/VerificationModals/TransactionPin/TransactionPin";
import RewardWalletLock from "../../../../components/VerificationModals/RewardWalletLock/RewardWalletLock";
import star from "../../../../assets/icons/star.svg";
import mtn_logo from "../../../../assets/icons/mtn-logo.svg";
import airtel_logo from "../../../../assets/icons/airtel-logo.svg";
import glo_logo from "../../../../assets/icons/glo-logo.svg";
import etisalat_logo from "../../../../assets/icons/9mobile-logo.svg";
import { getPrivacySettings } from "../../../../redux/reducers/privacy.reducer";
import { getUserDetails } from "../../../../redux/reducers/auth.reducer";
import useNairaWallet from "../../../../hooks/core/FundTransfer/useNairaWallet";
import { secondaryComponents } from "../../../../components/secondaryComponents";

const PurchaseMobileData = ({ navigation }) => {
  const mobileDataSchema = yup.object().shape({
    wallet: yup.string().required("Select a wallet"),
    network_provider: yup.string().required("Choose network provider"),
    phone_number: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),
  });

  const [modalOpen, setModalOpen, , CustomModal] = useModal();
  const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
  const [transactionPinOpen, setTransactionPinOpen, toggleTransactionPin] =
    useModal();
  const { dashboard, mobileData, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [selectedWallet, setSelectedWallet] = useState("cash wallet");
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(0);
  const [amount, setAmount] = useState("");
  const [variationCode, setVariationCode] = useState("");
  const [biller, setBiller] = useState("");
  const [billerProduct, setBillerProduct] = useState("");
  const [pointFactor, setPointFactor] = useState(0);
  const [rewardError, setRewardError] = useState(false);
  const [rewardErrorMessage, setRewardErrorMessage] = useState("");
  // const [isVerifiedUser, setIsVerifiedUser] = useState(false);
  const [isRewardWalletLocked, setIsRewardWalletLocked] = useState(false);

  const providerRef = useRef(null);

  const {
    enlumi_rewards_wallet_balance,
    lock_wallet,
    use_lock_wallet,
    has_pin,
  } = dashboard.data !== null && dashboard.data;

  const { data } = useNairaWallet();
  const { withdrawable_balance } = data !== null && data;
  const wallet_balance = parseFloat(withdrawable_balance);

  const { has_kyc } = auth.user !== null && auth.user;

  const { dataPlans } = mobileData;

  const walletLocked = lock_wallet && use_lock_wallet;

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      wallet: "CASH_WALLET",
      network_provider: "",
      phone_number: "",
      data_plan: "",
    },
    resolver: yupResolver(mobileDataSchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const handleGetDataPlans = (provider) => {
    if (!provider) return;
    dispatch(fetchDataPlans(provider)).then((response) => {
      const { point_factor, multiply } = response;
      if (multiply) {
        setPointFactor(point_factor);
      }
    });
  };

  const selected_plan = useWatch({
    name: "data_plan",
    control: control,
  });

  const provider = useWatch({
    name: "network_provider",
    control: control,
  });

  const funding_channel = useWatch({
    name: "funding_channel",
    control: control,
    defaultValue: "CASH_WALLET",
  });

  const phone_number = useWatch({
    name: "phone_number",
    control: control,
    defaultValue: "",
  });

  const onSubmit = (data) => {
    data.amount = amount;
    data.data_plan = variationCode;

    setBiller(providerRef.current.id.toUpperCase());
    setBillerProduct(data.data_plan);

    if (
      selectedWallet === "cash wallet" &&
      Number(data.amount) > wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Topup or Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    } else if (
      selectedWallet === "reward wallet" &&
      Number(data.amount) > enlumi_rewards_wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    }

    dispatch(
      setMobileDataForm({
        funding_channel: data.wallet,
        service_id: data.network_provider,
        variation_code: variationCode,
        phone_number: data.phone_number,
      })
    );

    if (selectedWallet === "reward wallet" && walletLocked) {
      setLoading(false);
      return setIsRewardWalletLocked(true);
    }

    if (!has_kyc) {
      setLoading(false);
      return setVerifyModalOpen(true);
    }

    if (!has_pin) return toggleTransactionPin();

    setLoading(true);

    dispatch(
      getRewardPoints({
        funding_channel: funding_channel,
        service_id: provider,
        variation_code: variationCode,
        phone_number: phone_number,
      })
    )
      .then((res) => {
        setLoading(false);
        const { point } = res;
        setPoints(point);
        dispatch(
          setSummary({
            biller: biller,
            product: billerProduct,
            phone_number: data.phone_number,
            amount: data.amount,
            points: point,
          })
        );
        navigation.next();
      })
      .catch((error) => {
        setLoading(false);
        setRewardError(true);
        if (error.response) {
          setRewardErrorMessage(error.response.data.detail);
        }
      });
  };

  useEffect(() => {
    if (provider) {
      handleGetDataPlans(provider);
    }
  }, [provider]);

  useEffect(() => {
    dispatch(getPrivacySettings());
    dispatch(getUserDetails());
    if (selected_plan && dataPlans !== null) {
      const _plan = dataPlans.find((plan) => plan.name === selected_plan);

      const { variation_amount, variation_code } = _plan;
      setAmount(variation_amount);
      setVariationCode(variation_code);
    }
  }, [selected_plan, dispatch]);

  // useEffect(() => {
  //     if (funding_channel && provider && phone_number.length === 10) {
  //         if (funding_channel === "" && provider === "") return;
  //     }
  // }, [dispatch, funding_channel, provider, phone_number]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mobile__data-form">
      <div className="wallet__group">
        <h4 className="group__heading">Select Wallet:</h4>
        <div className="wallet__group-container">
          <label
            onClick={() => setSelectedWallet("cash wallet")}
            htmlFor="cash--wallet"
            className={`cash__wallet ${
              selectedWallet === "cash wallet" ? "selected" : ""
            }`}
          >
            <div className="wallet__top">
              <p className="wallet__name">Naira Wallet</p>
              <input
                type="radio"
                className="radio__input"
                value="CASH_WALLET"
                id="cash--wallet"
                {...register("wallet")}
              />
              <div className="custom__radio-input"></div>
            </div>
            <div className="wallet__details">
              <p className="wallet__name">Naira Wallet</p>
              <h2 className="wallet__balance">
                N{wallet_balance ? wallet_balance.toLocaleString() : 0}
              </h2>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                if (!has_kyc) {
                  toggleVerifyModal();
                } else {
                  setModalOpen(true);
                }
              }}
              className="wallet__topup-mobile"
            >
              <span className="wallet__name-mobile">Topup wallet</span>
              <span className="icon-arrow-right"></span>
            </div>
            <Button
              className="btn btn__topup"
              onClick={(e) => {
                e.preventDefault();
                if (!has_kyc) {
                  toggleVerifyModal();
                } else {
                  setModalOpen(true);
                }
              }}
            >
              Top up wallet
            </Button>
          </label>
          <label
            onClick={() => setSelectedWallet("reward wallet")}
            htmlFor="reward--wallet"
            className={`reward__wallet ${
              selectedWallet === "reward wallet" ? "selected" : ""
            }`}
          >
            <div className="wallet__top">
              <p className="wallet__name">Reward Wallet</p>
              <input
                type="radio"
                className="radio__input"
                value="REWARDS_WALLET"
                id="reward--wallet"
                {...register("wallet")}
              />
              <div className="custom__radio-input"></div>
            </div>
            <div className="wallet__details">
              <p className="wallet__name">Rewards Wallet</p>
              <h2 className="wallet__balance">
                N
                {enlumi_rewards_wallet_balance &&
                  enlumi_rewards_wallet_balance.toFixed(2).toLocaleString()}
              </h2>
            </div>
          </label>
        </div>
        {errors.wallet && <ErrorText message={errors.wallet.message} />}
      </div>
      {/* Put the rest section here */}
      <div className="provider__group">
        <h4 className="group__heading">Select Biller:</h4>
        <div className="provider__group-container">
          <label htmlFor="mtn" className="provider">
            <input
              type="radio"
              className="provider__input"
              {...register("network_provider")}
              value="mtn-data"
              id="mtn"
              ref={providerRef}
              // onChange={(event) => handleChange(event)}
            />
            <div className="custom__radio-provider">
              <img src={mtn_logo} alt="mtn" />
            </div>
          </label>
          <label htmlFor="airtel" className="provider">
            <input
              type="radio"
              className="provider__input"
              {...register("network_provider")}
              value="airtel-data"
              id="airtel"
              ref={providerRef}
              // onChange={(event) => handleChange(event)}
            />
            <div className="custom__radio-provider">
              <img src={airtel_logo} alt="airtel" />
            </div>
          </label>
          <label htmlFor="globacom" className="provider">
            <input
              type="radio"
              className="provider__input"
              {...register("network_provider")}
              value="glo-data"
              id="globacom"
              ref={providerRef}
              // onChange={(event) => handleChange(event)}
            />
            <div className="custom__radio-provider">
              <img src={glo_logo} alt="globacom" />
            </div>
          </label>
          <label htmlFor="9mobile" className="provider">
            <input
              type="radio"
              className="provider__input"
              {...register("network_provider")}
              value="etisalat-data"
              id="9mobile"
              ref={providerRef}
              // onChange={(event) => handleChange(event)}
            />
            <div className="custom__radio-provider">
              <img src={etisalat_logo} alt="globacom" />
            </div>
          </label>
        </div>
        {errors.network_provider && (
          <ErrorText message={errors.network_provider.message} />
        )}
      </div>
      {/* Input fields here... */}
      <div className="details__group">
        <div className="details__group-container">
          <div className="group__one">
            <FormGroup>
              <h4 className="input__heading">Product:</h4>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <SelectInput
                    arrayOfObject={true}
                    nameKey={"name"}
                    idKey={"name"}
                    options={dataPlans}
                    placeholder="Select Data Plan"
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={control}
                name="data_plan"
                defaultValue=""
              />
              {errors.data_plan && (
                <ErrorText message={errors.data_plan.message} />
              )}
            </FormGroup>
            <FormGroup>
              <h4 className="input__heading">Amount</h4>
              <InputField
                register={register}
                label="amount"
                placeholder="Enter Amount"
                error={errors.amount}
                required
                readOnly
                value={amount}
              />
            </FormGroup>
          </div>
          <div className="group__two">
            <FormGroup>
              <h4 className="input__heading">Phone Number</h4>
              <PhoneInput
                type="tel"
                inputMode="numeric"
                register={register}
                label="phone_number"
                placeholder="Enter Mobile Number"
                error={errors.phone_number}
                required
              />
            </FormGroup>
          </div>
          {selectedWallet === "cash wallet" && (
            <div className="points__earned">
              <div className="points__detail">
                <img src={star} alt="star" />
                <p>Points to be earned</p>
              </div>
              <p>+200</p>
            </div>
          )}
        </div>
      </div>

      <Button className="btn btn--primary btn__mobile-data">
        {loading ? (
          <FontAwesomeIcon icon="spinner" className="fa-spin" />
        ) : (
          "Proceed"
        )}
      </Button>

      <CustomModal
        isActive={rewardError}
        closeModal={() => setRewardError(false)}
        width={"350px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose onClick={() => setRewardError(false)} />
          </div>
          <ResponseModal status={"fail"} message={rewardErrorMessage} />
        </div>
      </CustomModal>

      <NubanVerification
        verifyModalOpen={verifyModalOpen}
        setVerifyModalOpen={setVerifyModalOpen}
      />

      {modalOpen && (
        <secondaryComponents.TopupOptionModal
          showTopUpModal={modalOpen}
          setShowTopUpModal={setModalOpen}
        />
      )}

      <RewardWalletLock
        isLocked={isRewardWalletLocked}
        setIsRewardWalletLocked={setIsRewardWalletLocked}
      />

      <CustomModal
        isActive={transactionPinOpen}
        closeModal={() => setTransactionPinOpen(false)}
        width={"400px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose
              onClick={() => {
                setTransactionPinOpen(false);
              }}
            />
          </div>
          <TransactionPin setPinModalOpen={setTransactionPinOpen} />
        </div>
      </CustomModal>
    </Form>
  );
};

export default PurchaseMobileData;
