import "./withdraw-funds.scss";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPrivacySettings } from "../../redux/reducers/privacy.reducer";
import useModal from "../../hooks/useModal";
import arrow_left from "../../assets/icons/arrow-left.svg";
import profile_icon from "../../assets/images/profile-icon.svg";
import Label from "../../components/Label";
import WithdrawalCheckout from "./WithdrawalCheckout";
import { getUserBankDetails } from "../../services/bankServices";
import Button from "../../components/Button";
import PageLoader from "../../components/PageLoader";
import SetupEnlumiPassword from "./SetupEnlumiPassword";
import VerifyAccount from "../../components/VerifyAccount";
import NubanVerification from "../../components/VerificationModals/NubanVerification/NubanVerification";


const WithdrawFunds = () => {
    const params = new URLSearchParams(window.location.search);
    // hooks section
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    // state section
    const enlumiRewardsWalletBalance = useSelector((state) => state.dashboard.data.enlumi_rewards_wallet_balance);
    const cashWalletBalance = useSelector((state) => state.dashboard.data.cash_wallet_balance);
    const membershipLevel = useSelector((state) => state.dashboard.data.level);
    const privacyDetails = useSelector((state) => state.privacy.data);
    const isVerified = useSelector((state) => state.auth?.user?.has_kyc);
    const [withdrawalAmount, setWithdrawalAmount] = useState((params.get("wallet") === "cash-wallet") ? cashWalletBalance : enlumiRewardsWalletBalance);
    const [selectedWallet, setSelectedWallet] = useState(params.get("wallet"));
    const amountError = useRef(false);
    const [amountErrorMessage, setAmountErrorMessage] = useState("");
    const [proceedToWithdrawCheckout, setProceedToWithdrawCheckout] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [bankName, setBankName] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [hasEnlumiPassword, setHasEnlumiPassword] = useState(true);

    useEffect(() => {
        setPageLoader(true);
        getUserBankDetails().then((response) => {
            setBankName(response.data.data.bank_name);
            setAccountName(response.data.data.name);
            setAccountNumber(response.data.data.masked_number);
            if (privacyDetails == null) {
                getPrivacyDetails();
            } else {
                setPageLoader(false);
            }
        }).catch(() => {
            setPageLoader(false);
        });
    }, []);

    useEffect(() => {
        validateWithdrawalAmount();
    }, [withdrawalAmount]);

    useEffect(() => {
        if (withdrawalAmount != null) {
            validateWithdrawalAmount();
        }
    }, [selectedWallet]);

    const getPrivacyDetails = () => {
        // get privacy details to know if the account is via google or facebook
        dispatch(getPrivacySettings()).then(() => {
            setPageLoader(false);
        }).catch(() => {
            setPageLoader(false);
        })
    }

    const checkAccountType = () => {
        if (isVerified) {
            // check the account type and confirm if it has a password set
            if (privacyDetails.user_has_set_password) {
                //proceed to withdrawal if password is set
                setProceedToWithdrawCheckout(true);
            } else {
                // show set password modal if password is not set
                setHasEnlumiPassword(false);
            }
        }else {
            setModalOpen(true);
        }
    }

    const validateWithdrawalAmount = () => {
        // handles validation for amount
        let allowedWithdrawAmount = (selectedWallet == "cash-wallet") ? parseFloat(process.env.REACT_APP_WITHDRAWAL_LIMIT_FOR_NAIRA_WALLET) : parseFloat(process.env.REACT_APP_WITHDRAWAL_LIMIT_FOR_REWARDS_WALLET);
        let wallet = (selectedWallet === "cash-wallet") ? "naira wallet" : "rewards wallet";
        if (withdrawalAmount < allowedWithdrawAmount || withdrawalAmount == null) {
            amountError.current = true;
            setAmountErrorMessage(`The mininum withdrawable amount for ${wallet} is N${allowedWithdrawAmount}`);
        } else {
            amountError.current = false;
            setAmountErrorMessage("");
        }
    }

    return (
        <div className="withdraw-fund-page">
            {/* title and back button section */}
            <div className="withdraw-fund-page__header" onClick={() => {
                if (proceedToWithdrawCheckout == true) {
                    setProceedToWithdrawCheckout(false);
                } else {
                    history.push("/rewards");
                }
            }}>
                <img
                    src={arrow_left}
                    alt="arrow left"
                />
                <h2 className="">Withdraw funds</h2>
            </div>
            {!proceedToWithdrawCheckout ? (
                <div>
                    <p className="withdraw-fund-page__description">Kindly provide the following details for your withdrawal</p>
                    {!pageLoader ? (
                        <div className="withdraw-fund-page__content mt-5">
                            <div className="withdraw-fund-page__content__wallet-group">
                                <h4 className="withdraw-fund-page__content__wallet-group__heading">Select wallet:</h4>
                                {/* wallet section */}
                                <div className="withdraw-fund-page__content__wallet-group__group-container mb-3">
                                    <label
                                        htmlFor="cash--wallet"
                                        className={`mb-3 cash-wallet ${selectedWallet === "cash-wallet"
                                            ? "selected"
                                            : ""
                                            }`}
                                    >
                                        <div className="top-section">
                                            <p className="white--text wallet-name mr-2">
                                                Naira Wallet Balance
                                            </p>
                                            <input
                                                type="radio"
                                                className="radio--input"
                                                value="CASH_WALLET"
                                                id="cash--wallet"
                                                name="wallet_type"
                                                checked={selectedWallet == "cash-wallet"}
                                                onChange={() => {
                                                    setSelectedWallet("cash-wallet");
                                                    setWithdrawalAmount(cashWalletBalance);
                                                }}
                                            />
                                            <div className="custom--radio-input"></div>
                                        </div>
                                        <div className="cash-wallet__details">
                                            <p className="white--text cash-wallet__details__name mr-2">
                                                Naira Wallet Balance
                                            </p>
                                            <h2 className="white--text cash-wallet__details__balance">
                                                N
                                                {cashWalletBalance &&
                                                    cashWalletBalance.toLocaleString()}
                                            </h2>
                                        </div>
                                    </label>
                                    {(membershipLevel !== "member" && membershipLevel !== "bronze") ? (
                                        <label
                                            htmlFor="reward--wallet"
                                            className={`mb-3 reward-wallet ${selectedWallet === "reward-wallet"
                                                ? "selected"
                                                : ""
                                                }`}
                                        >
                                            <div className="top-section">
                                                <p className="white--text wallet-name mr-2">
                                                    Rewards Wallet Balance
                                                </p>
                                                <input
                                                    type="radio"
                                                    className="radio--input"
                                                    value="REWARDS_WALLET"
                                                    id="reward--wallet"
                                                    name="wallet_type"
                                                    checked={selectedWallet === "reward-wallet"}
                                                    onChange={() => {
                                                        setSelectedWallet("reward-wallet");
                                                        setWithdrawalAmount(enlumiRewardsWalletBalance);
                                                    }}
                                                />
                                                <div className="custom--radio-input"></div>
                                            </div>
                                            <div className="reward-wallet__details">
                                                <p className="white--text reward-wallet__details__name mr-2">
                                                    Rewards Wallet Balance
                                                </p>
                                                <h2 className="white--text reward-wallet__details__balance">
                                                    N
                                                    {enlumiRewardsWalletBalance &&
                                                        enlumiRewardsWalletBalance.toLocaleString()}
                                                </h2>
                                            </div>
                                        </label>) : null}
                                </div>
                            </div>
                            {/* account details */}
                            <div className="withdraw-fund-page__content__user-account">
                                <h3 className="mb-2">Account details</h3>
                                <div className="withdraw-fund-page__content__user-account__details">
                                    <div className="bank-details">
                                        <img className="profile-icon mr-3" src={profile_icon} alt="" />
                                        <div className="mt-2">
                                            <h5>{accountName}</h5>
                                            <p>{bankName}, {accountNumber}</p>
                                        </div>
                                    </div>
                                    <Link className="mt-5 link purple--text" to={`/rewards/cash/edit-bank-account`}>Edit</Link>
                                </div>
                            </div>
                            <div className="withdraw-fund-page__content__form mt-5">
                                {/* amount input section */}
                                <div>
                                    <Label>Amount (NGN)*</Label>
                                    <div className="withdraw-fund-page__content__form__input-section">
                                        <input
                                            type="number"
                                            className="input--field"
                                            value={withdrawalAmount}
                                            disabled={true}
                                        />
                                        {/* this button shows on desktop view */}
                                        <Button className="ml-2 btn btn--primary desktop-btn" onClick={() => {
                                            validateWithdrawalAmount();
                                            if (!amountError.current) {
                                                checkAccountType()
                                            }
                                        }}>
                                            Proceed
                                        </Button>
                                    </div>
                                    <p className={`mb-0 mt-1 ${(amountError) ? 'error--text' : 'purple--text'}`} style={{ maxWidth: '400px' }}>
                                        {amountError ? amountErrorMessage : null}
                                    </p>
                                    <p className="input-wallet-balance purple--text mt-1">
                                        {selectedWallet == "cash-wallet" ? `Wallet balance: N${cashWalletBalance.toLocaleString()}` : `Wallet Balance: N${enlumiRewardsWalletBalance.toLocaleString()}`
                                        }</p>
                                </div>
                                {/* button that shows on mobile */}
                                <Button className="btn btn--primary mobile-btn mt-4" onClick={() => {
                                   // validateWithdrawalAmount();
                                    if (!amountError.current) {
                                        checkAccountType()
                                    }
                                }}>
                                    Proceed
                                </Button>
                            </div>
                        </div>) : (
                        <PageLoader />
                    )}
                </div>) : <WithdrawalCheckout withdrawalDetails={{
                    walletType: selectedWallet,
                    balance: (selectedWallet == "cash-wallet") ? cashWalletBalance : enlumiRewardsWalletBalance,
                    withdrawalAmount: withdrawalAmount,
                    bankName: bankName,
                    accountNumber: accountNumber,
                    accountName: accountName,
                }} />}
            {/* modal that shows when user does not have password set yet */}
            <CustomModal
                isActive={!hasEnlumiPassword}
                closeModal={() => {
                    setHasEnlumiPassword(true);
                }}
                width={"450px"}
            >
                <SetupEnlumiPassword
                    setHasEnlumiPassword={setHasEnlumiPassword}
                    setProceedToWithdrawCheckout={setProceedToWithdrawCheckout}
                />
            </CustomModal>
            {/* verification modal */}
            {/* <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width={"600px"}
            >
                <VerifyAccount setModalOpen={setModalOpen} />
            </CustomModal> */}
            <NubanVerification verifyModalOpen={modalOpen} setVerifyModalOpen={setModalOpen} />
        </div>
    )
}
export default WithdrawFunds