import { useState } from "react";
import ReactDOM from "react-dom";

import Modal from "../components/Modal";
//import ModalContent from "../components/ModalContent";
//import ModalClose from "../components/ModalClose";

const CustomModal = ({ isActive, children, closeModal, width }) => {
    return ReactDOM.createPortal(
        <Modal isActive={isActive} closeModal={closeModal} width={width} >
            {children}
        </Modal>
        ,
        document.getElementById("portal--root")
    );
};

const useModal = (modalState = false) => {
    const [modalOpen, setModalOpen] = useState(modalState);
    const toggleModal = () => setModalOpen(!modalOpen);
    return [modalOpen, setModalOpen, toggleModal, CustomModal];
};

export default useModal;
