import "./about.scss";

import Group_251 from "../../assets/websiteImages/Group-251.png";
import Rectangle_96 from "../../assets/websiteImages/Rectangle-96.png";
import Rectangle_97 from "../../assets/websiteImages/Rectangle-97.png";
import Rectangle_98 from "../../assets/websiteImages/Rectangle-98.png";
import Rectangle_99 from "../../assets/websiteImages/Rectangle-99.png";
import Rectangle_100 from "../../assets/websiteImages/Rectangle-100.png";
import Rectangle_101 from "../../assets/websiteImages/Rectangle-101.png";
import Rectangle_102 from "../../assets/websiteImages/Rectangle-102.png";
import Rectangle_103 from "../../assets/websiteImages/Rectangle-103.png";
import Rectangle_104 from "../../assets/websiteImages/Rectangle-104.png";
import Rectangle_105 from "../../assets/websiteImages/Rectangle-105.png";
import Group_177 from "../../assets/websiteImages/Group-177.svg";
import MetaTags from 'react-meta-tags';

const About = () => {
    return (
        <div>
            <MetaTags>
                <title>Earn Points on Every Purchase and Redeem for Cash  or Gift Card</title>
                <meta name="description" content="Lumi turns all your retail and restaurant purchase into savings. Simply shop online or offline at any of our participating  grocery, retail or restaurant and save on thousands of popular products. Earn points on every purchase and redeem for cash" />
                <meta property="og:title" content="Earn Points on Every Purchase and Redeem for Cash  or Gift Card" />
                {/* <meta property="og:image" content="path/to/image.jpg" /> */}
            </MetaTags>


            <div id="aboutpage">
                <div className="banner">
                    <div className="text-section">
                        <div>
                            <h1>Providing value daily</h1>
                            <p>Lumi is the easiest way to earn rewards when you take care of your daily lifestyle needs.
                                Powered by integrations to a host of essential services such as bill pay and airtime and partnerships with some
                                of Nigeria’s favorite brands and service providers.
                            </p>
                        </div>
                    </div>
                    <div className="img-section">
                        <img src={Group_251} alt="" />
                    </div>
                </div>
                {/* section 2 */}
                <div className="section-two">
                    {/* img section */}
                    <div className="img-section">
                        <div className="img-container">
                            <img src={Rectangle_96} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_97} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_98} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_99} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_100} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_101} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_102} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_103} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_104} alt="" />
                        </div>
                        <div className="img-container">
                            <img src={Rectangle_105} alt="" />
                        </div>
                    </div>
                    {/* text section */}
                    <div className="text-section">
                        <div>
                            <div data-aos="fade-up">
                                <h3 className="mb-3 mt-0">Our Story</h3>
                                <p className="mb-5">Lumi was founded in 2020 by a team of data scientists, marketing consultants and engineers.
                                    We built Lumi as a helping hand to all Nigerians striving to thrive and also as a way for business
                                    to better connect with their customers.</p>
                            </div>

                            <div data-aos="fade-up" data-aos-duration="1000">
                                <h3 className="mb-3">Our Core Values</h3>
                                <p className="mb-3">As a company, there are 5 core values we live by that guide us in all of our endeavors.</p>
                                <div className="mb-5">
                                    <div className="listed-items">
                                        <img className="mr-3" src={Group_177} alt="" />
                                        <p>We create value</p>
                                    </div>
                                    <div className="listed-items">
                                        <img className="mr-3" src={Group_177} alt="" />
                                        <p>We put our users first</p>
                                    </div>
                                    <div className="listed-items">
                                        <img className="mr-3" src={Group_177} alt="" />
                                        <p>We are transparent</p>
                                    </div>
                                    <div className="listed-items">
                                        <img className="mr-3" src={Group_177} alt="" />
                                        <p>We treat all with respect</p>
                                    </div>
                                    <div className="listed-items">
                                        <img className="mr-3" src={Group_177} alt="" />
                                        <p>We innovate</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 className="mb-3">Our Mission</h3>
                                <p className="mb-5">Lumi's mission is to bring light into the lives of all
                                    Nigerians through simple and enjoyable passive income.</p>
                            </div>

                            <div>
                                <h3 className="mb-3">Our Vision</h3>
                                <p className="mb-0">Lumi’s vision is to make it easier for all Nigerians to lead lives they love by
                                    growing their savings and helping them connect to and build brands that bring them joy.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default About;
