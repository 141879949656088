import { useRouteMatch, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useModal from "../../../../hooks/useModal";
import { getPrivacySettings } from "../../../../redux/reducers/privacy.reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAirtimeForm } from "../../../../redux/actions/airtime.actions";
import useNairaWallet from "../../../../hooks/core/FundTransfer/useNairaWallet";
import { secondaryComponents } from "../../../../components/secondaryComponents";

import "./airrtime-data.scss";

import mtn_logo from "../../../../assets/icons/mtn-logo.svg";
import airtel_logo from "../../../../assets/icons/airtel-logo.svg";
import glo_logo from "../../../../assets/icons/glo-logo.svg";
import etisalat_logo from "../../../../assets/icons/9mobile-logo.svg";
import arrow_left from "../../../../assets/icons/arrow-left.svg";
import star from "../../../../assets/icons/star.svg";

import Form from "../../../../components/Form";
import Button from "../../../../components/Button";
import FormGroup from "../../../../components/FormGroup";
import InputField from "../../../../components/InputField";
import ErrorText from "../../../../components/ErrorText";
import PhoneInput from "../../../../components/PhoneInput";
import ModalClose from "../../../../components/ModalClose";
import ResponseModal from "../../../../components/ResponseModal";

import RewardWalletLock from "../../../../components/VerificationModals/RewardWalletLock/RewardWalletLock";
import NubanVerification from "../../../../components/VerificationModals/NubanVerification/NubanVerification";
import TransactionPin from "../../../../components/VerificationModals/TransactionPin/TransactionPin";

const Airtime = () => {
  const airtimeSchema = yup.object().shape({
    wallet: yup.string().required("Select a wallet"),
    network_provider: yup.string().required("Choose network provider"),
    phone_number: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),
    recharge_amount: yup.string().required("Enter amount to recharge"),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      wallet: "CASH_WALLET",
      network_provider: "",
      phone_number: "",
      recharge_amount: "",
    },
    resolver: yupResolver(airtimeSchema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
  const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
  const [transactionPinOpen, setTransactionPinOpen, toggleTransactionPin] =
    useModal();
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashboard, auth } = useSelector((state) => state);

  // const { fromRewards } = location.state !== undefined && location.state;

  const [selectedWallet, setSelectedWallet] = useState("cash wallet");
  const [loading, setLoading] = useState(false);
  const [points, setPoints] = useState(0);
  const [purchaseError, setPurchaseError] = useState("");
  const [isRewardWalletLocked, setIsRewardWalletLocked] = useState(false);

  const {
    enlumi_rewards_wallet_balance,
    lock_wallet,
    use_lock_wallet,
    has_pin,
  } = dashboard.data !== null && dashboard.data;

  const { has_kyc } = auth.user !== null && auth.user;
  const { data } = useNairaWallet();
  const { withdrawable_balance } = data !== null && data;
  const wallet_balance = parseFloat(withdrawable_balance);

  const walletLocked = lock_wallet && use_lock_wallet;

  const onSubmit = (data) => {
    if (
      selectedWallet === "cash wallet" &&
      Number(data.recharge_amount) > wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Topup or Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    } else if (
      selectedWallet === "reward wallet" &&
      Number(data.recharge_amount) > enlumi_rewards_wallet_balance
    ) {
      return setError(
        "wallet",
        {
          type: "validate",
          message: "Insufficient Funds. Switch wallet to continue",
        },
        { shouldFocus: true }
      );
    }

    if (Number(data.recharge_amount) < 100) {
      return setError(
        "recharge_amount",
        {
          type: "validate",
          message: "Minimum recharge amount is N100",
        },
        { shouldFocus: true }
      );
    }

    if (selectedWallet === "reward wallet" && walletLocked) {
      // toggle lock reward wallet modal
      return setIsRewardWalletLocked(true);
    }

    if (selectedWallet === "reward wallet" && !has_kyc) {
      // toggle modal to verify account
      return setTransactionPinOpen(true);
    }

    if (selectedWallet === "cash wallet" && !has_kyc) {
      // toggle modal to verify account
      return setTransactionPinOpen(true);
    }

    if (has_pin) {
      dispatch(
        setAirtimeForm({
          amount: data.recharge_amount,
          funding_channel: data.wallet,
          phone_number: data.phone_number,
          network: data.network_provider,
          points_to_earn: points,
        })
      );

      history.push(`${path}/summary`);
    } else {
      // Toggle modal to setup enlumi transaction pin
      toggleTransactionPin();
    }
  };

  useEffect(() => {
    // dispatch(getDashboard());
    dispatch(getPrivacySettings());
    // dispatch(getUserDetails());
    // if (fromRewards) setSelectedWallet('reward wallet');
  }, [dispatch]);

  return (
    <div className="airtime--data">
      <div className="airtime--data-header" onClick={() => history.goBack()}>
        <img src={arrow_left} alt="arrow left" />
        <div>
          <h2 className="airtime--heading">Mobile Top-up</h2>
        </div>
      </div>
      <p className="airtime--description">
        Kindly provide the details for your recharge
      </p>
      <Form onSubmit={handleSubmit(onSubmit)} className="airtime--form">
        <div className="wallet--group">
          <h4 className="group--heading">Select wallet:</h4>
          <div className="wallet--group-container">
            <label
              onClick={() => setSelectedWallet("cash wallet")}
              htmlFor="cash--wallet"
              className={`cash--wallet ${
                selectedWallet === "cash wallet" ? "selected" : ""
              }`}
            >
              <div className="wallet--top">
                {/* <input
                                    type="radio"
                                    className="radio--input"
                                    {...register("wallet")}
                                    value="CASH_WALLET"
                                    id="cash--wallet"
                                /> */}
                <p className="wallet--name">Naira Wallet</p>
                <input
                  type="radio"
                  className="radio--input"
                  {...register("wallet")}
                  value="CASH_WALLET"
                  id="cash--wallet"
                />
                <div className="custom--radio-input"></div>
              </div>
              <div className="wallet--details">
                <p className="wallet--name">Naira Wallet</p>
                <h2 className="balance">
                  N{wallet_balance && wallet_balance.toLocaleString()}
                </h2>
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (!has_kyc) {
                    toggleVerifyModal();
                  } else {
                    setModalOpen(true);
                  }
                }}
                className="topup__mobile"
              >
                <span className="text">Topup wallet</span>
                <span className="icon-arrow-right"></span>
              </div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  if (!has_kyc) {
                    toggleVerifyModal();
                  } else {
                    setModalOpen(true);
                  }
                }}
                className="btn btn--topup"
              >
                Top up wallet
              </Button>
              {/* <CustomModal
                                isActive={modalOpen}
                                closeModal={() => setModalOpen(false)}
                            >
                                <div className="modal__container__content">
                                    <div className="modal__container__content__default-header mb-3">
                                        <ModalClose
                                            onClick={() => setModalOpen(false)}
                                        />
                                    </div>
                                    <TopUpWallet toggleModal={toggleModal} />
                                </div>
                            </CustomModal> */}
            </label>
            <label
              onClick={() => setSelectedWallet("reward wallet")}
              htmlFor="reward--wallet"
              className={`reward--wallet ${
                selectedWallet === "reward wallet" ? "selected" : ""
              }`}
            >
              <div className="wallet--top">
                {/* <input
                                    type="radio"
                                    className="radio--input"
                                    {...register("wallet")}
                                    value="REWARDS_WALLET"
                                    id="reward--wallet"
                                /> */}
                <p className="wallet--name">Rewards Wallet</p>
                <input
                  type="radio"
                  className="radio--input"
                  {...register("wallet")}
                  value="REWARDS_WALLET"
                  id="reward--wallet"
                />
                <div className="custom--radio-input"></div>
              </div>
              <div className="wallet--details">
                <p className="wallet--name">Rewards Wallet</p>
                <h2 className="balance">
                  N
                  {enlumi_rewards_wallet_balance &&
                    enlumi_rewards_wallet_balance.toFixed(2).toLocaleString()}
                </h2>
              </div>
            </label>
          </div>
          {errors.wallet && <ErrorText message={errors.wallet.message} />}
        </div>
        <div className="provider--group">
          <h4 className="group--heading">Select network provider:</h4>
          <div className="provider--group-container">
            <label htmlFor="mtn" className="provider">
              <input
                type="radio"
                className="provider--input"
                {...register("network_provider")}
                value="MTN"
                id="mtn"
              />
              <div className="custom--radio-provider">
                <img src={mtn_logo} alt="mtn" />
              </div>
            </label>
            <label htmlFor="airtel" className="provider">
              <input
                type="radio"
                {...register("network_provider")}
                value="AIRTEL"
                className="provider--input"
                id="airtel"
              />
              <div className="custom--radio-provider">
                <img src={airtel_logo} alt="airtel" />
              </div>
            </label>
            <label htmlFor="globacom" className="provider">
              <input
                className="provider--input"
                type="radio"
                {...register("network_provider")}
                value="GLO"
                id="globacom"
              />
              <div className="custom--radio-provider">
                <img src={glo_logo} alt="globacom" />
              </div>
            </label>
            <label htmlFor="9mobile" className="provider">
              <input
                className="provider--input"
                type="radio"
                {...register("network_provider")}
                value="9MOBILE"
                id="9mobile"
              />
              <div className="custom--radio-provider">
                <img src={etisalat_logo} alt="9mobile" />
              </div>
            </label>
          </div>
          {errors.network_provider && (
            <ErrorText message={errors.network_provider.message} />
          )}
        </div>
        <div className="details--group">
          <div className="details--group-container">
            <FormGroup>
              <h4 className="input--heading">Phone Number</h4>
              <PhoneInput
                type="tel"
                inputMode="numeric"
                register={register}
                label="phone_number"
                placeholder="Enter Mobile Number"
                error={errors.phone_number}
                required
              />
            </FormGroup>
            <FormGroup>
              <h4 className="input--heading">Amount</h4>
              <InputField
                inputMode="numeric"
                register={register}
                label="recharge_amount"
                placeholder="Enter Amount"
                error={errors.recharge_amount}
                required
                onChange={(event) => {
                  const { value } = event.target;
                  setPoints(Math.floor(value * 0.75));
                }}
              />
            </FormGroup>
          </div>
        </div>
        {selectedWallet === "cash wallet" && (
          <div className="points--earned">
            <div className="points--earned-detail">
              <img src={star} alt="star" />
              <p>Points to be earned</p>
            </div>
            <p>{points}</p>
          </div>
        )}
        <Button className="btn btn--primary btn--airtime">
          {loading ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : (
            "Proceed"
          )}
        </Button>

        <CustomModal
          isActive={purchaseError !== ""}
          closeModal={() => setPurchaseError("")}
          width={"320px"}
        >
          <div className="modal__container__content">
            <div className="modal__container__content__default-header mb-3">
              <ModalClose
                onClick={() => {
                  setPurchaseError("");
                }}
              />
            </div>
            <ResponseModal message={purchaseError} status={"fail"} />
          </div>
        </CustomModal>

        <NubanVerification
          verifyModalOpen={verifyModalOpen}
          setVerifyModalOpen={setVerifyModalOpen}
        />

        {modalOpen && (
          <secondaryComponents.TopupOptionModal
            showTopUpModal={modalOpen}
            setShowTopUpModal={setModalOpen}
          />
        )}

        <RewardWalletLock
          isLocked={isRewardWalletLocked}
          setIsRewardWalletLocked={setIsRewardWalletLocked}
        />
      </Form>
      <CustomModal
        isActive={transactionPinOpen}
        closeModal={() => setTransactionPinOpen(false)}
        width={"400px"}
      >
        <div className="modal__container__content">
          <div className="modal__container__content__default-header mb-3">
            <ModalClose
              onClick={() => {
                setTransactionPinOpen(false);
              }}
            />
          </div>
          <TransactionPin setPinModalOpen={setTransactionPinOpen} />
        </div>
      </CustomModal>
    </div>
  );
};

export default Airtime;
