import { useState } from 'react';

export const useSelectField = ({ options = [], valueKey, labelKey, value = null, onChange}) => {
    const [selectedItem, setSelectedItem] = useState(value);

    const serializeOptions = () => {
        return options.map((option) => {
            return {
                label: typeof option === 'object' ? option[labelKey] : option,
                value: typeof option === 'object' ? option[valueKey] : option,
            };
        });
    };

    const handleChange = (item) => {
        const { value } = item;
        setSelectedItem(value);
        onChange(value);
      };

    return {
        selectedItem,
        items: serializeOptions(options),
        handleChange
    };
};
