import httpClient from "../axiosBase/axiosHandler.js";

export const initiateTopUp = (payload) => {
    const response = httpClient.post(`/topup/initiate/`, payload);
    return response;
};

export const airtimeTopUp = (
    amount,
    wallet,
    phone_number,
    provider,
    pin
) => {

    const response = httpClient.post(
        `/billing/airtime/`,
        {
            amount: amount,
            funding_channel: wallet,
            phone_number: `+234${phone_number}`,
            network: provider,
            pin,
        }
    );
    return response;
};

export const getDiscos = () => {
    const response = httpClient.get(`/billing/power/list/`);
    return response;
};

export const resolveMeterNumber = ({ disco, meter_number, meter_type, retry }) => {
    const response = httpClient.post(
        `/billing/power/verify/`,
        {
            disco,
            meter_number,
            meter_type,
            retry
        }
    );
    return response;
};

export const payElectricityBill = ({
    funding_channel,
    disco,
    meter_number,
    meter_type,
    amount,
    pin,
}) => {

    const response = httpClient.post(
        `/billing/power/pay/`,
        { funding_channel, disco, meter_number, meter_type, amount, pin }
    );
    return response;
};

export const getElectricityToken = (orderId) => {
    const response = httpClient.get(`/billing/power/${orderId}/`);
    return response;
};

export const getElectricityPurchaseHistory = () => {
    const response = httpClient.get(`/billing/power/cache/`);
    return response;
};

export const getMobileDataPlans = (service_id) => {
    const response = httpClient.get( `/billing/data/list/?service_id=${service_id}`);
    return response;
};

export const purchaseMobileData = (
    funding_channel,
    service_id,
    variation_code,
    phone_number,
    pin,
    amount
) => {
    const response = httpClient.post(`/billing/data/pay/`,
        {
            funding_channel,
            service_id,
            variation_code,
            phone_number,
            pin,
            amount,
        }
    );
    return response;
};

export const getPurchaseRewardPoints = (payload) => {
    const response = httpClient.post(`/billing/power/reward/`,{ ...payload });
    return response;
};

export const getMobileDataPurchaseReward = (payload) => {
    const response = httpClient.post(`/billing/data/reward/`,{ ...payload },);
    return response;
};
