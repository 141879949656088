import { useForm } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, Link } from "react-router-dom";
import { forgotPassword } from "../../services/auth.service";

import "./forgot-password.scss";

import LoginLayout from "../../Layouts/LoginLayout";
import Form from "../../components/Form";
import FormGroup from "../../components/FormGroup";
import Label from "../../components/Label";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import ErrorText from "../../components/ErrorText";
import GoogleRecaptchaV3 from "../../components/GoogleRecaptchaV3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const recaptchaRef = useRef();
  const resetPasswordSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(resetPasswordSchema), mode: "all" });

  useEffect(() => {
    if (email !== null) {
      setLoading(true);
      recaptchaRef.current.handleLoaded();
    }
  }, [email]);

  const onSubmit = (recaptchaToken) => {
    if (email === null) return;
    setLoading(true);
    forgotPassword({
      email: email,
      "g-recaptcha-response": recaptchaToken,
    }).then(() => {
      history.push("/forgot-password-success");
    });
  };

  const setEmailDetails = (data) => {
    setEmail(data.email);
  };

  return (
    <LoginLayout
      content1="A few clicks away from amazing rewards!"
      content2="Shop, Pay bills, take surveys, & automatically get rewarded."
      content3="Kick off your earnings!"
      showHeadings={true}
      title="Reset your Enlumi password"
      description="Enter the email address associated with your account and we'll send an email with instructions to reset your password."
      linkedPage={
        <span className="big-text-size">
          Remember password?{" "}
          <Link className="purple--text big-text-size ml-1" to="/login">
            Log in
          </Link>
        </span>
      }
    >
      <div className="forgot-password-page">
        <Form onSubmit={handleSubmit(setEmailDetails)}>
          {/* google recaptcha */}
          <GoogleRecaptchaV3
            ref={recaptchaRef}
            resolved={onSubmit}
            onReset={() => setLoading(false)}
            page="login"
          />

          <FormGroup>
            <Label>Email</Label>
            <InputField
              label="email"
              register={register}
              placeholder="Enter Email"
              required
            />
          </FormGroup>
          {errors.email && <ErrorText message={errors.email.message} />}
          <Button disabled={loading} className="btn btn--primary mt-4">
            {loading ? (
              <>
                {" "}
                <FontAwesomeIcon icon="spinner" className="fa-spin" /> Sending
              </>
            ) : (
              "Send reset password email"
            )}
          </Button>
        </Form>
      </div>
    </LoginLayout>
  );
};

export default ForgotPasswordPage;