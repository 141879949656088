const Button = ({ type, children, onClick, disabled, ...others }) => {
    return (
        <button
            className="btn"
            {...others}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
