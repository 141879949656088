import Types from "../constants";

import {
    getAllPlans,
    setMobileDataForm,
    getSelectedPlan,
    buyMobileData,
} from "../actions/mobile-data.actions";

import {
    getMobileDataPlans,
    purchaseMobileData,
    getMobileDataPurchaseReward,
} from "../../services/topup-service";

const {
    mobileDataTypes: {
        GET_ALL_PLANS,
        SET_MOBILE_DATA_FORM,
        SET_SELECTED_PLAN,
        SET_SUMMARY,
    },
} = Types;

const initialState = {
    dataPlans: [],
    formData: {
        funding_channel: "",
        service_id: "",
        variation_code: "",
        phone_number: "",
        pin: "",
    },
    summary: {
        biller: "",
        product: "",
        phone_number: "",
        amount: "",
        points: "",
    },
    selectedPlan: null,
};

export const mobileDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PLANS:
            return { ...state, dataPlans: action.payload };
        case SET_MOBILE_DATA_FORM:
            return { ...state, formData: { ...action.payload } };
        case SET_SELECTED_PLAN:
            return { ...state, selectedPlan: action.payload };
        case SET_SUMMARY:
            return { ...state, summary: { ...action.payload } };
        default:
            return state;
    }
};

export const fetchDataPlans = (service_id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getMobileDataPlans(service_id)
                .then((response) => {
                    if (response.data) {
                        const { list, point_factor, multiply } = response.data;
                        dispatch(getAllPlans(list.varations));
                        resolve({ point_factor, multiply });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data);
                    } else {
                        reject(error.message);
                    }
                });
        });
    };
};

export const buyData = (payload) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const {
                funding_channel,
                service_id,
                variation_code,
                amount,
                phone_number,
                pin,
            } = payload;
            purchaseMobileData(
                funding_channel,
                service_id,
                variation_code,
                phone_number,
                pin,
                amount
            )
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    } else {
                        reject(error.message);
                    }
                });
        });
    };
};

export const getRewardPoints = (payload) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getMobileDataPurchaseReward(payload)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response);
                    } else {
                        reject(error.message);
                    }
                });
        });
    };
};
