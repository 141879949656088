import RewardSummary from "./RewardSummary";
import WalletSummary from "./WalletSummary";

import { Link } from "react-router-dom";

const AccountSummary = (props) => {
    return (
        <div className="dashboard__summary">
            <div className="dashboard__summary__container">
                <div className="user__account-summary">
                    <RewardSummary />
                    <WalletSummary />
                </div>
                <div className="account__history">
                    <Link to="/wallet-history" className="mt-2 account__history__link">
                        <span className="account__history__link__text">
                            View history
                        </span>
                        <span className="icon-arrow-right ml-2" style={{fontSize: '10px'}}></span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AccountSummary;
