import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useModal from "../hooks/useModal";
import Button from "./Button";
import NubanVerification from "./VerificationModals/NubanVerification/NubanVerification";
import useNairaWallet from "../hooks/core/FundTransfer/useNairaWallet";
import { secondaryComponents } from "./secondaryComponents";
import { useState } from "react";

const WalletSummary = () => {
  const auth = useSelector((state) => state.auth);
  const { has_kyc } = auth.user !== null && auth.user;
  const { data } = useNairaWallet();
  const { withdrawable_balance } = data !== null && data;
  const naira_wallet_balance = parseFloat(withdrawable_balance);
  const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
  const [showTopUpModal, setShowTopUpModal] = useState(false);

  return (
    <>
      <div className="wallet--summary">
        <div className="wallet--balance">
          <p>Naira Wallet</p>
          <h2 className="balance">N{naira_wallet_balance.toLocaleString()}</h2>
        </div>
        <div className="wallet--summary-actions">
          <Button
            // onClick={() => !is_verified ? toggleVerifyModal() : toggleModal()}
            onClick={() => {
              if (!has_kyc) {
                toggleVerifyModal();
              } else {
                setShowTopUpModal(true);
              }
            }}
            className="btn btn--primary btn--topUp-wallet"
          >
            Top up wallet
          </Button>
          <Link to={"/fund-transfer"}>
            <Button className="btn btn--outline btn--withdraw">
              Withdraw Cash
            </Button>
          </Link>

          <NubanVerification
            verifyModalOpen={verifyModalOpen}
            setVerifyModalOpen={setVerifyModalOpen}
          />

          {showTopUpModal && (
            <secondaryComponents.TopupOptionModal
              showTopUpModal={showTopUpModal}
              setShowTopUpModal={setShowTopUpModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WalletSummary;
