import { getSurvey } from '../../../redux/reducers/survey.reducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsService from '../../../services/news.service';
import useBlog from '../../../hooks/core/News/useBlog';
import { Link } from 'react-router-dom';
import { fetchIncentives } from '../../../services/dashboard.service';

import './activities.scss';
import Button from '../../../components/Button';
import SurveyRow from '../../../components/SurveyRow';
import PayBillsRow from '../../../components/PayBillsRow';
import InviteFriendsRow from '../../../components/InviteFriendsRow';
import NewsRow from '../../../components/NewsRow';
import PositiveActionRow from '../../../components/PositiveActionRow';

import LumiAd from '../../../components/LumiAd/LumiAd';

import pay_bills_icon from '../../../assets/icons/pay-bills-icon.svg';
import useAuth from '../../../hooks/useAuth';
import { mapIncentives } from '../../../helpers/constants';
import { nFormatter } from '../../../helpers/nFormatter';
import useFetchArticles from '../../../hooks/core/News/useFetchArticles';

const Activities = () => {
    const dispatch = useDispatch();
    const surveys = useSelector((state) => state.survey);
    const profileSurvey = useSelector(
        (state) => state.dashboard.data.has_completed_profile_survey
    );
    const [loadMore, setLoadMore] = useState(false);
    const [surveyIsFinished, setSurveyIsFinished] = useState(false);
    const isAuth = useAuth();
    const [incentives, setIncentives] = useState([]);
    const [page, setPage] = useState(1);

    const { data: articles } = useFetchArticles(page);

    const getIncentives = async () => {
        try {
            const res = await fetchIncentives();
            mapIncentives(res.data?.data, setIncentives);
        } catch (error) {
            setIncentives([]);
        }
    };

    function renderIncentives(incentives) {
        return incentives
            .filter((incentive) => incentive.qualified === true)
            .map((incentive) => {
                return (
                    <PositiveActionRow
                        key={incentive.key}
                        title={incentive.title}
                        icon={incentive.icon}
                        btn__title={incentive.buttonText}
                        description={incentive.description}
                        point={nFormatter(incentive.points_reward)}
                        link={incentive.path}
                        border={incentive?.hasBorder}
                    />
                );
            });
    }

    useEffect(() => {
        if (isAuth) {
            if (surveys.surveys.length === 0 && isAuth) {
                dispatch(getSurvey(1));
            }
        }
    }, [dispatch]);

    const loadMoreActivities = () => {
        if (
            surveys.surveyPagination.current_page <
            surveys.surveyPagination.last_page
        ) {
            setLoadMore(true);
            dispatch(getSurvey(surveys.surveyPagination.next_page))
                .then(() => {
                    setLoadMore(false);
                })
                .catch(() => {
                    setLoadMore(false);
                });
        } else {
            setSurveyIsFinished(true);
            setTimeout(() => {
                setSurveyIsFinished(false);
            }, 3000);
        }
    };

    return (
        <div className="activities">
            {/* <div style={{width: '90%', margin: '20px auto 30px auto'}}>
                <AdsenseMounter slot="5972918423"/>
            </div> */}
            {!surveys.loading ? (
                <div>
                    {!profileSurvey ? (
                        <SurveyRow
                            surveyDetails={{
                                name: 'Profile setup',
                                id: '000000',
                                point: '2500',
                                minutes: '12 minutes',
                                path: '/activities/surveys',
                            }}
                            buttonState={true}
                        />
                    ) : null}

                    <PayBillsRow
                        billDetails={{
                            name: 'Buy airtime and pay bills',
                            id: 'pay-bills/bills',
                            icon: `${pay_bills_icon}`,
                            description:
                                'Buy airtime, data and pay bills with your Enlumi wallet and earn points for this.',
                            point: '10k',
                            path: '/activities',
                        }}
                    />

                    <LumiAd slot={'Activities'}>
                        <LumiAd.Media>
                            {({
                                media,
                                advert,
                                handleClickAd,
                                userDemographics,
                            }) => (
                                <Link
                                    style={{
                                        width: '100%',
                                        borderBottom: '1px solid #333',
                                    }}
                                    className="px-4 py-3  activities__ad-slot"
                                    to={{ pathname: advert?.url }}
                                    target="_blank"
                                >
                                    <img
                                        style={{ borderRadius: '5px' }}
                                        className="m-auto"
                                        onClick={() =>
                                            handleClickAd({
                                                advert_id: advert?.id,
                                                media_id: media?.id,
                                                ...userDemographics,
                                            })
                                        }
                                        src={media?.url}
                                        alt={media?.file_name}
                                    />
                                </Link>
                            )}
                        </LumiAd.Media>
                    </LumiAd>

                    {isAuth && (
                        <InviteFriendsRow
                            inviteDetails={{
                                name: 'Referral Bonus Points',
                                id: 'invite',
                                description:
                                    'Earn extra points each time you invite your friends and family',
                                point: '3750',
                            }}
                        />
                    )}

                    {renderIncentives(incentives)}

                    {surveys.surveys.map((survey, index) => (
                        <SurveyRow
                            key={survey.id + index}
                            surveyDetails={{
                                name: `${survey.name}`,
                                id: `${survey.id}`,
                                point: survey.max_points,
                                minutes: survey.minutes,
                                hasEnded: survey.has_ended,
                                is_quiz: survey.is_quiz,
                                path: '/activities/surveys',
                            }}
                            buttonState={profileSurvey}
                        />
                    ))}

                    {articles !== null &&
                        articles.map((article, index) => (
                            <NewsRow
                                key={article?.id + article?.title}
                                {...article}
                            />
                        ))}

                    {surveyIsFinished ? (
                        <h3 className="mt-4" style={{ textAlign: 'center' }}>
                            No surveys available!
                        </h3>
                    ) : null}

                    {loadMore ? (
                        <div className="activities__loader">
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin"
                            />
                        </div>
                    ) : null}

                    <div className="activities--bottom">
                        {!loadMore ? (
                            <Button
                                className="btn btn--outline btn--load-more"
                                onClick={() => {
                                    loadMoreActivities();
                                    setPage((prevPage) => prevPage + 1);
                                }}
                            >
                                Load More
                            </Button>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className="activities__loader">
                    <FontAwesomeIcon icon="spinner" className="fa-spin" />
                </div>
            )}
        </div>
    );
};

export default Activities;
