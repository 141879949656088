export const surveyTypes = {
    FETCH_SURVEY_REQUEST: "FETCH_SURVEY_REQUEST",
    FETCH_SURVEY_SUCCESS: "FETCH_SURVEY_SUCCESS",
    FETCH_SURVEY_FAILURE: "FETCH_SURVEY_FAILURE",

    FETCH_SURVEY_DETAILS_REQUEST: "FETCH_SURVEY_DETAILS_REQUEST",
    FETCH_SURVEY_DETAILS_SUCCESS: "FETCH_SURVEY_DETAILS_SUCCESS",
    FETCH_SURVEY_DETAILS_FAILURE: "FETCH_SURVEY_DETAILS_FAILURE",

    SUBMIT_SURVEY_REQUEST: "SUBMIT_SURVEY_REQUEST",
    SUBMIT_SURVEY_SUCCESS: "SUBMIT_SURVEY_SUCCESS",
    SUBMIT_SURVEY_FAILURE: "SUBMIT_SURVEY_FAILURE",  
    
    FETCH_POLLS_REQUEST: "FETCH_POLLS_REQUEST",
    FETCH_POLLS_SUCCESS: "FETCH_POLLS_SUCCESS",
    FETCH_POLLS_FAILURE: "FETCH_POLLS_FAILURE",
};