import { countryStateActionTypes } from "./country-states.types";
import { authTypes } from "./auth.types";
import { dashboardTypes } from "./dashboard.types";
import { referredUsersTypes } from "./referred-users.types";
import { verifyEmailTypes } from "./verify-email.types";
import { formTypes } from "./form.types";
import { privacyTypes } from "./privacy.types";
import { forgortPasswordTypes } from "./forgot-password.types";
import { resetPasswordTypes } from "./reset-password.types";
import { surveyTypes } from "./survey.types";
import { airtimeTypes } from "./airtime-types";
import { transactionTypes } from "./transaction-types";
import { notificationTypes } from "./notificatons.types";
import { bankServicesTypes } from "./bankServices.types";
import { electricityTypes } from "./electricity.types";
import { mobileDataTypes } from "./mobile-data.types";
import { newsTypes } from "./news.types";

const types = {
  countryStateActionTypes,
  authTypes,
  dashboardTypes,
  referredUsersTypes,
  verifyEmailTypes,
  formTypes,
  privacyTypes,
  forgortPasswordTypes,
  resetPasswordTypes,
  surveyTypes,
  airtimeTypes,
  transactionTypes,
  notificationTypes,
  bankServicesTypes,
  electricityTypes,
  mobileDataTypes,
  newsTypes,
};

export default types;
