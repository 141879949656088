import { Link, Redirect, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../hooks/useModal";
import { logoutHandler } from "../../helpers/logoutHandler";
import { getReferredUsers } from "../../redux/reducers/referred-users.reducer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import UserAccountDetails from "../../components/UserAccountDetails/UserAccountDetails";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import NubanVerification from "../../components/VerificationModals/NubanVerification/NubanVerification";
import MorePageLayout from "../../Layouts/MorePageLayout";
import "./more-page.scss";

import settings_icon from "../../assets/icons/settings-account-more.svg";
import user_icon from "../../assets/icons/user-account-profile.svg";

const MorePageHeader = () => {
  const { user } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();

  const { lifetime_points, level, is_verified } = user !== null && user;
  return (
    <>
      <UserProfileCard />
      <UserAccountDetails
        level={level}
        is_verified={is_verified}
        lifetime_points={lifetime_points}
        toggleModal={toggleModal}
      />
      {/* <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width={'600px'}
            >
                <VerifyAccount setModalOpen={setModalOpen} />
            </CustomModal> */}
      <NubanVerification
        verifyModalOpen={modalOpen}
        setVerifyModalOpen={setModalOpen}
      />
    </>
  );
};

const MorePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { width } = useWindowDimensions();

  const handleLogout = () => {
    logoutHandler();
  };

  useEffect(() => {
    dispatch(getReferredUsers());
  }, [dispatch]);

  if (width >= 698) {
    return <Redirect to="/membership" />;
  }

  return (
    <MorePageLayout page_title={"More"} header={<MorePage.Header />}>
      <div className="more__page">
        <div className="user__profile__container">
          <div className="more__page__controls">
            <Link to={{ pathname: "/membership" }} className="control">
              <div className="control__title">
                <img src={user_icon} alt="settings" />
                <span>Membership & Points</span>
              </div>
              <span className="material-icons-outlined">chevron_right</span>
            </Link>
            {/* <Link to={{ pathname: '/leaderboard' }} className="control">
                        <div className="control__title">
                            <img src={leaderboard_icon} alt="settings" />
                            <span>Leaderboard</span>
                        </div>
                        <span className="material-icons-outlined">
                            chevron_right
                        </span>
                    </Link> */}
            <Link to="/settings" className="control">
              <div className="control__title">
                <img src={settings_icon} alt="settings" />
                <span>Settings</span>
              </div>
              <span className="material-icons-outlined">chevron_right</span>
            </Link>
          </div>
          <button onClick={handleLogout} className="logout__button">
            Log Out
          </button>
        </div>
      </div>
    </MorePageLayout>
  );
};

MorePage.Header = MorePageHeader;

export default MorePage;