export const leadQuestion = {
    campaign: "lead000000",
    id: "lead000111",
    is_required: false,
    is_screening: true,
    media_source: "",
    my_route: "QL",
    next_route: "",
    next_route_name: "",
    options: [{ image: "", value: "yes", next_route: "Q0", next_route_name: null }, { image: "", value: "no", next_route: "Q0", next_route_name: null },],
    options_type: "TEXT",
    question: "Do you consent to sharing your contact details with Sparkle Business?",
    type: "MULTIPLE_CHOICE",
}