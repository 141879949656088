
import { Route } from "react-router-dom";
import AuthenticatedLogic from "./AuthenticatedLogic";
import ShutDownModal from "../ShutDownModal";
import useShutDown from "../../hooks/useShutDown";

const Authenticated = ({ component: Component, ...rest }) => {
  const { grantAccess } = AuthenticatedLogic();
  const {isShutDownDate} = useShutDown()
  return (
    <Route>
      {isShutDownDate? <div className="mt-4"><ShutDownModal closeModal={()=> {}}/></div> : grantAccess ? (
        <div id="webapp">
          <Component />
        </div>
      ) : null}
    </Route>
  );
};

export default Authenticated;