import {
  getWalletInfo,
  fetchLatestBalance,
} from "../../../../services/bank-transfer-service";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { primaryComponents } from "../../../primaryComponents";
import "./AccountDetails.scss";

const AccountDetails = ({ setIsBankTransfer }) => {
  const [loading, setLoading] = useState(false);
  const [walletInfo, setWalletInfo] = useState({});
  const [copied, setCopied] = useState(false);
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const [updatedBalance, setUpdatedBalance] = useState(null);

  const handleFetchWalletInfo = () => {
    setLoading(true);
    getWalletInfo()
      .then((response) => {
        setLoading(false);
        setWalletInfo(response.data?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchUpdatedBalance = () => {
    setIsRefreshingBalance(true);
    fetchLatestBalance({})
      .then((response) => {
        setIsRefreshingBalance(false);
        setUpdatedBalance(response.data.data.balance);
      })
      .catch(() => {
        setIsRefreshingBalance(false);
      });
  };

  useEffect(() => {
    handleFetchWalletInfo();
  }, []);

  return (
    <div className="account-details">
      <div className="account-details__top-bar mb-3">
        <div
          className="account-details__top-bar-icon"
          onClick={() => setIsBankTransfer(false)}
        >
          <FontAwesomeIcon icon="angle-left" style={{fontSize: "20px"}}/>
        </div>
        <h5 className="account-details__top-bar-title">
          Fund wallet using Bank Transfer
        </h5>
      </div>

      <h5>The account number provided is unique to your Lumi account.</h5>
      <p className="mt-2">1. Copy the account details provided below:</p>
      {loading ? (
        <FontAwesomeIcon
          className="d-block purple--text mx-auto my-4"
          icon={["fas", "spinner"]}
          spin
        />
      ) : (
        <div className="account-details__info">
          <div>
            <p>Bank Name</p>
            <h5>{walletInfo.bank_name ? walletInfo.bank_name : "N/A"}</h5>
          </div>
          <div>
            <p>Bank Account Number</p>
            <h5>
              {walletInfo.account_number ? walletInfo.account_number : "N/A"}
            </h5>
          </div>
          <div>
            <p>Account Name</p>
            <h5>{walletInfo.account_name ? walletInfo.account_name : "N/A"}</h5>
          </div>
        </div>
      )}
      <p>
        2. Transfer the amount you want to fund, note that sending money to the
        above account would always go to your Lumi wallet
      </p>
      <p className="mt-2">3. Your Lumi wallet would be funded</p>
      <CopyToClipboard
        onCopy={() => setCopied(true)}
        text={walletInfo.account_number}
      >
        <primaryComponents.Button className="btn btn--primary cover mt-4">
          {" "}
          {copied ? "Copied" : "Copy Account Number"}
        </primaryComponents.Button>
      </CopyToClipboard>

      {/* refresh account balance */}
      {updatedBalance !== null && (
        <p className="text-center mb-0 mt-3">
          Account balance:{" "}
          <span className="purple--text">
            N{updatedBalance.toLocaleString()}
          </span>
        </p>
      )}

      <primaryComponents.Button
        classNames="btn refresh-btn mt-2 cover"
        isDisabled={isRefreshingBalance}
        isLoading={isRefreshingBalance}
        loadingText="Refreshing..."
        onClick={fetchUpdatedBalance}
      >
        Refresh Account Balance
      </primaryComponents.Button>
      <p className="mt-2 text-center">Powered by Kuda Bank</p>
    </div>
  );
};

export default AccountDetails;
