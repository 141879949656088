import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import Label from '../../../components/Label';
import Button from '../../../components/Button';
import CheckBox from '../../../components/CheckBox';
import PasswordInput from '../../../components/PasswordInput';
import FormHeader from '../../../components/FormHeader';

const CreatePasswordForm = ({
    setPasswords,
    setActiveStep,
    loading,
    subscribeUpdate,
    setSubscribeUpdate,
    agreeTerms,
    setAgreeTerms,
    socialDetails,
}) => {
    const [initialPassword, setInitialpassword] = useState({
        password: '',
        confirm_password: '',
    });

    const passwordSchema = yup.object().shape({
        password: yup
            .string()
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                'Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number'
            )
            .required('Password is required'),
        confirm_password: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords do not match!')
            .required('Confirm Password'),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(passwordSchema),
        defaultValues: {
            password: initialPassword.password,
            confirm_password: initialPassword.confirm_password,
        },
    });

    const onSubmit = (data) => {
        setPasswords(data);
        setActiveStep(4);
    };

    return (
        <div className="password-section">
            <FormHeader
                title="Create your password"
                subtitle="You’re few clicks away from creating your survey."
            />
            <div className="password-section__form">
                <div className="mb-3">
                    <Label htmlFor="password">Create Password*</Label>
                    <PasswordInput
                        placeholder="Enter password"
                        register={register}
                        label="password"
                        error={errors.password}
                        required
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="confirmPassword">Confirm Password*</Label>
                    <PasswordInput
                        placeholder="Re-enter password"
                        register={register}
                        label="confirm_password"
                        error={errors.confirm_password}
                        required
                    />
                </div>
                {/* terms and condition section */}
                <div className="mt-4">
                    <div className="d-flex mb-2">
                        <CheckBox
                            id="subscribeUpdate"
                            value={subscribeUpdate}
                            onChange={(value) => setSubscribeUpdate(value)}
                            defaultChecked={subscribeUpdate}
                        />
                        <p className="ml-2">
                            I would like to receive the latest platform updates,
                            plus tips and best practices for mastering agile
                            market research
                        </p>
                    </div>
                    <div className="d-flex">
                        <CheckBox
                            id="agreeTerms"
                            value={agreeTerms}
                            onChange={(value) => setAgreeTerms(value)}
                            defaultChecked={agreeTerms}
                        />
                        <p className="ml-2 ">
                            I agree to Enlumi{' '}
                            <Link to="/terms-and-condition">
                                <span
                                    className="purple--text"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Terms &amp; Conditions
                                </span>
                            </Link>{' '}
                            and{' '}
                            <Link to="/privacy-policy">
                                <span
                                    className="purple--text"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Privacy Policy.
                                </span>
                            </Link>
                        </p>
                    </div>
                </div>
                {/* btn section */}
                <div className="btn-section mt-4">
                    <Button
                        onClick={() => setActiveStep(2)}
                        className="btn prev-btn"
                    >
                        Previous
                    </Button>
                    <Button
                        disabled={!agreeTerms}
                        onClick={handleSubmit(onSubmit)}
                        // onClick={() => {
                        //     handleSubmit(onSubmit);
                        //     setActiveStep(4);
                        // }}
                        className="btn btn--primary next-btn"
                    >
                        Next
                    </Button>
                    {/* <Button
                        disabled={!agreeTerms}
                        className="btn btn--primary next-btn"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon icon="spinner" className="fa-spin mr-2" />
                                Submiting...
                            </>
                        ) : (
                            "Submit"
                        )}
                    </Button> */}
                </div>
            </div>
        </div>
    );
};

export default CreatePasswordForm;
