import { Link, useRouteMatch } from 'react-router-dom';

const TransactionsMobile = ({ transactions }) => {
    const { path, url } = useRouteMatch();

    console.log(transactions);

    return (
        <div className="transactions__mobile">
            <div className="transactions__container">
                {transactions &&
                    transactions.map((transaction) => (
                        <Link
                            key={transaction.created_at}
                            to={`${url}/${transaction.tag}`}
                        >
                            <div className="transaction">
                                <div className="transaction__details">
                                    <div
                                        className={`transaction__icon ${
                                            transaction.type === 'CREDIT'
                                                ? 'credit'
                                                : 'debit'
                                        }`}
                                    >
                                        {transaction.type === 'CREDIT'
                                            ? '+'
                                            : '-'}
                                    </div>
                                    <div className="transaction__detail">
                                        <h5 className="transaction__title">
                                            {transaction.wallet ||
                                                'Naira Wallet'}
                                        </h5>
                                        <p>{transaction.description}</p>
                                        <p className="transaction__time">
                                            {new Date(
                                                transaction.created_at
                                            ).toLocaleString()}
                                        </p>
                                        {transaction.balance && (
                                            <p>
                                                Balance -{' '}
                                                {`NGN${transaction?.balance}`}
                                            </p>
                                        )}
                                        {transaction?.formatted_closing_balance && (
                                            <p>
                                                Balance -{' '}
                                                {`NGN${transaction?.formatted_closing_balance}`}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`transaction__amount ${
                                        transaction.type === 'CREDIT'
                                            ? 'credit'
                                            : 'debit'
                                    }`}
                                >{`₦${transaction.amount.toLocaleString()}`}</div>
                            </div>
                        </Link>
                    ))}
                {/* <div className="transaction">
                    <div className="transaction__details">
                        <div className="transaction__icon">+</div>
                        <div className="transaction__detail">
                            <h5 className="transaction__title">
                                Cash Wallet Credit
                            </h5>
                            <p className="transaction__time">
                                12-08-2021 8:32am
                            </p>
                        </div>
                    </div>
                    <div className="transation__amount">₦4,000.00</div>
                </div> */}
            </div>
        </div>
    );
};

export default TransactionsMobile;
