import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import MetaTags from "react-meta-tags";
import ReactGA from "react-ga";
//import { stopReportingRuntimeErrors } from "react-error-overlay";

import "./Fontawesome";
import "./assets/sass/index.scss";
import ErrorHandler from "./components/ErrorHandler";
// auth pages and components
import LoginPage from "./onboardingPages/loginPages/LoginPage";
import SignupPage from "./onboardingPages/signupPages/SignupPage.jsx";
import VerifyEmail from "./webappPages/VerifyEmail/VerifyEmail";
import ForgotPasswordPage from "./onboardingPages/forgotPasswordpages/ForgotPassword";
import VerificationPage from "./webappPages/VerificationPage/VerificationPage";
import Authenticated from "./components/Authenticated/Authenticated";
import VerifyLayout from "./Layouts/VerifyLayout";
import ScrollToTop from "./components/ScrollToTop";
import ResetPasswordPage from "./onboardingPages/ResetPasswordPage/ResetPasswordPage";
import ForgotPasswordSuccess from "./onboardingPages/forgotPasswordpages/ForgotPasswordSuccess";
import Preview from "./webappPages/PreviewQuestion/Preview";
import { secondaryComponents } from "./components/secondaryComponents";
// main application pages
import App from "./App";
// websites pages
import WebsiteLayout from "./Layouts/WebsiteLayout";
import Home from "./websitePages/Home/Home";
import About from "./websitePages/About/About";
import TermsAndCondition from "./websitePages/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./websitePages/PrivacyPolicy/PrivacyPolicy";
import site_image from "./assets/websiteImages/favicon.ico";
import CacheBuster from './components/CacheBuster';

const Main = () => {
    const history = useHistory();
    const initReactGA = useCallback(() => {
        ReactGA.initialize("UA-185772638-4");
        history.listen((location) => {
            ReactGA.pageview(location.pathname);
        });
    }, [history])

    useEffect(() => {
        if (process.env.REACT_APP_ENV === "production") {
            //stopReportingRuntimeErrors(); // disables error overlays
            initReactGA();
        }
    }, [initReactGA]);

    return (
        <Provider store={store}>
            <CacheBuster>
                <ErrorHandler>
                    <secondaryComponents.AppNotice>
                        <ScrollToTop />
                        <MetaTags>
                            <title>
                                Lumi | Shop or Share your Opinion to Earn Cash Rewards |
                                Join Free
                            </title>
                            <meta
                                name="description"
                                content="Lumi app is a lifestyle app that rewards you with cash when shop at your favourite brand or share your opinion on product and services."
                            />
                            <meta
                                property="og:title"
                                content="Lumi | Shop or Share your Opinion to Earn Cash Rewards | Join Free"
                            />
                            <meta property="og:image" content={site_image} />
                        </MetaTags>
                        <Switch>
                            <WebsiteLayout
                                exact
                                path="/"
                                component={Home}
                                background="white"
                            />
                            <WebsiteLayout
                                exact
                                path="/about"
                                component={About}
                                background="white"
                            />
                            <WebsiteLayout
                                exact
                                path="/terms-and-condition"
                                component={TermsAndCondition}
                                background="#F0EEFB"
                            />
                            <WebsiteLayout
                                exact
                                path="/privacy-policy"
                                component={PrivacyPolicy}
                                background="#F0EEFB"
                            />
                            <Authenticated exact path="/login" component={LoginPage} />
                            <Authenticated path="/signup" component={SignupPage} />
                            <Authenticated
                                path="/reset-password"
                                component={ResetPasswordPage}
                            />
                            <Authenticated path="/verify-email">
                                <VerifyLayout>
                                    <VerifyEmail />
                                </VerifyLayout>
                            </Authenticated>

                            <Authenticated path="/forgot-password" component={ForgotPasswordPage} />
                            <Authenticated path="/forgot-password-success" component={ForgotPasswordSuccess} />
                            <Route path="/verify" component={VerificationPage} />
                            <Route path="/preview-survey" component={Preview} />
                            {/* ads.txt redirect */}
                            <Route path="/ads.txt" component={() => {
                                window.location.href = 'https://a.pub.network/getlumi-io/ads.txt';
                                return null;
                            }} />

                            <Route component={App} />
                        </Switch>
                    </secondaryComponents.AppNotice >
                </ErrorHandler>
            </CacheBuster>
        </Provider >
    );
};

ReactDOM.render(
    <Router>
        <Main />
    </Router>,
    document.getElementById("root")
);