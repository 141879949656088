import congratulation_image from "../assets/images/congratulation-image.svg"
import { Link } from "react-router-dom";
import { nFormatter } from "../helpers/nFormatter";
import { useHistory } from "react-router";
import profile_survey_img from "../assets/images/profile-survey-success-img.png";

const SurveySucessModalContent = (props) => {
    const params = new URLSearchParams(window.location.search);
    const history = useHistory();
    return (
        <div className="survey-success-modal-content">
            <img className="survey-success-modal-content__cheers-img" src={congratulation_image} alt="survey-success-modal" />

            <h1>Congratulations</h1>
            <p className="gray--text mb-1 mt-1">you have just earned</p>
            <h3 className="purple--text">{nFormatter((props.totalQuestionAnswered * 100), 1)} points</h3>

            <img alt="" className="survey-image mb-4 mt-3" src={(params.get('survey-id') === "000000") ? profile_survey_img : props.image_url} />

            <h4>{props.headline}</h4>
            <p className="mb-2 mt-2">{props.description}</p>
            {(params.get('survey-id') === "000000") ? (
                <span className="survey-success-modal-content__href" onClick={() => {
                    history.push(props.cta_url)
                    window.location.reload();
                }}>{props.cta_text}</span>
            ) : (
                <Link to={{ pathname: `${props.cta_url}` }} target="_blank" className="survey-success-modal-content__href">
                    {props.cta_text}
                </Link>
            )}
        </div>
    )
}
export default SurveySucessModalContent;