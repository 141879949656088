import activity_header_img_1 from "../assets/images/Group 115443.svg";
import activity_header_img_2 from "../assets/images/Group 115444.svg";

import SearchActivities from "../components/SearchActivities";

const ActivitiesHeaderPurchase = () => {
    return (
        <div className="activities-page__header__purchase">
            <div className="activities-page__header__purchase__left-img">
                <img src={activity_header_img_1} alt="" />
            </div>
            {/* this contains the main header content */}
            <div className="activities-page__header__purchase__center">
                <div className="activities-page__header__purchase__center__item">
                    <h2 className="white--text">Save every time you pay your bills</h2>
                    <p className="white--text">Enjoy instant cashback every time you pay your bills.</p>
                    <SearchActivities />
                </div>
            </div>
            <div className="activities-page__header__purchase__right-img">
                <img src={activity_header_img_2} alt="" />
            </div>
        </div>
    );
}

export default ActivitiesHeaderPurchase