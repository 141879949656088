import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import queryString from "query-string";

import { verifyUserEmail } from "../../redux/reducers/verify-email.reducer";

import "./verify-email.scss";


const VerifyEmail = () => {
    const { verified, error } = useSelector((state) => state.verifyEmail);

    const dispatch = useDispatch();
    const location = useLocation();

    const renderVerifyEmailContent = () => {
        if (verified !== null) {
            return (
                <>
                    <h2 className="verify--email-heading">{verified[0]}</h2>
                    <p className="verify--email-description">
                        You can now <Link className="hyper__link" to="/login">login</Link> to your account.
                    </p>
                </>
            );
        } else if (error !== null) {
            return (
                <>
                    <h2 className="verify--email-heading">
                        Email Verification UnSuccessful
                    </h2>
                    <p className="verify--email-description">
                        {error[0]}
                        <br />
                        Please try again.
                    </p>
                </>
            );
        }
    };

    const token = new URLSearchParams(queryString.parse(location.search)).get(
        "token"
    );

    useEffect(() => {
        if (token !== "") {
            dispatch(verifyUserEmail(token));
        }
    }, [token, dispatch]);

    return (
        <div className="verify--email">
            <div className="verify--email-container">
                <div className="verify--email-content">
                    {renderVerifyEmailContent()}
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
