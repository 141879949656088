import { useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
    useHistory,
    NavLink,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useModal from '../../hooks/useModal';
import { FaAngleLeft } from 'react-icons/fa';
import { getUserDetails } from '../../redux/reducers/auth.reducer';
import { nFormatter } from '../../helpers/nFormatter';
import { logoutHandler } from '../../helpers/logoutHandler';

import Container from '../../components/Container';

import BiodataSettings from './BiodataSettings/BiodataSettings';
import PrivacySettings from './PrivacySettings/PrivacySettings';
// import ReferralSettings from '../../webappPages/SettingsPage/ReferralSettings/ReferralSettings';
import TransactionPinSettings from './TransactionPinSettings/TransactionPinSettings';
import VerifyAccount from '../../components/VerifyAccount';
import Button from '../../components/Button';
import NubanVerification from "../../components/VerificationModals/NubanVerification/NubanVerification";

import user_img from '../../assets/icons/profile-photo-icon.svg';
import gold_medal from '../../assets/icons/gold-membership-icon.svg';
import silver_medal from '../../assets/icons/silver-membership-icon.svg';

import gold_medal_badge from '../../assets/icons/gold-medal-icon.svg';
import silver_medal_badge from '../../assets/icons/silver-medal-icon.svg';
import bronze_medal_badge from '../../assets/icons/bronze-medal-icon.svg';

// user levels
const steps = [
    {
        id: 'bronze',
        title: 'Bronze Member',
        icon: { locked: silver_medal, unlocked: bronze_medal_badge },
        completed: false,
        points: 5000,
        referrals: 0,
    },
    {
        id: 'silver',
        title: 'Silver Member',
        icon: { locked: silver_medal, unlocked: silver_medal_badge },
        completed: false,
        points: 30000,
        referrals: 5,
    },
    {
        id: 'gold',
        title: 'Gold Member',
        icon: { locked: gold_medal, unlocked: gold_medal_badge },
        completed: false,
        points: 100000,
        referrals: 10,
    },
];

const renderBadge = (level) => {
    switch (level) {
        case 'bronze':
            return bronze_medal_badge;
        case 'gold':
            return gold_medal_badge;
        case 'silver':
            return silver_medal_badge;
        default:
            return null;
    }
};

const renderAccountType = (level) => {
    switch (level) {
        case 'bronze':
            return 'BRONZE MEMBER';
        case 'gold':
            return 'GOLD MEMBER';
        case 'silver':
            return 'SILVER MEMBER';
        default:
            return 'MEMBER';
    }
};

const tabs = [
    {
        id: 1,
        title: 'Biodata',
        path: '',
    },
    {
        id: 2,
        title: 'Password',
        path: '/privacy',
    },
    {
        id: 3,
        title: 'Pin',
        path: '/transaction-pin',
    },
];

const SettingsPage = () => {
    const { url, path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, user } = useSelector((state) => state.auth);
    const { referrals } = useSelector((state) => state);
    const { data } = referrals;
    const { referees } = data;
    const [levelsObtained, setLevelsObtained] = useState([]);
    const [verifyUser, setVerifyUser] = useState(false);

    const [modalOpen, setModalOpen, , CustomModal] = useModal();

    const {
        lifetime_points,
        is_verified,
        phone_number,
        level,
        username,
        email,
        has_kyc
    } = user !== null && user;

    // user referral count
    const user_ref_count = referees.filter(
        ({ is_verified, has_completed_profile_survey }) =>
            is_verified && has_completed_profile_survey
    ).length;

    const handleMembershipLevels = (level) => {
        const membershipLevels = ['member', 'bronze', 'silver', 'gold'];
        const currentMembershipLevels = [];

        for (let i = 0; i < membershipLevels.length; i++) {
            currentMembershipLevels.push(membershipLevels[i]);
            if (membershipLevels[i] === level) {
                break;
            }
        }
        setLevelsObtained(currentMembershipLevels);
    };

    const handleLogout = () => {
        logoutHandler();
    };

    useEffect(() => {
        if (email === undefined || email === null) {
            dispatch(getUserDetails());
        }
        handleMembershipLevels(level);
    }, [dispatch, email, level]);

    return (
        <div className="settings">
            <Container>
                <div className="settings--header">
                    <h2 className="settings--title">
                        <FaAngleLeft onClick={() => history.goBack()} />
                        Settings
                    </h2>
                    <p className="settings--description">
                        Redeem your points for rewards! Choose how you would
                        like to get paid from the payout options below. You can
                        change this at any time.
                    </p>
                </div>
                <div className="user--profile-container">
                    <div className="user--profile-header-mobile">
                        <div className="profile--img">
                            <img src={user_img} alt="user profile" />
                        </div>
                        <div className="profile--details">
                            <h5 className="username">{username}</h5>
                            <p>
                                <span>{email}</span> |{' '}
                                <span>{phone_number}</span>
                            </p>
                        </div>
                    </div>
                    <div className="user--account-details-mobile">
                        <div className="user--badge">
                            {level === 'member' ? null : (
                                <img
                                    src={renderBadge(level)}
                                    alt="account badge"
                                />
                            )}
                        </div>
                        <div className="user--account">
                            <div className="membership">
                                {renderAccountType(level)}
                            </div>
                            <div className="user--points">
                                {nFormatter(lifetime_points)} Points
                            </div>
                        </div>
                        {is_verified ? (
                            <div className="verification--status-mobile verified">
                                Verified
                            </div>
                        ) : (
                            <p
                            onClick={() =>
                                !has_kyc && setVerifyUser(true)
                            }
                                className="verify"
                            >
                                <span className="verify__title">
                                    Verify account
                                </span>
                                <span className="icon-arrow-right verify__icon"></span>
                            </p>
                        )}
                    </div>
                    <div className="user--profile-header">
                        <div className="user--profile-image">
                            <img src={user_img} alt="user profile" />
                        </div>
                        <div className="user--profile-details">
                            <div className="user--account-progress">
                                {steps.map((step, idx) => (
                                    <div
                                        key={step.id + idx}
                                        className={`progress ${
                                            levelsObtained.includes(step.id) &&
                                            'completed'
                                        }`}
                                    >
                                        <div className="level">
                                            <span>{step.title}</span>
                                            <img
                                                src={
                                                    levelsObtained.includes(
                                                        step.id
                                                    )
                                                        ? step.icon.unlocked
                                                        : step.icon.locked
                                                }
                                                alt="medal"
                                            />
                                            <span className="level--points">
                                                {lifetime_points >= step.points
                                                    ? nFormatter(step.points)
                                                    : nFormatter(
                                                          lifetime_points
                                                      )}{' '}
                                                / {nFormatter(step.points)}{' '}
                                                Points
                                            </span>
                                            <span className="level--referrals">
                                                {user_ref_count >=
                                                step?.referrals
                                                    ? step?.referrals
                                                    : user_ref_count}{' '}
                                                / {step?.referrals} Referrals
                                            </span>
                                        </div>
                                        {idx !== steps.length - 1 && (
                                            <div
                                                className={`divider--line`}
                                            ></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="user--account-overview">
                                <div className="user--account-type">
                                    <div className="user--account-type-badge">
                                        {level === 'member' ? null : (
                                            <img
                                                src={renderBadge(level)}
                                                alt="badge"
                                            />
                                        )}
                                    </div>
                                    <span className="user--account-type-title">
                                        {renderAccountType(level)}
                                    </span>
                                </div>
                                <div className="user--account-points">
                                    <span className="user--account-point">
                                        {lifetime_points
                                            ? nFormatter(lifetime_points)
                                            : 0}
                                    </span>
                                    <span>Points</span>
                                </div>
                                <div className="verify--account">
                                    {loading ? (
                                        'Loading...'
                                    ) : is_verified ? (
                                        <div className="verification--status verified">
                                            Verified
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                !has_kyc && setVerifyUser(true)
                                            }
                                            className="btn btn--outline btn--verify-account"
                                        >
                                            Verify account
                                        </Button>
                                    )}

                                    <CustomModal
                                        isActive={modalOpen}
                                        closeModal={() => setModalOpen(false)}
                                        width={'30%'}
                                    >
                                        <VerifyAccount
                                            setModalOpen={setModalOpen}
                                        />
                                    </CustomModal>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user--profile-body">
                        <div className="user--profile-body-header">
                            <div className="user--profile-body-header-container">
                                <div className="profile-body-header-tabs">
                                    {tabs.map((tab) => (
                                        <NavLink
                                            exact
                                            key={tab.id + tab.title}
                                            to={`${url}${tab.path}`}
                                            className={`btn btn--settings-header`}
                                            activeClassName="active"
                                        >
                                            {tab.title}
                                        </NavLink>
                                    ))}
                                    <NavLink to={"/membership"} className={"btn btn--settings-header"}>Membership</NavLink>
                                </div>
                                <Button
                                    onClick={handleLogout}
                                    className="btn btn--outline btn--logout"
                                >
                                    Log Out
                                    <span className="icon-arrow-right logout--icon"></span>
                                </Button>
                            </div>
                        </div>
                        <div className="user--profile-body-container">
                            <Switch>
                                <Route exact path={path}>
                                    <BiodataSettings />
                                </Route>
                                <Route path={`${path}/transaction-pin`}>
                                    <TransactionPinSettings />
                                </Route>
                                <Route path={`${path}/privacy`}>
                                    <PrivacySettings />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </Container>

            <NubanVerification
                verifyModalOpen={verifyUser}
                setVerifyModalOpen={setVerifyUser}
            />
        </div>
    );
};

export default SettingsPage;