import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBankList } from "../../redux/reducers/bankServices.reducer";
import arrow_left from "../../assets/icons/arrow-left.svg";
import AccNoInputField from "../../components/AccNoInputField";
import Label from "../../components/Label";
import BankSelectField from "../../components/BankSelectInput";
import Button from "../../components/Button";
import PageLoader from "../../components/PageLoader";
import useModal from "../../hooks/useModal";
import ModalClose from "../../components/ModalClose";
import ResponseModal from "../../components/ResponseModal"
import { resoloveAccountName, addBankAccount } from "../../services/bankServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddBankAccount = () => {
    // hooks section
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const history = useHistory();
    const dispatch = useDispatch();
    // states section
    const bankServices = useSelector((state) => state.bankServices);
    const [fetchingAccName, setFetchingAccName] = useState(false);
    const [bankCode, setBankCode] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const accountNameError = useRef(false);
    const [accountNameErrorMessage, setAccountNameErrorMessage] = useState("");
    const bankListError = useRef(false);
    const [bankListErrorMessage, setBankListErrorMessage] = useState("");
    const [wrongAccountDetails, setwrongAccountDetails] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [statusImage, setStatusImage] = useState("fail");
    const [addAccountLoader, setAddAccountLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);

    useEffect(() => {
        setPageLoader(true);
        fetchBankList();
    }, []);

    useEffect(() => {
        if (bankName != null) {
            validateBank();
            getAccountDetails();
        }
    }, [bankCode, accountNumber]);

    useEffect(() => {
        if (accountNumber != "") {
            validateAccountNumber();
            getAccountDetails();
        }
    }, [accountNumber]);

    const fetchBankList = () => {
        //  fetch bank list available for transactions
        if (bankServices.bankList.length === 0) {
            dispatch(getBankList()).then(() => {
                setPageLoader(false);
            }).catch(() => {
                setPageLoader(false);
            });
        }
    }

    const getAccountDetails = () => {
        // this function helps to resolve account details and validate that it exist 
        if (bankCode !== null && accountNumber.length == 10) {
            setFetchingAccName(true);
            resoloveAccountName({
                bank_code: bankCode,
                account_number: accountNumber
            }).then((response) => {
                setFetchingAccName(false);
                accountNameError.current = false;
                setAccountName(response.data.data.account_name);
                setwrongAccountDetails(false);
            }).catch((error) => {
                setFetchingAccName(false);
                if (error.response) {
                    if (error.response.status === 400 || error.response.status === 422) {
                        setwrongAccountDetails(true);
                        accountNameError.current = true;
                        setAccountNameErrorMessage(error.response.data.detail);
                    }
                }
            })
        } else {
            setFetchingAccName(false);
            setAccountName("");
        }
    }

    const validateError = () => {
        // trigger validation for both bank and account number
        validateBank();
        validateAccountNumber();
    }

    const validateBank = () => {
        // validate that user has selected a bank 
        if (bankName == null) {
            bankListError.current = true;
            setBankListErrorMessage("Bank name is required");
        } else {
            bankListError.current = false;
            setBankListErrorMessage("");
        }
    }

    const validateAccountNumber = () => {
        // validate user account number to be sure its 10 digits
        if (accountNumber.length !== 10) {
            accountNameError.current = true;
            setAccountNameErrorMessage("Account number must be 10 digits")
        } else {
            accountNameError.current = false;
            setAccountNameErrorMessage("");
        }
    }

    const saveAccountDetails = () => {
        // Save user account details
        if (!wrongAccountDetails) {
            validateError();
            if (!accountNameError.current && !bankListError.current) {
                setAddAccountLoader(true);
                addBankAccount({
                    bank_code: bankCode,
                    account_number: accountNumber,
                    bank_name: bankName
                }).then(() => {
                    setStatusImage("pass")
                    setStatusMessage("Account details added successfully!");
                    setStatusDialog(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }).catch((error) => {
                    setAddAccountLoader(false);
                    if (error.response.status === 400 || error.response.status === 422) {
                        setStatusImage("fail");
                        setStatusDialog(true);
                        setStatusMessage(error.response.data.detail);
                    }
                })
            }
        }
    }

    return (
        <div className="add-bank-acc-page">
            {/* titke and back button */}
            <div className="add-bank-acc-page__header" onClick={() => history.goBack()}>
                <img
                    src={arrow_left}
                    alt="arrow left"
                />
                <h2 className="">Add Bank Account</h2>
            </div>
            <p className="add-bank-acc-page__description">Kindly provide the following details for your reward withdrawal</p>
            {!bankServices.loading ? (
                <div className="add-bank-acc-page__form">
                    {/* Bank name input section */}
                    <div className="add-bank-acc-page__form__input mb-3">
                        <Label>Bank*</Label>
                        <BankSelectField
                            options={bankServices.bankList}
                            value={bankCode}
                            onChange={(parameter) => {
                                setBankCode(parameter.value);
                                setBankName(parameter.bankName);
                            }}
                            placeholder="Choose bank..."
                            bankName={bankName}
                            nameKey="name"
                            valueKey="code"
                            bankListError={bankListError.current}
                            bankListErrorMessage={bankListErrorMessage}
                            arrayOfObject={true}
                        />
                    </div>
                    {/* account number input section */}
                    <div className="add-bank-acc-page__form__input mb-3">
                        <Label>Account number*</Label>
                        <AccNoInputField
                            fetchingAccName={fetchingAccName}
                            accountName={accountName}
                            accNumber=""
                            onChange={(value) => {
                                setAccountNumber(value)
                            }}
                            accountNameError={accountNameError.current}
                            accountNameErrorMessage={accountNameErrorMessage}
                        />
                    </div>
                    {/* btn section */}
                    <div className="mt-3 add-bank-acc-page__form__btn">
                        <Button className="btn btn--primary" onClick={saveAccountDetails} disabled={(addAccountLoader) ? true : false}>
                            {addAccountLoader ? (
                                <span>
                                    <FontAwesomeIcon
                                        icon="spinner"
                                        className="fa-spin mr-1"
                                    />
                                    <span>Saving...</span>
                                </span>
                            ) : (
                                "Save account"
                            )}
                        </Button>
                    </div>
                </div>) : (
                <PageLoader />
            )}
            {/* this is the default modal that shows for a failure or success request*/}
            <CustomModal
                isActive={statusDialog}
                closeModal={() => setStatusDialog(false)}
                width={"350px"}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setStatusDialog(false)} />
                    </div>
                    <ResponseModal message={statusMessage} status={statusImage}/>
                </div>
            </CustomModal>
        </div>
    )
}

export default AddBankAccount;