import Types from "../constants";

const {
    resetPasswordTypes: {
        RESET_PASSWORD_FAILURE,
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
    },
} = Types;

export const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = (payload) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: payload,
});

export const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error,
});
