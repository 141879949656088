import activity_header_img_1 from "../assets/images/banner-image-02.png";
import activity_header_img_2 from "../assets/images/Group 115442.svg";

import SearchActivities from "../components/SearchActivities";
const ActivitiesHeaderReward = () => {
    return (
        <div className="activities-page__header__reward">
            <div className="activities-page__header__reward__left-img">
                <img src={activity_header_img_1} alt="" />
            </div>
            {/* this contains the main header content */}
            <div className="activities-page__header__reward__center">
                <div className="activities-page__header__reward__center__item">
                    <h2 className="white--text">Share your opinion for amazing rewards</h2>
                    <p className="white--text">Your opinion can help shape the product of tomorrow</p>
                    <SearchActivities />
                </div>
            </div>
            <div className="activities-page__header__reward__right-img">
                <img src={activity_header_img_2} alt="" />
            </div>
        </div>
    );
}

export default ActivitiesHeaderReward