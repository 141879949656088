import { Link } from 'react-router-dom';
import Button from './Button';

const PositiveActionRow = (props) => {
    return (
        <div className="positive__action__row">
            <div className="positive__action__row-left">
                <div
                    className={`
                        positive__action__logo ${
                            props.border === false ? 'no-border' : ''
                        }
                    `}
                >
                    <img src={props.icon} alt="action--icon" />
                </div>
                <div className="positive__action__details">
                    <h5 className="positive__action__details-heading">
                        {props.title}
                    </h5>
                    {props.description && (
                        <p className="positive__action__details-description">
                            {props.description}
                        </p>
                    )}
                    <Link
                        to={props.link}
                        className="positive__action__details-link"
                    >
                        <span>{props.btn__title}</span>
                        <span
                            className="icon-arrow-right ml-2"
                            style={{ fontSize: '10px', paddingBottom: '.3em' }}
                        ></span>
                    </Link>
                </div>
            </div>
            <div className="positive__action__row-right">
                <div className="positive__action__point">
                    +{props.point} Points
                </div>
                <Link to={props.link}>
                    <Button className="btn btn--primary positive__action__btn">
                        {props.btn__title}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default PositiveActionRow;
