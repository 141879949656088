import './reset-pin.scss';
import caution_img from '../../../../assets/icons/Failure illustration.svg';

import { useStep } from 'react-hooks-helper';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendTransactionPinOtp } from '../../../../services/transaction-pin.service';
import useModal from '../../../../hooks/useModal';

import Button from '../../../../components/Button';
import ResetPinForm from './ResetPinForm';
import ResponseModal from '../../../../components/ResponseModal';
import ModalClose from '../../../../components/ModalClose';

const ResetPin = () => {
    const steps = [
        { id: 1, title: 'OTP' },
        { id: 2, title: 'Reset Pin Form' },
    ];

    const { navigation, step } = useStep({ steps, initialStep: 0 });
    const [loading, setLoading] = useState(false);
    const [otpError, setOtpError] = useState(null);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();

    const handleSendOtp = async () => {
        setLoading(true);
        try {
            const res = await sendTransactionPinOtp();
            if (res) {
                setLoading(false);
                navigation.next();
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                if (error.response.data) {
                    const message = error.response.data.detail;
                    setOtpError(message);
                    toggleModal();
                }
            } else {
                setOtpError(error.message);
            }
        }
    };

    return (
        <div className="reset-pin">
            {step.title === 'OTP' && (
                <div className="reset-pin__container">
                    <h2 className="mb-3">Reset Pin</h2>
                    <div className="reset-pin__container-details">
                        <img src={caution_img} alt="caution" />
                        <p>
                            Use this option only if you can not recall your
                            current pin. Note that to reset your pin, you must
                            have access to your phone number associated with
                            your Lumi account to provide a verification code to
                            proceed.{' '}
                        </p>
                        <div style={{ width: '60%' }}>
                            <Button
                                disabled={loading}
                                onClick={handleSendOtp}
                                className="btn btn--primary"
                            >
                                {loading ? (
                                    <FontAwesomeIcon
                                        icon={'spinner'}
                                        className="fa-spin mr-2"
                                    />
                                ) : null}
                                <span>
                                    {loading ? 'Sending OTP...' : 'Send OTP'}
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {step.title === 'Reset Pin Form' && <ResetPinForm />}

            <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width="25%"
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header">
                        <ModalClose onClick={() => setModalOpen(false)} />
                    </div>
                    <ResponseModal status={'fail'} message={otpError} />
                </div>
            </CustomModal>
        </div>
    );
};

export default ResetPin;
