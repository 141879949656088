import search_icon from "../assets/images/search-icon.svg";

const SearchActivities = () => {
    return (
        <div className="search-activities">
            <input type="text" placeholder="Search activities"/>
            <img src={search_icon} alt="" />
        </div>
    );
}

export default SearchActivities