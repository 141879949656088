import Types from "../constants/index";

const { countryStateActionTypes } = Types;

export const fetchCountryStatesRequest = () => {
    return {
        type: countryStateActionTypes.FETCH_STATES_REQUEST,
    };
};

export const fetchCountryStatesSuccess = (states) => {
    return {
        type: countryStateActionTypes.FETCH_STATES_SUCCESS,
        payload: states,
    };
};

export const fetchCountryStatesFailure = (error) => {
    return {
        type: countryStateActionTypes.FETCH_STATES_FAILURE,
        payload: error,
    };
};

export const fetchSelectedState = (selectedState) => {
    return {
        type: countryStateActionTypes.SELECT_STATE,
        payload: selectedState,
    };
};
