import { useEffect, useState } from 'react';
import { getDashboard } from '../../redux/reducers/dashboard.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useModal from '../../hooks/useModal';
import ModalClose from '../../components/ModalClose';
import useAuth from '../../hooks/useAuth';
import { __REFERRAL_POINTS__ } from '../../helpers/constants';

import './dashboard.scss';
import Greeting from '../../components/Greeting';
import UserDetails from '../../components/UserDetails';
import Button from '../../components/Button';
import AccountSummary from '../../components/AccountSummary';
import QuickActions from '../../components/QuickActions';
import Container from '../../components/Container';
import InviteFriends from '../../components/InviteFriends';
// import RewardSummaryMobile from '../../components/RewardSummaryMobile';
import AccountSummaryMobile from '../../components/AccountSummaryMobile';
import LumiAd from '../../components/LumiAd/LumiAd';
import NewAccount from '../../components/PositiveActionModals/NewAccount';
import bronze_medal_badge from '../../assets/icons/bronze-medal-icon.svg';
import gold_medal_badge from '../../assets/icons/gold-medal-icon.svg';
import silver_medal_badge from '../../assets/icons/silver-medal-icon.svg';
import { secondaryComponents } from '../../components/secondaryComponents';
import ShutDownModal from '../../components/ShutDownModal';

const DashboardPage = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const __email_verified__ = user !== null && user.email_verified;

    const dashboard = useSelector((state) => state.dashboard);
    const user_data = dashboard.data !== null && dashboard.data;
    const { username, level, enlumi_points, point_per_referral } = user_data;

    const [isFirstPurchase] = useState(
        JSON.parse(localStorage.getItem('isFirstTimePurchase')) || false
    );
    const [showAd, setShowAd] = useState(true);
    const [verifyEmail, setVerifyEmail] = useState(false);
    // const history = useHistory();

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [isShutDownNote, setIsShutDownNote] = useState(true)
    // const [pinModalOpen, setPinModalOpen, togglePinModal] = useModal(has_pin);
    const isAuth = useAuth();
    //const location = useLocation();

    const renderBadges = (level) => {
        switch (level) {
            case 'bronze':
                return bronze_medal_badge;
            case 'gold':
                return gold_medal_badge;
            case 'silver':
                return silver_medal_badge;
            default:
                return null;
        }
    };

    const renderAccountType = (level) => {
        switch (level) {
            case 'bronze':
                return 'BRONZE MEMBER';
            case 'gold':
                return 'GOLD MEMBER';
            case 'silver':
                return 'SILVER MEMBER';
            default:
                return 'MEMBER';
        }
    };

    useEffect(() => {
        if (username === null || username === undefined) {
            if (isAuth) {
                dispatch(getDashboard());
            }
        }
    }, []);

    useEffect(() => {
        if (__email_verified__ === false) {
            setVerifyEmail(true);
        }
    }, [__email_verified__]);

    return (
        <div className="dashboard">
            <Container>
                <div className="dashboard__nav">
                    {level !== 'member' && (
                        <Greeting user={username} greeting="Hello" />
                    )}
                    <div className="dashboard__nav__details">
                        {level === 'member' && (
                            <Greeting user={username} greeting="Hello" />
                        )}
                        {level !== 'member' && (
                            <>
                                <UserDetails
                                    medal={renderBadges(level)}
                                    totalPoints={enlumi_points}
                                    accountType={renderAccountType(level)}
                                />
                            </>
                        )}
                        <Button
                            onClick={toggleModal}
                            className="btn btn--primary invite--btn"
                        >
                            Invite Friends ({point_per_referral.toLocaleString()} Points)
                        </Button>
                    </div>
                </div>
                <div className="dashboard__nav--mobile">
                    <h3 className="mr-5">
                        <span style={{ fontWeight: 'normal' }}>Hello </span>
                        <span>{username}</span>,
                    </h3>
                    <div className="dashboard__nav--mobile__details">
                        <div className="mt-2">
                            <p className="member-level">
                                {renderAccountType(level)}
                            </p>
                            <Link to="/membership" className="profile-btn">
                                View progress
                            </Link>
                        </div>
                        {level !== 'member' && (
                            <img
                                className="member-icon "
                                src={renderBadges(level)}
                                alt="badge"
                            />
                        )}
                    </div>
                </div>
                {/* shows on desktop */}
                <AccountSummary className="dashboard__account-summary" />
                {/* shows on mobile */}
                <AccountSummaryMobile className="dashboard__account-summary--mobile" />

                {/* shows on mobile */}
                {/* <RewardSummaryMobile className="dashboard__reward-summary" /> */}

                {/* dashboard adsense */}
                {showAd && (
                    <LumiAd slot={'Dashboard'}>
                        <LumiAd.Media>
                            {({
                                media,
                                advert,
                                handleClickAd,
                                userDemographics,
                            }) => (
                                <div className="mt-4 mb-4 dashboard__ad-slot">
                                    {media?.url && (
                                        <span
                                            onClick={() => {
                                                setShowAd(false);
                                            }}
                                            className="cancel__ad-btn"
                                        >
                                            x
                                        </span>
                                    )}
                                    <img
                                        style={{ borderRadius: '5px', width: "100%" }}
                                        className="m-auto mb-4"
                                        onClick={() => {
                                            handleClickAd({
                                                advert_id: advert?.id,
                                                media_id: media?.id,
                                                ...userDemographics,
                                            });
                                            const win = window.open(
                                                advert?.url,
                                                '_blank'
                                            );
                                            win.focus();
                                        }}
                                        src={media?.url}
                                        alt={media?.file_name}
                                    />
                                </div>
                            )}
                        </LumiAd.Media>
                    </LumiAd>
                )}
                {/* <div className="mt-4 mb-2" >
                    <AdsenseMounter slot='4743613148' />
                </div> */}

                <QuickActions />
                <Button
                    onClick={toggleModal}
                    className="btn btn--primary invite--btn invite--btn--mobile"
                >
                    Invite Friends ({__REFERRAL_POINTS__} Points)
                </Button>

                {/* Freestar ads slot */}
                {(window.innerWidth <= 450) &&
                    <secondaryComponents.FreestarAds
                        placementName="getlumi_medrec_1"
                        slotId="getlumi_medrec_1"
                        publisher="getlumi-io"
                        classNames={['mt-2 d-flex justify-content-center']} />
                }
            </Container>

            <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width={'600px'}
            >
                <div className="modal__container__content--invite">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setModalOpen(false)} />
                    </div>
                    <InviteFriends />
                </div>
            </CustomModal>

            <CustomModal
                isActive={isShutDownNote}
                closeModal={() => setIsShutDownNote(false)}
                width={'700px'}
            >
                <ShutDownModal closeModal={()=> setIsShutDownNote(false)} />
            </CustomModal>

            <NewAccount
                isFirstTime={isFirstPurchase.isNewSignup}
                username={username}
            />
        </div>
    );
};

export default DashboardPage;
