import "./privacyPolicy.scss";
const PrivacPolicy = () => {

    return (
        <div className="privacy-policy-container">
            <div className="title-section">
                <h2>Enlumi Privacy Policy</h2>
            </div>
            <div className="content-section">
                <div className="sidenav">
                    <div className="sidenav-body">
                        <h5>Introduction</h5>
                        <div className="mt-3">
                            <a href="#Introduction" className="d-block mb-2">Introduction</a>
                            <a href="#Age-Restriction" className="d-block mb-2">Age Restriction</a>
                            <a href="#Information-We-Collect" className="d-block mb-2">Information We Collect</a>
                            <a href="#Personally-Identifiable-Information" className="d-block mb-2">Using Your Personally
                                Identifiable Information</a>
                            <a href="#other-Information-We-Collect" className="d-block mb-2">Other Information We Collect</a>
                            <a href="#Disclosures" className="d-block mb-2">Disclosures</a>
                            <a href="#International-Transfers" className="d-block mb-2">International Transfers</a>
                            <a href="#Your-Rights" className="d-block mb-2">Your Rights</a>
                            <a href="#Website-Security" className="d-block mb-2">Website Security</a>
                            <a href="#Compliance-Law-Enforcement" className="d-block mb-2">Compliance with Laws and Law
                                Enforcement</a>
                            <a href="#Use-of-Cookies" className="d-block mb-2">Use of Cookies</a>
                            <a href="#Data-We-Retain" className="d-block mb-2">The Data We Retain</a>
                            <a href="#Links-Third-Party-Websites" className="d-block mb-2">Links to Third Party Websites</a>
                            <a href="#Limitation-of-Liability" className="d-block mb-2">Limitation of Liability</a>
                            <a href="#Changes-to-Privacy-Policy" className="d-block mb-2">Changes to this Privacy Policy</a>
                            <a href="#Contact-Us" className="d-block mb-2">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="py-2 content">
                    {/* introductions */}
                    <h6 id="Introduction" className="mb-4 mt-2 purple--text">1. Introduction</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            At Enlumi Insights, we value your privacy, and we are committed to safeguarding your personal
                            information. All personal information that you provide us will be protected and kept
                            confidential
                            among our affiliates, representatives, and privies.<br /><br />

                            Throughout the website, the terms “we”, “us” and “our” refer to Enlumi Insights.<br /><br />

                            This Privacy Policy explains how we collect, use, share and protect your personal information in
                            connection with your relationship with us as a client or potential client. It applies to all our
                            clients, potential clients, consultants, partners and every other person we hold information
                            about.<br /><br />

                            This policy also sets out your rights and who you may contact for further information.<br /><br />

                            You agree to this Privacy Policy by visiting our website and when you use our services.<br /><br />

                            Our website is not directed at you if we are prohibited by any law of any jurisdiction from
                            making
                            the information on our website available to you and is not intended for any use that would be
                            contrary to local law or regulation.
                        </span>
                    </p>
                    {/* Age restriction */}
                    <h6 id="Age-Restriction" className="mb-4 mt-5 purple--text">2. Age Restriction</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            You affirm that you are a Nigerian resident, attained the minimum age of 15 and that you have
                            read the above authorization and fully understand its contents.
                        </span>
                    </p>
                    {/*  Information We Collect */}
                    <h6 id="Information-We-Collect" className="mb-4 mt-5 purple--text">3. Information We Collect</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            There are two categories of information we collect. We collect a variety of information from our
                            users and visitors to our website. As described below, some information is automatically
                            collected when you visit our website, and some you provide to us when registering an account or
                            filling out a survey or communicating with us.<br /><br />
                            <span className="d-block ms-4">
                                <span className="purple--text">i. Information Collected Automatically:</span> Whenever you visit
                                our website, our web servers
                                automatically collect non-personal information such as the domain name of the internet
                                access provider, the internet protocol address used to connect the computer to the internet,
                                the average time spent on our website, pages viewed, information searched for, access times,
                                and other relevant statistics.<br /><br />

                                <span className="purple--text">ii. Information You Provide Us:</span> If you provide us with
                                personal information by contacting
                                us, we collect that personal information.
                            </span>
                        </span>
                    </p>
                    {/* Using Your Personally Identifiable Information */}
                    <h6 id="Personally-Identifiable-Information" className="mb-4 mt-5 purple--text">4. Using Your Personally
                        Identifiable Information</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            “Personally Identifiable Information” means any information that (a) identifies or can be used
                            to identify, contact, or locate the person to whom such information pertains, or (b) from which
                            identification or contact information of an individual person can be derived. This includes
                            name, location, date of birth, email address, phone number, physical address, and other contact
                            details. <br /><br />

                            We primarily collect your information to ensure that we provide the most efficient service to
                            you, monitor the use and improve our website and other legitimate interests. Your information
                            will solely be used and disclosed for the following purposes:<br /><br />
                            <span className="d-block ms-4">
                                i. To help us verify your identity;<br /><br />

                                ii. To carry out our obligations ensuing from any contracts entered into between you and
                                us;<br /><br />

                                iii. To provide you with the products, services and information you request from us.<br /><br />

                                iv. To assist you with enquiries and improve our customer service;<br /><br />

                                v. To assist us in carrying out marketing analysis and customer profiling (including
                                transactional information), conduct research, including creating statistical and testing
                                information;<br /><br />

                                vi. To allow us communicate with you in any way (including e-mail, telephone, and text or
                                multimedia messages);<br /><br />

                                vii. For receiving points and redeeming your rewards;<br /><br />

                                viii. To help prevent and detect fraud or loss; <br /><br />

                                ix. To update our records; <br /><br />

                                x. To make recommendations and suggestions to you about services offered by us unless you
                                have previously asked us not to do so; <br /><br />

                                xi. Send you service or support messages, such as updates, security alerts, email
                                notifications and /or newsletters; <br /><br />

                                xii. Conduct investigations and risk assessments; and <br /><br />

                                xiii. For compliance with a legal and regulatory obligations. <br /><br />
                            </span>
                        </span>
                    </p>
                    {/* Other Information We Collect */}
                    <h6 id="other-Information-We-Collect" className="mb-4 mt-5 purple--text">5. Other Information We Collect
                    </h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            Other information which may be automatically collected from you when you visit our website
                            include; domain name of your internet access provider, the internet protocol address used to
                            connect the computer to the internet, the average time spent on our website, pages viewed,
                            information searched for, access times, your geographical location, operating system, referral
                            source, and other relevant statistics. We may also collect information from you using cookies
                            and other analytical tools especially when you use our products and services. More details are
                            provided below in our section on Cookies.
                        </span>
                    </p>
                    {/* Disclosures */}
                    <h6 id="Disclosures" className="mb-4 mt-5 purple--text">6. Disclosures</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            i. We will not sell, publish, or disclose to third parties your personally identifiable
                            information collected on our website, through our servers or otherwise obtained by us, other
                            than to provide our services and as set forth in this privacy policy. We may share generic
                            aggregated demographic information not linked to any personally identifiable information
                            regarding visitors and Users with our business partners, trusted affiliates, professional
                            advisers and advertisers for the purposes outlined above. We may share your information with
                            these third parties for those limited purposes provided that you have given us your
                            permission and in compliance with the Nigeria Data Protection Regulation 2019 (NDPR).<br /><br />

                            ii. We will not share personal information you provide when you complete surveys and reviews
                            with any third parties including our clients, and business partners.<br /><br />

                            iii. We may request information about you from third parties to provide our services.<br /><br />

                            iv. We will notify you as soon as we become aware of a harmful data breach which may result
                            in a risk of your rights and freedom.<br /><br />

                            v. You have the right to request an erasure of your data at any time.<br /><br />

                            vi. We will notify you if we are transferring your data.<br /><br />

                            vii. You may request at any time that we halt further dissemination of your data or cease to
                            use your data.<br /><br />

                            viii. If you submit content in a public forum or a social media post, or use a similar
                            feature on our website, that content is publicly visible.<br /><br />

                            ix. We may disclose Personally Identifiable Information if required to do so by law or in
                            the good faith belief that such action is necessary to (a) conform with the requirements of
                            the law or comply with legal process served on us, or (b) act in urgent circumstances to
                            protect the personal safety of users of our service or members of the public.<br /><br />

                            x. To the extent practicable and legally permitted, we will attempt to advise you prior to
                            any such disclosure, so that you may seek a protective order or other relief limiting such
                            disclosure.<br /><br />
                        </span>
                    </p>
                    {/*  International Transfers */}
                    <h6 id="International-Transfers" className="mb-4 mt-5 purple--text">7. International Transfers</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            The transfer of your information to a foreign country or international organisation will only be
                            done with your consent and in compliance with the NDPR. We will ensure that there are adequate
                            data protection laws in the recipient country or organisation before transferring your
                            information.
                        </span>
                    </p>
                    {/*  Your Rights */}
                    <h6 id="Your-Rights" className="mb-4 mt-5 purple--text">8. Your Rights</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            Subject to certain limitations and exceptions, you are entitled to the following principal
                            rights under the NDPR:<br /><br />
                            <span className="d-block ms-4">
                                i. You have the right to be notified if we are transferring your personal
                                information.<br /><br />

                                ii. You have the right to request an erasure of your personal information at any
                                time.<br /><br />

                                iii. You have the right to request that we rectify inaccurate personal information.<br /><br />

                                iv. You may request at any time that we halt further dissemination of your data or cease to
                                use
                                your personal information.<br /><br />

                                v. You have the right to request for copies of your personal information.</span>
                        </span>
                    </p>
                    {/*  Website Security */}
                    <h6 id="Website-Security" className="mb-4 mt-5 purple--text">9. Website Security</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            We are committed to ensuring that your information is secure. In order to prevent unauthorised
                            access or disclosure, we have put in place suitable physical, electronic and managerial
                            procedures such as secure sockets layer (SSL) to safeguard and secure the information we collect
                            online. We use encryption tools when accepting and transmitting delicate visitor information
                            through our website.
                        </span>
                    </p>
                    {/*  Compliance with Laws and Law Enforcement */}
                    <h6 id="Compliance-Law-Enforcement" className="mb-4 mt-5 purple--text">10. Compliance with Laws and Law
                        Enforcement</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            We cooperate with government and law enforcement officials to enforce and comply with the law.
                            We will disclose any information about Users upon valid request by government or law officials
                            as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal
                            process (including without limitation subpoenas), to protect your property and rights, or the
                            property and rights of a third party, to protect the safety of the public or any person, or stop
                            activity we consider illegal or unethical.
                        </span>
                    </p>
                    {/*  Use of Cookies */}
                    <h6 id="Use-of-Cookies" className="mb-4 mt-5 purple--text">11. Use of Cookies</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            We use cookies to identify you as a User and make your user experience easier, customise our
                            services, content and advertising; help you ensure that your account security is not
                            compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website.
                            Cookies allow our servers to remember your account log-in information when you visit our
                            website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent
                            activities. If your browser or browser add-on permits, you have the choice to disable cookies on
                            our website; however, this may limit your ability to use our website.
                        </span>
                    </p>
                    {/*  The Data We Retain */}
                    <h6 id="Data-We-Retain" className="mb-2 mt-4 purple--text">12. The Data We Retain</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            We will retain your information for as long as needed to provide you with our services, comply
                            with our legal and statutory obligations or verify your information with a digital identity and
                            verification institution.<br /><br />

                            We are statutory obligated to retain the data you provide us with in order to process your
                            rewards, verify your identity, conduct surveys and in compliance with laws and regulatory
                            guidelines applicable to us, our business partners, clients, trusted affiliates and professional
                            advisers.</span>
                    </p>
                    {/*  Links to Third Party Websites */}
                    <h6 id="Links-Third-Party-Websites" className="mb-4 mt-5 purple--text">13. Links to Third Party Websites
                    </h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            i. Our website may contain links to third-party websites or services that are not owned or
                            controlled by us.<br /><br />

                            ii. We have no control over, and assume no responsibility for, the content, privacy policies, or
                            practices of any third-party websites or services. You further acknowledge and agree that we
                            shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                            alleged to be caused by or in connection with use of or reliance on any such content, goods or
                            services available on or through any such web sites or services.<br /><br />

                            iii. We strongly advise you to read the terms and conditions and privacy policies of any
                            third-party web sites or services that you visit.
                        </span>
                    </p>
                    {/*  Limitation of Liability */}
                    <h6 id="Limitation-of-Liability" className="mb-4 mt-5 purple--text">14. Limitation of Liability</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            We exercise reasonable efforts to safeguard the security and confidentiality of your personal
                            data; however, we will not be liable for unauthorized disclosure of personal data that occurs
                            through no fault of ours.</span>
                    </p>
                    {/*  Changes to this Privacy Policy */}
                    <h6 id="Changes-to-Privacy-Policy" className="mb-4 mt-5 purple--text">15. Changes to this Privacy Policy
                    </h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            Changes may be made to this Privacy Policy from time. Whenever such changes are made, we will
                            notify you. These changes will take effect immediately after you have been notified.
                        </span>
                    </p>
                    {/* Contact Us */}
                    <h6 id="Contact-Us" className="mb-4 mt-5 purple--text">16. Contact Us</h6>
                    <p className="mb-0">
                        <span className="d-block ms-4">
                            If you would like more information or you have any comments or questions on our privacy policy,
                            please contact us at info@enlumidata.com.<br /><br />

                            This policy is effective as of 19th of December 2020.<br /><br />

                            Last updated: 23rd of March 2021.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
export default PrivacPolicy;