import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NewsService from "../services/news.service";

const useLikeArticle = (articleId) => {
  const [isLiked, setIsLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [likes, setLikes] = useState(0);

  const { data } = useSelector((state) => state.dashboard);
  const { username } = data !== null && data;

  const handleLike = async () => {
    try {
      const res = await NewsService.likeArticle(articleId, username);
      if (res) {
        setLoading(false);
        setIsLiked(true);
      }
    } catch (error) {
      setLoading(false);
      setIsLiked(false);
    }
  };

  const getArticleLikesCount = async () => {
    try {
      const res = await NewsService.fetchArticleLikesCount(articleId);
      if (res) {
        setLoading(false);
        setLikes(res.data.data[0].count.id);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticleLikesCount();
  }, [isLiked]);

  return { isLiked, handleLike, loading, likes };
};


export default useLikeArticle;