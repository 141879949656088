function ArticleImage({ imageId, image }) {
  const ACCESS_TOKEN = process.env.REACT_APP_BLOG_ACCESS_TOKEN;

  return (
    <div className="article__img">
      <img
        src={`https://enlumi-blog.herokuapp.com/assets/${imageId}?access_token=${ACCESS_TOKEN}`}
        alt="article image"
      />
    </div>
  );
}

export default ArticleImage;
