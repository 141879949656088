const TableHead = ({ columns }) => {
    if (columns && columns.length > 0) {
        const _cols = columns.map((col, idx) => (
            <th scope="col" className="table__heading" key={col}>
                <div className="table__heading-container">
                    {/* {idx === 0 && <input type="checkbox" />} */}
                    {col}
                    {/* {col !== "" && idx !== 1 && (
                        <span className="material-icons-round">
                            expand_more
                        </span>
                    )} */}
                </div>
            </th>
        ));
        return (
            <thead className="table__head">
                <tr className="table__row">{_cols}</tr>
            </thead>
        );
    } else {
        return null;
    }
};

export default TableHead;
