import axios from "axios";
import store from "../redux/store.js";
import {
  setErrorState
} from "../redux/reducers/errorHandler.reducer";
import { logoutHandler } from "../helpers/logoutHandler";
import { setAccessToken, setRequestingTokens } from "../redux/actions/auth.actions";


// base url
let baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
let blogUrl = process.env.REACT_APP_BLOG_URL;

const httpClient = axios.create({
  baseURL: baseUrl,
});

// Blog axios instance
export const blogHttpClient = axios.create({ baseURL: blogUrl });

// setting authorization header
const httpRequest = (config) => {
  const state = store.getState();
  const { accessToken } = state.auth
  //config.headers["X-platform"] = "web";
  if (accessToken !== null) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

// blog auth header config
const blogHttpRequest = (config) => {
  const accessToken = process.env.REACT_APP_BLOG_ACCESS_TOKEN;
  //config.headers["X-platform"] = "web";
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error, requestClient) => {
  if (error.response) {
    if (error.response.status) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        const state = store.getState();
        const { requestingTokens } = state.auth
        if (requestingTokens) return requestClient(originalRequest);

        if (localStorage.getItem("refresh_token")) {
          originalRequest._retry = true;
          store.dispatch(setRequestingTokens(true));
          return axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/token/refresh/`, {
            refresh: localStorage.getItem("refresh_token")
          }).then(res => {
            store.dispatch(setRequestingTokens(false));
            localStorage.setItem("refresh_token", res.data.refresh);
            store.dispatch(setAccessToken(res.data.access));
            // Change Authorization header
            requestClient.defaults.headers.common['Authorization'] = `Bearer ${res.data.access}`
            // return originalRequest object with Axios.
            return requestClient(originalRequest);
          }).catch((error) => {
            store.dispatch(setRequestingTokens(false));
            localStorage.setItem("next_route", `${window.location.pathname}`);
            logoutHandler();
          })
        }
        localStorage.setItem("next_route", `${window.location.pathname}`);
        logoutHandler()
      } else if (error.response.status === 403) {
        store.dispatch(
          setErrorState({
            message: "You are not Authorized to view this resource.",
            requestError: true,
          })
        );
      } else if (error.response.status === 500 || error.response.status === 503) {
        store.dispatch(
          setErrorState({
            message:
              "Sorry this service is currently unavailable, please try again later",
            requestError: true,
          })
        );
      } else if (error.response.status === 404) {
        store.dispatch(
          setErrorState({
            message: "This resource does not exist or might have been removed",
            requestError: true,
          })
        );
      } else if (error.response.status === 429) {
        store.dispatch(
          setErrorState({
            message: "Duplicate transaction detected, try again in few minutes",
            requestError: true,
          })
        );
      }
      return Promise.reject(error);
    }
    if (!navigator.onLine) {
      store.dispatch(
        setErrorState({
          message:
            "No internet connection, please check your network connectivity",
          requestError: true,
        })
      );
    }
  }
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(httpRequest);
blogHttpClient.interceptors.request.use(blogHttpRequest);

/** Adding the response interceptors */
httpClient.interceptors.response.use(onFulfilled, (error) => onRejected(error, httpClient));
blogHttpClient.interceptors.response.use(onFulfilled, (error) => onRejected(error, blogHttpClient));

export default httpClient;

