import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InputField = ({
    error,
    type,
    onChange,
    fetchingAccName,
    accNumber,
    accountName,
    accountNameError,
    accountNameErrorMessage,
    ...inputProps
}) => {
    const handleAccountNumber = (e) => {
        onChange(e.target.value);
    }
    return (
        <div style={{ position: "relative" }}>
            <input
                type="number"
                className="input--field"
                defaultValue={accNumber}
                onChange={handleAccountNumber}
                {...inputProps}
            />

            <span className="acc-name-loader-body">
                {fetchingAccName ? (
                    <FontAwesomeIcon icon="spinner" className="fa-spin" />) : null}
            </span>
            <p className={`mb-0 mt-1 ${(accountNameError) ? 'error--text' : 'purple--text'}`}>
                {accountNameError ? accountNameErrorMessage: accountName}
            </p>
        </div>
    );
};

export default InputField;
