import authHttpClient from "../axiosBase/authHandler";
import httpClient from "../axiosBase/axiosHandler";

export const fetchToken = (data) => {
    const response = authHttpClient.post(`/token/`, data);
    return response;
};

export const createUser = (payload) => {
    const response = authHttpClient.post(`/register/`, payload);
    return response;
};

export const fetUserDetails = () => {
    const response = httpClient.get(`/settings/biodata/`);
    return response;
};

export const forgotPassword = (payload) => {
    const response = authHttpClient.post(`/forgot-password/`, payload);
    return response;
};

export const updateUserDetails = (payload) => {
    const response = httpClient.post(`/settings/biodata/`, payload);
    return response;
};

export const verifyUser = (email) => {
    const response = httpClient.get(`/users/${email}/status/`);
    return response;
};

export const VerifyEmail = (token) => {
    const response = httpClient.get(`/verify-email/?token=${token}`);
    return response;
};

export const forgotPasswordService = (payload) => {
    const response = authHttpClient.post(`/forgot-password/`, payload);
    return response;
};

export const resetPasswordService = (token, payload) => {
    const response = authHttpClient.post(`/reset-password/?token=${token}`, payload);
    return response;
};

export const exchangeGoogleToken = (data) => {
    const response = authHttpClient.post(`/social/google-oauth2/`, data);
    return response;
};

export const exchangeFacebookToken = (data) => {
    const response =  authHttpClient.post(`/social/facebook/`, data);
    return response;
};