import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getReferredUsers } from '../redux/reducers/referred-users.reducer';
import { useEffect } from 'react';
import { __REFERRAL_POINTS__ } from '../helpers/constants';

import Button from './Button';
import InputField from './InputField';

const InviteFriends = () => {
    const referrals = useSelector((state) => state.referrals);
    const {point_per_referral} = useSelector((state) => state.dashboard?.data);
    const signupUrl = window.location.origin;
    const [copied, setCopied] = useState(false);
    const { register } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReferredUsers());
    }, [dispatch]);

    return (
        <div className="invite--friends">
            <div className="invite--friends-header">
                <div className="invite--friends-header-intro">
                    <h2 className="invite--heading">Invite your friends</h2>
                    <div className="invite--points">{point_per_referral.toLocaleString()} Points</div>
                </div>
                <p className="invite--friends-header-description">
                    Invite your friends to join Lumi Rewards 🥰
                    <ul>
                        <li>We'll send you 3,750 points</li>
                        <li>While your friends will earn 4000 points</li>
                    </ul>
                </p>
                <p className="invite--friends-header-description">
                    They must complete their profile and verify their account
                    with an ID for points to be awarded
                </p>
            </div>
            <div className="invite--friends-body">
                <p className="referral--intro mb-0">Your referral link:</p>
                <InputField
                    className="referral--link mt-4 mb-5"
                    register={register}
                    label="referralLink"
                    value={
                        signupUrl +
                        '/signup?referral_id=' +
                        referrals.data.referral_code
                    }
                    disabled={true}
                    required
                />
                <div className="referral--buttons">
                    <CopyToClipboard
                        onCopy={() => setCopied(true)}
                        text={
                            signupUrl +
                            '/signup?referral_id=' +
                            referrals.data.referral_code
                        }
                    >
                        <Button className="btn btn--outline btn--copy">
                            {copied ? 'Copied' : 'Copy link'}
                        </Button>
                    </CopyToClipboard>
                    <Button
                        className="btn btn--primary"
                        style={{ background: '#ddd' }}
                        disabled={true}
                    >
                        Share link
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InviteFriends;
