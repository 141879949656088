import Articles from './Articles';
import ArticlePage from './ArticlePage';
import LumiAd from '../../../components/LumiAd/LumiAd';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';
import ad_one from '../../../assets/images/ad-1.jpeg';
import ad_two from '../../../assets/images/ad-2.jpeg';
import ad_three from '../../../assets/images/ad-3.jpeg';

import './news.scss';

const News = () => {
    const { path } = useRouteMatch();

    return (
        <div className="news__page">
            {/* <div className="news__page__header">
        <p>Add news page header and filter</p>
      </div> */}
            <div className="news__page__container">
                <div className="articles__container">
                    <Switch>
                        <Route path={path} exact>
                            <Articles />
                        </Route>
                        <Route path={`${path}/:articleId`}>
                            <ArticlePage />
                        </Route>
                    </Switch>
                </div>
                <div className="ads__section">
                    <div className="mb-4">
                        <LumiAd slot={'Blog'}>
                            <LumiAd.Media>
                                {({
                                    media,
                                    advert,
                                    handleClickAd,
                                    user_id,
                                }) => (
                                    <Link
                                        to={{ pathname: advert?.url }}
                                        target="_blank"
                                    >
                                        <img
                                            onClick={() =>
                                                handleClickAd({
                                                    advert_id: advert.id,
                                                    media_id: media.id,
                                                    user_id,
                                                })
                                            }
                                            className="mb-4"
                                            src={media?.url}
                                            alt={media?.file_name}
                                        />
                                    </Link>
                                )}
                            </LumiAd.Media>
                        </LumiAd>
                        <img className="mb-4" src={ad_one} alt="ad image" />
                        <img className="mb-4" src={ad_three} alt="ad image" />
                        <img className="mb-4" src={ad_two} alt="ad image" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
