import { useState, useEffect } from 'react';
import { nFormatter } from '../../helpers/nFormatter';

import './UserAccountProgress.scss';
import gold_medal_badge from '../../assets/icons/gold-medal-icon.svg';
import silver_medal_badge from '../../assets/icons/silver-medal-icon.svg';
import bronze_medal_badge from '../../assets/icons/bronze-medal-icon.svg';
import silver_medal from '../../assets/icons/silver-membership-icon.svg';
import gold_medal from '../../assets/icons/gold-membership-icon.svg';

const UserAccountProgress = ({ level, lifetime_points, referrals }) => {
    const steps = [
        {
            id: 'bronze',
            title: 'Bronze Member',
            icon: { locked: silver_medal, unlocked: bronze_medal_badge },
            completed: false,
            points: 5000,
            referrals: 0,
        },
        {
            id: 'silver',
            title: 'Silver Member',
            icon: { locked: silver_medal, unlocked: silver_medal_badge },
            completed: false,
            points: 30000,
            referrals: 5,
        },
        {
            id: 'gold',
            title: 'Gold Member',
            icon: { locked: gold_medal, unlocked: gold_medal_badge },
            completed: false,
            points: 100000,
            referrals: 10,
        },
    ];

    // const [currentLevel, setCurrentLevel] = useState("")
    const [levelsObtained, setLevelsObtained] = useState([]);

    useEffect(() => {
        // findLevelById(level);
        const membershipLevels = ['member', 'bronze', 'silver', 'gold'];
        const currentMembershipLevels = [];
        for (let i = 0; i < membershipLevels.length; i++) {
            currentMembershipLevels.push(membershipLevels[i]);
            if (membershipLevels[i] === level) {
                break;
            }
        }

        setLevelsObtained(currentMembershipLevels);
    }, [level]);

    return (
        <div className="user__account__progress__bar">
            {/* <p className="progress__bar__heading">level</p> */}
            <div className="user__account__levels">
                {steps.map((level, index) => (
                    <div key={level.id} className="user__account__level">
                        <div>
                            <p>{level.title.split(' ')[0]}</p>
                        </div>
                        <img
                            src={
                                levelsObtained.includes(level.id)
                                    ? level.icon.unlocked
                                    : level.icon.locked
                            }
                            alt="level"
                        />
                        <span className="user__account__level__points">
                            {lifetime_points >= level.points
                                ? nFormatter(level.points)
                                : nFormatter(lifetime_points)}{' '}
                            / {nFormatter(level.points)} Points
                        </span>
                        <span className="user__account__level__referrals">
                            {referrals >= level.referrals
                                ? level.referrals
                                : referrals}{' '}
                            / {level.referrals} Referrals
                        </span>
                        <div
                            className={`${
                                index !== steps.length - 1 && 'divider__line'
                            }`}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserAccountProgress;
