import search_icon from "../../assets/icons/search.svg";
import filter_icon from "../../assets/icons/Filter.svg";
import dropdown_icon from "../../assets/icons/Arrow-Down.svg";

import Button from "../../components/Button";

const TransactionSearch = () => {
    return (
        <div className="transactions--search-bar">
            <div className="transactions--search-input">
                <img src={search_icon} alt="search icon" />
                <input type="text" />
                <img src={filter_icon} alt="filter icon" />
            </div>
            {/* <div>
                <Button className="btn transaction--search-btn">
                    Actions
                    <img src={dropdown_icon} alt="drop down" />
                </Button>
            </div> */}
        </div>
    );
};

export default TransactionSearch;
