import axios from 'axios';
const LUMI_AD_URL = process.env.REACT_APP_LUMI_AD_URL;

export const getAd = (userDemograpichs, slot) => {
    return axios.get(
        LUMI_AD_URL, {
            params: {
                ...userDemograpichs,
                placement: slot
            }
        }
    );
};

export const clickAd = (payload) => {
    return axios.post(
        LUMI_AD_URL,
        payload
    );
};

// REACT_APP_LUMI_AD_URL=https://app.insiight.io/api/lumi-a

/**
 * dashboard
 * actvitity
 * survey
 * billing
 */
