import "./questionsInput.scss";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Checkboxes = (props) => {
    const optionValues = props.options;
    const initialCheckboxes = []
    const [selectedCheckboxes, setSelectedCheckboxes] = useState((props.answer) ? props.answer.split('|') : initialCheckboxes);

    useEffect(() => {
        resetAnswer();
    }, [props.id]);

    useEffect(() => {
        setData();
    }, [selectedCheckboxes]);

    const resetAnswer = () => {
        setSelectedCheckboxes((props.answer) ? props.answer.split('|') : initialCheckboxes);
    }

    const checkForNextRoute = () => {
        let nextRoute = props.options[0].next_route;
        return nextRoute;
    }

    const handleOnChange = (e) => {
        if (selectedCheckboxes.includes(e.target.value)) {
            let newSelectedCheckboxes = selectedCheckboxes;
            let index = newSelectedCheckboxes.indexOf(e.target.value);
            if (index !== -1) {
                newSelectedCheckboxes.splice(index, 1);
            }
            setSelectedCheckboxes(newSelectedCheckboxes)
        } else {
            let newSelectedCheckboxes = selectedCheckboxes;
            newSelectedCheckboxes.push(e.target.value);
            setSelectedCheckboxes(newSelectedCheckboxes)
        }
        setData();
    }

    const setData = () => {
        props.onchange({
            answer: selectedCheckboxes.join("|"),
            nextRoute: checkForNextRoute()
        });
    }
    return (
        <div className="flex-answers">{
            optionValues.map((optionValue, index) => (
                <label key={index} htmlFor={`option-${index}`} className={`questions-input ${optionValue.image != "" ? "questions-input--inline-block" : ""} ${selectedCheckboxes.includes(optionValue.value) ? "active" : ""}`}>
                    {optionValue.image != "" ? (
                        <img className="questions-input__image" src={optionValue.image} alt="" />
                    ) : null}

                    <div className="questions-input__section">
                        <input
                            className="questions-input__section__input-field"
                            type="checkbox"
                            id={`option-${index}`}
                            name="question"
                            value={optionValue.value}
                            checked={selectedCheckboxes.includes(optionValue.value)}
                            onChange={handleOnChange}
                        />
                        <label htmlFor={`option-${index}`} className="questions-input__section__label">
                            {optionValue.value}
                            <span className="questions-input__section__label__checked">
                                <FontAwesomeIcon icon="check" />
                            </span>
                        </label>
                    </div>
                </label>))
        }</div>
    )
}

export default Checkboxes;