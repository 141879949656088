import { useEffect, useState } from "react";
import "./questionsInput.scss";

const Time = (props) => {
    const initialAnswer = "";
    const [answer, setAnswer] = useState((props.answer) ? props.answer : initialAnswer);

    useEffect(() => {
        setAnswer((props.answer) ? props.answer : initialAnswer);
    }, [props.id]);

    useEffect(() => {
        setData();
    }, [answer]);

    const checkForNextRoute = () => {
        let nextRoute = props.next_route;
        return nextRoute;
    }
    const handleChange = (e) => {
        setAnswer(e.target.value)
    }
    const setData = () => {
        props.onchange({
            answer: answer,
            nextRoute: checkForNextRoute()
        });
    }
    return (
        <input className="questions-input__input-field" value={answer} type="time" onChange={handleChange} />
    )
}

export default Time;