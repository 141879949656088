import { useHistory, Link } from 'react-router-dom';
import Button from '../../../components/Button';
import ComingSoon from '../../../components/ComingSoon';

import LumiAd from '../../../components/LumiAd/LumiAd';

import './shop.scss';

import shop_icon from '../../../assets/images/shop-icon.svg';

const Shop = () => {
    const history = useHistory();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <ComingSoon
                message={
                    'Shop for brands you love and get automatically rewarded'
                }
                icon={shop_icon}
            />
            <Button
                onClick={() => history.push('/activities')}
                className="btn btn--primary btn--coming-soon"
            >
                Go back
            </Button>
            <div className="ad__section">
                <LumiAd>
                    <LumiAd.Media>
                        {({ media, advert, handleClickAd, user_id }) => {
                            return (
                                <Link
                                    to={{
                                        pathname: advert?.url,
                                    }}
                                    target="_blank"
                                >
                                    <img
                                        src={media?.url}
                                        onClick={() =>
                                            handleClickAd({
                                                media_id: media.id,
                                                advert_id: advert.id,
                                                user_id,
                                            })
                                        }
                                        alt={media?.file_name}
                                    />
                                </Link>
                            );
                        }}
                    </LumiAd.Media>
                </LumiAd>
            </div>
        </div>
    );
};

export default Shop;
