import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


const AuthenticatedLogic = () => {
    const [grantAccess, setGrantAcces] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('refresh_token')) history.push("/dashboard")
        setGrantAcces(true)
    })
    return { grantAccess };
};

export default AuthenticatedLogic;