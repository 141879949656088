import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import * as yup from "yup";
import { updatePrivacySettings } from "../../redux/reducers/privacy.reducer";

import "./setup-enlumi-password.scss";

import Form from "../../components/Form";
import PasswordInput from "../../components/PasswordInput";
import Button from "../../components/Button";
import Label from "../../components/Label";
import FormGroup from "../../components/FormGroup";
import ModalClose from "../../components/ModalClose";
import ErrorText from "../../components/ErrorText";

const SetupEnlumiPassword = ({ setHasEnlumiPassword, setProceedToWithdrawCheckout }) => {
    const passwordSchema = yup.object().shape({
        password: yup
            .string()
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                "Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number"
            )
            .required("Password is required"),
        confirm_password: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords do not match!")
            .required("Confirm Password"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(passwordSchema),
        defaultValues: {
            password: "",
            confirm_password: "",
        },
    });
    const dispatch = useDispatch();
    const {
        privacy: { loading },
    } = useSelector((state) => state);

    const [error, setError] = useState(null);

    const onSubmit = (data) => {
        const {
            confirm_password: new_password_confirmation,
            password: new_password,
        } = data;

        dispatch(
            updatePrivacySettings({ new_password, new_password_confirmation })
        )
            .then(() => {
                setProceedToWithdrawCheckout(true);
                setHasEnlumiPassword(true);
            })
            .catch((error) => {
                setError(error.message);
            });
    };

    return (
        <div className="setup--enlumi-password">
            <ModalClose onClick={() => setHasEnlumiPassword(true)} />
            <h3>Enlumi Password</h3>
            <p>Please setup your enlumi password to continue</p>
            {error && <ErrorText message={error} />}
            <Form
                className="enlumi--password-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormGroup>
                    <Label>New Password</Label>
                    <PasswordInput
                        register={register}
                        placeholder="Please enter your new password"
                        required
                        label="password"
                        error={errors.password}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Confirm Password</Label>
                    <PasswordInput
                        register={register}
                        placeholder="Re-enter your new password"
                        required
                        label="confirm_password"
                        error={errors.confirm_password}
                    />
                </FormGroup>
                <Button disabled={loading} className="btn btn--primary">
                    {loading ? (
                        <span>
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin mr-1"
                            />
                            <span>Setting up...</span>
                        </span>
                    ) : (
                        "Setup password"
                    )}
                </Button>
            </Form>
        </div>
    );
};

export default SetupEnlumiPassword;
