import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import user_img from "../../../assets/images/profile-icon.svg";

function Comment({ date_created, user_name, body }) {
  return (
    <div className="comment__item">
      <div className="comment__user">
        <img src={user_img} alt="user" />
      </div>
      <div className="comment__details">
        <p className="username">{user_name}</p>
        <p className="comment__text">{body}</p>
        <p>
          {new Date(date_created).toDateString()}{" "}
          {new Date(date_created).toLocaleTimeString()}
        </p>
      </div>
    </div>
  );
}

function Comments({ comments, loading }) {
  return (
    <div className="comments__list">
      {loading ? (
        <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
      ) : (
        comments.map((comment) => <Comment key={comment.id} {...comment} />)
      )}
    </div>
  );
}

export default Comments;
