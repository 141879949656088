import { useHistory } from "react-router-dom";

import Button from "./Button";

import success_image from "../assets/images/congratulations.png";

const NotificationModal = (props) => {
    const history = useHistory();

    const handleClick = () => {
        history.push("/activities/pay-bills/bills");
        window.location.reload();
    };

    return (
        <div className="notification--modal">
            <img
                className="notification--modal-bg"
                src={success_image}
                alt="congratulations your transaction was successful"
            />
            <h2 className="notification--heading">AWESOME!</h2>
            <p className="notification--message">
                {props.points
                    ? "You transaction was successful and you’ve earned"
                    : "You transaction was successful"}
            </p>
            {props.points && (
                <p className="notification--reward">
                    {props.points} <span>Points!</span>
                </p>
            )}
            <Button
                onClick={handleClick}
                className="btn btn--primary btn--notification"
            >
                Return to bills
            </Button>
        </div>
    );
};

export default NotificationModal;
