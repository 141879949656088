import { useForm } from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, Link } from 'react-router-dom';
import { resetPasswordService } from '../../services/auth.service';

import './reset-password.scss';

import LoginLayout from '../../Layouts/LoginLayout';
import Form from '../../components/Form';
import Label from '../../components/Label';
import Button from '../../components/Button';
import PasswordInput from '../../components/PasswordInput';
import GoogleRecaptchaV3 from '../../components/GoogleRecaptchaV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ResetPasswordPage = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const params = new URLSearchParams(window.location.search);
    const recaptchaRef = useRef();
    const passwordSchema = yup.object().shape({
        newPassword: yup
            .string()
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                'Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number'
            )
            .required('Password is required'),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref('newPassword'), null], 'Passwords do not match!')
            .required('Confirm Password'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            newPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(passwordSchema),
    });

    useEffect(() => {
        if (newPassword !== null) {
            setLoading(true);
            recaptchaRef.current.handleLoaded();
        }
    }, [newPassword]);

    const onSubmit = (recaptchaToken) => {
        if (newPassword === null) return;
        resetPasswordService(params.get('token'), {
            password: newPassword,
            'g-recaptcha-response': recaptchaToken,
        })
            .then(() => {
                history.push('/login');
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    const { detail } = error.response.data;
                    setErrorMessage(detail);
                }
            });
    };

    const passwordDetails = (data) => {
        setNewPassword(data.newPassword);
    };

    return (
        <LoginLayout
            content1="A few clicks away from amazing rewards!"
            content2="Shop, Pay bills, take surveys, & automatically get rewarded."
            content3="Kick off your earnings!"
            showHeadings={true}
            title="Reset your Enlumi password"
            description=""
            linkedPage={
                <span className="big-text-size">
                    Remember password?{' '}
                    <Link
                        className="purple--text big-text-size ml-1"
                        to="/login"
                    >
                        Log in
                    </Link>
                </span>
            }
        >
            <div className="reset-password-page">
                <Form onSubmit={handleSubmit(passwordDetails)}>
                    {/* google recaptcha */}
                    <GoogleRecaptchaV3
                        ref={recaptchaRef}
                        resolved={onSubmit}
                        onReset={() => setLoading(false)}
                        page="reset"
                    />

                    {/* error message */}
                    <p className="error--text error-section mb-2">
                        {errorMessage}
                    </p>

                    {/* new password field */}
                    <div className="form--group mb-4">
                        <Label>New Password*</Label>
                        <PasswordInput
                            placeholder="New Password"
                            label="newPassword"
                            register={register}
                            error={errors.newPassword}
                            required
                        />
                    </div>
                    {/* confirm password field */}
                    <div className="form--group mb-4">
                        <Label>Confirm Password*</Label>
                        <PasswordInput
                            placeholder="Confirm Password"
                            label="confirmPassword"
                            register={register}
                            error={errors.confirmPassword}
                            required
                        />
                    </div>
                    {/* button section */}
                    <Button
                        disabled={loading}
                        className="btn btn--primary btn--reset-password mt-2"
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon
                                    icon="spinner"
                                    className="fa-spin mr-2"
                                />
                                Resetting...
                            </>
                        ) : (
                            'Reset password'
                        )}
                    </Button>
                </Form>
            </div>
        </LoginLayout>
    );
};

export default ResetPasswordPage;
