import { useState } from "react";

import eye_icon from "../assets/icons/eye-icon.svg";
import ErrorText from "./ErrorText";

const PasswordInput = ({ label, register, error, required, ...others }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="password--input">
                <input
                    className="input--field"
                    type={showPassword ? "text" : "password"}
                    {...register(label, { required })}
                    label={label}
                    {...others}
                />
                <div className="password--icon" onClick={handleShowPassword}>
                    <img src={eye_icon} alt="eye icon" />
                </div>
            </div>
            {error && <ErrorText message={error.message} />}
        </>
    );
};

export default PasswordInput;
