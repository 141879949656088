import { getSurvey } from "../../../redux/reducers/survey.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/Button";
import SurveyRow from "../../../components/SurveyRow";
import "./surveys.scss";
import FirstSurvey from "../../../components/PositiveActionModals/FirstSurvey";
import empty_state_img from "../../../assets/images/empty-state.svg";
import { secondaryComponents } from "../../../components/secondaryComponents";

const Surveys = () => {
  const dispatch = useDispatch();
  const surveys = useSelector((state) => state.survey);
  const profile_survey = useSelector(
    (state) => state.dashboard.data.has_completed_profile_survey
  );
  const [loadMore, setLoadMore] = useState(false);
  const [surveyIsFinished, setSurveyIsFinished] = useState(false);

  const dashboard = useSelector((state) => state.dashboard);
  const user_data = dashboard.data !== null && dashboard.data;
  const { username } = user_data;

  const [isFirstPurchase] = useState(
    JSON.parse(localStorage.getItem("isFirstTimePurchase")) || false
  );

  useEffect(() => {
    dispatch(getSurvey(1));
  }, [dispatch]);

  const loadMoreActivities = () => {
    if (
      surveys.surveyPagination.current_page < surveys.surveyPagination.last_page
    ) {
      setLoadMore(true);
      dispatch(getSurvey(surveys.surveyPagination.next_page))
        .then(() => {
          setLoadMore(false);
        })
        .catch(() => {
          setLoadMore(false);
        });
    } else {
      setSurveyIsFinished(true);
      setTimeout(() => {
        setSurveyIsFinished(false);
      }, 3000);
    }
  };

  return (
    <div className="activities-survey-page">
      {!surveys.loading ? (
        <div>
          {!profile_survey ? (
            <SurveyRow
              surveyDetails={{
                name: "Profile setup",
                id: "000000",
                point: "2500",
                minutes: "12 minutes",
                path: "/activities/surveys",
              }}
              buttonState={true}
            />
          ) : null}

          {surveys.surveys.map((survey, index) => (
            <SurveyRow
              key={index}
              surveyDetails={{
                name: `${survey.name}`,
                id: `${survey.id}`,
                point: survey.max_points,
                minutes: survey.minutes,
                path: "/activities/surveys",
                hasEnded: survey.has_ended,
                is_quiz: survey.is_quiz,
              }}
              buttonState={profile_survey}
            />
          ))}
        </div>
      ) : (
        <div className="activities-survey-page__loader">
          <FontAwesomeIcon icon="spinner" className="fa-spin" />
        </div>
      )}

      {surveyIsFinished ? (
        <div>
          <img
            width={"200"}
            className="mx-auto mt-2"
            src={empty_state_img}
            alt="no surveys"
          />
          <h3 className="mt-4" style={{ textAlign: "center" }}>
            No surveys available!
          </h3>
        </div>
      ) : null}

      {loadMore ? (
        <div className="activities-survey-page__loader">
          <FontAwesomeIcon icon="spinner" className="fa-spin" />
        </div>
      ) : null}

      <div className="activities-survey-page__bottom">
        {!loadMore && !surveyIsFinished ? (
          <Button
            className="btn btn--outline btn--load-more"
            onClick={() => {
              loadMoreActivities();
            }}
          >
            Load More
          </Button>
        ) : null}
      </div>

      {/* Freestar ads slot */}
      {window.innerWidth <= 450 && (
        <secondaryComponents.FreestarAds
          placementName="getlumi_medrec_2"
          slotId="getlumi_medrec_2"
          publisher="getlumi-io"
          classNames={["mt-2 d-flex justify-content-center"]}
        />
      )}

      <FirstSurvey
        isFirstTime={isFirstPurchase.isFirstSurvey}
        username={username}
      />
    </div>
  );
};

export default Surveys;
