import { fetchTransactions } from "../../services/transaction.service";

import {
    fetchTransactionFailure,
    fetchTransactionRequest,
    fetchTransactionSuccess,
    fetchCreditTransactions,
    fetchDebitTransactions,
    fetchAllTransactions,
} from "../actions/transaction.action";
import Types from "../constants";


const {
    transactionTypes: {
        FETCH_TRANSACTION_FAILURE,
        FETCH_TRANSACTION_REQUEST,
        FETCH_TRANSACTION_SUCCESS,
        FETCH_CREDIT_TRANSACTION,
        FETCH_DEBIT_TRANSACTION,
        FETCH_ALL_TRANSACTIONS,
    },
} = Types;

const initialState = {
    loading: false,
    filteredTransactions: null,
    data: null,
    error: null,
};

export const transactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRANSACTION_REQUEST:
            return { ...state, loading: true };
        case FETCH_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                filteredTransactions: action.payload,
            };
        case FETCH_ALL_TRANSACTIONS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                filteredTransactions: action.payload,
            };
        case FETCH_CREDIT_TRANSACTION:
            return {
                ...state,
                loading: false,
                filteredTransactions: action.payload,
            };
        case FETCH_DEBIT_TRANSACTION:
            return {
                ...state,
                loading: false,
                filteredTransactions: action.payload,
            };
        case FETCH_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getTransactions = () => {
    return (dispatch) => {
        dispatch(fetchTransactionRequest());
        fetchTransactions()
            .then((response) => {
                if (response) {
                    dispatch(fetchTransactionSuccess(response.data));
                }
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(fetchTransactionFailure(error.response.data));
                } else {
                    dispatch(fetchTransactionFailure(error.message));
                }
            });
    };
};

export const filterTransactions = (transactions, type) => {
    return (dispatch) => {
        let _filteredTransactions = null;
        if (type === "Credit" || type === "CREDIT") {
            _filteredTransactions = transactions.filter(
                (transaction) => transaction.type === type
            );
            dispatch(fetchCreditTransactions(_filteredTransactions));
        } else if (type === "Debit" || type === "DEBIT") {
            _filteredTransactions = transactions.filter(
                (transaction) => transaction.type === type
            );
            dispatch(fetchDebitTransactions(_filteredTransactions));
        } else if (type === "All" || type === "ALL") {
            dispatch(fetchAllTransactions(transactions));
        }
    };
};
