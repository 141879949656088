import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const BottomNavigation = () => {
    const location = useLocation();
    const isAuth = useAuth();

    if(!isAuth) return null;

    return (
        <nav
            className={`bottom-navigation ${
                location.pathname === "/activities/surveys/start" ? "hide" : ""
            }`}
        >
            <NavLink to={isAuth ? "/dashboard" : "/"} className="bottom-navigation__link">
                <span className="menu-icon icon-home"></span>
                <span className="link__text">{isAuth ? "Home" : "Home"}</span>
            </NavLink>
            <NavLink to={isAuth ? "/rewards" : "/about"} className="bottom-navigation__link">
                <span className="menu-icon icon-gift"></span>
                <span className="link__text">{isAuth ? "Rewards" : "About"}</span>
            </NavLink>
            <NavLink
                to="/activities"
                className="bottom-navigation__link bottom-navigation__link--circle"
            >
                <span className="icon-activities icon"></span>
            </NavLink>
            <NavLink
                to={isAuth ? "/activities/pay-bills/bills" : "/contact"}
                className="bottom-navigation__link"
            >
                <span className="menu-icon icon-paybills"></span>
                <span className="link__text">{isAuth ? "Bills" : "Contact"}</span>
            </NavLink>
            <NavLink to={isAuth ? "/more" : "https://help.enlumidata.com/kb/en"} target={!isAuth ? "_blank" : "_self"} className="bottom-navigation__link">
                {isAuth ? (
                    <FontAwesomeIcon icon={"ellipsis-v"} className="menu-icon" />
                    // <span className="menu-icon icon-more"></span>
                ) : (
                    <span className="icon-cell-phone"></span>
                )}
                <span className="link__text">{isAuth ? "More" : "Help"}</span>
            </NavLink>
        </nav>
    );
};

export default BottomNavigation;
