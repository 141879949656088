import { Route, useRouteMatch, NavLink, useLocation } from "react-router-dom";

import ActivitiesHeader from "../components/ActivitiesHeader";
import Activities from "../webappPages/ActivitiesPage/Activities/Activities";
import SurveysLayout from "./SurveysLayout";
import PayBills from "../webappPages/ActivitiesPage/PayBills/PayBills";
import News from "../webappPages/ActivitiesPage/News/News";
import Shop from "../webappPages/ActivitiesPage/Shop/Shop";
import DailyPolls from "../webappPages/ActivitiesPage/DailyPolls/DailyPolls";
import Airtime from "../webappPages/ActivitiesPage/PayBills/Airtime/Airtime";
import Bills from "../webappPages/ActivitiesPage/PayBills/Bills";
import AirtimeSummary from "../webappPages/ActivitiesPage/PayBills/Airtime/AirtimeSummary";
import Electricity from "../webappPages/ActivitiesPage/PayBills/Electricity/Electricity";
import ElectricitySummary from "../webappPages/ActivitiesPage/PayBills/Electricity/ElectricitySummary";
import MobileData from "../webappPages/ActivitiesPage/PayBills/MobileData/MobileData";
import MobileDataSummary from "../webappPages/ActivitiesPage/PayBills/MobileData/MobileDataSummary";
import Cable from "../webappPages/ActivitiesPage/PayBills/Cable/Cable";
import ProtectRoute from "../components/PrivateRoute/PrivateRoute";
//import Bills from "../webappPages/ActivitiesPage/PayBills/Bills";

const ActivityLayout = ({ children }) => {
  const tabs = [
    { id: 0, title: "All", path: "" },
    { id: 1, title: "Surveys", path: "/surveys" },
    { id: 2, title: "Bills", path: "/pay-bills" },
    { id: 3, title: "Polls", path: "/daily-polls" },
    { id: 4, title: "Shop", path: "/shop" },
    { id: 5, title: "News", path: "/news" },
  ];

  const { url, path } = useRouteMatch();
  const location = useLocation();

  const renderActivityTabs = () => {
    switch (location.pathname) {
      case "/activities/surveys/start":
        return null;
      case "/activities/pay-bills/bills":
        return null;
      case "/activities/pay-bills/airtime":
        return null;
      case "/activities/pay-bills/airtime/summary":
        return null;
      case "/activities/pay-bills/bills/mobile-data":
        return null;
      case "/activities/pay-bills/bills/electricity":
        return null;
      case "/activities/pay-bills/bills/electricity/summary":
        return null;
      case "/activities/pay-bills/bills/cable":
        return null;
      default:
        return true;
    }
  };

  return (
    <div className="activities--page">
      {location.pathname !== "/activities/surveys/start" ? (
        <ActivitiesHeader />
      ) : null}
      <div className="activities--page-body">
        <div className="activities--page-body-container">
          <div className="activities--page-body-content">
            {renderActivityTabs() && (
              <div className="content--header">
                {tabs.map((tab) => (
                  <NavLink
                    exact
                    to={`${url}${tab.path}`}
                    activeClassName={"hyperlink--active"}
                    className={`btn--activities-header hyperlink`}
                    key={tab.id}
                  >
                    {tab.title}
                  </NavLink>
                ))}
              </div>
            )}
            <div className="content--body">
              <Route exact path={`${path}`} component={Activities} />
              <ProtectRoute
                path={`${path}/surveys`}
                component={SurveysLayout}
              />
              <Route exact path={`${path}/pay-bills`} component={PayBills} />
              
              <ProtectRoute
                exact
                path={`${path}/pay-bills/airtime`}
                component={Airtime}
              />

              <ProtectRoute
                exact
                path={`${path}/pay-bills/airtime/summary`}
                component={AirtimeSummary}
              />
              <Route exact path={`${path}/pay-bills/bills`} component={Bills} />
              <ProtectRoute
                exact
                path={`${path}/pay-bills/bills/electricity`}
                component={Electricity}
              />
              <ProtectRoute
                exact
                path={`${path}/pay-bills/bills/electricity/summary`}
                component={ElectricitySummary}
              />
              <ProtectRoute
                exact
                path={`${path}/pay-bills/bills/mobile-data`}
                component={MobileData}
              />
              <ProtectRoute
                exact
                path={`${path}/pay-bills/bills/mobile-data/summary`}
                component={MobileDataSummary}
              />
              <ProtectRoute
                exact
                path={`${path}/pay-bills/bills/cable`}
                component={Cable}
              />
              <Route path={`${path}/news`} component={News} />
              <Route exact path={`${path}/shop`} component={Shop} />
              <ProtectRoute
                exact
                path={`${path}/daily-polls`}
                component={DailyPolls}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLayout;