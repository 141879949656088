import { Link } from 'react-router-dom';

import survey_icon from '../assets/icons/take-survey-icon.svg';
import airtime_icon from '../assets/icons/airtime-bills-icon.svg';
import shop_icon from '../assets/icons/shop-icon.svg';
import explore_icon from '../assets/icons/explore-icon.svg';
import transfer_icon from '../assets/icons/transfer-icon.svg';

import user_one from '../assets/images/image-1.png';
import user_two from '../assets/images/image-2.png';
import bubble_img from '../assets/images/congratulations.png';

import Button from './Button';

const QuickActions = () => {
    return (
        <div className="quick--actions">
            <h2 className="quick--actions-heading">Quick actions</h2>
            <div className="actions--container">
                <div className="actions">
                    <Link to="/activities/surveys" className="action">
                        <div className="action--header">
                            <div className="action--icon">
                                <img src={survey_icon} alt="Survey" />
                            </div>
                            <div className="action--points">
                                +10k{' '}
                                <span className="action--points--label ml-1">
                                    Points
                                </span>
                            </div>
                        </div>
                        <div className="action--body">
                            <h2 className="action--title">Take a survey</h2>
                            <p className="action--description">
                                Complete surveys and unlock unlimited rewards
                                for yourself.
                            </p>
                        </div>
                    </Link>
                    <Link to="/activities/pay-bills/bills" className="action">
                        <div className="action--header">
                            <div className="action--icon">
                                <img
                                    src={airtime_icon}
                                    alt="Airtime and Bills"
                                />
                            </div>
                            <div className="action--points">
                                +20k{' '}
                                <span className="action--points--label ml-1">
                                    Points
                                </span>
                            </div>
                        </div>
                        <div className="action--body">
                            <h2 className="action--title">Airtime and Bills</h2>
                            <p className="action--description">
                                Get rewarded when you pay your bills or buy
                                airtime
                            </p>
                        </div>
                    </Link>
                    <Link to="/activities/shop" className="action">
                        <div className="action--header">
                            <div className="action--icon">
                                <img src={shop_icon} alt="Shop" />
                            </div>
                            <div className="action--points">
                                +10k{' '}
                                <span className="action--points--label ml-1">
                                    Points
                                </span>
                            </div>
                        </div>
                        <div className="action--body">
                            <h2 className="action--title">Shop</h2>
                            <p className="action--description">
                                Shop at the brands you love, and automatically
                                get rewarded.
                            </p>
                        </div>
                    </Link>
                    <Link to="/fund-transfer" className="action">
                        <div className="action--header">
                            <div className="action--icon">
                                <img src={transfer_icon} alt="Explore" />
                            </div>
                            <div className="action--points">
                                +90k{' '}
                                <span className="action--points--label ml-1">
                                    Points
                                </span>
                            </div>
                        </div>
                        <div className="action--body">
                            <h2 className="action--title">Transfer Funds</h2>
                            <p className="action--description">
                                Discover all the ways to earn unlimited rewards
                                on Enlumi.
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="raffle">
                    <div className="raffle--header">
                        <img src={bubble_img} alt="winner bubbles" />
                        <h2 className="raffle--title">
                            Congratulations to our latest raffle winners!
                        </h2>
                    </div>
                    <div className="raffle--body">
                        <div className="raffle--winners">
                            <div className="raffle--winner">
                                <img src={user_one} alt="winner" />
                            </div>
                            <div className="raffle--winner">
                                <img src={user_two} alt="winner" />
                            </div>
                        </div>
                        <Button className="btn btn--primary raffle--btn">
                            Coming soon!
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickActions;
