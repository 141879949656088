import { Route, Redirect } from "react-router-dom";
import ProtectRouteLogic from "./PrivateRouteLogic";
import useShutDown from "../../hooks/useShutDown";
import ShutDownModal from "../ShutDownModal";

const ProtectRoute = ({ component: Component, ...rest }) => {
  const { pathname, emailVerified } = ProtectRouteLogic();
  const {isShutDownDate} = useShutDown()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isShutDownDate) {
          return <ShutDownModal closeModal={()=> {}}/>
        } else if (!localStorage.getItem("refresh_token")) {
          localStorage.setItem('next_route', pathname);
          return <Redirect to="/login" />;
        } else if (emailVerified === false) {
          localStorage.setItem("next_route", pathname);
          return <Redirect to="/verify" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectRoute;
