import { Link } from "react-router-dom";

import secondary_logo from "../assets/websiteImages/secondary-logo.svg";
import primary_logo from "../assets/websiteImages/primary-logo.svg";
import FormHeader from "../components/FormHeader";

const LoginLayout = ({ children, content1, content2, content3, title, description, linkedPage, showHeadings }) => {
    return (
        <div className="login-layout">
            <div className="login-layout__left">
                <Link to="/">
                    <img src={secondary_logo} style={{ width: '120px' }} alt="enlumi logo" />
                </Link>
                <h1 className="white--text">{content1}</h1>
                <p className="white--text mt-4">{content2}<br /><br />{content3}</p>
            </div>
            <div className="login-layout__right">
                <Link to="/" className="login-layout__right__logo">
                    <img src={primary_logo} style={{ width: '70px' }} alt="enlumi logo" />
                </Link>
                <div className="login-layout__right__nav d-flex justify-content-end">
                    {linkedPage}
                </div>
                {showHeadings ? (
                    <FormHeader
                        title={title}
                        subtitle={description}
                    />
                ) : null}
                <div className="login-layout__right__content">
                    {children}
                </div>
                <div className="login-layout__right__nav--mobile text-center mt-4">
                    {linkedPage}
                </div>
            </div>
        </div>
    );
};

export default LoginLayout;
