import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import NewsService from '../../../services/news.service';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    EmailShareButton,
} from 'react-share';

import AddComment from './AddComment';
import Comments from './Comments';
import LumiAd from '../../../components/LumiAd/LumiAd';

import comments_icon from '../../../assets/icons/comment-icon.svg';
import share_icon from '../../../assets/icons/share-icon.svg';
import arrow_left from '../../../assets/icons/arrow-left.svg';

function ArticlePage() {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [isLiked, setIslLiked] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loadingMoreComments, setLoadingMoreComments] = useState(false);
    const [commentsFInished, setCommentsFinished] = useState(false);
    // const [articleError, setArticleError] = useState('');
    // const [commentsError, setCommentsError] = useState('');

    const { data } = useSelector((state) => state.dashboard);
    const { username } = data !== null && data;
    const user = username ? username : 'Anonymous';

    const history = useHistory();

    const articleUrl = JSON.stringify(window.location.href);
    const ACCESS_TOKEN = process.env.REACT_APP_BLOG_ACCESS_TOKEN;

    const getArticle = async () => {
        try {
            const response = await NewsService.fetchArticle(articleId);
            if (response) {
                setLoading(false);
                setArticle(response.data.data);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getComments = async () => {
        try {
            const response = await NewsService.fetchComments(articleId, page);
            if (page > 1) setLoadingMoreComments(true);
            if (response) {
                if (response.data.data.length === 0) {
                    setCommentsFinished(true);
                }
                setLoading(false);
                setLoadingMoreComments(false);
                setCommentsFinished(false);
                setComments(response.data.data);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getArticleLikesCount = async () => {
        try {
            const res = await NewsService.fetchArticleLikesCount(articleId);
            if (res) {
                setLoading(false);
                setLikesCount(res.data.data[0].count.id);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getArticleCommentsCount = async () => {
        try {
            const res = await NewsService.fetchArticleCommentsCount(articleId);
            if (res) {
                setLoading(false);
                setCommentsCount(res.data.data[0].count.id);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleLikeArticle = () => {
        NewsService.likeArticle(articleId, user)
            .then((res) => {
                setIslLiked(true);
                getArticleLikesCount();
            })
            .catch((error) => {
                setIslLiked(false);
            });
    };

    // const handleFetchMoreComments = () => {
    //     setPage((prevPage) => prevPage + 1);
    // };

    useEffect(() => {
        getArticle();
        getComments();
        getArticleLikesCount();
        getArticleCommentsCount();
    }, [articleId]);

    function renderArticle(article) {
        if (loading && article === null && comments.length <= 0) {
            return (
                <div className="articles__loader">
                    <FontAwesomeIcon
                        icon={'spinner'}
                        size="lg"
                        className="fa-spin"
                    />
                </div>
            );
        }

        return (
            <div className="article__page">
                <div onClick={() => history.goBack()} className="back__button">
                    <img src={arrow_left} alt="back" />
                    <h3>Back</h3>
                </div>
                <h3 className="article__title">{article?.title}</h3>
                <div className="article__details">
                    <span className="category">{article?.category}</span>
                    <p>
                        {new Date(article?.date_created).toDateString()}{' '}
                        {new Date(article?.date_created).toLocaleTimeString()}
                    </p>
                </div>
                <div className="share__article">
                    <p>Share this story</p>
                    <div className="share__article__button__group">
                        <FacebookShareButton url={'articleUrl'}>
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={articleUrl}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton url={articleUrl}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                    </div>
                </div>
                <img
                    className="article__page__img"
                    src={`https://enlumi-blog.herokuapp.com/assets/${article?.featured_image}?access_token=${ACCESS_TOKEN}`}
                    alt="article"
                />
                <div
                    className="article__body"
                    dangerouslySetInnerHTML={{ __html: article?.body }}
                />
                <div className="article__stats">
                    <div onClick={handleLikeArticle} className="likes__count">
                        <span className="article__icon like__icon">
                            <FontAwesomeIcon
                                className={'article__likes__icon'}
                                icon={[`${isLiked ? 'fas' : 'far'}`, 'heart']}
                            />
                        </span>
                        <span className="count__title">
                            {likesCount <= 0
                                ? article?.likes.length
                                : likesCount}{' '}
                            Likes
                        </span>
                    </div>
                    <div className="comments__count">
                        <span className="article__icon">
                            <img src={comments_icon} alt="comments" />
                        </span>
                        <span className="count__title">
                            {commentsCount <= 0
                                ? article?.comments.length
                                : commentsCount}{' '}
                            Comments
                        </span>
                    </div>

                    <EmailShareButton
                        className="share__story__btn__wrapper"
                        url={articleUrl}
                    >
                        <div className="share__story__btn">
                            <span className="share__story__icon">
                                <img src={share_icon} alt="share" />
                            </span>
                            <span className="share__story__title">
                                Share this story
                            </span>
                        </div>
                    </EmailShareButton>
                </div>
                <div className="divider"></div>
                <div className="comments__link">
                    <div className="commenst__link__title">
                        {commentsCount <= 0
                            ? article?.comments.length
                            : commentsCount}{' '}
                        Comments
                    </div>
                </div>
                <div className="comments__disclaimer">
                    <h4>Disclaimer</h4>
                    <p>
                        Comments expressed here do not reflect the opinions of
                        getlumi.io or any employee thereof.
                    </p>
                </div>
                <LumiAd slot={'Blog'}>
                    <LumiAd.Media>
                        {({ media, advert, handleClickAd, user_id }) => (
                            <Link
                              className='article__page__ad'
                                to={{ pathname: advert?.url }}
                                target="_blank"
                            >
                                <img
                                    onClick={() =>
                                        handleClickAd({
                                            media_id: media.id,
                                            advert_id: advert.id,
                                            user_id,
                                        })
                                    }
                                    src={media?.url}
                                    alt={media?.file_name}
                                />
                            </Link>
                        )}
                    </LumiAd.Media>
                </LumiAd>
                <AddComment
                    articleId={articleId}
                    getComments={getComments}
                    getArticleCommentsCount={getArticleCommentsCount}
                />
                <Comments loading={loading} comments={comments} />
                <div className="divider"></div>
            </div>
        );
    }

    return renderArticle(article);
}

export default ArticlePage;
