import useModal from '../../hooks/useModal';
import ModalClose from '../ModalClose';
import success_image from '../../assets/images/congratulations.png';
import lumi_image from '../../assets/images/lumi-rewards.svg';

import { useHistory } from 'react-router-dom';

import './positive-action-modals-styles.scss';

const FirstSurvey = ({ isFirstTime, username = '...' }) => {
    const [modalOpen, setModalOpen, toggleModal, CustomModal] =
        useModal(isFirstTime);

    const history = useHistory();

    return (
        <CustomModal
            isActive={modalOpen}
            closeModal={() => {
                setModalOpen(false);
                localStorage.removeItem('isFirstTimePurchase');
            }}
            width="480px"
        >
            <div className="modal__container__content">
                {/* <div className="modal__container__content__default-header mb-3">
                    <ModalClose
                        onClick={() => {
                            setModalOpen(false);
                            localStorage.removeItem('isFirstTimePurchase');
                        }}
                    />
                </div> */}
                <div className="positive__action__modal">
                    <div className="positive__action__modal-header">
                        <h3>Lumi Rewards</h3>
                    </div>
                    <div className="positive__action__modal-body">
                        <h3>Congratulations {username} 🥰</h3>
                        <p>
                            You have just been rewarded with{' '}
                            <span style={{ color: '#766df4', fontWeight: 700 }}>
                                375 bonus points
                            </span>{' '}
                            for completing your first survey on Lumi.
                            <br />
                            You can also earn additional{' '}
                            <span style={{ color: '#766df4', fontWeight: 700 }}>
                                18,750
                            </span>{' '}
                            bonus points monthly when{' '}
                            <ul>
                                <li>You become a gold member on Lumi</li>
                                <li>
                                    Spend a minimum of N500 using your Lumi
                                    Naira wallet
                                </li>
                                <li>
                                    Complete at least 12 surveys monthly on Lumi
                                </li>
                            </ul>
                        </p>
                        <button
                            onClick={() => {
                                setModalOpen(false);
                                localStorage.removeItem('isFirstTimePurchase');
                                history.push('/activities/pay-bills/bills');
                            }}
                        >
                            Earn More
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default FirstSurvey;
