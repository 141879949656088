import { useEffect, useState } from "react";
import { getBankUssdCodes, getWalletInfo, fetchLatestBalance } from "../../../../services/bank-transfer-service";

const UssdLogic = () => {
    const [amount, setAmount] = useState('')
    const [bank, setBank] = useState('')
    const [bankList, setBankList] = useState([])
    const [isFetchingBankUssd, setIsFetchingBankUssd] = useState(false)
    const [showTransferButton, setShowTransferButton] = useState(false)
    const [ussdCodes, setUssdCodes] = useState("");
    const [walletInfo, setWalletInfo] = useState({});
    const [counter, setCounter] = useState(0);
    const [isCountDown, setIsCountDown] = useState(false);
    const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);

    useEffect(() => {
        fetchBankUssdCode()
    }, [])

    useEffect(() => {
        let interval = setInterval(() => {
            if (counter <= 0) {
                clearInterval(interval)
                setIsCountDown(false)
                return
            }
            setCounter(prevState => prevState - 1)
        }, 1000)

        return () => clearInterval(interval)
    })

    const handleFetchWalletInfo = () => {
        getWalletInfo()
            .then((response) => {
                setIsFetchingBankUssd(false)
                setWalletInfo(response.data?.data);
            })
            .catch(() => {
                setIsFetchingBankUssd(false)
            });
    };

    const fetchBankUssdCode = () => {
        setIsFetchingBankUssd(true);
        getBankUssdCodes().then(response => {
            setBankList(response.data.data)
            setIsFetchingBankUssd(false)
            handleFetchWalletInfo()
        })
            .catch(() => { setIsFetchingBankUssd(false) })
    }

    const updateBalance = () => {
        setIsRefreshingBalance(true)
        fetchLatestBalance({})
            .then(() => {
                window.location.reload()
            })
            .catch(() => {
                setIsRefreshingBalance(false);
            });
    }

    const setBankUssdCode = (amountEntered, bankCode) => {
        if (amountEntered === '' || bankCode === '') return setShowTransferButton(false)
        setShowTransferButton(true)
        const selectedBank = bankList.find((item) => item.bank_code === bankCode);
        let ussdFormat = selectedBank.transfer_string
        ussdFormat = ussdFormat.replace("account_number", walletInfo.account_number);
        ussdFormat = ussdFormat.replace("amount", amountEntered);
        setUssdCodes(ussdFormat)
        setCounter(120);
        setIsCountDown(true)
    }

    return {
        setBank,
        setAmount,
        setBankList,
        bank,
        amount,
        bankList,
        fetchBankUssdCode,
        isFetchingBankUssd,
        showTransferButton,
        setBankUssdCode,
        ussdCodes,
        counter,
        isCountDown,
        updateBalance,
        isRefreshingBalance
    }
}

export default UssdLogic