import { useEffect, useCallback, useReducer } from 'react';
import { getWalletInfo } from '../../../services/bank-transfer-service';

const reducer = (state, action) => {
    switch (action.type) {
        case 'idle':
            return { ...state, status: 'idle' };
        case 'pending':
            return { ...state, status: 'pending' };
        case 'resolved':
            return { ...state, status: 'resolved', data: action.data };
        case 'rejected':
            return { ...state, status: 'rejected', error: action.error };
        default:
            return state;
    }
};

const useNairaWallet = () => {
    const [state, dispatch] = useReducer(reducer, {
        status: 'idle',
        data: null,
        error: null,
    });

    const handleFetchWalletInfo = useCallback(async () => {
        dispatch({ type: 'pending' });
        try {
            const response = await getWalletInfo();
            if (response.status === 200) {
                dispatch({ type: 'resolved', data: response.data?.data });
            }
        } catch (error) {
            dispatch({
                type: 'rejected',
                error: error?.response?.data?.detail,
            });
        }
    }, []);

    useEffect(() => {
        handleFetchWalletInfo();
    }, [handleFetchWalletInfo]);

    return { ...state };
};

export default useNairaWallet;
