import "./Ussd.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { primaryComponents } from "../../../primaryComponents";
import UssdLogic from "./UssdLogic";
import UssdValidation from "./UssdValidation";
import { Controller } from "react-hook-form";

const Ussd = ({ setIsUssdSection }) => {
  const {
    setBank,
    setAmount,
    setBankUssdCode,
    bank,
    amount,
    bankList,
    isFetchingBankUssd,
    showTransferButton,
    ussdCodes,
    isCountDown,
    counter,
    updateBalance,
    isRefreshingBalance,
  } = UssdLogic();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = UssdValidation(bank, amount);

  return (
    <div className="ussd-modal-topup">
      <div className="ussd-modal-topup__top-bar mb-3">
        <div
          className="ussd-modal-topup__top-bar-icon"
          onClick={() => setIsUssdSection(false)}
        >
          <FontAwesomeIcon icon="angle-left" style={{ fontSize: "20px" }} />
        </div>
        <h5 className="ussd-modal-topup__top-bar-title">USSD</h5>
      </div>

      <h5>How much would you want to fund your Lumi wallet with?</h5>

      {!isFetchingBankUssd ? (
        <div>
          <Controller
            name="amount"
            defaultValue={amount}
            render={({ field: { value, onChange } }) => (
              <primaryComponents.InputField
                label="Amount"
                isRequired={true}
                placeholder="₦ Enter Amount"
                name="amount"
                type="number"
                pattern="[0-9]*"
                inputMode="numeric"
                value={value}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setAmount(value);
                  if (bank === "") return;
                  handleSubmit(() => setBankUssdCode(value, bank))();
                }}
                errorMessage={errors.amount}
                register={register}
              />
            )}
            control={control}
          />

          <Controller
            name="bank"
            defaultValue={""}
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.SelectField
                label="Bank"
                isRequired={true}
                value={bank === "" && "Select Bank"}
                options={bankList}
                nameKey="bank_name"
                idKey="bank_code"
                onChange={(value) => {
                  onChange(value);
                  setBank(value);
                  if (amount === "") return;
                  handleSubmit(() => setBankUssdCode(amount, value))();
                }}
                errorMessage={errors.bank}
              />
            )}
          />
        </div>
      ) : (
        <div className="text-center mt-4">
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            className="fa-spin purple--text mr-2"
            style={{ fontSize: "20px" }}
          />
        </div>
      )}

      {ussdCodes !== "" && (
        <>
          <h5 className="mt-2 mb-0 text-center font-weight-nomral">
            {ussdCodes}
          </h5>
          <a
            className="dial-btn"
            href={`tel:${ussdCodes}`}
          >
            Tap here to dial code
          </a>

          <div className="bank-note mt-2">
            <FontAwesomeIcon  icon={["fas", "exclamation-circle"]} className="purple--text mr-3" style={{fontSize: "20px"}}/>

            <div>
              <h5 className="mb-0">USSD Destination Bank</h5>
              <p>Select Kuda Bank as the bank you're funding after dialling the code</p>
            </div>
          </div>
        </>
      )}

      {isCountDown && (
        <h3 className="text-center purple--text mt-3">
          {counter}
        </h3>
      )}

      <div>
        <primaryComponents.Button
          classNames="btn btn--primary cover mt-3"
          onClick={updateBalance}
          isDisabled={!showTransferButton || isCountDown || isRefreshingBalance}
          isLoading={isRefreshingBalance}
          loadingText="Refreshing..."
        >
          I have made the transfer
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default Ussd;
