import { Link } from "react-router-dom";

const UserDetails = ({ medal, totalPoints, accountType }) => {
    return (
        <div className="user--details">
            <div className="user--details-container">
                <div className="user--details-medal">
                    {accountType === "MEMBER" ? null : (
                        <img src={medal} alt="medal" />
                    )}
                </div>
                <div className="user--progress-details">
                    <p className="user--account-type">{accountType}</p>
                    {/* <Link to="/" className="user--points">
                        {totalPoints} Points
                    </Link> */}
                    <Link to="/settings" className="progress">
                        View progress
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
