import ErrorText from './ErrorText';
import React from 'react';

// const PhoneInput = React.forwardRef(
//     (
//         { placeholder, required, register, error, label, onChange, ...rest },
//         ref
//     ) => {
//         const _register = register(label, { required });
//         return (
//             <>
//                 <div className="phone--input">
//                     <input
//                         className="phone--field"
//                         placeholder={placeholder}
//                         {...rest}
//                         {..._register}
//                         onChange={(e) => {
//                             _register.onChange(e);
//                             onChange(e);
//                         }}
//                         ref={ref}
//                     />
//                     <p className="phone--prefix">+234</p>
//                 </div>
//                 {error && <ErrorText message={error.message} />}
//             </>
//         );
//     }
// );

const PhoneInput = ({
    placeholder,
    required,
    register,
    error,
    label,
    ...rest
}) => {
    return (
        <>
            <div className="phone--input">
                <input
                    className="phone--field"
                    placeholder={placeholder}
                    {...register(label, { required })}
                    {...rest}
                />
                <p className="phone--prefix">+234</p>
            </div>
            {error && <ErrorText message={error.message} />}
        </>
    );
};

export default PhoneInput;
