
import InputField from "./InputField/InputField";
import SelectField from "./SelectField/SelectField";
import Button from "./Button/Button";


export const primaryComponents = {
    InputField,
    SelectField,
    Button
}
