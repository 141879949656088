import { FaAngleLeft } from 'react-icons/fa';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    accountVerificationService,
    getBankList,
} from '../../services/verification.service';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Label from '../Label';
import FormGroup from '../FormGroup';
import Form from '../Form';
import Button from '../Button';
import InputField from '../InputField';
// import SelectInput from '../SelectInput';
import Dropdown from '../Dropdown/Dropdown';

const ExistingBankAccountForm = ({ navigation, bankList }) => {
    const schema = yup.object().shape({
        bank: yup.string().required('field is required'),
        account_number: yup
            .string()
            .min(10, 'Invalid account number format')
            .max(10, 'Invalid account number format')
            .required('field is required'),
    });

    const [loading, setLoading] = useState(false);
    const [verificationError, setVerificationError] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({ resolver: yupResolver(schema) });

    const handleVerify = async (data) => {
        setLoading(true);
        try {
            const response = await accountVerificationService(data);
            if (response.status === 200) {
                setLoading(false);
                navigation.go(3);
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                if (
                    error.response.status === 400 ||
                    error.response.status === 422
                ) {
                    const { detail } = error.response.data;
                    setVerificationError(detail);
                }
            }
        }
    };

    const onSubmit = async (data) => {
        const verification_type = 'Nuban';
        const { bank, account_number } = data;
        const payload = {
            verification_type,
            bank,
            identification_number: account_number,
        };
        await handleVerify(payload);
    };

    return (
        <>
            {verificationError && (
                <p className="text-danger my-2 text-center">
                    {verificationError}
                </p>
            )}
            <Form
                className="existing-account__form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormGroup>
                    <Label>Bank*</Label>
                    <Controller
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                value={bankList.find((val) => val.value === value)}
                                onChange={(val) => {
                                    onChange(val.value);
                                }}
                                placeholder="Select Bank"
                                options={bankList}
                                nameKey="name"
                                idKey="id"
                            />
                        )}
                        control={control}
                        defaultValue=""
                        name="bank"
                    />
                    {errors.bank && (
                        <p className="text-danger mt-2">
                            {errors.bank.message}
                        </p>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label>Account Number*</Label>
                    <InputField
                        register={register}
                        required
                        label={'account_number'}
                        type="tel"
                        placeholder="Enter your 10-digit account number"
                        error={errors.account_number}
                    />
                </FormGroup>
                <Button disabled={loading} className="btn btn--primary">
                    {loading ? (
                        <FontAwesomeIcon className="mr-2" icon="spinner" spin />
                    ) : null}
                    {loading ? 'Verifying...' : 'Continue'}
                </Button>
            </Form>
        </>
    );
};

const ExistingBankAccount = ({ navigation }) => {
    const [bankList, setBankList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBankList = async () => {
            setLoading(true);
            try {
                const response = await getBankList();
                if (response.status === 200) {
                    setLoading(false);
                    setBankList(response.data?.data);
                }
            } catch (error) {
                setLoading(false);
            }
        };
        fetchBankList();
    }, []);

    return (
        <div className="existing-account">
            <h3 onClick={() => navigation.go(0)} className="d-flex mb-2">
                <FaAngleLeft className="mr-2" />
                <span>Verify your Bank Account</span>
            </h3>
            <p>
                Use your existing account from your bank to verify your identity
            </p>
            {loading ? (
                <FontAwesomeIcon className="text-center mx-auto my-3 d-block purple--text" icon="spinner" spin />
            ) : (
                <ExistingBankAccountForm bankList={bankList} navigation={navigation} />
            )}
        </div>
    );
};

export default ExistingBankAccount;
