import verify_email_logo from "../../assets/icons/verify-email.svg";
import LoginLayout from "../../Layouts/LoginLayout";
import { Link } from "react-router-dom";
import "./forgot-password.scss";

const ForgotPasswordSuccess = () => {
    return (
        <LoginLayout
            content1="A few clicks away from creating your survey."
            content2="Create your survey in as easy as a few clicks, and conveniently access your results."
            content3="Save time and costs!"
            showHeadings={false}
            linkedPage={(<span className="big-text-size">Remember password? <Link className='big-text-size purple--text ml-1' to='/login'>Log in</Link></span>)}>
            <div className="forgot-password-page-success">
                <img src={verify_email_logo} alt="verify email" />
                <h1 className="mt-4 mb-2">Request Successful</h1>
                <p className="big-text-size">
                    A reset link has been sent to your email. Please check your
                    email
                </p>
            </div>
        </LoginLayout>
    );
};

export default ForgotPasswordSuccess;
