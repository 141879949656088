import './summary.scss';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useModal from '../../../../hooks/useModal';
import useNairaWallet from '../../../../hooks/core/FundTransfer/useNairaWallet';

import { buyElectricity } from '../../../../redux/reducers/electricity.reducer';

import arrow_left from '../../../../assets/icons/arrow-left.svg';
import congratulations_image from '../../../../assets/images/congratulation-image.svg';

import ErrorText from '../../../../components/ErrorText';
import Form from '../../../../components/Form';
import FormGroup from '../../../../components/FormGroup';
import Button from '../../../../components/Button';
import PayBillSuccessModalContent from '../../../../components/PayBillSuccessModalContent';
import ResponseModal from '../../../../components/ResponseModal';
import ModalClose from '../../../../components/ModalClose';
import ElectricityPaymentResponse from './ElectricityPaymentResponse';

const ElectricitySummary = () => {
    const passwordSchema = yup.object().shape({
        pin: yup
            .string()
            .min(6, 'Invalid transaction pin format')
            .max(6, 'Invalid transaction pin format')
            .required('Enter your transaction pin'),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(passwordSchema),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const { electricity, dashboard } = useSelector((state) => state);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccessfull, setIsSuccessfull] = useState(false);
    const [purchasing, setPurchasing] = useState(false);
    const [purchaseDetails, setPurchaseDetails] = useState({});

    const { data } = useNairaWallet();
    const { withdrwalable_balance } = data !== null && data;
    const naira_wallet_balance = parseFloat(withdrwalable_balance) || 0;

    const { enlumi_rewards_wallet_balance } =
        dashboard.data !== null && dashboard.data;

    const { formData, meterSummary } = electricity;
    const { funding_channel, amount, meter_number, meter_type, disco } =
        formData;

    const onSubmit = (data) => {
        setLoading(true);
        setPurchasing(true);
        dispatch(buyElectricity({ ...data, ...formData }))
            .then((response) => {
                setLoading(false);
                setIsSuccessfull(true);
                const { data } = response;
                const { task_status } = data;
                if (task_status === 'PENDING') {
                    setPurchaseDetails(data);
                }

                const reward_message = response?.reward_message;
                if (reward_message) {
                    const purchaseObj = {
                        isFirstArtime: false,
                        isFirstMobileData: false,
                        isNewSignup: false,
                        isFirstCable: false,
                        isFirstPower: true,
                    };
                    localStorage.setItem(
                        'isFirstTimePurchase',
                        JSON.stringify(purchaseObj)
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                setIsSuccessfull(false);
                setPurchasing(false);
                if (error.response) {
                    if (
                        error.response.status === 422 ||
                        error.response.status === 400
                    ) {
                        if (error.response.data) {
                            setErrorMessage(error.response.data.detail);
                            toggleModal();
                        }
                    }
                }
            });
    };

    return (
        <div className="electricity__summary">
            <div
                onClick={() => history.goBack()}
                className="electricity__summary-header"
            >
                <img src={arrow_left} alt="back" />
                <div>
                    <h2 className="electricity__summary-heading">
                        Electricity Bill
                    </h2>
                </div>
            </div>
            <div className="electricity__summary-container">
                <div className="summary__details">
                    <div className="summary__detail">
                        <p className="left">From:</p>
                        <p className="right">
                            <span className="wallet">
                                {funding_channel === 'CASH_WALLET'
                                    ? 'Naira Wallet'
                                    : 'Rewards Wallet'}
                            </span>
                            <span className="balance">
                                Balance: #
                                {funding_channel === 'CASH_WALLET'
                                    ? naira_wallet_balance
                                    : enlumi_rewards_wallet_balance}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Biller:</p>
                        <p className="right">
                            <span className="wallet">{meterSummary.disco}</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Product:</p>
                        <p className="right">
                            <span className="wallet">
                                {meterSummary.disco} {meterSummary.meter_type}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Meter Number:</p>
                        <p className="right">
                            <span className="wallet">{meter_number}</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Receipient:</p>
                        <p className="right">
                            <span className="wallet">
                                {meterSummary.recipient}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Receipient Address:</p>
                        <p className="right">
                            <span className="wallet">
                                {meterSummary.recipient_address}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Convenience Fee:</p>
                        <p className="right">
                            <span className="wallet">
                                #{meterSummary.conveniency_fee}
                            </span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Amount:</p>
                        <p className="right">
                            <span className="wallet">#{amount}</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Total:</p>
                        <p className="right">
                            <span className="wallet">
                                #{Number(amount) + meterSummary.conveniency_fee}
                            </span>
                        </p>
                    </div>
                    {funding_channel !== 'REWARDS_WALLET' && (
                        <div className="summary__detail">
                            <p className="left">Points to be earned:</p>
                            <p className="right">
                                <span className="wallet">
                                    {meterSummary.points}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="summary__form"
                >
                    <FormGroup>
                        <h4 className="form__group-heading">
                            Enter Your Transaction Pin to continue
                        </h4>
                        <input
                            type="password"
                            className="enlumi__password-field"
                            id="enlumi__password"
                            {...register('pin')}
                            inputMode="numeric"
                        />
                        {errors.pin && (
                            <ErrorText message={errors.pin.message} />
                        )}
                    </FormGroup>
                    <Button className="btn btn--primary btn__summary">
                        {loading ? (
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin"
                            />
                        ) : (
                            'Pay Now'
                        )}
                    </Button>
                    <CustomModal
                        isActive={modalOpen}
                        closeModal={() => setModalOpen(false)}
                        width={'25%'}
                    >
                        <div className="modal__container__content">
                            <div className="modal__container__content__default-header mb-3">
                                <ModalClose
                                    onClick={() => setModalOpen(false)}
                                />
                            </div>
                            <ResponseModal
                                status={'fail'}
                                message={errorMessage}
                            />
                        </div>
                    </CustomModal>
                </Form>

                <CustomModal isActive={purchasing}>
                    <div className="modal__container__content">
                        <ElectricityPaymentResponse
                            isSuccessfull={isSuccessfull}
                            token={purchaseDetails.token}
                            points={
                                funding_channel !== 'REWARDS_WALLET' &&
                                meterSummary.points
                            }
                        />
                    </div>
                </CustomModal>
            </div>
        </div>
    );
};

export default ElectricitySummary;