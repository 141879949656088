import axios from "axios";
import httpClient from "../axiosBase/axiosHandler.js";
import { profileSurvey } from "../localdata/profileQuestion.js";

export const fetchSurvey = (page) => {
    const response = httpClient.get(`/survey/?page=${page}`,);
    return response;
};

export const fetchSurveyDetails = (survey_id) => {
    if (survey_id !== "000000") {
        const response = httpClient.get(`/survey/${survey_id}/`,);
        return response;
    } else {
        return new Promise(resolve => {
            resolve(profileSurvey.data);
        })
    }
}

export const submitProfileSurvey = (payload) => {
    return new Promise((resolve, reject) => {
        httpClient.post(`/profile-survey/`, payload,).then((response) => resolve(response))
            .catch((error) => reject(error))
    })
}

export const submitSurveyData = (data, survey_id) => {
    const response = httpClient.post(`/survey/${survey_id}/submit/`, data);
    return response;
}

export const fetchPolls = (page) => {
    const response = httpClient.get(`/poll-surveys/?page=${page}`,);
    return response;
};

export const startQuizRequest = (data) => {
    const response = httpClient.get(`/quiz-record/?survey_id=${data.survey_id}&time=${data.time}`);
    return response;
};

export const checkQualification = () => {
    const response = httpClient.get(`/quiz/qualify`);
    return response;
}

export const previewSurvey = (preview_id) => {
    const response = axios.get(`${process.env.REACT_APP_BUSINESS_APP_BASE_URL}/campaigns-preview/${preview_id}`);
    return response;
}
