import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectRouteLogic = () => {
    const { user } = useSelector((state) => state.auth);
    const emailVerified = user !== null && user.email_verified;
    const { pathname } = useLocation();

    return { emailVerified, pathname }
};

export default ProtectRouteLogic;
