export const profileSurvey = {

    data: {
        data: {
            description: "Let Us Get To Know You 🤗 so we can match you with reward opportunities just for you alone",
            id: "000000",
            is_lead: false,
            name: "Tell us a bit about you",
            max_points: 2500,
            minutes: "12 minutes",
            questions: [
                {
                    campaign: "000000",
                    id: 162,
                    is_required: false,
                    is_screening: false,
                    media_source: "",
                    my_route: "Q1",
                    next_route: "Q0",
                    next_route_name: "",
                    options: null,
                    options_type: "TEXT",
                    range: 10,
                    question: "How good are you with technology on a scale of 0-10? 10 being very good and 0 being not good at all.",
                    type: "LINEAR_SCALE",
                    key: "tech_affinity"
                },
                {
                    campaign: "000000",
                    id: 162,
                    is_required: false,
                    is_screening: false,
                    media_source: "",
                    my_route: "Q2",
                    next_route: "Q0",
                    next_route_name: "",
                    options: null,
                    options_type: "TEXT",
                    min_range: 1,
                    range: 10,
                    "question": "How often do you use social media such as Facebook, Instagram or TikTok on a scale of 0-10? 10 being very often and 0 being not often at all.",
                    type: "LINEAR_SCALE",
                    key: "social_media_use"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q3",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    key: "gender",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Male",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Female",
                        }
                    ],
                    "question": "What is your gender?",
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q0",
                    "next_route": "",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Single",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Married",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Dating",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Divorced",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Engaged",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Separated",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "It's complicated",
                        }
                    ],
                    "question": "What is your relationship status?",
                    key: "relationship_status"
                },
                {
                    "type": "DATE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q5",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    question: "Select your date of birth",
                    key: "dob",
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q6",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Igbo",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Hausa",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yoruba",
                        },
                    ],
                    "question": "What is your ethnic group?",
                    key: "ethnic_group",
                },
                {
                    "type": "CHECKBOXES",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q7",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Igbo",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Hausa",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yoruba",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Fulfulde",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Kanuri",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Ijaw",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Pidgin English",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Tiv",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Ibibio",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Edo",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Arabic",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "French",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "English",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Other",
                        },
                    ],
                    "question": "Which languages do you speak (select all that apply)?",
                    key: "languages"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q8",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Christianity",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Islam",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Other",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Prefer not to say",
                        },
                    ],
                    "question": "Which religion do you practice?",
                    key: "religion"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q9",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Primary school",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Secondary school",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Completed some university",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Graduated from university",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Completed some postgraduate",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Postgraduate degree",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Did not attend school",
                        },
                    ],
                    "question": "What is the highest level of education you have completed?",
                    key: "education_level"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q10",
                    "next_route": "",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Full-time",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Part-time",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Contract or temporary employee",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Self employed",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Stay-at-Home Parent",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Retired",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Student",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Not employed",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Business owner",
                        },
                    ],
                    "question": "Which of these best describes your employment status?",
                    key: "employment_status"
                },
                {
                    "type": "DROPDOWN",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q11",
                    "next_route": "",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Accounting"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Advertising"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Agriculture"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Automotive"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Aviation"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Banking/Financial"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Bio-Tech"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Brokerage"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Carpenting"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Chemicals"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Communications"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Computer Hardware"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Computer Reseller"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Computer Software"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Construction"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Consulting"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Consumer Electronics"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Consumer Packaged Goods"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Education"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Energy (Utility, Oil & Gas)"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Engineering"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Environmental Services"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Fashion"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Food/Beverage"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Government (Public Sector)"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Healthcare"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Hospitality (Tourism)"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Information Technology"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Insurance"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Internet"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Legal"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Manufacturing"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Market Research"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Marketing"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Media (Entertainment)"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Military"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Non Profit"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Personal Services"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Restaurant"
                        }
                        , {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null, value: "Social Services"
                        }
                    ],
                    "question": "Which of the following best describes the industry your work is in?",
                    key: "work_industry"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q12",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No",
                        },
                    ],
                    "question": "Are you registered to vote?",
                    key: "registered_to_vote"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q13",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "1 person",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "2 People",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "3 People",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "4 People",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "5 People",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "More than 5 people",
                        },
                    ],
                    "question": "How many people live in your household (including yourself)?",
                    key: "household_size"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q14",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Less than 100,000 NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "100,000 NGN - 299,000 NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "300,000 NGN - 499,000 NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "500,000 NGN - 699,000 NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "700,000 NGN - 999,000 NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "1 million NGN - 5 million NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "5 million NGN - 10 million NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "10 million NGN - 20 million NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "20 million NGN - 30 million NGN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "More than 30 million NGN",
                        }
                    ],
                    "question": "What is your household's total annual income?",
                    key: "household_annual_income"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q15",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Live with family/relatives",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Renting an apartment/house",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Homeowner",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Others",
                        },
                    ],
                    "question": "What is your housing situation?",
                    key: "housing_situation"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q16",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No",
                        },
                    ],
                    "question": "Are you a parent?",
                    key: "is_parent"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q17",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "None",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "One",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Two",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Three",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Four",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "More than four",
                        },
                    ],
                    question: "How many children aged 18 or under live in your household?",
                    key: "children_under_eighteen"
                },
                {
                    "type": "CHECKBOXES",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q18",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Weight lifting",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Jogging",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Cardio",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yoga",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Walking",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Dance",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "I do not exercise",
                        },
                    ],
                    question: "What is your normal exercise routine? (select all that apply)",
                    key: "excercise_routine"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q19",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Share decisions equally",
                        },
                    ],
                    "question": "Do you make most of the daily purchasing decisions in your household?",
                    key: "purchasing_decision_maker"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q20",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "All of it",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Almost all of it",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "About half of it",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Only a small part of it",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "None of it",
                        },
                    ],
                    question: "How much of your household's grocery shopping do you personally do?",
                    key: "grocercy_responsibility"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q21",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes I smoke",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes I smoke every once in a while",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No I don't smoke",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No I used to smoke but I quit",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Prefer not to say",
                        },
                    ],
                    "question": "Do you smoke?",
                    key: "smoking"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q22",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes I drink",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Yes I drink every once in a while",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No I dont drink",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "No I used to drink but I quit",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Prefer not to say",
                        },
                    ],
                    "question": "Do you drink alcohol?",
                    key: "drinking"
                },
                // {
                //     "type": "MULTIPLE_CHOICE",
                //     "campaign": "0000000",
                //     "id": 143,
                //     "is_required": false,
                //     "is_screening": false,
                //     "media_source": "",
                //     "my_route": "Q23",
                //     "next_route": "Q0",
                //     "next_route_name": "",
                //     "options_type": "TEXT",
                //     "options": [
                //         {
                //             "image": "",
                //             "next_route": "Q0",
                //             "next_route_name": null,
                //             "value": "50 - 70years",
                //         },
                //     ],
                //     question: "What age group do you fall in",
                //     key: "age_group"
                // },
                {
                    "type": "CHECKBOXES",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q23",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "MTN",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Globacom",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Airtel",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "9Mobile",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "Others",
                        }
                    ],
                    question: "What mobile network do you use ? (select all that apply)",
                    key: "mobile_network"
                },
                {
                    "type": "MULTIPLE_CHOICE",
                    "campaign": "0000000",
                    "id": 143,
                    "is_required": false,
                    "is_screening": false,
                    "media_source": "",
                    "my_route": "Q24",
                    "next_route": "Q0",
                    "next_route_name": "",
                    "options_type": "TEXT",
                    "options": [
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "stop",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "play",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "carry last!",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "say hello",
                        },
                        {
                            "image": "",
                            "next_route": "Q0",
                            "next_route_name": null,
                            "value": "care",
                        },
                    ],
                    "question": "Finish this statement: Naija no dey",
                    key: "comic_relief"
                }
            ],
            'thankyoupages': [
                {
                    'image_url': 'static',
                    'headline': 'Profile Survey',
                    'description': 'Thanks for taking the profile survey',
                    'cta_url': '/activities',
                    'cta_text': ' EARN MORE REWARDS'
                }
            ]
        }
    }
}