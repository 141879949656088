import { forwardRef, useEffect, useState } from "react";

import check_mark from "../assets/icons/check-mark.svg";

// const CheckBox = ({ name, id, value, onChange, defaultChecked, ...rest }) => {
//     const [checked, setChecked] = useState(defaultChecked);

//     useEffect(() => {
//         if (onChange) {
//             onChange(checked);
//         }
//     }, [checked, onChange]);

//     return (
//         <label
//             onClick={() => setChecked(!checked)}
//             className="custom--checkbox"
//             htmlFor={id}
//         >
//             <input
//                 className="custom--checkbox-input"
//                 type="checkbox"
//                 name={name}
//                 value={value}
//                 checked={checked}
//                 onChange={(e) => {
//                     setChecked(e.target.checked);
//                 }}
//                 {...rest}
//                 id={id}
//             />
//             <img
//                 src={check_mark}
//                 alt="check mark icon"
//                 className="checkbox--icon"
//             />
//         </label>
//     );
// };

const CheckBox = forwardRef(
    (
        { label, name, value, onChange, defaultChecked, id, ...rest },
        forwardedRef
    ) => {
        const [checked, setChecked] = useState(defaultChecked);

        useEffect(() => {
            if (onChange) {
                onChange(checked);
            }
        }, [checked, onChange]);

        return (
            <label
                className="custom--checkbox"
                htmlFor={id}
            >
                <input
                    className="custom--checkbox-input"
                    type="checkbox"
                    ref={forwardedRef}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    {...rest}
                    id={id}
                />
                <img
                    src={check_mark}
                    alt="check mark icon"
                    className="checkbox--icon"
                />
            </label>
        );
    }
);

// const CheckBox = ({ id, ...others }) => {

//     return (
//         <label className="custom--checkbox" htmlFor={id}>
//             <input
//                 className="custom--checkbox-input"
//                 type="checkbox"
//                 {...others}
//                 id={id}
//             />
//             <img
//                 src={check_mark}
//                 alt="check mark icon"
//                 className="checkbox--icon"
//             />
//         </label>
//     );
// };

export default CheckBox;
