import "./transaction-pin.scss";
import dialog_img from "../../../assets/icons/celebration-confetti.svg";
import { useStep } from "react-hooks-helper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Button from "../../Button";
import NewPin from "./NewPin";
import ConfirmPin from "./ConfirmPin";
import { useState } from "react";
import { setNewTransactionPin } from "../../../services/transaction-pin.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserDetails } from "../../../redux/reducers/auth.reducer";
import { getDashboard } from "../../../redux/reducers/dashboard.reducer";
import { useDispatch } from "react-redux";

const TransactionPin = ({ setPinModalOpen }) => {
  const { index, navigation } = useStep({ steps: 3, initialStep: 0 });
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    new_pin: Yup.string().required("New Pin is required"),
    confirm_pin: Yup.string().required("Confirm Pin is required"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      new_pin: "",
      confirm_pin: "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = async (data) => {
    if (data.confirm_pin !== data.new_pin)
      return setErrorMessage("New and confirm pin do not match");

    setLoading(true);
    setNewTransactionPin(data)
      .then(() => {
        setLoading(false);
        fetchUserData();
        navigation.next();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response === undefined) return;
        if (error.response.status === 400)
          return setErrorMessage(error.response.data.detail);
      });
  };

  const fetchUserData = () => {
    dispatch(getUserDetails());
    dispatch(getDashboard());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="transaction-pin-modal">
      {index !== 2 && <h3>Create Transaction Pin</h3>}
      <p className="text-center text-danger my-2">{errorMessage}</p>

      {index === 0 && <NewPin error={errors.new_pin} control={control} />}

      {index === 1 && (
        <ConfirmPin error={errors.confirm_pin} control={control} />
      )}

      {index === 0 && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            navigation.next();
          }}
          className="btn btn--primary"
        >
          Next
        </Button>
      )}

      {index === 1 && (
        <Button disabled={loading} type={"submit"} className="btn btn--primary">
          {loading ? (
            <>
              <FontAwesomeIcon
                className="mr-2"
                icon={["fas", "spinner"]}
                spin
              />
              <span>Creating Pin...</span>
            </>
          ) : (
            "Create Pin"
          )}
        </Button>
      )}

      {index === 2 && (
        <div className="change-pin__success-dialog" style={{ paddingBottom: "20px" }}>
          <img src={dialog_img} alt="" />
          <div
            className="change-pin__success-dialog__content"
          >
            <h3>Changes saved</h3>
            <p className="my-3">
              You can now make use of your new <br /> PIN for all transactions
            </p>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setPinModalOpen(false);
              }}
              className="btn btn--primary"
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

export default TransactionPin;
