import arrow_left from "../../../../assets/icons/arrow-left.svg";

import "./summary.scss";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useModal from "../../../../hooks/useModal";

import Form from "../../../../components/Form";
import FormGroup from "../../../../components/FormGroup";
import ErrorText from "../../../../components/ErrorText";
import PayBillSuccessModalContent from "../../../../components/PayBillSuccessModalContent";
import Button from "../../../../components/Button";

const MobileDataSummary = () => {
    const electricitySchema = yup.object().shape({
        password: yup.string().required("Enter your enlumi password"),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(electricitySchema),
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const { dashboard } = useSelector((state) => state);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [loading, setLoading] = useState(false);

    const { cash_wallet_balance, enlumi_rewards_wallet_balance } =
        dashboard.data !== null && dashboard.data;

    return (
        <div className="mobile__data-summary">
            <div
                onClick={() => history.goBack()}
                className="mobile__data-summary-header"
            >
                <img src={arrow_left} alt="back" />
                <div>
                    <h2 className="mobile__data-summary-heading">
                        Mobile Top-up
                    </h2>
                </div>
            </div>

            <div className="mobile__data-summary-container">
                <div className="summary__details">
                    <div className="summary__detail">
                        <p className="left">From:</p>
                        <p className="righ">
                            <span className="wallet">Cash Wallet</span>
                            <span className="balance">Balance: #2000</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Biller:</p>
                        <p className="right">
                            <span className="wallet">MTN</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Product:</p>
                        <p className="right">
                            <span className="wallet">MTN VTU</span>
                        </p>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Phone Number:</p>
                        <div className="right">
                            <span className="wallet">+234805544780</span>
                        </div>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Amount:</p>
                        <div className="right">
                            <span className="wallet">#5,000.00</span>
                        </div>
                    </div>
                    <div className="summary__detail">
                        <p className="left">Points to earn:</p>
                        <div className="right">
                            <span className="wallet">750</span>
                        </div>
                    </div>
                </div>
                <Form className="summary__form">
                    <FormGroup>
                        <h4 className="form__group-heading">
                            Enter Enlumi password to continue
                        </h4>
                        <input
                            type="password"
                            className="enlumi__password-field"
                            id="enlumi__password"
                            {...register("password")}
                        />
                        {errors.password && (
                            <ErrorText message={errors.password.message} />
                        )}
                    </FormGroup>

                    <Button className="btn btn--primary btn__summary">
                        {loading ? (
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin"
                            />
                        ) : (
                            "Pay Now"
                        )}
                    </Button>
                </Form>
            </div>
            <CustomModal
                isActive={modalOpen}
                handleClose={() => setModalOpen(false)}
                width={"25%"}
            >
                <PayBillSuccessModalContent
                    // points={airtimeForm.points_to_earn}
                    toggleModal={toggleModal}
                />
            </CustomModal>
        </div>
    );
};

export default MobileDataSummary;
