import { useEffect, useState } from 'react';
import NewsService from '../../../services/news.service';
import Article from './Article';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import LumiAd from '../../../components/LumiAd/LumiAd';

function Articles() {
    const [page, setPage] = useState(1);
    const [articles, setArticles] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [articlesFinished, setArticlesFinished] = useState(false);

    const getArticles = async () => {
        if (page > 1) setLoadingMore(true);
        try {
            const res = await NewsService.fetchArticles(page);
            if (res) {
                setLoading(false);
                setLoadingMore(false);

                const _articles = res.data.data;
                if (_articles.length === 0) {
                    setArticlesFinished(true);
                }

                setArticles((prevArticles) => [...prevArticles, ..._articles]);
            }
        } catch (error) {
            setLoading(false);
            setLoadingMore(false);
            setArticlesFinished(false);
        }
    };

    const handleFetchMoreArticles = () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        getArticles();
    }, [page]);

    function renderArticles(articles) {
        if (loading) {
            return (
                <div className="articles__loader">
                    <FontAwesomeIcon
                        icon="spinner"
                        size="lg"
                        className="fa-spin articles__spinner"
                    />
                </div>
            );
        }

        const _articles = [...articles];
        const articlesList = _articles.map((article, index) => {
            return (
                <Article
                    key={article.id}
                    articleId={article.id}
                    published={article.date_created}
                    articleTitle={article.title}
                    articleImage={article.featured_image}
                    image={article.image}
                    comments={article.comments}
                    likes={article.likes}
                />
            );
        });

        return articlesList;
    }

    const adItem = (
        <LumiAd slot={'News Feed'}>
            <LumiAd.Media>
                {({ media, advert, handleClickAd, user_id }) => (
                    <Link
                        className="news__article news__article__ad"
                        to={{ pathname: advert?.url }}
                        target="_blank"
                    >
                        <img
                            onClick={() =>
                                handleClickAd({
                                    advert_id: advert.id,
                                    media_id: media.id,
                                    user_id,
                                })
                            }
                            src={media?.url}
                            alt={media?.file_name}
                        />
                    </Link>
                )}
            </LumiAd.Media>
        </LumiAd>
    );

    return (
        <>
            <div className="news__articles">
                {adItem}
                {renderArticles(articles)}
            </div>
            {loadingMore ? (
                <div className="more__articles__loader">
                    <FontAwesomeIcon
                        icon={'spinner'}
                        className="fa-spin articles__spinner"
                        size="lg"
                    />
                </div>
            ) : null}
            {articlesFinished && (
                <div className="articles__finished">
                    <h3>No articles avalilable!</h3>
                </div>
            )}
            {!loading && (
                <button
                    onClick={handleFetchMoreArticles}
                    className="load__more__articles__btn"
                >
                    Load More
                </button>
            )}
        </>
    );
}

export default Articles;
