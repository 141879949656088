import "./SelectField.scss";
import Select from "react-select";
import { useSelectField } from "./SelectFieldLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectField = ({
  value,
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  placeholder,
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
}) => {
  const { items, selectedItem } = useSelectField({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    value,
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:
        state.isFocused || errorMessage !== undefined
          ? `1px solid ${
              errorMessage !== undefined ? "#dc3545" : "#7647ee"
            } !important`
          : "1px solid #cccccc",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #7647ee !important",
        boxShadow: "none",
      },
    }),

    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#7647ee" : null,
        color: isFocused ? "#fff" : null,
      };
    },
  };

  return (
    <div className="custom-dropdown">
      {label !== null && (
        <label htmlFor="">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <Select
        className="custom-dropdown__select"
        classNamePrefix="custom-dropdown__select"
        options={items}
        onChange={(answer) => onChange(answer.value, answer)}
        defaultValue={{ label: selectedItem }}
        placeholder={placeholder}
        isDisabled={isDisabled}
        label={label}
        styles={customStyles}
      />
      {/* error message */}
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default SelectField;
