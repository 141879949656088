import Types from "../constants";

import {
    verifyEmailFailure,
    verifyEmailSuccess,
} from "../actions/verify-email.action";

import { VerifyEmail } from "../../services/auth.service";

const {
    verifyEmailTypes: { VERIFY_EMAIL_FAILURE, VERIFY_EMAIL_SUCCESS },
} = Types;

const initialState = {
    verified: null,
    error: null,
};

export const VerifyEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_EMAIL_SUCCESS:
            return { ...state, verified: action.payload, error: null };
        case VERIFY_EMAIL_FAILURE:
            return { ...state, verified: false, error: action.payload };
        default:
            return state;
    }
};

export const verifyUserEmail = (token) => {
    return (dispatch) => {
        VerifyEmail(token)
            .then((response) => {
                dispatch(verifyEmailSuccess(response.data));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(verifyEmailFailure(error.response.data));
                } else {
                    dispatch(verifyEmailFailure(error.message));
                }
            });
    };
};
