import Types from "../constants";

const { referredUsersTypes } = Types;

export const fetchReferredUsersRequest = () => ({
        type: referredUsersTypes.FETCH_REFERRED_USERS_REQUEST,
});

export const fetchReferredUsersSuccess = (payload) => ({
        type: referredUsersTypes.FETCH_REFERRED_USERS_SUCCESS,
        payload: payload,
});

export const fetchReferredUsersFailure = (error) => ({
        type: referredUsersTypes.FETCH_REFERRED_USERS_FAILURE,
        payload: error,
});


export const fetchQualifiedReferees = (payload) => ({
        type: referredUsersTypes.FETCH_QUALIFIED_REFEREES,
        payload
});

export const fetchUnqualifiedReferees = (payload) => ({
        type: referredUsersTypes.FETCH_UNQUALIFIED_REFEREES,
        payload
});

export const fetchAllReferees = (payload) => ({
        type: referredUsersTypes.FETCH_ALL_REFEREES,
        payload: payload,
});

