import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PayBillsRow from '../../../components/PayBillsRow';
import { useRouteMatch } from 'react-router-dom';

import airtime_data_icon from '../../../assets/icons/airtime-bills.svg';
import pay_bills_icon from '../../../assets/icons/pay-bills-icon.svg';

import FirstAirtime from '../../../components/PositiveActionModals/FirstAirtime';
import FirstData from '../../../components/PositiveActionModals/FirstData';
import FirstElectricity from '../../../components/PositiveActionModals/FirstElectricity';
import FirstCable from '../../../components/PositiveActionModals/FirstCable';

import './pay-bills.scss';
import PositiveActionRow from '../../../components/PositiveActionRow';
import WeeklySpending from '../../../components/PositiveActionModals/WeeklySpending';

import { fetchIncentives } from '../../../services/dashboard.service';
import { mapIncentivesBills } from '../../../helpers/constants';
import { nFormatter } from '../../../helpers/nFormatter';

const PayBills = () => {
    const [isFirstPurchase] = useState(
        JSON.parse(localStorage.getItem('isFirstTimePurchase')) || false
    );
    const [incentives, setIncentives] = useState([]);
    const dashboard = useSelector((state) => state.dashboard);
    const user_data = dashboard.data !== null && dashboard.data;
    const { username } = user_data;

    const { url, path } = useRouteMatch();

    const getIncentives = async () => {
        try {
            const res = await fetchIncentives();
            mapIncentivesBills(res.data?.data, setIncentives);
        } catch (error) {
            if (error) {
                setIncentives([]);
            }
        }
    };

    function renderIncentives(incentives) {
        return incentives
            .filter((incentive) => incentive.key !== 'gold_membership' && incentive.key !== 'first_survey')
            .filter((incentive) => incentive.qualified === true)
            .map((incentive) => {
                return (
                    <PositiveActionRow
                        key={incentive.key}
                        title={incentive.title}
                        icon={incentive.icon}
                        btn__title={incentive.buttonText}
                        description={incentive.description}
                        point={nFormatter(incentive.points_reward)}
                        link={`${url}${incentive.path}`}
                        border={incentive?.hasBorder}
                    />
                );
            });
    }

    useEffect(() => {
        getIncentives();
    }, []);

    return (
        <div className="pay--bills">
            {renderIncentives(incentives)}
            <PayBillsRow
                billDetails={{
                    name: 'Buy airtime',
                    id: 'airtime',
                    description:
                        'Buy airtime & data with your Enlumi wallet and earn points for this.',
                    point: '10k',
                    path: '/activities/pay-bills',
                    icon: airtime_data_icon,
                }}
            />
            <PayBillsRow
                billDetails={{
                    name: 'Pay bills',
                    id: 'bills',
                    description:
                        'Pay your bills  online with your Enlumi wallet and earn points for this.',
                    point: '10k',
                    path: '/activities/pay-bills',
                    icon: pay_bills_icon,
                }}
            />

            <FirstAirtime
                isFirstTime={isFirstPurchase.isFirstAirtime}
                username={username}
            />
            <FirstData
                isFirstTime={isFirstPurchase.isFirstMobileData}
                username={username}
            />
            <FirstCable
                isFirstTime={isFirstPurchase.isFirstCable}
                username={username}
            />
            <FirstElectricity
                isFirstTime={isFirstPurchase.isFirstPower}
                username={username}
            />
            <WeeklySpending
                isFirstTime={isFirstPurchase.isWeeklySpending}
                username={username}
            />
        </div>
    );
};

export default PayBills;
