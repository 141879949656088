import { useRouteMatch, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLikeArticle from "../hooks/useLikeArticle";
import NewsService from "../services/news.service";

import comment_icon from "../assets/icons/comment-icon.svg";
import { useEffect, useState } from "react";

function NewsRow({ title, featured_image, date_created, id, comments, likes }) {
  const access_token = process.env.REACT_APP_BLOG_ACCESS_TOKEN;
  const { url } = useRouteMatch();
  const { isLiked, handleLike, likes: likesCount } = useLikeArticle(id);
  const [commentsCount, setCommentsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getArticleCommentsCount = async () => {
    try {
      const res = await NewsService.fetchArticleCommentsCount(id);
      if (res) {
        setLoading(false);
        setCommentsCount(res.data.data[0].count.id);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticleCommentsCount();
  }, [])

  return (
    <div className="news__row">
      <div className="news__row__image">
        <img
          src={`https://enlumi-blog.herokuapp.com/assets/${featured_image}?access_token=${access_token}`}
          alt={title}
        />
      </div>
      <div className="news__row__details">
        <h4 className="news__row__title">{title}</h4>
        <div className="news__row__published">
          <span>Published:</span>
          <span>
            {new Date(date_created).toDateString()}{" "}
            {new Date(date_created).toLocaleTimeString()}{" "}
          </span>
        </div>
        <div className="news__row__stats">
          <div onClick={handleLike} className="stat">
            <FontAwesomeIcon
              icon={[`${isLiked ? "fas" : "far"}`, "heart"]}
              className="stat__icon"
            />
            {/* <span className="stat__icon">stat icon</span> */}
            <span className="stat__count">
              {!isLiked && likesCount === 0 ? likes.length : likesCount} Likes
            </span>
          </div>
          <div className="stat">
            <span className="stat__icon">
              <img src={comment_icon} alt="comment" />
            </span>
            <span className="stat__count">
              {commentsCount <= 0 ? comments.length : commentsCount} Comments
            </span>
          </div>
        </div>
      </div>
      <Link
        to={{
          pathname: `${url}/news/${id}`,
          hash: title,
        }}
        className="read__article__btn"
      >
        <span className="read__article__btn__title">Read More</span>
        <span className="show__icon__mobile icon-arrow-right"></span>
      </Link>
    </div>
  );
}

export default NewsRow;
