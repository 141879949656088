import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useModal from "../hooks/useModal";
import { secondaryComponents } from "./secondaryComponents";
import NubanVerification from "./VerificationModals/NubanVerification/NubanVerification";
import useNairaWallet from "../hooks/core/FundTransfer/useNairaWallet";

const AccountSummaryMobile = () => {
  const [modalOpen, setModalOpen, ,] = useModal();
  const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
  const { data } = useNairaWallet();
  const { withdrawable_balance } = data !== null && data;
  const naira_wallet_balance = parseFloat(withdrawable_balance);
  const enlumi_rewards_wallet_balance = useSelector(
    (state) => state.dashboard.data.enlumi_rewards_wallet_balance || 0
  );
  const auth = useSelector((state) => state.auth);
  const { is_verified } = auth.user !== null && auth.user;

  return (
    <div className="account-summary-mobile">
      <div className="account-summary-mobile__top-section">
        <div className="account-summary-mobile__top-section__reward balance-item">
          <div>
            <p className="white--text">Rewards Wallet</p>
            <h4 className="white--text mt-1">
              N{enlumi_rewards_wallet_balance.toFixed(2).toLocaleString()}
            </h4>
          </div>
          <Link to="/fund-transfer" className="purple--text claim-reward-btn">
            Claim
          </Link>
        </div>
        <div className="account-summary-mobile__top-section__cash balance-item">
          <div>
            <p className="white--text">Naira Wallet</p>
            <h4 className="white--text mt-1">N{naira_wallet_balance}</h4>
          </div>
          <div>
            <span
              className="purple--text withdraw-funds"
              onClick={() => {
                if (!is_verified) {
                  toggleVerifyModal();
                } else {
                  setModalOpen(true);
                }
              }}
            >
              Top up
            </span>
          </div>
        </div>
      </div>
      <div className="account-summary-mobile__bottom-section">
        <div className="scroll-icon"></div>
        <Link className="purple--text" to="/wallet-history">
          View wallet history
        </Link>
      </div>

      {modalOpen && (
        <secondaryComponents.TopupOptionModal
          showTopUpModal={modalOpen}
          setShowTopUpModal={setModalOpen}
        />
      )}

      <NubanVerification
        verifyModalOpen={verifyModalOpen}
        setVerifyModalOpen={setVerifyModalOpen}
      />
    </div>
  );
};
export default AccountSummaryMobile;
