import './user-profile-header.scss';
import user_icon from '../../assets/icons/profile-photo-icon.svg';
import gold_medal_badge from '../../assets/icons/gold-medal-icon.svg';
import silver_medal_badge from '../../assets/icons/silver-medal-icon.svg';
import bronze_medal_badge from '../../assets/icons/bronze-medal-icon.svg';
import gold_medal from '../../assets/icons/gold-membership-icon.svg';
import silver_medal from '../../assets/icons/silver-membership-icon.svg';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { nFormatter } from '../../helpers/nFormatter';
import { getReferredUsers } from '../../redux/reducers/referred-users.reducer';
import { getUserDetails } from '../../redux/reducers/auth.reducer';
import Button from '../Button';

const steps = [
    {
        id: 'bronze',
        title: 'Bronze Member',
        icon: { locked: silver_medal, unlocked: bronze_medal_badge },
        completed: false,
        points: 5000,
        referrals: 0,
    },
    {
        id: 'silver',
        title: 'Silver Member',
        icon: { locked: silver_medal, unlocked: silver_medal_badge },
        completed: false,
        points: 30000,
        referrals: 5,
    },
    {
        id: 'gold',
        title: 'Gold Member',
        icon: { locked: gold_medal, unlocked: gold_medal_badge },
        completed: false,
        points: 100000,
        referrals: 10,
    },
];

const UserProfileHeader = () => {
    const [levelsObtained, setLevelsObtained] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { loading, user } = useSelector((state) => state.auth);
    const referrals = useSelector((state) => state.referrals);
    const { data } = referrals;
    const { current_tab } = location;
    const { referees } = data;
    const { lifetime_points, level, email, is_verified } =
        user !== null && user;

    // user referral count
    const user_ref_count = referees.filter(
        ({ is_verified, has_completed_profile_survey }) =>
            is_verified && has_completed_profile_survey
    ).length;

    const renderBadge = (level) => {
        switch (level) {
            case 'bronze':
                return bronze_medal_badge;
            case 'gold':
                return gold_medal_badge;
            case 'silver':
                return silver_medal_badge;
            default:
                return null;
        }
    };

    const renderAccountType = (level) => {
        switch (level) {
            case 'bronze':
                return 'BRONZE MEMBER';
            case 'gold':
                return 'GOLD MEMBER';
            case 'silver':
                return 'SILVER MEMBER';
            default:
                return 'MEMBER';
        }
    };

    useEffect(() => {
        if (email === undefined || email === null) {
            dispatch(getUserDetails());
        }

        const membershipLevels = ['member', 'bronze', 'silver', 'gold'];
        const currentMembershipLevels = [];
        for (let i = 0; i < membershipLevels.length; i++) {
            currentMembershipLevels.push(membershipLevels[i]);
            if (membershipLevels[i] === level) {
                break;
            }
        }
        setLevelsObtained(currentMembershipLevels);

        dispatch(getReferredUsers());
    }, [dispatch, email, level]);

    return (
        <div className="user--profile-header">
            <div className="user--profile-image">
                <img src={user_icon} alt="user profile" />
            </div>
            <div className="user--profile-details">
                <div className="user--account-progress">
                    {steps.map((step, idx) => (
                        <div
                            key={step.id + idx}
                            className={`progress ${
                                levelsObtained.includes(step.id) && 'completed'
                            }`}
                        >
                            <div className="level">
                                <span>{step.title}</span>
                                <img
                                    src={
                                        levelsObtained.includes(step.id)
                                            ? step.icon.unlocked
                                            : step.icon.locked
                                    }
                                    alt="medal"
                                />
                                <span className="level--points">
                                    {lifetime_points >= step.points
                                        ? nFormatter(step.points)
                                        : nFormatter(lifetime_points)}{' '}
                                    / {nFormatter(step.points)} Points
                                </span>
                                <span className="level--referrals">
                                    {user_ref_count >= step?.referrals
                                        ? step?.referrals
                                        : user_ref_count}{' '}
                                    / {step?.referrals} Referrals
                                </span>
                            </div>
                            {idx !== steps.length - 1 && (
                                <div className={`divider--line`}></div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="user--account-overview">
                    <div className="user--account-type">
                        <div className="user--account-type-badge">
                            {level === 'member' ? null : (
                                <img src={renderBadge(level)} alt="badge" />
                            )}
                        </div>
                        <span className="user--account-type-title">
                            {renderAccountType(level)}
                        </span>
                    </div>
                    <div className="user--account-points">
                        <span className="user--account-point">
                            {lifetime_points ? nFormatter(lifetime_points) : 0}
                        </span>
                        <span>Points</span>
                    </div>
                    <div className="verify--account">
                        {loading ? (
                            'Loading...'
                        ) : is_verified ? (
                            <div className="verification--status verified">
                                Verified
                            </div>
                        ) : (
                            <Button
                                // onClick={toggleModal}
                                // onClick={() =>
                                //     phone_number_verified
                                //         ? toggleModal()
                                //         : setVerifyPhoneNumber(true)
                                // }
                                className="btn btn--outline btn--verify-account"
                            >
                                Verify account
                            </Button>
                        )}

                        {/* <CustomModal
                            isActive={modalOpen}
                            closeModal={() => setModalOpen(false)}
                            width={'30%'}
                        >
                            <VerifyAccount setModalOpen={setModalOpen} />
                        </CustomModal> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileHeader;
