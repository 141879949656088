import './summary.scss';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { airtimeTopUp } from '../../../../services/topup-service';
import { getDashboard } from '../../../../redux/reducers/dashboard.reducer';
import useModal from '../../../../hooks/useModal';
import useNairaWallet from '../../../../hooks/core/FundTransfer/useNairaWallet';

import arrow_left from '../../../../assets/icons/arrow-left.svg';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import FormGroup from '../../../../components/FormGroup';
import ErrorText from '../../../../components/ErrorText';
import ModalClose from '../../../../components/ModalClose';
import failed_icon from '../../../../assets/images/failure-icon.svg';
import PayBillSuccessModalContent from '../../../../components/PayBillSuccessModalContent';
import ResponseModal from '../../../../components/ResponseModal';

const AirtimeSummary = () => {
    const passwordSchema = yup.object().shape({
        pin: yup
            .string()
            .min(6, 'Invalid transaction pin format')
            .max(6, 'Invalid transaction pin format')
            .required('Transaction Pin is required'),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(passwordSchema),
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const { airtimeForm, dashboard } = useSelector((state) => state);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [loading, setLoading] = useState(false);
    const [statusDialog, setStatusDialog] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusImage, setStatusImage] = useState(failed_icon);
    const [airtimeError, setAirtimeError] = useState('');
    //const [transactionMessage, setTransactionMessage] = useState(null);
    const { data } = useNairaWallet();
    const { withdrawable_balance } = data !== null && data;
    const naira_wallet_balance = parseFloat(withdrawable_balance) || 0;

    const { enlumi_rewards_wallet_balance } =
        dashboard.data !== null && dashboard.data;

    const onSubmit = (data) => {
        setLoading(true);
        airtimeTopUp(
            airtimeForm.amount,
            airtimeForm.funding_channel,
            airtimeForm.phone_number,
            airtimeForm.network,
            data.pin
        )
            .then((response) => {
                toggleModal();
                if (response.data?.reward_message) {
                    const purchaseObj = {
                        isFirstAirtime: true,
                        isFirstMobileData: false,
                    };
                    localStorage.setItem(
                        'isFirstTimePurchase',
                        JSON.stringify(purchaseObj)
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (
                        error.response.status === 422 ||
                        error.response.status === 400
                    ) {
                        setAirtimeError(error.response.data.detail);
                    }
                }
            });
    };

    return (
        <div className="airtime--summary">
            <div className="airtime--summary-header">
                <img
                    onClick={() => history.goBack()}
                    src={arrow_left}
                    alt="arrow left"
                />
                <div>
                    <h2 className="summary--heading">Mobile Top-up</h2>
                </div>
            </div>
            <div className="summary--container">
                <div className="summary--details">
                    <div className="summary--detail">
                        <p className="left">From:</p>
                        <p className="right">
                            <span className="wallet">
                                {airtimeForm.funding_channel === 'CASH_WALLET'
                                    ? 'Naira Wallet'
                                    : 'Rewards Wallet'}
                            </span>
                            <span className="balance">
                                Balance: #
                                {airtimeForm.funding_channel === 'CASH_WALLET'
                                    ? naira_wallet_balance.toLocaleString()
                                    : enlumi_rewards_wallet_balance.toLocaleString()}
                            </span>
                        </p>
                    </div>
                    <div className="summary--detail">
                        <p className="left">Biller:</p>
                        <p className="right">
                            <span className="wallet">
                                {airtimeForm.network}
                            </span>
                        </p>
                    </div>
                    {/* <div className="summary--detail">
                        <p className="left">Product:</p>
                        <p className="right">
                            <span className="wallet">MTN VTU</span>
                        </p>
                    </div> */}
                    <div className="summary--detail">
                        <p className="left">Reference:</p>
                        <p className="right">
                            <span className="wallet">
                                {'+234' + airtimeForm.phone_number}
                            </span>
                        </p>
                    </div>

                    <div className="summary--detail">
                        <p className="left">Amount:</p>
                        <p className="right">
                            <span className="wallet">
                                #{airtimeForm.amount.toLocaleString()}
                            </span>
                        </p>
                    </div>
                    {airtimeForm.funding_channel !== 'REWARDS_WALLET' && (
                        <div className="summary--detail">
                            <p className="left">Points to be earned:</p>
                            <p className="right">
                                <span className="wallet">
                                    {airtimeForm.points_to_earn}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="summary--form"
                >
                    <FormGroup>
                        <h4 className="form--group-heading">
                            Enter Your Transaction Pin to continue
                        </h4>
                        <input
                            type="password"
                            className="enlumi--password-field"
                            id="enlumi_password"
                            {...register('pin')}
                            inputMode="numeric"
                        />
                        {errors.pin && (
                            <ErrorText message={errors.pin.message} />
                        )}
                    </FormGroup>
                    <Button className="btn btn--primary btn--summary">
                        {loading ? (
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin"
                            />
                        ) : (
                            'Pay Now'
                        )}
                    </Button>
                </Form>
            </div>
            {/* this is the default modal that shows for a failure or success request*/}
            <CustomModal
                isActive={statusDialog}
                closeModal={() => setStatusDialog(false)}
                width={'350px'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setStatusDialog(false)} />
                    </div>

                    <img src={statusImage} alt="" className="status-image" />

                    <h4>{statusMessage}</h4>
                </div>
            </CustomModal>
            <CustomModal
                isActive={modalOpen}
                handleClose={() => {
                    history.push('/activities/pay-bills/bills');
                    window.location.reload();
                }}
                width={'420px'}
            >
                <PayBillSuccessModalContent
                    points={
                        airtimeForm.funding_channel === 'CASH_WALLET' &&
                        airtimeForm.points_to_earn
                    }
                />
            </CustomModal>
            <CustomModal
                isActive={airtimeError !== ''}
                closeModal={() => setAirtimeError('')}
                width={'320px'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setAirtimeError('')} />
                    </div>
                    <ResponseModal status={'fail'} message={airtimeError} />
                </div>
            </CustomModal>
        </div>
    );
};

export default AirtimeSummary;
