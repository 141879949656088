import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewsService from "../../../services/news.service";

import user_img from "../../../assets/images/profile-icon.svg";

function AddComment({ articleId, getComments, getArticleCommentsCount }) {
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState("");
  const [commentText, setCommentText] = useState("");
  const { data } = useSelector((state) => state.dashboard);
  const { username } = data !== null && data;

  const user = username ? username : "Anonymous";

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    NewsService.postComment(articleId, commentText, user)
      .then((res) => {
        setLoading(false);
        setCommentText("");
        getComments();
        getArticleCommentsCount();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="add__comment">
      <div className="user__avatar">
        <img src={user_img} alt="user" />
      </div>
      <form onSubmit={handleSubmit} className="comment__form">
        <textarea
          placeholder="Add Comment"
          className="comment__input"
          name="comment"
          value={commentText}
          onChange={(event) => setCommentText(event.target.value)}
        ></textarea>
        <button
          disabled={commentText === "" || loading}
          className="submit__comment"
        >
          {loading ? (
            <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
          ) : (
            "Comment"
          )}
        </button>
      </form>
    </div>
  );
}

export default AddComment;
