import React, { useState } from "react";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { getAd, clickAd } from "../../services/ads-service";

const AD_CONTEXT = React.createContext();
const USER_DEMOGRAPHICS = {
  user_id: null,
  city: null,
  local_government: null,
  state: null,
  country: null,
  age: null,
  gender: null,
  ethnicity: null,
  language: null,
  religion: null,
  parent: null,
  relationship_status: null,
  employment_status: null,
  work_industry: null,
  registered_voter: null,
  number_in_household: null,
  annual_household_income: null,
  housing_situation: null,
  tech_affinity: null,
  device: null,
  mobile_network: null,
  social_media_use: null,
  educational_level: null,
};

const Media = ({ children, ...rest }) => {
  return (
    <AD_CONTEXT.Consumer>
      {(context) => children({ ...context, ...rest })}
    </AD_CONTEXT.Consumer>
  );
};

const LumiAd = ({ children, slot }) => {
  const [userDemographics, setUserDemographics] = useState(USER_DEMOGRAPHICS);
  const [media, setMedia] = React.useState(null);
  const [advert, setAdvert] = React.useState(null);
  const [lumiAdError, setLumiAdError] = React.useState(false);
  const token  = useSelector((state) => state.auth.accessToken);
  const [userId, setUserId] = React.useState(null);

  const handleClickAd = React.useCallback(async (payload) => {
    /**
     * POST Data -> media_id, advert_id, user_id
     */
    try {
      const res = await clickAd({ ...payload });
      if (res) {
        console.log("Click success", res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleFetchLumiAd = React.useCallback(async () => {
    try {
      const response = await getAd(
        {
          user_id: userId,
          ...userDemographics,
        },
        slot
      );
      if (response) {
        const _lumi_ad = response.data?.data;
        setLumiAdError(false);
        setAdvert(_lumi_ad.advert);
        setMedia(_lumi_ad.media);
      }
    } catch (error) {
      setMedia(null);
      setAdvert(null);
      setLumiAdError(true);
    }
  }, [userDemographics, userId]);

  React.useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      if (user !== null) {
        delete user["jti"];
        delete user["token_type"];
        delete user["exp"];

        const id = user.user_id;
        setUserId(id);

        setUserDemographics((prevDetails) => ({
          ...prevDetails,
          ...user,
        }));
      }
    }

    if (userId !== null) {
      handleFetchLumiAd();
    }
  }, [token, userId]);

  // React.useEffect(() => {
  //     const handleFetchLumiAd = async () => {
  //         try {
  //             const res = await getAd(userDemographics, slot);
  //             if (res) {
  //                 const _lumi_ad = res.data?.data;
  //                 setLumiAdError(false);
  //                 setAdvert(_lumi_ad.advert);
  //                 setMedia(_lumi_ad.media);
  //             }
  //         } catch (error) {
  //             setMedia(null);
  //             setAdvert(null);
  //             setLumiAdError(true);
  //         }
  //     };

  //     handleFetchLumiAd();
  // }, [userDemographics, slot]);

  const value = React.useMemo(
    () => ({ advert, media, lumiAdError, handleClickAd, userDemographics }),
    [advert, media, lumiAdError, handleClickAd, userDemographics]
  );

  return <AD_CONTEXT.Provider value={value}>{children}</AD_CONTEXT.Provider>;
};

LumiAd.Media = Media;

export default LumiAd;
