import { Link, useHistory } from 'react-router-dom';
import './faq.scss';

const FaqPage = () => {
    const history = useHistory();
    return (
        <div className="faq__page">
            <div className="faq__page-header">
                <h2 className="faq__page-header__title">
                    Looking for Answers?
                </h2>
                <p className="faq__page-header__description">
                    Browse our articles to find the answer you need
                </p>
                <form>
                    <div>
                        <input
                            type={'text'}
                            placeholder="Search by keyword or ask a question"
                        />
                    </div>
                </form>
            </div>

            <div className="faq__page-body">
                <h2>How it Works</h2>
                <ul>
                    <li>
                        Must be a gold member on Lumi ( see how:
                        <Link
                            style={{ color: '#766df4', fontSize: '1rem' }}
                            to={
                                'https://help.enlumidata.com/kb/en/article/lumi-membership-badges'
                            }
                            target="_blank"
                        >
                            https://help.enlumidata.com/kb/en/article/lumi-membership-badges
                        </Link>
                        )
                    </li>
                    <li>Must complete at least 12 surveys monthly</li>
                    <li>
                        Must spend a Minimum of N500 from their Lumi Naira
                        wallet weekly
                    </li>
                    <li>
                        Rewards will be allocated once in a month, anytime these
                        requirement are met
                    </li>
                </ul>

                <div onClick={() => history.goBack()} className="faq__page-btn">
                    <span
                        className="icon-arrow-right ml-2"
                        style={{ fontSize: '10px' }}
                    ></span>
                    <span style={{ paddingTop: '.3em' }}>Go Back</span>
                </div>
            </div>
        </div>
    );
};

export default FaqPage;
