import Label from "../../components/Label";
import Button from "../../components/Button";
import ModalClose from "../../components/ModalClose";
import ResponseModal from "../../components/ResponseModal"
import congratulation_image from "../../assets/images/congratulation-image.svg"
import useModal from "../../hooks/useModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { withdrawFunds } from "../../services/bankServices";
import { getDashboard } from "../../redux/reducers/dashboard.reducer";

const WithdrawalCheckout = ({ withdrawalDetails }) => {
    // hooks section
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    // state section
    const passwordError = useRef(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [password, setPassword] = useState(null);
    const [statusDialog, setStatusDialog] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [statusImage, setStatusImage] = useState("fail");
    const [withdrawLoader, setWithdrawLoader] = useState(false);
    const [successWithdrawalDialog, setSuccessWithdrawalDialog] = useState(false);
    const [transactionCharges, setTransactionCharges] = useState(0);
    const [receivableAmount, setReceivableAmount] = useState(0);

    useEffect(() => {
        if (password != null) {
            validatePassword();
        }
    }, [password]);

    useEffect(() => {
        setTransactionDetails();
    }, []);

    const setTransactionDetails = () => {
        // this function helps set transaction charges based on the amount this to be withdrawed
        let charges = null;
        if (withdrawalDetails.withdrawalAmount <= 5000) {
            charges = parseFloat(process.env.REACT_APP_WITHDRAWAL_CHARGE_BELOW_5K);
        } else if (withdrawalDetails.withdrawalAmount > 5000 && withdrawalDetails.withdrawalAmount <= 50000) {
            charges = parseFloat(process.env.REACT_APP_WITHDRAWAL_CHARGE_ABOVE_5K);
        } else if (withdrawalDetails.withdrawalAmount > 50000) {
            charges = parseFloat(process.env.REACT_APP_WITHDRARWAL_CHARGE_ABOVE_50K);
        }

        if (withdrawalDetails.walletType == "cash-wallet") {
            charges = charges + (parseFloat(process.env.REACT_APP_WITHDRAWAL_CHARGE_CASH_WALLET) * withdrawalDetails.withdrawalAmount)
        }

        let amount = withdrawalDetails.withdrawalAmount - charges;
        setReceivableAmount(amount);
        setTransactionCharges(charges);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const validatePassword = () => {
        // validate that a password was entered
        if (password == null || password == "") {
            passwordError.current = true;
            setPasswordErrorMessage("please enter a valid password")
        } else {
            setPasswordErrorMessage("");
            passwordError.current = false;
        }
    }

    const withdrawFundsNow = () => {
        // this function triggers the withdrawal of funds
        validatePassword();
        if (!passwordError.current) {
            setWithdrawLoader(true);
            withdrawFunds({ password: password, walletType: withdrawalDetails.walletType }).then(() => {
                setWithdrawLoader(false);
                setSuccessWithdrawalDialog(true);
            }).catch((error) => {
                setWithdrawLoader(false);
                if (error.response) {
                    if (error.response.status === 422 || error.response.status === 400) {
                        setStatusImage("fail");
                        setStatusDialog(true);
                        setStatusMessage(error.response.data.detail);
                    }
                }
            })
        }
    }

    return (
        <div className="withdraw-fund-page__summary">
            <p className="withdraw-fund-page__description">Find summary of your withdrawal below:</p>
            {/* summary card */}
            <div className="withdraw-fund-page__summary__details-card mt-5">
                <p className="user-withdraw-details mb-2" style={{ textAlign: 'right' }}>From:
                    <span className="purple--text">
                        {(withdrawalDetails.walletType == "cash-wallet") ? "Naira wallet" : "Rewards wallet"}<br />
                        <span style={{ color: 'black' }}>Balance: N{withdrawalDetails.balance.toLocaleString()}</span>
                    </span>
                </p>
                <p className="user-withdraw-details mb-2">To:
                    <span className="purple--text" style={{ textAlign: 'right' }}>
                        {withdrawalDetails.bankName}<br />{withdrawalDetails.accountName}<br></br>{withdrawalDetails.accountNumber}
                    </span>
                </p>
                <p className="user-withdraw-details mb-2">Amount:<span>N{withdrawalDetails.withdrawalAmount.toLocaleString()}</span></p>
                <p className="user-withdraw-details mb-2">Charges:<span>N{transactionCharges.toLocaleString()}</span></p>
                <p className="user-withdraw-details mb-2">Total receivable amount:<span>N{receivableAmount.toLocaleString()}</span></p>
            </div>

            <div className="withdraw-fund-page__summary__password-form mt-5">
                {/* password input section */}
                <div>
                    <Label>Enter Enlumi password to continue*</Label>
                    <input
                        type="password"
                        className="input--field"
                        onChange={handlePassword}
                    />
                    <p className={`mb-0 mt-1 ${(passwordError) ? 'error--text' : 'purple--text'}`} style={{ maxWidth: '500px' }}>
                        {passwordError ? passwordErrorMessage : null}
                    </p>
                </div>
                {/* withdrawal button */}
                <Button className="btn btn--primary mt-3" onClick={withdrawFundsNow} disabled={withdrawLoader}>
                    {withdrawLoader ? (
                        <span>
                            <FontAwesomeIcon
                                icon="spinner"
                                className="fa-spin mr-1"
                            />
                            <span>Withdrawing...</span>
                        </span>
                    ) : (
                        "Withdraw now"
                    )}
                </Button>
            </div>
            {/* this is the default modal that shows for a failure or success request*/}
            <CustomModal
                isActive={statusDialog}
                closeModal={() => setStatusDialog(false)}
                width={"350px"}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setStatusDialog(false)} />
                    </div>
                    <ResponseModal message={statusMessage} status={statusImage}/>
                </div>
            </CustomModal>

            <CustomModal
                isActive={successWithdrawalDialog}
                closeModal={() => { 
                    dispatch(getDashboard());
                    history.push("/dashboard") 
                }}
                width={"420px"}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => { 
                            dispatch(getDashboard());
                            history.push("/dashboard");
                        }} />
                    </div>

                    <img className="withdraw-fund-page__summary__status-image" src={congratulation_image} />

                    <div style={{ paddingTop: '70px' }}>
                        <h3 className="mb-3">WELL DONE!</h3>
                        <p>Your withdrawal is currently being processed.</p>
                        <Button onClick={()=> {
                            dispatch(getDashboard());
                            history.push("/dashboard");
                        }} className="btn btn--primary mt-4">Return</Button>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}
export default WithdrawalCheckout;