import './nuban-verification.scss';
import bvn_verify_icon from '../../../assets/icons/bvn-verification-icon.svg';
import existing_bank_icon from '../../../assets/icons/bank-financial.svg';
import congratulations_img from '../../../assets/icons/celebration-confetti.svg';

import { useStep } from 'react-hooks-helper';
import BVNVerification from '../../FundWalletModal/BVNVerification';
import ExistingBankAccount from '../../FundWalletModal/ExistingBankAccount';
import AccountVerification from '../../FundWalletModal/AccountVerification';
import useModal from '../../../hooks/useModal';
import ModalClose from '../../ModalClose';
import { FaAngleRight } from 'react-icons/fa';
import Button from '../../Button';

const __steps__ = [
    { id: 1, title: 'Verification-Options' },
    { id: 2, title: 'BVN-Verification' },
    { id: 3, title: 'Existing-Account' },
    { id: 4, title: 'Account-Verification' },
    { id: 5, title: 'Success' },
];

const NubanVerification = ({ verifyModalOpen, setVerifyModalOpen }) => {
    const [, , , CustomModal] = useModal();
    const { step, navigation } = useStep({ steps: __steps__, initialStep: 0 });

    return (
        <CustomModal
            isActive={verifyModalOpen}
            closeModal={() => {
                setVerifyModalOpen(false);
            }}
            width="450px"
        >
            <div className="modal__container__content">
                <div className="modal__container__content__default-header">
                    <ModalClose
                        onClick={() => {
                            navigation.go(0);
                            setVerifyModalOpen(false);
                        }}
                    />
                </div>
                <div className="nuban-verification-modal">
                    {step.title === 'Verification-Options' && (
                        <>
                            <h3 className="d-flex">
                                <span>Lets verify your identity</span>
                            </h3>
                            <p style={{ textAlign: 'left' }}>
                                Please select an option that suits you to begin
                                with
                            </p>

                            <div className="nuban-verification-modal__options">
                                <div
                                    onClick={() => navigation.go(1)}
                                    className="nuban-verification-modal__options__option"
                                >
                                    <img src={bvn_verify_icon} alt="" />
                                    <div className="nuban-verification-modal__options__option-detail">
                                        <div>
                                            <h5>Verify with your BVN</h5>
                                            <p>
                                                Your BVN is used to verify your
                                                identity. Dial *565*0# to get
                                                your BVN
                                            </p>
                                        </div>
                                        <FaAngleRight />
                                    </div>
                                </div>
                                <div
                                    onClick={() => navigation.go(2)}
                                    className="nuban-verification-modal__options__option"
                                >
                                    <img src={existing_bank_icon} alt="" />
                                    <div className="nuban-verification-modal__options__option-detail">
                                        <div>
                                            <h5>
                                                Verify with your Bank Account{' '}
                                            </h5>
                                            <p>
                                                Use your existing account from
                                                your bank to verify your
                                                identity
                                            </p>
                                        </div>
                                        <FaAngleRight />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {step.title === 'BVN-Verification' && (
                        <BVNVerification navigation={navigation} />
                    )}
                    {step.title === 'Existing-Account' && (
                        <ExistingBankAccount navigation={navigation} />
                    )}
                    {step.title === 'Account-Verification' && (
                        <AccountVerification navigation={navigation} />
                    )}
                    {step.title === 'Success' && (
                        <div className="nuban-verification-modal__success mt-4">
                            <img src={congratulations_img} alt="" />
                            <h3 className="mb-3 mt-4">
                                <span>
                                    Your bank information has been verified
                                </span>
                            </h3>
                            <p className="mb-4">
                                You can now use your bank account to fund your
                                wallet
                            </p>
                            <Button
                                className="btn btn--primary"
                                onClick={() => {
                                    setVerifyModalOpen(false);
                                    window.location.reload();
                                }}
                            >
                                Continue
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </CustomModal>
    );
};

export default NubanVerification;
