import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';

import ActivitiesHeaderReward from './ActivitiesHeaderReward';
import ActivitiesHeaderPurchase from './ActivitiesHeaderPurchase';
import ActivitiesHeaderNextLevel from './ActivitiesHeaderNextLevel';
import activity_header_img_1 from '../assets/images/banner-image-02-mobile.png';
import activity_header_img_2 from '../assets/images/banner-image-04-mobile.png';
import activity_header_img_3 from '../assets/images/banner-image-03.png';


import SearchActivities from './SearchActivities';
//import GoogleAds from "./GoogleAds";
const ActivitiesHeader = () => {
    const settings = {
        dots: false,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        arrows: false,
    };

    const location = useLocation();

    const showHeader =
        location.pathname === '/activities/pay-bills/airtime' ||
        location.pathname === '/activities/pay-bills/airtime/summary' ||
        location.pathname === '/activities/pay-bills/bills/electricity' ||
        location.pathname ===
            '/activities/pay-bills/bills/electricity/summary' ||
        location.pathname === '/activities/pay-bills/bills/mobile-data' ||
        location.pathname ===
            '/activities/pay-bills/bills/mobile-data/summary' ||
        location.pathname === '/activities/pay-bills/bills/cable' ||
        location.pathname === '/activities/pay-bills/bills';

    const mobileSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
    };
    return (
        <div className={showHeader ? 'hide__mobile' : ''}>
            <div className={'activities-page__header'}>
                <Slider {...settings}>
                    <ActivitiesHeaderReward />
                    <ActivitiesHeaderPurchase />
                    <ActivitiesHeaderNextLevel />
                </Slider>
            </div>
            <div className="activities-page__header--mobile">
                <SearchActivities />
                <Slider
                    {...mobileSettings}
                    className="activities-page__header--mobile__services mt-4"
                >
                    {/* slider 1 */}
                    <div className="activities-page__header--mobile__services__reward">
                        <div className="item-1">
                            <h3 className="white--text mb-2">
                                Share your opinions for amazing rewards
                            </h3>
                            <p className="white--text">
                                Your opinion can help shape the product of
                                tomorrow
                            </p>
                        </div>
                        <div className="item-2">
                            <img src={activity_header_img_1} alt="" />
                        </div>
                    </div>
                    <div className="activities-page__header--mobile__services__purchase">
                        <div className="item-1">
                            <h3 className="white--text mb-3">
                                Save every time you pay your bills
                            </h3>
                            <p className="white--text">
                                Enjoy instant cashback every time you pay your
                                bills.
                            </p>
                        </div>
                        <div className="item-2">
                            <img src={activity_header_img_2} alt="" />
                        </div>
                    </div>
                    <div className="activities-page__header--mobile__services__next-level">
                        <div className="item-1">
                            <h3 className="white--text mb-3">
                                Next Level Rewards
                            </h3>
                            <p className="white--text">
                                Get rewarded at places you shop, dine, or make
                                purchases
                            </p>
                        </div>
                        <div className="item-2">
                            <img src={activity_header_img_3} alt="" />
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default ActivitiesHeader;
