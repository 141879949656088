import Types from "../constants";

const { verifyEmailTypes } = Types;

export const verifyEmailSuccess = (payload) => ({
    type: verifyEmailTypes.VERIFY_EMAIL_SUCCESS,
    payload: payload,
});

export const verifyEmailFailure = (error) => ({
    type: verifyEmailTypes.VERIFY_EMAIL_FAILURE,
    payload: error,
});
