import { blogHttpClient } from "../axiosBase/axiosHandler";

class NewsService {
  static fetchAllArticles() {
    const response = blogHttpClient.get(
      `/items/articles?filter[status][_eq]=published&sort=sort,-date_created`
    );

    return response;
  }

  /**
   *
   * @param {object} filter pagination and sorting object
   * @returns {Promise}
   */
  static fetchArticles(page = 1) {
    // fetch all articles
    const response = blogHttpClient.get(
      `/items/articles?filter[status][_eq]=published&limit=10&page=${page}&sort=sort,-date_created`
    );

    return response;
  }

  /**
   *
   * @param {string} id article id
   * @returns {Promise}
   */
  static fetchArticle(id) {
    // fetch single article
    const response = blogHttpClient.get(`/items/articles/${id}`);

    return response;
  }

  /**
   *
   * @param {string} articleId article id
   * @param {string} comment comment message
   * @param {string} user username
   * @returns {Promise}
   */
  static postComment(articleId, comment, user) {
    // post new comment
    const data = {
      user_name: user,
      article_id: articleId,
      body: comment,
    };

    const response = blogHttpClient.post(`/items/comments`, data);
    return response;
  }

  /**
   *
   * @param {string} imageId image id
   * @returns {Promise}
   */
  static fetchArticleImage(imageId) {
    // fetch article image
    const response = blogHttpClient.get(`/assets/${imageId}`);
    return response;
    /*
    if (response) {
      // const { url, baseURL } = response.config;
      // const imgUrl = baseURL + url;

      // if (imgUrl !== "") {
      //   const res = await blogHttpClient.get(imgUrl);
      //   if (res) {
      //     return res.config;
      //   }
      // }
      return response;
    }

    /*
    if (response) {
      const { url, baseURL } = response.config;
      const imgUrl = baseURL + url;

      if (imgUrl !== "") {
        const res = blogHttpClient.get(imgUrl);
        if (res) _url = res;
      }
    }
    return _url;
    */
  }

  /**
   *
   * @param {string} imageId image id
   * @param {object} config image transformation object (width, height, fit, quality)
   * @returns {Promise}
   */
  static fetchArticleImageWithTransform(imageId, config) {
    // fetch article image with transformation
    const { width, height, fit } = config;
    const response = blogHttpClient.get(
      `/assets/${imageId}?fit=${fit}&width=${width}&height=${height}&quality=80`
    );

    return response;
  }

  /**
   *
   * @param {string} articleId article id
   * @param {string} user username
   * @returns {Promise}
   */
  static likeArticle(articleId, user) {
    // like article
    const data = {
      user_id: user,
      article_id: articleId,
    };

    const response = blogHttpClient.post(`/items/likes`, data);

    return response;
  }

  static fetchArticleLikesCount(articleId) {
    const response = blogHttpClient.get(
      `/items/likes?filter[article_id][_eq]=${articleId}&aggregate[count]=id`
    );
    return response;
  }

  static fetchArticleCommentsCount(articleId) {
    const response = blogHttpClient.get(
      `/items/comments?filter[article_id][_eq]=${articleId}&aggregate[count]=id`
    );
    return response;
  }

  /**
   *
   * @param {string} id article id
   * @returns {Promise}
   */
   static fetchComments(id, page = 1) {
    // fetch article comments
    const response = blogHttpClient.get(
      `/items/comments?filter[article_id][_eq]=${id}&limit=1000&page=${page}&sort=-date_created`
    );
    return response;
  }
}

export default NewsService;
