import ModalClose from "./ModalClose";
import useShutDown from "../hooks/useShutDown";
import publicimg from "../assets/images/publicimg.svg"

const ShutDownModal = ({closeModal}) => {
    const {isShutDownDate} = useShutDown()

    return (
        <div className="px-4 py-4" style={{display:"flex", flexDirection: "column",gap:"15px"}}>
            <ModalClose onClick={()=> {if(!isShutDownDate)closeModal()}}/>
            <img style={{maxWidth:"600px"}} src={publicimg}  alt=""/> 
            {!isShutDownDate?<p style={{ fontSize:"20px"}}><span style={{color:"black"}}>Hello cherished customer.</span><br/> Due to a discontinuation of service by one of
                our critical service providers we are forced to pause all functions on Lumi Rewards.
                We know how much you love the app so we are working very hard on finding a new partner so 
                that we can restore service. Please make sure to <span style={{fontWeight:"bold",color: "black"}}>transfer out any balance in your account by May 
                11th to avoid any loss of your funds.</span> If you have any questions or concerns kindly reach out to 
                us at <span style={{color: "#766df4"}}>customerservice@enlumidata.com</span></p>:
                <p style={{ fontSize:"20px"}}>
                    <span style={{color:"black"}}>Hello cherished customer.</span><br/> Due to a discontinuation of service by one of our critical service
                    providers we have had to pause all functions on Lumi Rewards. We know how much you love the 
                    app so we are working very hard on finding a new partner so that we can restore service. 
                    If you are an existing account holder and you have any questions or concerns kindly reach out 
                    to us at <span style={{color: "#766df4"}}>customerservice@enlumidata.com</span></p>}
        </div>
    );
};

export default ShutDownModal;
