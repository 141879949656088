import Types from "../constants";
import {
    getTelcos,
    payBill,
} from "../actions/electricity.actions";

import {
    getDiscos,
    getElectricityPurchaseHistory,
    getPurchaseRewardPoints,
    payElectricityBill,
} from "../../services/topup-service";

const {
    electricityTypes: {
        FETCH_ALL_DISCOS,
        SET_ELECTIRICY_FORM,
        SET_METER_SUMMARY,
        SET_CONVENIENCY_FEE,
    },
} = Types;

const initialState = {
    discos: [],
    formData: {
        funding_channel: "",
        disco: "",
        meter_number: "",
        meter_type: "",
        amount: "",
        pin: "",
    },
    meterSummary: {
        funding_channel: "",
        disco: "",
        meter_number: "",
        meter_type: "",
        amount: "",
        recipient: "",
        recipient_address: "",
        points: "",
        conveniency_fee: "",
    },
};

export const electricityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_DISCOS:
            return { ...state, discos: action.payload };
        case SET_ELECTIRICY_FORM:
            return { ...state, formData: { ...action.payload } };
        case SET_METER_SUMMARY:
            return { ...state, meterSummary: { ...action.payload } };
        case SET_CONVENIENCY_FEE:
            return {
                ...state,
                meterSummary: {
                    ...state.meterSummary,
                    conveniency_fee: action.payload,
                },
            };
        default:
            return state;
    }
};

export const getDiscosList = () => {
    return (dispatch) => {
        getDiscos()
            .then((response) => {
                if (response.data) {
                    const { list, conveniency_fee } = response.data;

                    // const _res = list.filter((disco) => disco.status === true);

                    dispatch({
                        type: SET_CONVENIENCY_FEE,
                        payload: conveniency_fee,
                    });

                    dispatch(getTelcos(list));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getRecentElectricityPurchase = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getElectricityPurchaseHistory()
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        reject(error.response.data);
                    } else {
                        reject(error.message);
                    }
                });
        });
    };
};

export const buyElectricity = (payload) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            payElectricityBill(payload)
                .then((response) => {
                    if (response.data) {
                        dispatch(payBill(response.data));
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        // reject(error.response.data);
                        reject(error);
                    } else {
                        reject(error.message);
                    }
                });
        });
    };
};

export const getPoints = (payload) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            getPurchaseRewardPoints(payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
};
