import ErrorText from "./ErrorText";

const InputField = ({
    register,
    error,
    label,
    required,
    type,
    onChange,
    ...inputProps
}) => {
    return (
        <>
            <input
                type={type}
                className="input--field"
                {...register(label, { required })}
                onChange={onChange}
                {...inputProps}
            />
            {error && <ErrorText message={error.message} />}
        </>
    );
};

export default InputField;
