
const Greeting = ({ user, greeting }) => {
    return (
        <div className="greeting">
            <span className="greeting--text">{greeting}</span>
            <h2 className="username">{user},</h2>
        </div>
    );
};

export default Greeting;
