import "./cash-reward.scss";
import { useHistory, Link } from "react-router-dom";
import arrow_left from "../../assets/icons/arrow-left.svg";

const RewardsPage = () => {
    const history = useHistory();
    return (
        <div className="cash-reward">
            <div>
                {/* header section and return button */}
                <div className="cash-reward__header" onClick={() => history.goBack()}>
                    <img
                        src={arrow_left}
                        alt="arrow left"
                    />
                    <h2 className="">Cash rewards</h2>
                </div>
                <p className="cash-reward__description">Where would you like to withdraw your reward to?</p>
            </div>

            <div className="cash-reward__content">
                {/* links to withdraw page by default but links a user without account details to add bank account details */}
                <Link to={`/rewards/cash/withdraw?wallet=cash-wallet`} className="cash-reward__content__add-bank-acc-btn">
                    <span className="plus-icon">+</span>
                    <p>Add Bank Account</p>
                </Link>
            </div>
        </div>
    );
};

export default RewardsPage;
