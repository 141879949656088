import { Link } from "react-router-dom";
import secondary_logo from "../../assets/websiteImages/secondary-logo.svg";
import facebook_logo from "../../assets/websiteImages/facebook.svg";
import twitter_logo from "../../assets/websiteImages/twitter.svg";

import "../../assets/sass/website/components/footer.scss";

const Footer = () => {
    return (
        <div className="footer">
        {/* middle section of footer */}
        <div className="dark footer-middle">
            <h5 className="white--text">Subscribe to our newsletter</h5>
            <div className="input-container ms-0 ms-md-5">
                <input type="email" placeholder="Enter your email address" />
                <a className="purple--text subcribe-btn">Sign up</a>
            </div>
        </div>
        {/* bottom section of footer */}
        <div className="dark footer-bottom">
            <div className="row ">
                <div className="col-lg-7 col-md-12 pr-3 mb-4">
                    <div><img style={{width: '100px'}} src={secondary_logo} alt="" /></div>
                    <p className="mt-4 white--text">Help us unlock Africa's potential while earning awesome rewards.</p>
                </div>
                <div className="col-lg-4 col-sm-8 col-12 mb-4">
                    <div className="row">
                        <div className="col-6">
                            <h5 className="title white--text mb-4">Company</h5>
                            <Link to="/about" className="link mb-2">About</Link>
                            <Link to="" className="link mb-2">Careers</Link>
                            <Link to={{ pathname:  'https://help.enlumidata.com/kb/en'}} target="_blank" className="link mb-2">Help</Link>
                            <Link to={{ pathname:  'https://www.enlumidata.com'}} target="_blank" className="link">Enlumi business</Link>
                        </div>
                        <div className="col-6">
                            <h5 className="title white--text mb-4">Legal</h5>
                            <Link to="/terms-and-condition" className="link mb-2">Terms & Conditions</Link>
                            <Link to="/privacy-policy" className="link mb-2">Privacy Policy</Link>
                            <Link to="/privacy-policy" className="link mb-2">Data Protection Policy</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1 col-sm-4 col-12">
                    <h5 className="title white--text mb-4">Follow us</h5>
                    <a href="https://web.facebook.com/lumirewards" target="_blank" rel="noreferrer" style={{cursor: 'pointer', }} className="mb-4 mr-3"><img src={facebook_logo} alt="" /></a>
                    <a href="https://twitter.com/Lumirewards" target="_blank" rel="noreferrer" style={{cursor: 'pointer', }} className="mb-4"><img className="ms-3" src={twitter_logo} alt="" /></a>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Footer;