import "./EmailUnsubscribe.scss";
import EmailUnsubscribeLogic from "./EmailUnsubscribeLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmailUnsubscribe = () => {
  const { isUnsubscribing, errorMessage } = EmailUnsubscribeLogic();
  return (
    <div className="email-unsubscribe-page">
      {isUnsubscribing ? (
        <>
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            className="fa-spin mb-4"
            style={{ fontSize: "25px" }}
          />
          <h3>Unsubscribing you from email promotions.....</h3>
        </>
      ) : (
        <>
          {errorMessage === null ? (
            <h2 className="purple--text">
              You have successfull opt out of Lumi email promotions
            </h2>
          ) : (
            <h2 className="error--text">{errorMessage}</h2>
          )}
        </>
      )}
    </div>
  );
};

export default EmailUnsubscribe;
