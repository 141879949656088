import Types from "../constants/index";
import {
    fetchCountryStatesSuccess,
    fetchSelectedState,
} from "../actions/country-states.action";

import { getStates, getState } from "../../services/country.service";

const {
    FETCH_STATES_FAILURE,
    FETCH_STATES_REQUEST,
    FETCH_STATES_SUCCESS,
    SELECT_STATE,
} = Types.countryStateActionTypes;

const initialState = {
    loading: false,
    states: [],
    error: null,
    selectedState: null,
};

export const countryStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATES_REQUEST:
            return { ...state, loading: true, error: null, states: [] };
        case FETCH_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                states: action.payload,
            };
        case FETCH_STATES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SELECT_STATE:
            return {
                ...state,
                loading: false,
                error: null,
                selectedState: action.payload,
            };
        default:
            return state;
    }
};

export const fetchCountryStates = () => {
    return (dispatch) => {
        const states = getStates();
        dispatch(fetchCountryStatesSuccess(states));
    };
};

export const fetchState = (state) => {
    return (dispatch) => {
        const response = getState(state);
        dispatch(fetchSelectedState(response));
    };
};
