import { usePagination, DOTS } from "../hooks/usePagnation";

import arrow_left from "../assets/icons/Arrow-Left-alt.svg";
import arrow_right from "../assets/icons/Arrow-Right-alt.svg";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 2,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination(
        totalCount,
        currentPage,
        pageSize,
        siblingCount
    );

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onDOubleNext = () => {
        onPageChange(currentPage + 2);
    }

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const onDoublePrevious = () => {
        onPageChange(currentPage - 2);
    }

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className="pagination">
            <button
                onClick={onDoublePrevious}
                disabled={currentPage === 1}
                className="page--btn"
                >
                    <img src={arrow_left} alt="prev" />
                    <img src={arrow_left} alt="prev" />
            </button>
            <button
                onClick={onPrevious}
                disabled={currentPage === 1}
                className="page--btn"
            >
                <img src={arrow_left} alt="prev" />
            </button>
            {paginationRange.map((pageNumber) => {
                if (pageNumber === DOTS) {
                    return <p>...</p>;
                }

                return (
                    <button
                        key={pageNumber}
                        onClick={() => onPageChange(pageNumber)}
                        className={`page--btn ${
                            pageNumber === currentPage && "active"
                        }`}
                    >
                        {pageNumber}
                    </button>
                );
            })}
            <button
                disabled={currentPage === lastPage}
                onClick={onNext}
                className="page--btn"
            >
                <img src={arrow_right} alt="next" />
            </button>
            <button
                onClick={onDOubleNext}
                disabled={currentPage === lastPage}
                className="page--btn"
                >
                    <img src={arrow_right} alt="next" />
                    <img src={arrow_right} alt="next" />
            </button>
        </div>
    );
};

export default Pagination;
