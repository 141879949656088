import { combineReducers } from "redux";

import { countryStateReducer } from "./country-states.reducer";
import { authReducer } from "./auth.reducer";
import { dashboardReducer } from "./dashboard.reducer";
import { referredUsersReducer } from "./referred-users.reducer";
import { VerifyEmailReducer } from "./verify-email.reducer";
import { formReducer } from "./form.reducer";
import { forgotPasswordReducer } from "./forgot-password.reducer";
import { privacyReducer } from "./privacy.reducer";
import { resetPasswordReducer } from "./reset-password.reducer";
import { surveyReducer } from "./survey.reducer";
import { airtimeFormReducer } from "./airtime.reducer";
import { transactionReducer } from "./transaction.reducer";
import { notificationReducer } from "./notifications.reducer";
import { errorHandlerReducer } from "./errorHandler.reducer";
import { bankServicesReducer } from "./bankServices.reducer";
import { electricityReducer } from "./electricity.reducer";
import { mobileDataReducer } from "./mobile-data.reducer";
import { newsReducer } from "./news.reducer";

const reducers = combineReducers({
  states: countryStateReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  referrals: referredUsersReducer,
  verifyEmail: VerifyEmailReducer,
  form: formReducer,
  forgot_password: forgotPasswordReducer,
  privacy: privacyReducer,
  passwordReset: resetPasswordReducer,
  survey: surveyReducer,
  airtimeForm: airtimeFormReducer,
  transactions: transactionReducer,
  notifications: notificationReducer,
  errorHandler: errorHandlerReducer,
  bankServices: bankServicesReducer,
  electricity: electricityReducer,
  mobileData: mobileDataReducer,
  news: newsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export default rootReducer;
