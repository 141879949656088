import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import congratulations_image from "../../../../assets/images/congratulation-image.svg";

import Button from "../../../../components/Button";

import "./payment-response.scss";

const ElectricityPaymentResponse = ({ token = "", isSuccessfull, points }) => {
  const [copied, setCopied] = useState(false);

  const history = useHistory();

  return (
    <div className="electricity__response__modal">
      <img
        className="electricity__response__modal-image"
        src={congratulations_image}
        alt="congratulations"
      />
      {/* Only display if token has been generated */}
      {isSuccessfull && (
        <>
          <h3 className="mb-3">AWESOME!</h3>
          <p>
            {points
              ? "Your transaction was successfull and has earned you"
              : "Your transaction was successfull"}
          </p>
          {points && (
            <p className="electricity__points">
              <span>{points}</span>Points!
            </p>
          )}
        </>
      )}

      <div className="electricity__token">
        {/* {!isSuccessfull ? (
                    <span className="token__generation__loader">
                        <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
                        <span className="ml-2">Generating token...</span>
                    </span>
                ) : (
                    <>
                        <input
                            type="text"
                            className="electricity__token-input"
                            readOnly
                            value={token}
                        />
                        <CopyToClipboard
                            onCopy={() => setCopied(!copied)}
                            text={token}
                        >
                            <p>
                                {copied
                                    ? "Copied!!!"
                                    : "Click here to copy your token"}
                            </p>
                        </CopyToClipboard>
                    </>
                )} */}

        {!isSuccessfull ? (
          <span className="token__generation__loader">
            <FontAwesomeIcon icon={"spinner"} className="fa-spin" />
          </span>
        ) : (
          <div>
            <p>
              Your token is being generated and will be sent to your email
              within 5 minutes.
            </p>
          </div>
        )}
      </div>
      <Button
        onClick={() => history.push("/activities/pay-bills")}
        className="btn btn--primary btn__electricity__success"
      >
        Return to bills
      </Button>
    </div>
  );
};

export default ElectricityPaymentResponse;
