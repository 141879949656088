import { useState } from "react";
import { secondaryComponents } from "..";
import { icons } from "../../../assets/icons";
import Button from "../../Button";
import "./TopupOptionModal.scss";

const TopupOptionModal = ({ showTopUpModal, setShowTopUpModal }) => {
  const [isUssdSection, setIsUssdSection] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);

  return (
    <secondaryComponents.Modal
      className="topup-option-modal"
      width="500px"
      isActive={showTopUpModal}
      closeModal={() => setShowTopUpModal(false)}
    >
      <div className="topup-modal">
        {!isUssdSection && !isBankTransfer && (
          <div>
            <h5 className="topup-modal__text1">Top up</h5>
            <div className="topup-modal__text2">
              <h4 className="topup-modal__text2-1">
                How would you like to fund your Lumi wallet?
              </h4>
              <p className="topup-modal__text2-2">
                Please, select any option that suits you
              </p>
            </div>
            <div className="topup-modal__funding-option">
              <Button
                className="topup-modal__wrapper"
                onClick={() => setIsBankTransfer(true)}
              >
                <div className="topup-modal__option">
                  <img
                    className="topup-modal__option-icon"
                    src={icons.bankTransfer}
                    alt="naira-currency-icon"
                  />
                  <p className="topup-modal__option-text">Bank Transfer</p>
                </div>
                <img
                  className="topup-modal__right-arrow-icon"
                  src={icons.rightArrow}
                  alt="right-arrow-icon"
                />
              </Button>
              <Button
                className="topup-modal__wrapper"
                onClick={() => setIsUssdSection(true)}
              >
                <div className="topup-modal__option">
                  <img
                    className="topup-modal__option-icon"
                    src={icons.nairaCurrency}
                    alt="naira-currency-icon"
                  />
                  <p className="topup-modal__option-text">USSD</p>
                </div>
                <img
                  className="topup-modal__right-arrow-icon"
                  src={icons.rightArrow}
                  alt="right-arrow-icon"
                />
              </Button>
            </div>
          </div>
        )}
        
        {isUssdSection && (
          <secondaryComponents.Ussd setIsUssdSection={setIsUssdSection} />
        )}
        {isBankTransfer && (
          <secondaryComponents.AccountDetails
            setIsBankTransfer={setIsBankTransfer}
          />
        )}
      </div>
    </secondaryComponents.Modal>
  );
};

export default TopupOptionModal;
