import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { nFormatter } from "../helpers/nFormatter";

const RewardSummary = () => {
    const dashboard = useSelector((state) => state.dashboard);
    const rewards_summary = dashboard.data !== null && dashboard.data;

    const {
        enlumi_points = 0,
        referrals = 0,
        claimed_rewards = 0,
    } = rewards_summary;

    return (
        <div className="reward__summary__mobile">
            <div className="reward__summary__mobile__summary">
                <p className="summary--title mb-3">Points</p>
                <h2 className="summary--value">{nFormatter(enlumi_points, 1)}</h2>
            </div>
            <div className="reward__summary__mobile__summary center">
                <p className="summary--title mb-3">Claimed (N)</p>
                <h2 className="summary--value">
                    N{claimed_rewards.toFixed(2)}
                </h2>
            </div>
            <div className="reward__summary__mobile__summary">
                <p className="summary--title mb-3">Refferals</p>
                <h2 className="summary--value">{referrals.toLocaleString()}</h2>
            </div>
        </div>
    );
};

export default RewardSummary;
