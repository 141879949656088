import httpClient from "../axiosBase/axiosHandler.js";

export const getPrivacyDetails = () => {
    const response = httpClient.get(`/settings/privacy/`);
    return response;
};

export const updatePrivacyDetails = (payload) => {
    const response = httpClient.post(`/settings/privacy/`, payload);
    return response;
};

export const deleteUserAccount = (payload) => {
    const response = httpClient.delete(`/users/delete/`, {data: payload});
    return response;
};
