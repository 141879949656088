import { nFormatter } from '../../helpers/nFormatter';

import "./UserAccountDetails.scss";
import bronze_medal_badge from '../../assets/icons/bronze-medal-icon.svg';
import gold_medal_badge from '../../assets/icons/gold-medal-icon.svg';
import silver_medal_badge from '../../assets/icons/silver-medal-icon.svg';

const renderBadge = (level) => {
    switch (level) {
        case 'bronze':
            return bronze_medal_badge;
        case 'gold':
            return gold_medal_badge;
        case 'silver':
            return silver_medal_badge;
        default:
            return null;
    }
};

const renderAccountType = (level) => {
    switch (level) {
        case 'bronze':
            return 'BRONZE MEMBER';
        case 'gold':
            return 'GOLD MEMBER';
        case 'silver':
            return 'SILVER MEMBER';
        default:
            return 'MEMBER';
    }
};

const UserAccountDetails = ({
    level,
    is_verified,
    lifetime_points,
    toggleModal,
}) => {
    return (
        <div className="user__account__details">
            <div className="user__badge">
                <img src={renderBadge(level)} alt="account badge" />
            </div>
            <div className="user__account">
                <div className="user__account__level white--text">
                    {renderAccountType(level)}
                </div>
                <div className="user__account__points">
                    {nFormatter(lifetime_points)} Points
                </div>
            </div>
            {is_verified ? (
                <div className="user__account__verification__status verified">
                    Verified
                </div>
            ) : (
                <p
                    onClick={() => toggleModal()}
                    className="account__verification__btn white--text mb-3"
                >
                    <span className="account__verification__btn-title">
                        Verify account
                    </span>
                    <span className="icon-arrow-right verify__icon"></span>
                </p>
            )}
        </div>
    );
};

export default UserAccountDetails;
