import congratulations_image from "../../../../assets/images/congratulation-image.svg";

import { useHistory } from "react-router-dom";

import Button from "../../../../components/Button";

import "./payment-response.scss";

const PaymentResponse = ({ points = 0 }) => {
    const history = useHistory();

    return (
        <div className="payment__response__modal">
            <img
                className="payment__response__modal-image"
                src={congratulations_image}
                alt="congratulations"
            />
            <h3 className="mb-3">AWESOME</h3>
            <p>
                {points
                    ? "Your transaction was successfull and has earned you"
                    : "Your transaction was successfull"}
            </p>
            {points && (
                <p className="mobile__data__points">
                    <span>{points}</span>Points!
                </p>
            )}
            <Button
                onClick={() => history.push("/activities/pay-bills")}
                className="btn btn--primary btn__data__success"
            >
                Return to bills
            </Button>
        </div>
    );
};

export default PaymentResponse;
