import { Controller } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';

const VerifyPin = ({ control, errors }) => {
    const { user } = useSelector((state) => state.auth);
    const { phone_number } = user !== null && user;

    return (
        <div className="verify-pin">
            <h2>Enter Verification Code</h2>
            <p>
                Kindly provide the 4- digit verification code sent <br /> to
                your phone number{"   "}
                <span style={{ color: '#766df4' }}>+234{phone_number}</span>
            </p>
            <Controller
                render={({ field: { value, onChange } }) => (
                    <OtpInput
                        className="verify-pin__input"
                        onChange={onChange}
                        value={value}
                        numInputs={4}
                    />
                )}
                control={control}
                name="code"
                defaultValue={''}
            />
            {errors.code && (
                <p className="mt-3 text-danger">{errors.code.message}</p>
            )}
        </div>
    );
};

export default VerifyPin;
