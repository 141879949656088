import Types from "../constants";

import {
    getNotifications,
    readAllNotifications,
} from "../actions/notifications.actions";
import { getAllNotifications } from "../../services/notification.service";

const { notificationTypes } = Types;
const { GET_NOTIFICATIONS, READ_ALL_NOTIFICATIONS } =
    notificationTypes;

const initialState = {
    data: [],
    all: [],
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                ...state,
                data: action.payload,
                all: action.payload,
                unread: [],
            };
        case READ_ALL_NOTIFICATIONS:
            return { ...state, all: [] };
        default:
            return state;
    }
};

export const fetchNotifications = () => {
    return (dispatch) => {
        getAllNotifications()
            .then((response) => {
                if (response) {
                    dispatch(getNotifications(response.data));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const markAllAsRead = (notifications) => {
    if (!notifications) return;

    return (dispatch) => {
        const _readNotifications = notifications.map((notification) => {
            return {
                ...notification,
                read: true,
            };
        });

        dispatch(readAllNotifications(_readNotifications));
    };
};
