import Types from "../constants";
import {
    resetPasswordRequest,
    resetPasswordFailure,
    resetPasswordSuccess,
} from "../actions/reset-password.actions";
import { resetPasswordService } from "../../services/auth.service";


const {
    resetPasswordTypes: {
        RESET_PASSWORD_FAILURE,
        RESET_PASSWORD_REQUEST,
        RESET_PASSWORD_SUCCESS,
    },
} = Types;


const initialState = {
    error: null,
    data: null,
    loading: false,
};

export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return { ...state, loading: true, data: null, error: null };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null,
            };
        default:
            return state;
    }
};

export const resetPassword = (token, payload) => {
    return (dispatch) => {
        dispatch(resetPasswordRequest());
        return new Promise((resolve, reject) => {
            resetPasswordService(token, payload)
                .then((response) => {
                    dispatch(resetPasswordSuccess(response.data));
                    resolve(response.data);
                })
                .catch((error) => {
                    ;
                    if (error.response) {
                        dispatch(resetPasswordFailure(error.response.data));
                        reject(error.response.data);
                    } else {
                        dispatch(resetPasswordFailure(error.message));
                        reject(error.message);
                    }
                });
        });
    };
};
