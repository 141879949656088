import Types from "../constants";

const { bankServicesTypes } = Types;

export const fetchBankListRequest = () => {
    return {
        type: bankServicesTypes.FETCH_BANKLIST_REQUEST,
    };
};

export const fetchBankListSuccess = (payload) => {
    return {
        type: bankServicesTypes.FETCH_BANKLIST_SUCCESS,
        payload: payload,
    };
};

export const fetchBankListFailure = (error) => {
    return {
        type: bankServicesTypes.FETCH_BANKLIST_FAILURE,
        payload: error,
    };
};