import React from "react";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import BiodataForm from "./Biodata/BiodataForm";
import LocationForm from "./Location/LocationForm";
import CreatePasswordForm from "./CreatePassword/CreatePasswordForm";
import CreatePin from "./CreatePin/CreatePin";
import SignupLayout from "../../Layouts/SignupLayout";
import { createUser } from "../../services/auth.service";
import { setAccessToken } from "../../redux/actions/auth.actions";
import GoogleRecaptchaV3 from "../../components/GoogleRecaptchaV3";

const Signup = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [biodata, setBiodata] = useState({});
  const [bioLocations, setLocations] = useState({});
  const [socialDetails, setSocialDetails] = useState({});
  const [passwords, setPasswords] = useState({});
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [subscribeUpdate, setSubscribeUpdate] = useState(true);
  const [transactionPin, setTransactionPin] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const recaptchaRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (biodata.email !== undefined) {
      setActiveStep(2);
    }
  }, [biodata]);

  useEffect(() => {
    if (bioLocations.state !== undefined) {
      if (
        socialDetails.registration_type === "google" ||
        socialDetails.registration_type === "facebook"
      ) {
        setActiveStep(4);
      } else {
        setActiveStep(3);
      }
    }
  }, [bioLocations, socialDetails.registration_type]);

  useEffect(() => {
    if (transactionPin.pin !== undefined) {
      if (
        socialDetails.registration_type === "google" ||
        socialDetails.registration_type === "facebook"
      ) {
        setLoading(true);
        recaptchaRef.current.handleLoaded();
      }
    }
  }, [socialDetails.registration_type, transactionPin.pin]);

  useEffect(() => {
    if (transactionPin.pin !== undefined) {
      if (
        socialDetails.registration_type !== "google" ||
        socialDetails.registration_type !== "facebook"
      ) {
        setLoading(true);
        recaptchaRef.current.handleLoaded();
      }
    }
  }, [transactionPin.pin, socialDetails.registration_type]);

  const getDataForSubmission = () => {
    let data = {};
    data.username = biodata.username;
    data.phone_number = biodata.phone_number;
    data.email = biodata.email;
    data.state = bioLocations.state;
    data.city = bioLocations.city;
    data.local_government = bioLocations.local_government;
    data.agree_terms = agreeTerms;
    data.subscribe_update = subscribeUpdate;
    data.pin = transactionPin.pin;

    if (biodata.referral_code !== "" || biodata.referral_code == null) {
      data.referral_code = biodata.referral_code;
    }
    if (
      socialDetails.registration_type === "google" ||
      socialDetails.registration_type === "facebook"
    ) {
      data.registration_type = socialDetails.registration_type;
      data.access_token = socialDetails.access_token;
    } else {
      data.password = passwords.password;
      data.confirm_password = passwords.confirm_password;
      data.registration_type = "enlumi";
    }
    return data;
  };

  const createAccount = (token) => {
    let data = getDataForSubmission();
    if (data.email === undefined) return;
    setLoading(true);
    data["g-recaptcha-response"] = token;

    createUser(data)
      .then((response) => {
        setLoading(false);
        const { access, refresh } = response.data;
        localStorage.setItem("refresh_token", refresh);
        dispatch(setAccessToken(access));
        if (
          socialDetails.registration_type === "google" ||
          socialDetails.registration_type === "facebook"
        )
          history.push("/dashboard");

        history.push("/verify");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setErrorMessage(error.response.data.detail);
        }

        if (!navigator.onLine) {
          setErrorMessage(
            "No internet connection, please check your network connectivity"
          );
        }
      });
  };

  return (
    <SignupLayout activeStep={activeStep}>
      <p className="mb-2 error--text text-center mt-4">{errorMessage}</p>

      {/* google recaptcha */}
      <GoogleRecaptchaV3
        ref={recaptchaRef}
        resolved={createAccount}
        onReset={() => setLoading(false)}
        page="signup"
      />

      {/* bio data form section */}
      <div className={`${activeStep !== 1 ? "d-none" : ""}`}>
        <BiodataForm
          setBiodata={setBiodata}
          setSocialDetails={setSocialDetails}
          socialDetails={socialDetails}
        />
      </div>
      {/* loacation form section */}
      <div className={`${activeStep !== 2 ? "d-none" : ""}`}>
        <LocationForm
          setActiveStep={setActiveStep}
          setLocations={setLocations}
          registrationType={socialDetails.registration_type}
          loading={loading}
          setAgreeTerms={setAgreeTerms}
          agreeTerms={agreeTerms}
          setSubscribeUpdate={setSubscribeUpdate}
          subscribeUpdate={subscribeUpdate}
        />
      </div>
      {/* createpasword form section */}
      <div className={`${activeStep !== 3 ? "d-none" : ""}`}>
        <CreatePasswordForm
          setActiveStep={setActiveStep}
          setPasswords={setPasswords}
          loading={loading}
          setAgreeTerms={setAgreeTerms}
          agreeTerms={agreeTerms}
          setSubscribeUpdate={setSubscribeUpdate}
          subscribeUpdate={subscribeUpdate}
          socialDetails={socialDetails}
        />
      </div>
      {activeStep === 4 ? (
        <CreatePin
          setActiveStep={setActiveStep}
          loading={loading}
          setAgreeTerms={setAgreeTerms}
          agreeTerms={agreeTerms}
          setSubscribeUpdate={setSubscribeUpdate}
          subscribeUpdate={subscribeUpdate}
          setTransactionPin={setTransactionPin}
          socialDetails={socialDetails}
        />
      ) : null}
    </SignupLayout>
  );
};
export default Signup;
