import { Link } from "react-router-dom";

import econdary_logo from "../assets/websiteImages/secondary-logo.svg";

const VerifyLayout = ({ children }) => {
    return (
        <div id="webapp">
            <div className="verify--layout">
                <div className="verify--layout-left">
                    <div className="verify--layout-left-container">
                        <Link className="verify--layout-logo" to="/">
                            <img src={econdary_logo} style={{width: '100px'}} alt="enlumi logo" />
                        </Link>
                        <h2 className="verify--layout-intro">
                            A few clicks away from creating your survey.
                        </h2>
                        <p className="verify--layout-description">
                            Create your survey in as easy as a few clicks, and
                            conveniently access your results.
                            <br />
                            <br />
                            Save time and costs!
                        </p>
                    </div>
                </div>
                <div className="verify--layout-right">
                    <div className="verify--layout-right-container">
                        <div className="verify--layout-nav">
                            <span>Already a user?</span>
                            <Link className="verify--layout-nav-link" to="/login">
                                Log in
                            </Link>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyLayout;
