import { RedirectToAppNotice } from "../../../hooks/context";
import { primaryComponents } from "../../primaryComponents";
import { useState } from "react";
import { Link } from "react-router-dom"
import "./AppNotice.scss";

const AppNotice = ({ children }) => {
  const [showAppNotice, setShowAppNotice] = useState(false);

  return (
    <RedirectToAppNotice.Provider value={setShowAppNotice}>
      {children}

      {showAppNotice && window.innerWidth <= 450 && (
        <div className="redirect-to-app">
          <h4 className="mb-1 white--text">Lumi is better on the app</h4>
          <p className="white--text">
            Enjoy other feature like quiz and get full experience. Open app now
          </p>

          <div className="d-flex justify-content-between mt-3">
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => setShowAppNotice(false)}
            >
              Not now
            </primaryComponents.Button>
            <Link
              to={{pathname:"https://zy8ka.app.link/lumirewards",}}
              target="_blank"
              style={{textDecoration: "none"}}
            >
              <primaryComponents.Button classNames="btn btn--outline">
                Switch to the app
              </primaryComponents.Button>
            </Link>
          </div>
        </div>
      )}
    </RedirectToAppNotice.Provider>
  );
};

export default AppNotice;
