import states from "../localdata/data.json";

const formatStates = () => {
    return Object.keys(states).map((state) => {
        return {
            state: state,
            ...states[state],
        };
    });
};

export const getStates = () => {
    const _states = formatStates().map((state) => {
        return state.state;
    });

    return _states;
};

export const getState = (selectedState) => {
    const _state = formatStates().filter((state) => {
        return state.state === selectedState;
    });

    return _state[0];
};
