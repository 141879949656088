import React from "react";
import { useEffect } from "react";
const { forwardRef, useImperativeHandle } = React;

const GoogleRecaptchaV3 = forwardRef(({ resolved, onReset, page }, ref) => {

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {
          action: page,
        })
        .then((token) => {
            resolved(token);
        }).catch(()=> onReset())
    });
  };

  useImperativeHandle(ref, () => ({ handleLoaded }));

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    //script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
      data-size="invisible"
    ></div>
  );
});

export default GoogleRecaptchaV3;
