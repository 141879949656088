import { useEffect, useRef } from "react";

export const useClickOutSide = (callback) => {
    const domNode = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (domNode.current && !domNode.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [domNode, callback]);

    return domNode;
};
