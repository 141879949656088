import Types from "../constants";

const { newsTypes } = Types;
const {
  FETCH_ALL_ARTICLES,
  FETCH_ARTICLES,
  FETCH_ARTICLE,
  FETCH_ARTICLE_LIKES_COUNT,
  FETCH_COMMENTS,
  ADD_COMMENT,
  LIKE_ARTICLE,
  NEWS_REQUEST_FAILURE,
} = newsTypes;

export const fetchAllArticles = (articles) => ({
  type: FETCH_ALL_ARTICLES,
  payload: articles,
});

export const fetchArticles = (articles) => ({
  type: FETCH_ARTICLES,
  payload: articles,
});

export const fetchArticle = (article) => ({
  type: FETCH_ARTICLE,
  payload: article,
});

export const fetchComments = (comments) => ({
  type: FETCH_COMMENTS,
  payload: comments,
});

export const fetchArticleLikesCount = (count) => ({
  type: FETCH_ARTICLE_LIKES_COUNT,
  payload: count,
});

export const addNewComment = (comment) => ({
  type: ADD_COMMENT,
  payload: comment,
});

export const likeArticle = (payload) => ({
  type: LIKE_ARTICLE,
  payload: payload,
});

export const requestFailure = (error) => ({
  type: NEWS_REQUEST_FAILURE,
  payload: error,
});
