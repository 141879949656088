import useModal from '../../../hooks/useModal';
import ModalClose from '../../ModalClose';
import Button from '../../Button';
// import TopUpWallet from '../../TopUpWallet';
import FundWallet from '../../FundWalletModal/FundWallet';
import NubanVerification from '../NubanVerification/NubanVerification';

import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

const RewardWalletLock = ({ isLocked, setIsRewardWalletLocked }) => {
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [verifyModalOpen, setVerifyModalOpen, toggleVerifyModal] = useModal();
    const [topupOpen, setTopupOpen, toggleTopupOpen, TopUpModal] = useModal();

    const { user } = useSelector((state) => state.auth);
    const username = user && user.username;
    const has_kyc = user && user.has_kyc;
    const history = useHistory();
    const {url} = useRouteMatch();

    return (
        <>
            <CustomModal
                closeModal={() => {
                    setIsRewardWalletLocked(false);
                }}
                isActive={isLocked}
                width="420px"
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header">
                        <ModalClose
                            onClick={() => {
                                setIsRewardWalletLocked(false);
                            }}
                        />
                    </div>
                    <div
                        style={{ textAlign: 'left' }}
                        className="wallet__lock px-3 pt-3"
                    >
                        <h2 className="mb-2 text-center">
                            Reward wallet is locked!
                        </h2>
                        <h4 className="text-center my-3">
                            Here is how to unlock
                        </h4>
                        <div className="mt-3">
                            <p className="mb-3">
                                1. Top up your naira wallet with any amount
                            </p>
                            <p className="mb-3">
                                2. Buy airtime, data, power or cable worth #200
                                or above with your naira wallet to unlock your
                                wallet for this week.
                            </p>
                            <p
                                style={{ fontWeight: 'normal' }}
                                className="purple--text"
                            >
                                N.B:{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    {username}
                                </span>
                                , please note that Rewards Wallets are locked
                                weekly. However, you can unlock it every week by
                                spending up to #200 on yourself from your Naira
                                wallet. Remember, the more you spend, the more
                                you earn
                            </p>
                        </div>
                        <Button
                            onClick={() => {
                                setIsRewardWalletLocked(false);
                                if (!has_kyc) {
                                    toggleVerifyModal();
                                } else {
                                    toggleTopupOpen();
                                }
                            }}
                            className="btn btn--primary mt-3"
                        >
                            Unlock Reward Wallet
                        </Button>
                    </div>
                </div>
            </CustomModal>
            <NubanVerification
                verifyModalOpen={verifyModalOpen}
                setVerifyModalOpen={setVerifyModalOpen}
            />
            <FundWallet setModalOpen={setTopupOpen} modalOpen={topupOpen} />
        </>
    );
};

export default RewardWalletLock;
