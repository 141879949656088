import Button from "./Button";
import airtime_data_icon from "../assets/icons/airtime-bills.svg";
import InviteFriends from "./InviteFriends";
import useModal from "../hooks/useModal";
import ModalClose from "../components/ModalClose";

const InviteFriendsRow = ({ inviteDetails }) => {

    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();

    // all css classes are present on survey-row.scss this is due to sharing of class properties
    return (
        <div>
            <div className="invite-friend-row__item">
                <div className="invite-friend-row__item__left-part">
                    <span className="survey-logo no-border-radius">
                        <img style={{ width: '80px' }} src={airtime_data_icon} alt="" />
                    </span>
                    <div>
                        <h5 className="survey--text">{inviteDetails.name}</h5>
                        <p className="description">{inviteDetails.description}</p>
                    </div>
                </div>
                <div className="invite-friend-row__item__right-part">
                    <span className="points">+{inviteDetails.point} points</span>
                    <Button className="btn btn--primary btn--activity" onClick={() => setModalOpen(true)}>
                        Invite now
                    </Button>
                </div>
            </div>

            <div className="invite-friend-row__item--mobile">
                <div className="invite-friend-row__item--mobile__top-section mb-3">
                    <span className="survey-logo no-border-radius">
                        <img style={{ width: '80px' }} src={airtime_data_icon} alt="" />
                    </span>
                    <span className="points">+{inviteDetails.point} pts</span>
                </div>
                <h5 className="survey--text">{inviteDetails.name}</h5>
                <span className="purple--text mt-2" onClick={() => setModalOpen(true)}>
                    <span>Invite now</span><span className="icon-arrow-right ml-2" style={{ fontSize: '10px' }}></span>
                </span>
            </div>

            <CustomModal
                isActive={modalOpen}
                handleClose={() => setModalOpen(false)}
                width={"600px"}
            >
                <div className="modal__container__content--invite">
                    <div className="modal__container__content__default-header mb-3">
                        <ModalClose onClick={() => setModalOpen(false)} />
                    </div>
                    <InviteFriends />
                </div>
            </CustomModal>
        </div>
    )
}
export default InviteFriendsRow;