import { useHistory } from "react-router-dom";

import Button from "../components/Button";

import not_found_img from "../assets/images/NotFound.png";

import "./notfound.scss";

const NotFoundPage = () => {
    const history = useHistory();

    return (
        <div className="notfound">
            <img src={not_found_img} alt="Page not found" />
            <h1>404 Not Found</h1>
            <p>You seem to be lost young one 😀</p>
            <Button
                onClick={() => history.goBack()}
                className="btn btn--primary btn--notfound"
            >
                Return
            </Button>
        </div>
    );
};

export default NotFoundPage;
