import { NavLink, Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { nFormatter } from '../helpers/nFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import nav_logo from '../assets/websiteImages/primary-logo.svg';
import notification_icon from '../assets/icons/notification.svg';
import user_avatar from '../assets/icons/profile-photo-icon.svg';
// import mobile_logo from "../assets/images/m-logo.png";
import mobile_bell from '../assets/images/notification-bell-alarm.svg';
import close_icon from '../assets/icons/close-icon.svg';

import Notifications from './Notifications';
import useAuth from '../hooks/useAuth';

import '../assets/sass/webapp/components/_navbar.scss';
import useModal from '../hooks/useModal';
import ShutDownModal from './ShutDownModal';
import useShutDown from '../hooks/useShutDown';

const Navbar = () => {
    const location = useLocation();
    const isAuth = useAuth();

    const [openNotifications, setOpenNotifications] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [menuBarList, setMenuBarList] = useState([
        '/privacy-policy',
        '/terms-and-condition',
        '/',
        '/about',
    ]);

    const toggle = () => {
        setOpenNotifications(!openNotifications);
    };

    const { dashboard, notifications } = useSelector((state) => state);
    const user_data = dashboard.data !== null && dashboard.data;

    const { enlumi_points = 0 } = user_data;

    const isPrivacy = location.pathname === '/privacy-policy';
    const isTermsAndConditions = location.pathname === '/terms-and-condition';

    const isNotifications =
        notifications.all
            .map((notification) => {
                return notification.read === false;
            })
            .reduce((arr, el) => {
                return arr.concat(el);
            }, []).length > 0;

    const [isShutDownNote, setIsShutDownNote] = useState(false)
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const {isShutDownDate} = useShutDown()

    return (
        <>
            <header
                style={{
                    position:
                        isPrivacy || isTermsAndConditions ? 'relative' : 'fixed',
                }}
            >
                <div className="navbar">
                    <Link to="/" className="navbar__brand">
                        <img src={nav_logo} alt="Enlumi Logo" />
                    </Link>
                    <div className="navbar__collapse">
                        <ul className="navbar__nav">
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    exact
                                    to={isAuth ? '/dashboard' : '/'}
                                >
                                    <span className="icon-home icon"></span>
                                    <span className="nav__link-text">
                                        {isAuth ? 'Home' : 'Home'}
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    to={isAuth ? '/rewards' : '/about'}
                                >
                                    {isAuth ? (
                                        <span className="icon-gift icon"></span>
                                    ) : (
                                        <span className="icon-info icon"></span>
                                    )}
                                    <span className="nav__link-text">
                                        {isAuth ? 'Rewards' : 'About'}
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink className="nav__link" to="/activities">
                                    <span className="icon-activities icon"></span>
                                    <span className="nav__link-text">
                                        Activities
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    to={{ pathname: '/activities/pay-bills' }}
                                    exact
                                >
                                    <span className="icon-paybills icon"></span>
                                    <span className="nav__link-text">
                                        Pay bills
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    to={{
                                        pathname: isAuth
                                            ? '/activities/pay-bills/airtime'
                                            : 'https://help.enlumidata.com/kb/en',
                                    }}
                                    target={!isAuth ? '_blank' : '_self'}
                                    exact
                                >
                                    <span className="icon-cell-phone icon"></span>
                                    <span className="nav__link-text">
                                        {isAuth ? 'Airtime & Data' : 'Help'}
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar__actions">
                        {isAuth && (
                            <div className="points">
                                <span className="points__count">
                                    {nFormatter(enlumi_points, 1)} pts
                                </span>
                            </div>
                        )}
                        {isAuth && (
                            <div className="notifications">
                                <button
                                    onClick={toggle}
                                    className="notifications__container"
                                >
                                    <img
                                        src={notification_icon}
                                        alt="notification icon"
                                    />
                                    {isNotifications && (
                                        <span className="notifications__status"></span>
                                    )}
                                </button>
                            </div>
                        )}
                        {isAuth && (
                            <div className="user__profile">
                                <NavLink
                                    to="/settings"
                                    className="user__profile-avatar"
                                >
                                    <img src={user_avatar} alt="user avatar" />
                                </NavLink>
                            </div>
                        )}
                        {!isAuth && (
                            <div className="account__btn__group">
                                <Link
                                    className="account__btn__group__login mt-2"
                                    to={isShutDownDate?"":'/login'}
                                    onClick={()=> setIsShutDownNote(true)}

                                >
                                    Login
                                </Link>
                                <Link
                                    className="account__btn__group__signup"
                                    to={{ pathname: "https://play.google.com/store/apps/details?id=com.getlumi" }}
                                    target="_blank"
                                >
                                    Download App
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
                {location.pathname !== '/activities/surveys/start' ? (
                    <div className="navbar--mobile">
                        {isAuth && !menuBarList.includes(location.pathname) ? (
                            <div className="points">
                                <span className="points__count">
                                    {nFormatter(enlumi_points, 1)} pts
                                </span>
                            </div>
                        ) : (
                            <div className="navbar__toggle">
                                <FontAwesomeIcon
                                    onClick={() => setOpenDrawer(true)}
                                    icon="bars"
                                    size="lg"
                                />
                            </div>
                        )}
                        <Link to="/">
                            <img
                                className="navbar--mobile__logo"
                                src={nav_logo}
                                alt=""
                            />
                        </Link>
                        {isAuth ? (
                            <div
                                className="navbar--mobile__notification"
                                onClick={toggle}
                            >
                                <img src={mobile_bell} alt="" />
                                <span className="status"></span>
                            </div>
                        ) : (
                            <Link
                                to={isShutDownDate?"":"/signup"}
                                onClick={()=> setIsShutDownNote(true)}
                                className="navbar--mobile__signup__btn"
                            >
                                Create account
                            </Link>
                        )}
                    </div>
                ) : null}
                <div
                    className={`side__drawer ${
                        openDrawer ? 'side__drawer__active' : ''
                    }`}
                >
                    <div
                        onClick={() => setOpenDrawer(false)}
                        className="side__drawer__backdrop"
                    ></div>
                    <div className="side__drawer__content">
                        <div className="side__drawer__toggle">
                            <img
                                onClick={() => setOpenDrawer(false)}
                                src={close_icon}
                                alt="close-drawer"
                            />
                        </div>
                        <div className="side__drawer__links">
                            <Link
                                onClick={() => setOpenDrawer(false)}
                                className="side__drawer__link"
                                to="/"
                            >
                                Home
                            </Link>
                            <Link
                                onClick={() => setOpenDrawer(false)}
                                className="side__drawer__link"
                                to="/about"
                            >
                                About Us
                            </Link>
                            <Link
                                onClick={() => setOpenDrawer(false)}
                                className="side__drawer__link"
                                to="/activities"
                            >
                                Activities
                            </Link>
                            <Link
                                onClick={() => setOpenDrawer(false)}
                                className="side__drawer__link"
                                to="/contact"
                            >
                                Contact Us
                            </Link>
                            <Link
                                onClick={() => setOpenDrawer(false)}
                                className="side__drawer__link"
                                to={{
                                    pathname: 'https://help.enlumidata.com/kb/en',
                                }}
                                target={'_blank'}
                            >
                                Help
                            </Link>
                            <Link
                                onClick={() => {
                                    setOpenDrawer(false);  
                                    setIsShutDownNote(true)
                                }}
                                className="side__drawer__link"
                                to={isShutDownDate?"":'/login'}
                            >
                                Login
                            </Link>
                        </div>
                    </div>
                </div>
                {isAuth && (
                    <Notifications toggle={toggle} show={openNotifications} />
                )}
            </header>

            <CustomModal
                isActive={isShutDownNote}
                closeModal={() => {}}
                width={'700px'}
            >
                <ShutDownModal closeModal={()=> {}} />
            </CustomModal>
        </>
    );
};

export default Navbar;
