import { Route } from "react-router-dom";
// import Header from "../components/WebsiteComponents/Header";
import Footer from "../components/WebsiteComponents/Footer";
import Navbar from "../components/Navbar";

const WebsiteLayout = ({ component: Component, background }) => {
  return (
    <Route
      render={() => {
        return (
          <div style={{ background: background }}>
            {/* <Header /> */}
            <div id="webapp">
              <Navbar />
            </div>
            <div id="website">
              <Component />
              <Footer />
            </div>
          </div>
        );
      }}
    />
  );
};
export default WebsiteLayout;
