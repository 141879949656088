import { useRouteMatch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

import arrow_left from '../../../assets/icons/arrow-left.svg';
import cable_tv from '../../../assets/icons/cable-tv-icon.svg';
import data_purchase from '../../../assets/icons/wifi-icon.svg';
import electricity_bill from '../../../assets/icons/electricity-bill-icon.svg';
import lottery_betting from '../../../assets/icons/lottery-icon.svg';
import mobile_topup from '../../../assets/icons/phone-icon.svg';

import FirstAirtime from '../../../components/PositiveActionModals/FirstAirtime';
import FirstData from '../../../components/PositiveActionModals/FirstData';
import FirstCable from '../../../components/PositiveActionModals/FirstCable';
import FirstElectricity from '../../../components/PositiveActionModals/FirstElectricity';
import WeeklySpending from '../../../components/PositiveActionModals/WeeklySpending';
import { useSelector } from 'react-redux';

const Bills = () => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const dashboard = useSelector((state) => state.dashboard);
    const user_data = dashboard.data !== null && dashboard.data;
    const { username } = user_data;

    const [isFirstPurchase] = useState(
        JSON.parse(localStorage.getItem('isFirstTimePurchase')) || false
    );

    return (
        <div className="bills">
            <div className="bills__header" onClick={() => history.goBack()}>
                <img src={arrow_left} alt="back" />
                <div>
                    <h2 className="bills__heading">Bills Payment</h2>
                </div>
            </div>
            <p className="bills__description">
                Select the type of payment you want to make.
            </p>
            <div className="bills__items">
                {/* mobile top */}
                <div
                    onClick={() =>
                        history.push('/activities/pay-bills/airtime')
                    }
                    className="bill"
                >
                    <div className="bill__icon topup">
                        <img src={mobile_topup} alt="Mobile Top-up" />
                    </div>
                    <h4 className="bill__title">Mobile Top-up</h4>
                </div>
                {/* data purchase */}
                <div
                    onClick={() => history.push(`${url}/mobile-data`)}
                    className="bill"
                >
                    <div className="bill__icon data">
                        <img src={data_purchase} alt="data subscription" />
                    </div>
                    <h4 className="bill__title">Data Purchase</h4>
                </div>
                {/* electricity bill */}
                <div
                    onClick={() => history.push(`${url}/electricity`)}
                    className="bill"
                >
                    <div className="bill__icon electricity-icon">
                        <img
                            src={electricity_bill}
                            alt="electricity bill payment"
                        />
                    </div>
                    <h4 className="bill__title">Electricity Bill</h4>
                </div>
                {/* cable section */}
                <div
                    className="bill"
                    onClick={() => history.push(`${url}/cable`)}
                >
                    <div className="bill__icon cable">
                        <img src={cable_tv} alt="cable subscription" />
                    </div>
                    <h4 className="bill__title">Cable TV</h4>
                </div>
                {/* lottery section comming soon */}
                <div className="bill">
                    <div className="bill__icon lottery">
                        <img src={lottery_betting} alt="lottery and betting" />
                    </div>
                    <h4 className="bill__title">Lottery & Betting</h4>
                    <p>(coming soon)</p>
                </div>

                <FirstAirtime
                    isFirstTime={isFirstPurchase.isFirstAirtime}
                    username={username}
                />
                <FirstData
                    isFirstTime={isFirstPurchase.isFirstMobileData}
                    username={username}
                />
                <FirstCable
                    isFirstTime={isFirstPurchase.isFirstCable}
                    username={username}
                />
                <FirstElectricity
                    isFirstTime={isFirstPurchase.isFirstPower}
                    username={username}
                />
                <WeeklySpending
                    isFirstTime={isFirstPurchase.isWeeklySpending}
                    username={username}
                />
            </div>
        </div>
    );
};

export default Bills;
