import './biodata-settings.scss';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetchCountryStates,
    fetchState,
} from '../../../redux/reducers/country-states.reducer';
import { getUserDetails } from '.././../../redux/reducers/auth.reducer';
import { updateUserDetails } from '.././../../services/auth.service';
import useModal from '../../../hooks/useModal';
import FormGroup from '../../../components/FormGroup';
import Label from '../../../components/Label';
import InputField from '../../../components/InputField';
import Form from '../../../components/Form';
import SelectInput from '../../../components/SelectInput';
import Button from '../../../components/Button';
import ResponseModal from '../../../components/ResponseModal';
import PhoneInput from '../../../components/PhoneInput';
import ModalClose from '../../../components/ModalClose';
import ErrorText from '../../../components/ErrorText';

const BiodataSettings = ({ refreshPage }) => {
    const biodataSchema = yup.object().shape({
        state: yup.string().nullable().required('Select a state'),
        city: yup.string().nullable().required('Select nearest city'),
        local_government: yup
            .string()
            .nullable()
            .required('Select local government'),
        phone_number: yup
            .string()
            .matches(/^[0-9]+$/, 'Phone number must contain only digits')
            .test(
                'num',
                'Invalid phone number format',
                (val) => !val.startsWith('0')
            )
            .min(10, 'Invalid phone number format')
            .max(10, 'Invalid phone number format')
            .required('Phone number is required'),
    });

    const {
        control,
        reset,
        handleSubmit,
        register,
        watch,
        setError,
        formState: { errors },
    } = useForm({ resolver: yupResolver(biodataSchema) });

    const dispatch = useDispatch();
    const { states, selectedState } = useSelector((state) => state.states);
    const { user } = useSelector((state) => state.auth);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusImage, setStatusImage] = useState('fail');
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();

    const onSubmit = (data) => {
        const {
            state = '',
            local_government = '',
            city = '',
            phone_number = '',
        } = data;
        const payload = { state, local_government, city, phone_number };
        setUpdateLoader(true);
        updateUserDetails(payload)
            .then(() => {
                setUpdateLoader(false);
                dispatch(getUserDetails());
                //refreshPage();
                setStatusImage('pass');
                setStatusMessage('Biodata updated successfully');
                setModalOpen(true);
            })
            .catch((error) => {
                setUpdateLoader(false);
                if (error.response) {
                    if (error.response.status === 400) {
                        setStatusImage('fail');
                        setStatusMessage(error.response.data.detail);
                    }
                    if (error.response.status === 422) {
                        setError(
                            'phone_number',
                            {
                                type: 'validate',
                                message:
                                    error.response.data.errors.phone_number[0],
                            },
                            { shouldFocus: true }
                        );
                    }
                }
            });
    };

    useEffect(() => {
        reset({
            email: user !== null && user.email,
            username: user !== null && user.username,
            phone_number: user !== null && user.phone_number && user.phone_number.charAt(0) === "0" ? user.phone_number.substring(1): user.phone_number,
            state: user !== null && user.state,
            city: user !== null && user.city,
            local_government: user !== null && user.local_government,
        });
    }, [user, reset]);

    useEffect(() => {
        dispatch(fetchCountryStates());

        const subscription = watch((value) => {
            if (value.state) {
                dispatch(fetchState(value.state));
            }
        });

        return () => subscription.unsubscribe();
    }, [dispatch, watch]);

    return (
        <div className="biodata--settings">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="biodata--settings-container">
                    <div className="personal--details">
                        <h4 className="personal--details-heading">
                            Personal Details
                        </h4>
                        <div className="personal--details-container">
                            <div className="personal--details-container-top">
                                <FormGroup>
                                    <Label>Email</Label>

                                    <InputField
                                        className="disabled--input"
                                        disabled={true}
                                        label="email"
                                        register={register}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Phone Number</Label>
                                    <PhoneInput
                                        label="phone_number"
                                        register={register}
                                        required
                                        error={errors.phone_number}
                                        readOnly={user?.phone_number_verified}
                                        disabled={user?.phone_number_verified}
                                    />
                                </FormGroup>
                            </div>
                            <div className="personal--details-container-bottom">
                                <FormGroup>
                                    <Label>Username</Label>
                                    <InputField
                                        className="disabled--input"
                                        disabled={true}
                                        label="username"
                                        register={register}
                                        required
                                        type="text"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="location--details">
                        <h4 className="location--details-heading">Location</h4>
                        <div className="location--details-container">
                            <div className="location--details-container-top">
                                <FormGroup>
                                    <Label>State</Label>
                                    <Controller
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <SelectInput
                                                options={states}
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                        control={control}
                                        name="state"
                                        defaultValue=""
                                    />
                                    {errors.state && (
                                        <ErrorText
                                            message={errors.state.message}
                                        />
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Local Government Area</Label>
                                    <Controller
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <SelectInput
                                                options={
                                                    selectedState !== null
                                                        ? selectedState.lgas
                                                        : []
                                                }
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                        control={control}
                                        name="local_government"
                                        defaultValue=""
                                    />
                                    {errors.local_government && (
                                        <ErrorText
                                            message={
                                                errors.local_government.message
                                            }
                                        />
                                    )}
                                </FormGroup>
                            </div>
                            <div className="location--details-container-bottom">
                                <FormGroup>
                                    <Label>Nearest City or Town</Label>
                                    <Controller
                                        render={({
                                            field: { value, onChange },
                                        }) => (
                                            <SelectInput
                                                options={
                                                    selectedState !== null
                                                        ? selectedState.cities
                                                            ? selectedState.cities
                                                            : []
                                                        : []
                                                }
                                                value={value}
                                                onChange={onChange}
                                            />
                                        )}
                                        control={control}
                                        name="city"
                                        defaultValue=""
                                    />
                                    {errors.city && (
                                        <ErrorText
                                            message={errors.city.message}
                                        />
                                    )}
                                </FormGroup>
                            </div>
                        </div>
                    </div>

                    <Button
                        className="btn btn--primary btn--biodata-settings"
                        disabled={updateLoader}
                    >
                        {updateLoader ? (
                            <span>
                                <FontAwesomeIcon
                                    icon="spinner"
                                    className="fa-spin mr-2"
                                />
                                Saving...
                            </span>
                        ) : (
                            'Save Changes'
                        )}
                    </Button>
                    <CustomModal
                        isActive={modalOpen}
                        handleClose={() => setModalOpen(false)}
                        width={'320px'}
                    >
                        <div className="modal__container__content">
                            <div className="modal__container__content__default-header mb-3">
                                <ModalClose
                                    onClick={() => setModalOpen(false)}
                                />
                            </div>
                            <ResponseModal
                                message={statusMessage}
                                status={statusImage}
                            />
                        </div>
                    </CustomModal>
                </div>
            </Form>
        </div>
    );
};

export default BiodataSettings;
