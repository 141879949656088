import { useStep } from 'react-hooks-helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { resetPin } from '../../../../services/transaction-pin.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useModal from '../../../../hooks/useModal';
import { useHistory } from 'react-router-dom';

import Form from '../../../../components/Form';
import Button from '../../../../components/Button';
import VerifyPin from './VerifyPin';
import NewPin from './NewPin';
import ConfirmPin from './ConfirmPin';
import ResponseModal from '../../../../components/ResponseModal';
import ModalClose from '../../../../components/ModalClose';

import dialog_img from '../../../../assets/icons/celebration-confetti.svg';

const ResetPinForm = () => {
    const steps = [
        { id: 1, title: 'Verify Pin' },
        { id: 2, title: 'New Pin' },
        { id: 3, title: 'Confirm Pin' },
    ];

    const schema = yup.object().shape({
        code: yup
            .string()
            .min(4, 'Invalid verification code format')
            .required('Verification code is required'),
        new_pin: yup
            .string(),
        confirm_pin: yup
            .string(),
    });

    const { navigation, step } = useStep({ steps, initialStep: 0 });
    const [loading, setLoading] = useState(false);
    const [resetPinError, setResetPinError] = useState(null);
    const [modalOpen, setModalOpen, toggleModal, CustomModal] = useModal();
    const [errorModalOpen, setErrorModalOpen, toggleErrorModal] = useModal();
    const history = useHistory();

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            code: '',
            new_pin: '',
            confirm_pin: '',
        },
    });

    function renderSteps() {
        switch (step.title) {
            case 'Verify Pin':
                return (
                    <VerifyPin
                        control={control}
                        errors={errors}
                        navigation={navigation}
                    />
                );
            case 'Confirm Pin':
                return (
                    <ConfirmPin
                        control={control}
                        errors={errors}
                        navigation={navigation}
                    />
                );
            case 'New Pin':
                return (
                    <NewPin
                        control={control}
                        errors={errors}
                        navigation={navigation}
                    />
                );
            default:
                return null;
        }
    }

    const handleClick = () => {
        if (step.title === 'Verify Pin' && errors.code) return
        if (step.title === 'New Pin' && errors.new_pin) return
        if (step.title === 'Confirm Pin' && errors.confirm_pin) return;
        navigation.next();
    };

    const handleResetPin = async (data) => {
        setLoading(true);
        try {
            const res = await resetPin(data);
            if (res) {
                setLoading(false);
                toggleModal();
            }
        } catch (error) {
            setLoading(false);
            if (error.response) {
                if (error.response.data) {
                    const message = error.response.data.detail;
                    setResetPinError(message);
                    toggleErrorModal();
                }
            } else {
                setResetPinError(error.message);
            }
        }
    };

    const onSubmit = async (data) => {
        await handleResetPin(data);
    };

    return (
        <>
            <Form className="reset-pin__form" onSubmit={handleSubmit(onSubmit)}>
                {renderSteps()}
                {step.id === 3 || step.title === 'Confirm Pin' ? (
                    <Button
                        disabled={loading}
                        type={'submit'}
                        className="btn btn--primary mt-4"
                    >
                        {loading ? (
                            <FontAwesomeIcon
                                icon={'spinner'}
                                className="fa-spin mr-2"
                            />
                        ) : null}
                        <span>{loading ? 'Resetting Pin...' : 'Finish'}</span>
                    </Button>
                ) : (
                    <Button
                        onClick={handleClick}
                        type={'button'}
                        className="btn btn--primary mt-4"
                    >
                        Next
                    </Button>
                )}
            </Form>
            <CustomModal
                isActive={errorModalOpen}
                closeModal={() => setErrorModalOpen(false)}
                width={'350px'}
            >
                <div className="modal__container__content">
                    <div className="modal__container__content__default-header">
                        <ModalClose onClick={() => setErrorModalOpen(false)} />
                    </div>
                    <ResponseModal status={'fail'} message={resetPinError} />
                </div>
            </CustomModal>
            <CustomModal
                isActive={modalOpen}
                closeModal={() => setModalOpen(false)}
                width="400px"
            >
                <div className="change-pin__success-dialog">
                    <img src={dialog_img} alt="" />
                    <div className="change-pin__success-dialog__content">
                        <h3>Changes saved</h3>
                        <p className="my-3">
                            You can now make use of your new <br /> PIN for all
                            transactions
                        </p>
                        <Button
                            onClick={() =>
                                history.push('/settings/transaction-pin')
                            }
                            className="btn btn--primary"
                        >
                            Return to Settings
                        </Button>
                    </div>
                </div>
            </CustomModal>
        </>
    );
};

export default ResetPinForm;
